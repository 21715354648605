import axios from 'axios';
import { TTP_API_URL } from 'Config';
import { throwCatchedError } from 'utils';


export const getOrganizationScore = (token, instanceId = null, organizationId = 0) => {
  const requestUrl = `${TTP_API_URL}/survey/ranking/organization/${organizationId}/nps`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      instance: instanceId,
    },
  }).catch((thrown) => { throwCatchedError(thrown); });
};
