import * as actions from 'actions';
import { getRankingClients, getRankingClientsOptions } from 'TamtamAPI';

export const fetchRankingClients = (instanceId, organization) => (dispatch, getState) => {
  const { token: userToken, appToken, currentClient } = getState().auth;
  const { country, city, clientName, pageSize, paginationPage } = getState().rankingClients.filter.filter;
  const { sortConfig } = getState().rankingClients.sort;

  const token = userToken != '' ? userToken : appToken.token;
  const organizationId = organization ?? currentClient?.id ?? 0;
  const data = {
    pageSize, paginationPage, country, city, clientName, sortConfig, organizationId,
  };
  return dispatch(actions.fetchRankingClients(
    getRankingClients(token, instanceId, data),
  ));
};

export const fetchRankingClientsOptions = () => (dispatch, getState) => {
  const { token: userToken, appToken } = getState().auth;

  const token = userToken != '' ? userToken : appToken.token;
  return dispatch(actions.fetchRankingClientsOptions(
    getRankingClientsOptions(token).then(res => res.data, err => err),
  ));
};
