const MY_RANKING = 'score';
const CAMPAIGN_STATS = 'campaign-stats';
const STATS = 'stats';
const RESULTS = 'results';
const ORGANIZATION_PARTICIPANTS = 'org-participants';
const USER_PARTICIPANTS = 'user-participants';
const USER_RESPONSES = 'user-responses';
const RANKING_ORGANIZATIONS = 'ranking-organizations';
const RANKING_CLIENTS = 'ranking-clients';

const OPINION_TABS = [USER_PARTICIPANTS, STATS, ORGANIZATION_PARTICIPANTS, CAMPAIGN_STATS];
const SATISFACTION_TABS = [USER_PARTICIPANTS, MY_RANKING, STATS, ORGANIZATION_PARTICIPANTS, RANKING_ORGANIZATIONS, RANKING_CLIENTS, RESULTS, CAMPAIGN_STATS, USER_RESPONSES];
const CUSTOM_TABS = [USER_PARTICIPANTS, STATS, CAMPAIGN_STATS];
const PORTAL_TABS = [STATS];
const QUIZ_TABS = [STATS];


export {
  MY_RANKING,
  STATS,
  CAMPAIGN_STATS,
  RESULTS,
  ORGANIZATION_PARTICIPANTS,
  RANKING_ORGANIZATIONS,
  RANKING_CLIENTS,
  OPINION_TABS,
  SATISFACTION_TABS,
  PORTAL_TABS,
  QUIZ_TABS,
  CUSTOM_TABS,
  USER_PARTICIPANTS,
  USER_RESPONSES
};
