import React, { Component } from 'react';
import { getUserNameForAvatar } from 'utils';
import _ from 'i18n';

export default class User extends Component {
  selectUser() {
    const { id } = this.props;
    const el = document.getElementById(`avatar-${id}`).classList;
    el.toggle('selected', !el.contains('selected'));

    const iconCheck = document.getElementById(`check-${id}`).classList;
    setTimeout(() => {
      iconCheck.toggle('active', !iconCheck.contains('active'));
    }, 300);

    const settingsIcon = document.getElementsByClassName('bubble__settings').item(0).classList;
    const selectedEl = document.getElementsByClassName('selected');
    const avatarEl = document.getElementsByClassName('employee');
    const selectAll = document.getElementsByClassName('bubble__select-all').item(0);
    const childI = selectAll.getElementsByTagName('i').item(0).classList;
    const childSpan = selectAll.getElementsByTagName('span').item(0);

    if (selectedEl.length > 0) {
      if (selectedEl.length == avatarEl.length && !childI.contains('icon-close')) {
        selectAll.classList.add('deselect');
        childI.add('icon-close');
        childI.remove('icon-check');
        childSpan.textContent = _('Deselect All');
      } else if (!childI.contains('icon-check')) {
        selectAll.classList.remove('deselect');
        childI.remove('icon-close');
        childI.add('icon-check');
        childSpan.textContent = _('Select All');
      }
      if (!settingsIcon.contains('active')) {
        settingsIcon.add('active');
      }
    } else if (settingsIcon.contains('active')) {
      settingsIcon.remove('active');
    }
  }

  render() {
    const {
      id, firstName, lastName, avatarUrl, role, surveyAuthorisation
    } = this.props;

    let avatarDiv = null;
    const checkDiv = (
      <div className="user-check">
        <i id={`check-${id}`} className="icon icon-check" />
      </div>
    );
    if (avatarUrl != undefined) {
      avatarDiv = (
        <div
          id={`avatar-${id}`}
          className="user-avatar employee"
          onClick={this.selectUser.bind(this)}
          style={{ backgroundImage: `url(${avatarUrl})` }}
        >
          {checkDiv}
        </div>
      );
    } else {
      avatarDiv = (
        <div id={`avatar-${id}`} className="user-avatar empty-avatar employee" onClick={this.selectUser.bind(this)}>
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
          {checkDiv}
        </div>
      );
    }

    const signatureDiv = [];
    signatureDiv.push(<h3 key="h3">{`${firstName} ${lastName}`}</h3>);
    /*         if (role && role.id != undefined) {
            signatureDiv.push(<h4 key="h4"><span>{_(role.type)}</span></h4>);
        } */
    signatureDiv.push(<h4 key="h4"><span>{(role && role.id != undefined) ? _(role.type) : '____'}</span></h4>);
    signatureDiv.push(<h5 key="h5">
      {(surveyAuthorisation && surveyAuthorisation.role != undefined) ? _(surveyAuthorisation.role) : '____'}
    </h5>); // eslint-disable-line react/jsx-closing-tag-location

    return (
      <div className="user column small-12 medium-6 large-4">
        {avatarDiv}
        <div className="user-header">
          {signatureDiv}
        </div>
      </div>
    );
  }
}
