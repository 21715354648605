import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentNavPage, setCurrentSurvey } from 'actions';
import { fetchSurvey } from 'thunks';
import { getTextByLanguage } from 'surveyUtils';
import {
  MY_RANKING,
  STATS,
  CAMPAIGN_STATS,
  // ORGANIZATION_PARTICIPANTS,
  RANKING_ORGANIZATIONS,
  RANKING_CLIENTS,
  RESULTS,
  OPINION_TABS,
  SATISFACTION_TABS,
  CUSTOM_TABS,
  USER_PARTICIPANTS,
  USER_RESPONSES,
} from 'constants';
import _ from 'i18n';
import Tab from './stats/Tab';
import Views from './stats/Views';
import NotFoundPage from './NotFoundPage';

const LEGAL_TABS = [...OPINION_TABS, ...SATISFACTION_TABS, ...CUSTOM_TABS];
const DEFAULT_TAB = STATS;
const tabs = (pathname, organization, lng) =>
  [
    {
      label: _('Stats'),
      title: _('Stats'),
      key: STATS,
      goTo: { pathname, search: `?tab=${STATS}` },
    },
    {
      label: _('Participants'),
      title: _('Participants'),
      key: USER_PARTICIPANTS,
      goTo: { pathname, search: `?tab=${USER_PARTICIPANTS}` },
    },
    {
      label: _('Campaign stats'),
      title: _('Campaign stats'),
      key: CAMPAIGN_STATS,
      goTo: { pathname, search: `?tab=${CAMPAIGN_STATS}` },
    },
    {
      label: _('Results'),
      title: _('Results'),
      key: RESULTS,
      goTo: { pathname, search: `?tab=${RESULTS}` },
    },
    {
      label: _('My organization'),
      title: _('My organization'),
      key: MY_RANKING,
      goTo: { pathname, search: `?tab=${MY_RANKING}` },
    },
    {
      label: _('Collaborators satisfaction'),
      title: _('Collaborators satisfaction'),
      key: USER_RESPONSES,
      goTo: { pathname, search: `?tab=${USER_RESPONSES}` },
    },
    {
      label: _('Ranking organizations'),
      title: _('Stats organizations'),
      key: RANKING_ORGANIZATIONS,
      goTo: { pathname, search: `?tab=${RANKING_ORGANIZATIONS}` },
    },
    {
      label: _('Clients satisfaction'),
      title: _('Clients satisfaction'),
      key: RANKING_CLIENTS,
      goTo: {
        pathname,
        search: `?tab=${RANKING_CLIENTS}`,
      },
    },
    // { label: _('Participated organizations'), title: _('Participants'), key: ORGANIZATION_PARTICIPANTS, goTo: { pathname, search: `?tab=${ORGANIZATION_PARTICIPANTS}` } },
  ].map(({ goTo, ...tab }) => ({
    ...tab,
    goTo: {
      ...goTo,
      search: `${goTo.search}&organization=${organization}&lng=${lng}`,
    },
  }));

class Stats extends Component {
  constructor(props) {
    super(props);
    const { params } = this.props.match;
    this.surveySlug = params.slug;
    this.state = { activeMenu: null, organization: null };
  }

  componentDidMount() {
    const {
      history,
      location: { pathname },
    } = this.props;
    const survey = this.findSurvey();
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');

    if (
      LEGAL_TABS.indexOf(tab) !== -1 ||
      (tab && tab.match(new RegExp(`${RESULTS}-\\d+`)))
    ) {
      const organization = params.get('organization');
      const dimension = params.get('dimension');
      this.setState({ activeMenu: tab, organization, dimension });
    } else {
      history.push({ pathname, search: `?tab=${DEFAULT_TAB}` });
    }

    if (
      this.surveySlug !== survey.slug ||
      !survey.dimensions ||
      !survey.latestInstance
    ) {
      // questions (dimensions) are needed as filters (isProfile)
      this.props.fetchSurvey(this.surveySlug);
    }
    this.props.setCurrentNavPage('STATS');
  }

  componentDidUpdate(prevProps, prevState) {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    const organization = params.get('organization');
    const dimension = params.get('dimension');

    if (
      prevState.activeMenu != tab ||
      prevState.organization != organization ||
      prevState.dimension != dimension
    ) {
      this.setState({
        // eslint-disable-line react/no-did-update-set-state
        activeMenu:
          LEGAL_TABS.indexOf(tab) !== -1 ||
          (tab && tab.match(new RegExp(`${RESULTS}-\\d+`)))
            ? tab
            : STATS,
        organization,
        dimension,
      });
    }
  }

  findSurvey = () => {
    const { items, setCurrentSurvey, survey } = this.props;
    const currentSurvey =
      items && items.find(item => item.slug === this.surveySlug);

    if (currentSurvey != null && currentSurvey.id !== survey.id) {
      setCurrentSurvey(currentSurvey);
    }
    if (currentSurvey != null) return currentSurvey;

    return survey;
  };

  tabChilds = (pathname, organization, language) => {
    const { survey, lng } = this.props;

    if (!survey || !Array.isArray(survey.dimensions)) {
      return null;
    }

    return survey.dimensions
      .filter(dimension => !dimension.isProfile)
      .map(dimension => ({
        label: getTextByLanguage(dimension.title, lng),
        title: getTextByLanguage(dimension.title, lng),
        key: `${RESULTS}-${dimension.id}`,
        goTo: {
          pathname,
          search: `?tab=${`${RESULTS}-${dimension.id}`}&dimension=${
            dimension.id
          }&organization=${organization}&lng=${language}`,
        },
      }));
  };

  render() {
    const { activeMenu, organization, dimension } = this.state;
    const {
      survey,
      currentClient,
      location: { pathname },
      fetched,
      fetching,
      lng
    } = this.props;
    const organizationId = organization ?? currentClient?.id ?? 0;
    const menus = tabs(pathname, organizationId, lng);
    const resultsActive =
      activeMenu == RESULTS ||
      (activeMenu && activeMenu.match(new RegExp(`${RESULTS}-\\d+`)));
    const childs =
      (resultsActive && this.tabChilds(pathname, organizationId, lng)) || [];
    const currentTab = [...menus, ...childs].find(
      menu => menu.key == activeMenu,
    );

    if (!fetching && !fetched) {
      return <NotFoundPage className="small-12" />;
    }

    return (
      <div className="stats-page">
        <div className="row expanded">
          <div className="small-2 columns">
            <div className="stats__menu">
              {menus.map(tab => {
                if (tab.key === RESULTS) {
                  return (
                    <Tab
                      key={tab.key}
                      menuName={tab.key}
                      goTo={tab.goTo}
                      title={tab.label}
                      childs={childs}
                      forceActive={resultsActive}
                      organization={organizationId}
                    />
                  );
                }
                return (
                  <Tab
                    key={tab.key}
                    menuName={tab.key}
                    goTo={tab.goTo}
                    title={tab.label}
                    organization={organizationId}
                  />
                );
              })}
            </div>
          </div>
          <div className="small-10 columns no-pading-left">
            <div className="stats__wrapper">
              <Views
                activeMenu={activeMenu}
                title={currentTab && currentTab.title}
                surveySlug={this.surveySlug}
                survey={survey}
                organization={organization}
                dimension={dimension}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lng: state.params.lng,
  items: state.surveys.list.items,
  survey: state.surveys.list.survey,
  fetching: state.surveys.list.fetching,
  fetched: state.surveys.list.fetched,
  currentClient: state.auth.currentClient,
});

const mapDispatchToProps = { setCurrentNavPage, fetchSurvey, setCurrentSurvey };

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
