const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  nbResult: 0,
  error: null,
};

export const rankingOrganizationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIALIZE_RANKING_ORGANIZATION': {
      return initialState;
    }
    case 'FETCH_RANKING_ORGANIZATIONS_PENDING': {
      return { ...state, fetching: true };
    }
    case 'FETCH_RANKING_ORGANIZATIONS_FULFILLED': {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult,
      };
    }
    case 'FETCH_RANKING_ORGANIZATIONS_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
    }
    default:
      return state;
  }
};

const initialStateForRankingSort = {
  sortConfig: {
    columnKey: 'nps',
    sortDirection: 'desc',
  },
};

export const rankingOrganizationsSortReducer = (state = initialStateForRankingSort, action) => {
  switch (action.type) {
    case 'SET_RANKING_ORGANIZATIONS_LIST_SORT_CONFIG': {
      return { ...state, sortConfig: action.config };
    }
    case 'INITIALIZE_RANKING_ORGANIZATION': {
      return initialStateForRankingSort;
    }
    default:
      return state;
  }
};
