import React, { Component } from 'react';
import { fetchOrganizationScore } from 'thunks';
import { initializeOrganizationScore } from 'actions';
import _ from 'i18n';
import NotFoundPage from 'NotFoundPage';
import controlAccess from './controlAccess';
import Piechart from './charts/PieChart';

class OrganizationScore extends Component {
  componentDidMount() {
    const { surveySlug, survey: { slug, latestInstance }, organization } = this.props;
    if (slug && surveySlug == slug) {
      this.props.initializeOrganizationScore();
      this.props.fetchOrganizationScore(latestInstance ? latestInstance.id : 0, organization);
    }
  }

  componentDidUpdate(prevProps) {
    const { slug, latestInstance } = this.props.survey;
    const prevSurvey = prevProps.survey;

    if (slug != prevSurvey.slug && slug != null) {
      this.props.fetchOrganizationScore(latestInstance ? latestInstance.id : 0, this.props.organization);
    }
  }

  render() {
    const { ranking, hide, disabled, survey, fetchingSurvey } = this.props;

    if (!fetchingSurvey && (hide || disabled)) {
      return <NotFoundPage mainClass="small-10" />;
    }

    return (
      <div className="row small-up-1 medium-up-2 large-up-4 rank-organization">
        {
          (survey.isSectorial || fetchingSurvey) && (
            <div className="column column-block">
              <div className="wrapper">
                <div className="text">{_('Ranking')}</div>
                <div className="rank">{(ranking && ranking.rank) || '--'}</div>
              </div>
              {/* <div className="rank-line" /> */}
            </div>
          )
        }
        <div className="column column-block">
          <Piechart
            label={_('NPS')}
            rate={(ranking && ranking.nps) || 0}
            pieColor="#f6bf1e"
            target={55}
          />
        </div>
        <div className="column column-block">
          <Piechart
            label={_('SCORE')}
            rate={(ranking && ranking.score) || 0}
            pieColor="#2cb0dd"
            target={55}
          />
        </div>
        {
          ((ranking && ranking.organizationNps) || !ranking) && (
            <div className="column column-block">
              <Piechart
                label={_('GLOBAL NPS')}
                rate={(ranking && ranking.organizationNps) || 0}
                pieColor="#f09b14"
                target={55}
              />
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ranking: state.organizationScore.ranking,
  fetching: state.organizationScore.fetching,
  fetchingSurvey: state.surveys.list.fetching,
  error: state.organizationScore.error,
});

const mapDispatchToProps = { fetchOrganizationScore, initializeOrganizationScore };

export default controlAccess(mapStateToProps, mapDispatchToProps)(OrganizationScore);
