import React, { Component } from 'react';
import { MY_RANKING,
  STATS,
  // ORGANIZATION_PARTICIPANTS,
  RANKING_ORGANIZATIONS,
  RANKING_CLIENTS,
  RESULTS,
  CAMPAIGN_STATS,
  USER_PARTICIPANTS,
  USER_RESPONSES
} from 'constants';
import NotFoundPage from 'NotFoundPage';
import Switch from 'common/Switch';
import _ from 'i18n';
import { connect } from 'react-redux';
import { setStatsFilterShowAll } from 'actions';
import { getTextByLanguage } from 'surveyUtils';
import RankingOrganizationsView from './RankingOrganizationsView';
import RankingClientsView from './RankingClientsView';
import OrganizationScoreView from './OrganizationScoreView';
import ResponseSummary from './ResponseSummary';
import DimensionResults from './results/DimensionResults';
import QuestionResults from './results/QuestionResults';
// import OrganizationParticipants from './OrganizationParticipantsView';
import UserParticipants from './UserParticipantsView';
import CampaignStats from './CampaignStats';
import UserResponses from './UserResponsesView';

class Stats extends Component {
  renderStats() {
    const { survey, activeMenu, surveySlug, organization, dimension } = this.props;

    switch (activeMenu) {
      case RANKING_ORGANIZATIONS: return <RankingOrganizationsView menuName={activeMenu} surveySlug={surveySlug} organization={organization} survey={survey} />;
      case RANKING_CLIENTS: return <RankingClientsView menuName={activeMenu} surveySlug={surveySlug} organization={organization} survey={survey} />;
      case MY_RANKING: return <OrganizationScoreView menuName={activeMenu} surveySlug={surveySlug} survey={survey} organization={organization} />;
      case CAMPAIGN_STATS: return <CampaignStats menuName={activeMenu} surveySlug={surveySlug} survey={survey} organization={organization} />;
      case STATS: return <ResponseSummary menuName={activeMenu} surveySlug={surveySlug} survey={survey} organization={organization}/>;
      case USER_PARTICIPANTS: return <UserParticipants menuName={activeMenu} surveySlug={surveySlug} survey={survey} organization={organization} />;
      case USER_RESPONSES: return <UserResponses menuName={activeMenu} surveySlug={surveySlug} survey={survey} organization={organization} />;
      case RESULTS: return <DimensionResults menuName={activeMenu} surveySlug={surveySlug} survey={survey} organization={organization} />;
      case ((activeMenu && activeMenu.match(new RegExp(`${RESULTS}-\\d+`))) || {}).input: return <QuestionResults menuName={activeMenu} surveySlug={surveySlug} survey={survey} organization={organization} dimension={dimension} />;
      // case ORGANIZATION_PARTICIPANTS: return <OrganizationParticipants menuName={activeMenu} surveySlug={surveySlug} survey={survey} organization={organization} />;
      default: return <NotFoundPage />;
    }
  }

  renderShowAllResults() {
    const { showAll } = this.props;

    return (
      <div className="stats__show-all">
        <div>{_('Show all results')}</div>
        <Switch name="autoSend" isChecked={showAll} onChange={() => this.props.setStatsFilterShowAll(!showAll)} />
      </div>
    );
  }

  render() {
    const { title, survey, lng, currentClient, activeMenu } = this.props;
    const organizationId = currentClient?.id ?? 0;
    const isShowAll = (STATS == activeMenu)
      && survey.isSectorial
      && (survey.organization?.id == organizationId)
      && survey.type != 'PORTAL';

    return (
      <>
        <div>
          <div className="row">
            <div className="small-12 stats__title__wrapper">
              <div className="row">
                <div className="column small-12 stats__title">
                  <h4>{title || ''}</h4>
                  <div className="title">{getTextByLanguage(survey.title, lng)}</div>
                  {isShowAll && this.renderShowAllResults()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="stats__content">{this.renderStats()}</div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  showAll: state.stats.filter.showAll,
  currentClient: state.auth.currentClient,
  lng: state.params.lng,
});

const mapDispatchToProps = { setStatsFilterShowAll };

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
