import React, { Component } from 'react';
import shortId from 'shortid';

export default class Languages extends Component {
  constructor(props) {
    super(props);
    const defaultValues = ['fr', 'nl', 'en'];

    this.state = {
      values: this.props.values || defaultValues,
    };
  }

  onClick(language) {
    const { disabled, value } = this.props;
    if (!disabled) {
      const currentValues = (value && value.split(',')) || [];

      if (currentValues.indexOf(language) === -1) {
        this.props.addLanguage(language);
      } else if (currentValues.length > 1) {
        this.props.removeLanguage(language);
      }
    }
  }

  render() {
    const { values } = this.state;
    const { disabled, label, value } = this.props;
    const currentValues = (value && value.split(',')) || [];

    return (
      <div className="settings__block">
        <div className="settings__container">
          <div className="settings__container__label">{label}</div>
          <div className="settings__container__item">
            <div className={`steps-list ${disabled ? 'disabled-link' : ''}`}>
              <ul>
                {
                  values.map((item, key) => (
                    <li
                      value={key}
                      className={currentValues.indexOf(item) !== -1 ? 'selected' : ''}
                      key={`step-${shortId.generate()}`}
                      onClick={this.onClick.bind(this, item)}
                    >
                      {String(item).toUpperCase()}
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
