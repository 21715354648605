import React, { Component } from 'react';
import SurveyListGridFetching from 'fetching/SurveyListGridFetching';
import SurveyItem from './SurveyItem';
import NoDataFound from '../NoDataFound';

export default class SurveyList extends Component {
  render() {
    const { surveys, isTemplate, openSurveyModal } = this.props;

    if (surveys.fetching) {
      return <SurveyListGridFetching />;
    }
    if (!surveys.fetched) {
      return (
        <div className="small-12 srv-list-list">
          <NoDataFound />
        </div>
      );
    }
    return (
      <div className="small-12 srv-list-list">
        <div className="row small-up-5">
          {
            Array.isArray(surveys.items)
            && surveys.items.map(survey => <SurveyItem survey={survey} isTemplate={isTemplate} key={survey.id} openSurveyModal={openSurveyModal} />)
          }
        </div>
      </div>
    );
  }
}
