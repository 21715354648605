import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'i18n';
import { NavLink } from 'react-router-dom';
import { AUTHORIZATIONS } from 'Common';

class CreateSurvey extends Component {
  componentDidMount() {
    $('#menusr').foundation();
  }

  render() {
    const { loggedAs } = this.props.auth;
    const authorizations = AUTHORIZATIONS[loggedAs] ? AUTHORIZATIONS[loggedAs] : [];
    const allowCreateSurvey = authorizations.indexOf('CREATE_SURVEY') !== -1;

    if (!allowCreateSurvey) {
      return null;
    }

    return (
      <li>
        <NavLink to={{ pathname: '/surveys/type/selector' }} activeClassName="active" className="menu__link">
          <span className="flex-column-center"><i className="icon icon-note" /></span>
          <span className="title">{_('create survey')}</span>
        </NavLink>
      </li>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(CreateSurvey);
