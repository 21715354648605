import axios from 'axios';
import { TTP_API_URL } from 'Config';
import { throwCatchedError } from 'utils';


export const getStats = (token, instanceId = null, organizationId, data) => {
  const requestUrl = `${TTP_API_URL}/survey/stats`;
  const { country, city, profiling: { language, questions }, showAll } = data;
  const filter = [];
  const answerFilter = [];

  if (country != null && country !== 'all') {
    filter.push({
      property: 'organization.country',
      value: country,
      operator: 'eq',
    });
  }
  if (city != null && city !== 'all') {
    filter.push({
      property: 'organization.city',
      value: city,
      operator: 'eq',
    });
  }
  if (language != null && language !== 'all') {
    filter.push({
      property: 'response.language',
      value: language,
      operator: 'eq',
    });
  }

  if (questions.length != 0) {
    questions.forEach(({ questionId, resourceType, property, value, operator }) => {
      const filters = {
        question: {
          property: 'question',
          value: questionId,
          operator: 'eq'
        },
        resourceType
      };

      filters.filter = [
        {
          property: property || 'answerValue',
          value,
          operator: operator || 'eq'
        }
      ];

      answerFilter.push(filters);
    });
  }

  const params = {
    access_token: token,
    instance: instanceId,
    organization: showAll ? null : organizationId,
  };

  params.filter = JSON.stringify(filter);
  params.answerFilter = JSON.stringify(answerFilter);

  return axios.get(requestUrl, { params }).catch((thrown) => { throwCatchedError(thrown); });
};

export const getStatsOptions = (token) => {
  const requestUrl = `${TTP_API_URL}/survey/ranking/filter-options`;

  const params = {
    access_token: token,
  };

  return axios.get(requestUrl, { params }).catch((thrown) => { throwCatchedError(thrown); });
};
