import React from 'react';
import _ from 'i18n';

export default function CampaignStatsListFetching() {
  return (
    <div className="campaign-stats__list row">
      <div className="row small-12 list-header">
        <span className="greetings column text-center">{_('Date')}</span>
        <span className="greetings column text-center">{_('Type')}</span>
        <span className="greetings column text-center small-3">{_('Object')}</span>
        <span className="greetings column text-center">{_('Language')}</span>
        <span className="greetings column text-center">{_('Targets')}</span>
        <span className="column text-center small-3">
          <div>{_('Initial stats')}</div>
          <div className="sub-header">{`${_('complete')} / ${_('incomplete')} / ${_('population')}`}</div>
        </span>
      </div>
      {
        Array.from(Array(3).keys()).map(item => (
          <div className="row small-12 item fetching" key={item}>
            <span className="column"><h5 className="no-margin no-content gradiant blue" /></span>
            <span className="column"><h5 className="no-margin no-content gradiant blue" /></span>
            <span className="column"><h5 className="no-margin no-content gradiant blue" /></span>
            <span className="column"><h5 className="no-margin no-content gradiant blue" /></span>
            <span className="column"><h5 className="no-margin no-content gradiant blue" /></span>
            <span className="column"><h5 className="no-margin no-content gradiant blue" /></span>
          </div>
        ))
      }
    </div>
  );
}
