import React from 'react';
import _ from 'i18n';

const BooleanFilter = (props) => {
  const data = [
    {
      name: props.trueLabel,
      value: 1
    },
    {
      name: props.falseLabel,
      value: 0
    }
  ];

  if (props.hasAll) {
    data.unshift({
      name: _('all').toUpperCase(),
      value: ''
    });
  }

  const onChange = ({ target: { value } }) => {
    props.handleFilterChange(props.property, value);
  };

  return (
    <label>
      {props.name}
      <select value={props.value} onChange={onChange}>
        {data.map(item => (
          <option key={`item-${item.name}`} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
    </label>
  );
};

export default BooleanFilter;
