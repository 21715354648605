import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setStatsProfilingFilterLanguge } from 'actions';
import _ from 'i18n';

class LanguageFilter extends Component {
  constructor(props) {
    super(props);
    this.handleFilter = this.handleFilter.bind(this);
  }

  handleFilter({ target: { value } }) {
    this.props.setStatsProfilingFilterLanguge(value);
  }

  render() {
    return (
      <div className="profiling-item columns">
        <div className="title profiling-item--lng">{_('Language')}</div>
        <div className="content">
          <select className="profiling-item--lng" value={this.props.language} placeholder={`${_('Language')} ...`} onChange={this.handleFilter}>
            <option value="all">{_('all').toUpperCase()}</option>
            <option value="fr">Fr</option>
            <option value="en">En</option>
            <option value="nl">Nl</option>
          </select>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.stats.filter.profiling.language,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setStatsProfilingFilterLanguge }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageFilter);
