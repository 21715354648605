// Notifications reducer
const initialStateForNotificationsReducer = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  updated: false,
  updating: false,
  nbResult: 0,
};

export const notificationsReducer = (state = initialStateForNotificationsReducer, action) => {
  switch (action.type) {
    case 'FETCH_NOTIFICATIONS_PENDING': {
      return { ...state, fetched: false, fetching: true };
    }
    case 'FETCH_NOTIFICATIONS_FULFILLED': {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult
      };
    }
    case 'FETCH_NOTIFICATIONS_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error
      };
    }
    case 'MARK_NOTIFICATION_AS_READ_PENDING': {
      return { ...state, updated: false, updating: true };
    }
    case 'MARK_NOTIFICATION_AS_READ_FULFILLED': {
      const { payload } = action;
      const updatedItems = state.items.map((item) => {
        if (item.id == payload.id) {
          return { ...item, ...payload };
        }
        return item;
      });
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        updated: true,
        updating: false,
        items: updatedItems
      };
    }
    default:
      return state;
  }
};
