import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  setCurrentNavPage,
  changeLanguage,
  setSurveyPaginationPage,
  initSurveyPagination,
  initSurveyListFilter,
  initSurvey
} from 'actions';
import { fetchSurveys } from 'thunks';
import SurveyList from 'survey/SurveyList';
import SurveyListFilter from 'survey/SurveyListFilter';
import { AUTHORIZATIONS } from 'Common';
import _ from 'i18n';
import { GRID } from 'constants';
import PageHeader from './common/PageHeader';
import TTRoundedButton from './common/TTRoundedButton';
import TTPPaginator from './common/TTPPaginator';

class SurveyListPage extends Component {
  constructor(props) {
    super(props);

    this.state = { isTemplate: false };
  }

  componentDidMount() {
    const {
      lng, setCurrentNavPage, changeLanguage, fetchSurveys, initSurveyListFilter, initSurveyPagination
    } = this.props;

    initSurveyListFilter();
    initSurveyPagination();

    if (lng) {
      changeLanguage(lng);
    }

    setCurrentNavPage('SURVEY_LIST');
    fetchSurveys();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.surveys.paginationPage !== this.props.surveys.paginationPage
      || prevProps.surveys.pageSize !== this.props.surveys.pageSize
      || prevProps.surveysFilter !== this.props.surveysFilter
    ) {
      this.props.fetchSurveys();
    }
  }

  handleSurveysPageClick = (data) => {
    this.props.setSurveyPaginationPage(data.selected + 1);
  }

  renderPaginate() {
    const { surveys } = this.props;
    if ((surveys.error != null && surveys.error.code == 404) || !surveys.fetched) {
      return '';
    }
    return (
      <TTPPaginator
        onPageChange={this.handleSurveysPageClick}
        pageSize={surveys.pageSize}
        nbResult={surveys.nbResult}
        paginationPage={surveys.paginationPage}
      />
    );
  }

  handleClick = () => {
    this.props.initSurvey();
    this.props.history.push(`/surveys/type/selector${location.search}`);
  }

  render() {
    const { view, auth: { currentClient, loggedAs }, lng } = this.props;
    const isDetached = !(currentClient && currentClient.id);
    const selectedView = isDetached ? GRID : view;

    const authorizations = AUTHORIZATIONS[loggedAs] ? AUTHORIZATIONS[loggedAs] : [];
    const disabled = authorizations.indexOf('CREATE_SURVEY') === -1;

    return (
      <div className="srv-list">
        <PageHeader
          icon={this.state.isTemplate ? 'icomoon icon-tt-folder' : 'icomoon icon-tt-stats'}
          title={this.state.isTemplate ? _('Library') : _('Surveys')}
          subTitle="CRÉEZ, GÉREZ ET ENVOYEZ VOS ENQUÊTES" // TODO add text
        >
          <TTRoundedButton
            icon="icomoon icon-tt-plus"
            text={_('create')}
            upperCase={true}
            disabled={disabled}
            onClick={this.handleClick}
          />
        </PageHeader>
        <div className="row">
          <SurveyListFilter />
        </div>
        <div className="row">
          <SurveyList view={selectedView} lng={lng} />
        </div>
        <div className="row">
          <div className="small-12 columns">
            {this.renderPaginate()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lng: state.params.lng,
  view: state.params.listView.surveys,
  surveys: state.surveys.list,
  surveysFilter: state.surveys.filter,
  auth: state.auth,
});

const mapDispatchToProps = {
  setCurrentNavPage,
  changeLanguage,
  fetchSurveys,
  setSurveyPaginationPage,
  initSurveyPagination,
  initSurveyListFilter,
  initSurvey
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyListPage);
