const initialStateForEmailingTemplates = {
  items: [],
  settings: [],
  nbResult: 0,
  fetching: false,
  fetched: false,
  filter: {
    searchWord: null,
    language: 'fr',
    type: 'SURVEY_INVITATION',
    pageSize: 25,
    paginationPage: 1,
  },
};

export const genericEmailReducer = (state = initialStateForEmailingTemplates, action) => {
  switch (action.type) {
    case 'INIT_GENERIC_EMAIL': {
      return initialStateForEmailingTemplates;
    }
    case 'FETCH_GENERIC_EMAIL_SETTINGS_STATS_PENDING': {
      return {
        ...state,
        fetching: true
      };
    }

    case 'FETCH_GENERIC_EMAIL_SETTINGS_STATS_FULFILLED': {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        settings: data,
        fetching: false,
        fetched: true,
        nbResult
      };
    }

    case 'FETCH_GENERIC_EMAIL_SETTINGS_STATS_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          };
          break;
        default:
      }
      return {
        ...state,
        settings: [],
        fetching: false,
        fetched: false,
        error
      };
    }
    default:
      return state;
  }
};
