import axios from 'axios';
import { TTP_API_URL } from 'Config';
import { throwCatchedError } from 'utils';

export const getSurveyQuestions = (token, isProfile, organizationId) => {
  const requestUrl = `${TTP_API_URL}/survey/survey-question`;
  const fields = ['*', 'question'];
  const filters = [
    {
      property: 'organization',
      value: organizationId,
      operator: isProfile ? 'null' : 'eq',
    },
  ];

  if (isProfile) {
    filters.push({
      property: 'question.profilingType',
      operator: 'nNull',
    });
  }

  return axios
    .get(requestUrl, {
      params: {
        access_token: token,
        fields: fields.join(','),
        filter: JSON.stringify(filters),
        sort: JSON.stringify([
          {
            property: 'createdAt',
            dir: 'desc',
          },
        ]),
      },
    })
    .catch(thrown => {
      throwCatchedError(thrown);
    });
};
