import _ from 'lodash';
import update from 'immutability-helper';
import {
  SURVEY,
  DIMENSION,
  QUESTION,
  CHOICE,
  GRID,
  SURVEY_BUILDER_TABS,
} from 'constants';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { generateSurvey, serializeSurvey, moveElement } from 'surveyUtils';
import { mergeMoments } from 'utils';
import {
  validateSurvey,
  validateDimension,
  validateQuestion,
  validateChoice,
} from 'validator/';
import { getAllQuestionsOfSurvey } from 'question';
import { getAllChoices } from 'choice';
import moment from 'moment';
import editorStateTransform from '../../transforms/editorStateTransform';

const initialSurveysState = {
  fetching: false,
  fetched: false,
  deleting: false,
  deleted: false,
  saving: false,
  saved: false,
  language: 'fr',
  items: [],
  usedDates: [],
  pageSize: 10,
  paginationPage: 1,
  nbResult: 0,
  view: GRID,
  toolbarTab: SURVEY_BUILDER_TABS.EDITOR,
  showSettingsSlider: false,
  survey: generateSurvey(),
  lockedQuestions: new Map(),
  lockedDimensions: new Map(),
  current: {
    question: null,
    startSurvey: false,
    currentDimensionIndex: 0,
    currentQuestionIndex: 0,
    currentChoiceIndex: -1,
  },
  validationErrors: {
    survey: {
      title: {},
      description: {},
    },
    dimensions: {
      title: {},
      description: {},
    },
    questions: {
      text: {},
      otherLabel: {},
    },
    choices: {
      text: {},
    },
  },
  error: null,
};

const reducer = (state = initialSurveysState, action) => {
  switch (action.type) {
    case 'INIT_SURVEY': {
      return {
        ...initialSurveysState,
        survey: generateSurvey(action.surveyType, action.language || undefined),
        items: state.items,
        usedDates: [],
      };
    }
    case 'INIT_SURVEY_PAGINATION': {
      const { pageSize, paginationPage, nbResult } = initialSurveysState;
      return {
        ...state,
        pageSize,
        paginationPage,
        nbResult,
      };
    }
    case 'SET_SURVEY_PAGINATION_PAGE': {
      return { ...state, paginationPage: action.pageNumber };
    }
    case 'SET_SURVEY_PAGE_SIZE': {
      return { ...state, pageSize: action.pageSize };
    }
    case 'SET_SURVEY_SETTINGS_CURRENT_TAB': {
      return { ...state, toolbarTab: action.tab, showSettingsSlider: true };
    }
    case 'SHOW_SURVEY_SETTINGS_SLIDER': {
      const { showSettingsSlider } = action;
      return {
        ...state,
        showSettingsSlider,
        toolbarTab: showSettingsSlider
          ? state.toolbarTab
          : initialSurveysState.toolbarTab, // return to default tab after close
      };
    }
    case 'SET_CURRENT_SURVEY': {
      const { survey, isParsed } = action;
      return { ...state, survey: serializeSurvey(survey, isParsed) };
    }
    case 'SET_CURRENT_SURVEY_THEME': {
      const { theme } = action;
      const { survey } = state;

      return {
        ...state,
        survey: { ...survey, theme },
      };
    }
    case 'SET_CURRENT_QUESTION': {
      const { questionOrder, dimensionOrder, level } = action;
      const { dimensions } = state.survey;

      if (dimensionOrder != -1 && questionOrder != -1) {
        return {
          ...state,
          current: {
            ...state.current,
            question: dimensions[dimensionOrder].questions
              ? dimensions[dimensionOrder].questions[questionOrder]
              : null,
            currentDimensionIndex: dimensionOrder,
            currentQuestionIndex: questionOrder,
            currentChoiceIndex: -1,
            level,
          },
        };
      }
      return {
        ...state,
        current: {
          ...state.current,
          question: null,
          currentDimensionIndex: dimensionOrder,
          currentQuestionIndex: questionOrder,
          currentChoiceIndex: -1,
          level,
        },
      };
    }
    case 'SET_CURRENT_CHOICE': {
      const { questionOrder, dimensionOrder, choiceOrder, level } = action;
      if (dimensionOrder != -1 && questionOrder != -1) {
        return {
          ...state,
          current: {
            ...state.current,
            question:
              state.survey.dimensions[dimensionOrder].questions[questionOrder],
            currentDimensionIndex: dimensionOrder,
            currentQuestionIndex: questionOrder,
            currentChoiceIndex: choiceOrder,
            level,
          },
        };
      }
      return {
        ...state,
        current: {
          ...state.current,
          currentDimensionIndex: dimensionOrder,
          currentQuestionIndex: questionOrder,
          currentChoiceIndex: choiceOrder,
          level,
        },
      };
    }
    case 'SET_SURVEY_ORGANIZATION_INSTANCE': {
      const { surveyId, organizationInstance } = action;
      let { survey } = state;
      if (survey.id === surveyId) {
        survey = { ...survey, organizationInstance };
      }

      return {
        ...state,
        survey,
        items: state.items.map(survey => {
          if (survey.id === surveyId) {
            return { ...survey, organizationInstance };
          }
          return survey;
        }),
      };
    }
    case 'ADD_VALIDATION_ERROR': {
      const { key, error, resourceType, property } = action;
      const { errors, source } = getErrorsParamsByResourceType(
        state,
        resourceType,
      );

      if (
        errors.hasOwnProperty(property) &&
        errors[property].hasOwnProperty(key)
      ) {
        const { code } = error;
        let exist = false;

        if (!_.isEmpty(errors[property][key])) {
          errors[property][key].forEach(propertyError => {
            if (propertyError.code === code) {
              exist = true;
            }
          });
        }

        if (!exist) {
          return {
            ...state,
            validationErrors: {
              ...state.validationErrors,
              [source]: update(errors, {
                [property]: {
                  [key]: { $push: [error] },
                },
              }),
            },
          };
        }
        return {
          ...state,
        };
      }

      return {
        ...state,
        validationErrors: {
          ...state.validationErrors,
          [source]: update(errors, {
            [property]: { $merge: { [key]: [error] } },
          }),
        },
      };
    }

    case 'REMOVE_VALIDATION_ERROR': {
      const { key, resourceType, property } = action;
      const { errors, source } = getErrorsParamsByResourceType(
        state,
        resourceType,
      );

      if (
        errors.hasOwnProperty(property) &&
        errors[property].hasOwnProperty(key)
      ) {
        return {
          ...state,
          validationErrors: {
            ...state.validationErrors,
            [source]: update(errors, { [property]: { $unset: [key] } }),
          },
        };
      }
      return {
        ...state,
      };
    }

    case 'RESET_VALIDATION_ERRORS': {
      return {
        ...state,
        validationErrors: _.cloneDeep(initialSurveysState.validationErrors),
      };
    }

    case 'VALIDATE_SURVEY': {
      const { survey } = state;
      const surveyErrors = state.validationErrors.survey;
      const dimensionsErrors = state.validationErrors.dimensions;
      const questionsErrors = state.validationErrors.questions;
      const choicesErrors = state.validationErrors.choices;

      validateSurvey(survey, surveyErrors);
      if (Array.isArray(survey.dimensions)) {
        survey.dimensions.forEach(dimension =>
          validateDimension(dimension, dimensionsErrors),
        );
      }
      const questions = getAllQuestionsOfSurvey(survey);
      const choices = getAllChoices(questions);

      questions.forEach(question =>
        validateQuestion(question, questionsErrors),
      );
      choices.forEach(choice => validateChoice(choice, choicesErrors));

      return {
        ...state,
        validationErrors: {
          ...state.validationErrors,
          survey: { ...surveyErrors },
          dimensions: _.cloneDeep(dimensionsErrors),
          questions: _.cloneDeep(questionsErrors),
          choices: _.cloneDeep(choicesErrors),
        },
      };
    }

    case 'CHANGE_LANGUAGE': {
      const authorizedLng = ['fr', 'en', 'nl'];
      const lng =
        authorizedLng.indexOf(action.language) !== -1 ? action.language : 'fr';
      return {
        ...state,
        language: lng,
      };
    }

    case 'SET_SURVEY_TYPE': {
      return {
        ...state,
        survey: {
          ...state.survey,
          type: action.surveyType,
        },
      };
    }
    case 'ADD_SURVEY_LANGUAGE': {
      const { language } = action;
      const { survey } = state;
      const oldLanguages = survey.languages || '';
      const languages = [...oldLanguages.split(','), language];

      return {
        ...state,
        survey: {
          ...survey,
          languages: _.uniq(languages.filter(lng => !_.isEmpty(lng))).join(','),
        },
      };
    }
    case 'REMOVE_SURVEY_LANGUAGE': {
      const { language } = action;
      const { survey } = state;
      let oldLanguages = survey.languages || '';
      oldLanguages = oldLanguages.split(',');

      return {
        ...state,
        survey: {
          ...survey,
          languages: oldLanguages.filter(lng => lng != language).join(','),
        },
      };
    }
    case 'SET_MEDIA_BANNER': {
      const { banner } = action;
      return {
        ...state,
        survey: {
          ...state.survey,
          banner: {
            ...state.survey.banner,
            [banner.language]: banner.webPath,
          },
        },
      };
    }
    case 'SET_MEDIA_WELCOME_SCREEN': {
      const { welcomeScreen } = action;
      return {
        ...state,
        survey: {
          ...state.survey,
          welcomeScreen: {
            ...state.survey.welcomeScreen,
            [welcomeScreen.language]: welcomeScreen.webPath,
          },
        },
      };
    }
    case 'SET_MEDIA_THANK_SCREEN': {
      const { thankScreen } = action;
      return {
        ...state,
        survey: {
          ...state.survey,
          thankScreen: {
            ...state.survey.thankScreen,
            [thankScreen.language]: thankScreen.webPath,
          },
        },
      };
    }
    case 'DELETE_MEDIA_WELCOME_SCREEN': {
      const { survey } = state;
      const { welcomeScreen, ...rest } = survey;

      return {
        ...state,
        survey: rest,
      };
    }
    case 'DELETE_MEDIA_BANNER': {
      const { survey } = state;
      const { banner, ...rest } = survey;

      return {
        ...state,
        survey: rest,
      };
    }
    case 'DELETE_MEDIA_THANK_SCREEN': {
      const { survey } = state;
      const { thankScreen, ...rest } = survey;

      return {
        ...state,
        survey: rest,
      };
    }
    case 'REFRESH_CURRENT_QUESTION': {
      const { currentDimensionIndex, currentQuestionIndex } = state.current;

      if (currentQuestionIndex != -1 && currentQuestionIndex != -1) {
        return {
          ...state,
          current: {
            ...state.current,
            question:
              state.survey.dimensions[currentDimensionIndex].questions[
                currentQuestionIndex
              ],
          },
        };
      }
      return {
        ...state,
        current: {
          ...state.current,
          question: null,
        },
      };
    }
    case 'UPDATE_TITLE': {
      return {
        ...state,
        survey: {
          ...state.survey,
          title: action.title,
        },
      };
    }
    case 'UPDATE_PENETRATION_RATE_FULFILLED': {
      const survey = action.payload.data.data;

      return {
        ...state,
        items: state.items.map(item => {
          if (item.id == survey.id) {
            return { ...item, penetrationRate: survey.penetrationRate };
          }
          return item;
        }),
      };
    }
    case 'UPDATE_SURVEY_DESCRIPTION': {
      const { language, description } = action;

      return {
        ...state,
        survey: {
          ...state.survey,
          description: { ...state.survey.description, [language]: description },
        },
      };
    }
    case 'REMOVE_SURVEY_DESCRIPTION': {
      const survey = { ...state.survey };
      if (Object.keys(survey).indexOf('description') !== -1) {
        delete survey.description;
      }
      return { ...state, survey };
    }
    case 'UPDATE_WELCOME_MESSAGE': {
      const { language, welcomeMessage } = action;

      return {
        ...state,
        survey: {
          ...state.survey,
          welcomeMessage: {
            ...state.survey.welcomeMessage,
            [language]: welcomeMessage,
          },
        },
      };
    }
    case 'REMOVE_WELCOME_MESSAGE': {
      const survey = { ...state.survey };
      if (Object.keys(survey).indexOf('welcomeMessage') !== -1) {
        delete survey.welcomeMessage;
      }
      return { ...state, survey };
    }
    case 'UPDATE_DIMENSION_DESCRIPTION': {
      const { currentDimensionIndex } = state.current;
      if (currentDimensionIndex !== -1) {
        if (action.description == null) {
          return {
            ...state,
            survey: {
              ...state.survey,
              dimensions: update(state.survey.dimensions, {
                [currentDimensionIndex]: { $unset: ['description'] },
              }),
            },
          };
        }
        return {
          ...state,
          survey: {
            ...state.survey,
            dimensions: update(state.survey.dimensions, {
              [currentDimensionIndex]: {
                description: { $set: action.description },
              },
            }),
          },
        };
      }
      return state;
    }
    case 'MOVE_DIMENSION': {
      const { dimensionOrder, direction } = action;
      const { dimensions } = state.survey;

      return {
        ...state,
        survey: {
          ...state.survey,
          dimensions: moveElement(dimensions, dimensionOrder, direction),
        },
      };
    }
    case 'ADD_DIMENSION': {
      const {
        survey: { dimensions },
        current: { currentDimensionIndex },
      } = state;

      if (dimensions == null) {
        return {
          ...state,
          survey: {
            ...state.survey,
            dimensions: [action.dimension],
          },
        };
      }

      const isProfileDimension = dimensions[0]?.isProfile;
      /** insert after Profile dimension */
      const newIndexStep =
        currentDimensionIndex === -1 && isProfileDimension ? 2 : 1;

      return {
        ...state,
        survey: {
          ...state.survey,
          dimensions: update(dimensions, {
            $splice: [
              [currentDimensionIndex + newIndexStep, 0, action.dimension],
            ],
          }),
        },
      };
    }
    case 'UPDATE_DIMENSION': {
      const { currentDimensionIndex } = state.current;
      return {
        ...state,
        survey: {
          ...state.survey,
          dimensions: update(state.survey.dimensions, {
            $splice: [[currentDimensionIndex, 1, action.dimension]],
          }),
        },
      };
    }
    case 'DELETE_DIMENSION_FULFILLED':
    case 'REMOVE_DIMENSION': {
      const { currentDimensionIndex } = state.current;
      return {
        ...state,
        deleted: true,
        deleting: false,
        survey: {
          ...state.survey,
          dimensions: update(state.survey.dimensions, {
            $splice: [[currentDimensionIndex, 1]],
          }),
        },
      };
    }

    case 'MOVE_QUESTION': {
      const { dimensionOrder, questionOrder, direction } = action;
      const { dimensions } = state.survey;

      return {
        ...state,
        survey: {
          ...state.survey,
          dimensions: dimensions.map((dimension, indexDimension) => {
            if (indexDimension !== dimensionOrder) return dimension;

            return {
              ...dimension,
              questions: moveElement(
                dimension.questions,
                questionOrder,
                direction,
              ),
            };
          }),
        },
      };
    }
    case 'ADD_QUESTION': {
      const { currentDimensionIndex } = state.current;
      let { currentQuestionIndex } = state.current;
      currentQuestionIndex = skipLinkedQuestions(
        currentQuestionIndex,
        state.survey.dimensions[
          currentDimensionIndex !== -1 ? currentDimensionIndex : 0
        ].questions,
      );

      if (currentDimensionIndex !== -1) {
        if (state.survey.dimensions[currentDimensionIndex].questions == null) {
          return {
            ...state,
            survey: {
              ...state.survey,
              dimensions: update(state.survey.dimensions, {
                [currentDimensionIndex]: {
                  $merge: { questions: [action.question] },
                },
              }),
            },
          };
        }

        return {
          ...state,
          survey: {
            ...state.survey,
            dimensions: update(state.survey.dimensions, {
              [currentDimensionIndex]: {
                questions: {
                  $splice: [[currentQuestionIndex + 1, 0, action.question]],
                },
              },
            }),
          },
        };
      }

      return state;
    }
    case 'UPDATE_QUESTION': {
      const { currentDimensionIndex, currentQuestionIndex } = state.current;

      if (currentDimensionIndex !== -1) {
        if (state.survey.dimensions[currentDimensionIndex].questions == null) {
          return {
            ...state,
            survey: {
              ...state.survey,
              dimensions: update(state.survey.dimensions, {
                [currentDimensionIndex]: {
                  $merge: { questions: [action.question] },
                },
              }),
            },
          };
        }
      }
      return {
        ...state,
        survey: {
          ...state.survey,
          dimensions: update(state.survey.dimensions, {
            [currentDimensionIndex]: {
              questions: {
                $splice: [[currentQuestionIndex, 1, action.question]],
              },
            },
          }),
        },
      };
    }
    case 'ADD_QUESTION_OPTIONS': {
      // options most have only one property, otherwise you should change the logic this function
      const { currentDimensionIndex, currentQuestionIndex } = state.current;
      const { options } = action;
      const firstKey = Object.keys(options)[0];

      if (currentDimensionIndex !== -1 && currentQuestionIndex !== -1) {
        if (options[firstKey] == null) {
          return {
            ...state,
            survey: {
              ...state.survey,
              dimensions: update(state.survey.dimensions, {
                [currentDimensionIndex]: {
                  questions: {
                    [currentQuestionIndex]: { $unset: [firstKey] },
                  },
                },
              }),
            },
          };
        }

        return {
          ...state,
          survey: {
            ...state.survey,
            dimensions: update(state.survey.dimensions, {
              [currentDimensionIndex]: {
                questions: {
                  [currentQuestionIndex]: { $merge: action.options },
                },
              },
            }),
          },
        };
      }
      return state;
    }
    case 'ADD_SURVEY_OPTIONS': {
      const { options } = action;
      const firstKey = Object.keys(options)[0];

      if (options[firstKey] == null) {
        return {
          ...state,
          survey: update(state.survey, { $unset: [firstKey] }),
        };
      }

      return {
        ...state,
        survey: update(state.survey, { $merge: action.options }),
      };
    }
    case 'ADD_META_OPTION': {
      const { property, value } = action;
      const meta = state.survey.meta || {};
      return {
        ...state,
        survey: { ...state.survey, meta: { ...meta, [property]: value } },
      };
    }
    case 'DELETE_QUESTION_FULFILLED':
    case 'REMOVE_QUESTION': {
      const { currentDimensionIndex, currentQuestionIndex } = state.current;
      return {
        ...state,
        deleted: true,
        deleting: false,
        survey: {
          ...state.survey,
          dimensions: update(state.survey.dimensions, {
            [currentDimensionIndex]: {
              questions: { $splice: [[currentQuestionIndex, 1]] },
            },
          }),
        },
      };
    }
    case 'ADD_CHOICE': {
      const {
        currentDimensionIndex,
        currentQuestionIndex,
        currentChoiceIndex,
      } = state.current;
      return {
        ...state,
        survey: {
          ...state.survey,
          dimensions: update(state.survey.dimensions, {
            [currentDimensionIndex]: {
              questions: {
                [currentQuestionIndex]: {
                  choices: {
                    $splice: [[currentChoiceIndex + 1, 0, action.choice]],
                  },
                },
              },
            },
          }),
        },
      };
    }
    case 'UPDATE_CHOICE': {
      const {
        currentDimensionIndex,
        currentQuestionIndex,
        currentChoiceIndex,
      } = state.current;
      return {
        ...state,
        survey: {
          ...state.survey,
          dimensions: update(state.survey.dimensions, {
            [currentDimensionIndex]: {
              questions: {
                [currentQuestionIndex]: {
                  choices: {
                    $splice: [[currentChoiceIndex, 1, action.choice]],
                  },
                },
              },
            },
          }),
        },
      };
    }
    case 'SET_CORRECT_CHOICE': {
      const {
        currentDimensionIndex,
        currentQuestionIndex,
        currentChoiceIndex,
      } = action;
      let { choices } = state.survey.dimensions[
        currentDimensionIndex
      ].questions[currentQuestionIndex];

      choices = choices.map((choice, index) => {
        if (index !== currentChoiceIndex && choice.isCorrect) {
          return { ...choice, isCorrect: false };
        }
        if (index === currentChoiceIndex) {
          return { ...choice, isCorrect: true };
        }

        return choice;
      });

      return {
        ...state,
        survey: {
          ...state.survey,
          dimensions: update(state.survey.dimensions, {
            [currentDimensionIndex]: {
              questions: {
                [currentQuestionIndex]: {
                  choices: { $set: choices },
                },
              },
            },
          }),
        },
      };
    }
    case 'DUPLICATE_SURVEY_FULFILLED': {
      const survey = action.payload.data.data;
      // const items = state.items.map((item) => {
      //   if (item.id == survey.id) {
      //     return survey;
      //   }

      //   return item;
      // });
      return {
        ...state,
        items: [survey, ...state.items],
      };
    }
    case 'DELETE_CHOICE_FULFILLED':
    case 'REMOVE_CHOICE': {
      const {
        currentDimensionIndex,
        currentQuestionIndex,
        currentChoiceIndex,
      } = state.current;
      return {
        ...state,
        deleted: true,
        deleting: false,
        survey: {
          ...state.survey,
          dimensions: update(state.survey.dimensions, {
            [currentDimensionIndex]: {
              questions: {
                [currentQuestionIndex]: {
                  choices: { $splice: [[currentChoiceIndex, 1]] },
                },
              },
            },
          }),
        },
      };
    }
    case 'PUSH_SURVEYS_PENDING': {
      return { ...state, fetching: true, fetched: false };
    }
    case 'FETCH_SURVEYS_PENDING': {
      return { ...state, fetching: true, fetched: false, items: [] };
    }
    case 'FETCH_SURVEYS_FULFILLED': {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult,
      };
    }
    case 'PUSH_SURVEYS_FULFILLED': {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: [...state.items, ...data],
        nbResult,
      };
    }
    case 'PUSH_SURVEYS_REJECTED': {
      return { ...state, fetching: false };
    }
    case 'FETCH_SURVEYS_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
    }

    case 'FETCH_SURVEY_PENDING': {
      return { ...state, fetching: true, fetched: false };
    }
    case 'FETCH_SURVEY_FULFILLED': {
      const survey = action.payload.data.data;
      const items = state.items.map(item => {
        if (item.id == survey.id) {
          return survey;
        }

        return item;
      });
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        survey: serializeSurvey(survey),
        items,
      };
    }
    case 'FETCH_SURVEY_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        survey: {},
        error,
      };
    }
    case 'FETCH_SURVEY_EXTRA_DATA_FULFILLED': {
      const { data } = action.payload.data;

      if (data.stats) {
        let lockedQuestions = [];
        let lockedDimensions = [];

        for (const dimensionData of data.stats) {
          if (dimensionData.nbResponses > 0) {
            lockedDimensions = [
              ...lockedDimensions,
              [dimensionData.dimension, true],
            ];
          }
          if (dimensionData.questions) {
            for (const questionData of dimensionData.questions) {
              if (questionData.nbResponses > 0) {
                lockedQuestions = [
                  ...lockedQuestions,
                  [questionData.question, true],
                ];
              }
            }
          }
        }

        return {
          ...state,
          lockedQuestions: new Map(lockedQuestions),
          lockedDimensions: new Map(lockedDimensions),
        };
      }

      return state;
    }
    case 'FETCH_USED_SURVEYS_DATES_FULFILLED': {
      const { data } = action.payload.data;
      const usedDates = data
        .filter(({ id }) => id !== state.survey?.id)
        .map(({ startedAt, endedAt }) => ({
          /** Do not use startOf('day') here, because we did it already in the settigns of survey */
          startedAt: moment.utc(startedAt.date),
          endedAt: moment.utc(endedAt.date),
        }));

      return {
        ...state,
        usedDates: mergeMoments(usedDates),
      };
    }
    case 'FETCH_USED_SURVEYS_REJECTED': {
      return {
        ...state,
        usedDates: [],
      };
    }
    case 'SAVE_SURVEY_PENDING': {
      return { ...state, fetched: false, fetching: true };
    }
    case 'SAVE_SURVEY_FULFILLED': {
      const survey = { ...action.payload.data.data };
      const items = state.items.map(item => {
        if (item.id == survey.id) {
          return survey;
        }

        return item;
      });

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items,
        survey: serializeSurvey(survey),
      };
    }
    case 'SAVE_SURVEY_REJECTED': {
      let error = action.payload;
      switch (error?.response?.status) {
        case 404:
          error = {
            title: error.response?.data?.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
      };
    }
    case 'CREATE_SURVEY_FROM_TEMPLATE_FULFILLED': {
      const survey = action.payload.data.data;
      const items = state.items.map(item => {
        if (item.id == survey.id) {
          return survey;
        }

        return item;
      });

      return {
        ...state,
        items,
        survey: serializeSurvey(survey),
      };
    }
    case 'DELETE_SURVEY_FULFILLED': {
      const surveyId = action.payload.data.deletedId;
      const items = state.items.filter(({ id }) => id != surveyId);

      return {
        ...state,
        deleted: true,
        deleting: false,
        items,
      };
    }

    case 'UPDATE_INSTANCE_STATUS_FULFILLED': {
      const instance = action.payload.data.data;
      const items = state.items.map(item => {
        if (item.id == instance.survey.id) {
          return { ...item, latestInstance: instance };
        }

        return item;
      });

      return {
        ...state,
        items,
      };
    }

    case 'DELETE_CHOICE_PENDING':
    case 'DELETE_QUESTION_PENDING':
    case 'DELETE_DIMENSION_PENDING':
    case 'DELETE_SURVEY_PENDING': {
      return { ...state, deleted: false, deleting: true };
    }

    case 'DELETE_CHOICE_REJECTED':
    case 'DELETE_QUESTION_REJECTED':
    case 'DELETE_DIMENSION_REJECTED':
    case 'DELETE_SURVEY_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        deleted: false,
        deleting: false,
        error,
      };
    }
    case 'CANCEL_PARTICIPATE_IN_TEMPLATE_PENDING':
    case 'PARTICIPATE_IN_TEMPLATE_PENDING': {
      return { ...state, saving: true };
    }
    case 'CANCEL_PARTICIPATE_IN_TEMPLATE_FULFILLED': {
      const { data } = action.payload.data;

      return {
        ...state,
        saving: false,
        saved: true,
        error: null,
        items: state.items.filter(({ id }) => id != data.surveyId),
      };
    }
    case 'PARTICIPATE_IN_TEMPLATE_FULFILLED': {
      const organizationSurvey = action.payload.data.data;
      const surveyId = organizationSurvey?.survey?.id;

      return {
        ...state,
        saving: false,
        saved: true,
        error: null,
        items: state.items.filter(({ id }) => id != surveyId),
      };
    }
    case 'CANCEL_PARTICIPATE_IN_TEMPLATE_REJECTED':
    case 'PARTICIPATE_IN_TEMPLATE_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        saving: false,
        saved: false,
        error,
      };
    }
    default:
      return state;
  }
};

function getErrorsParamsByResourceType(state, resourceType) {
  switch (resourceType) {
    case SURVEY:
      return { errors: state.validationErrors.survey, source: 'survey' };
    case DIMENSION:
      return {
        errors: state.validationErrors.dimensions,
        source: 'dimensions',
      };
    case QUESTION:
      return { errors: state.validationErrors.questions, source: 'questions' };
    case CHOICE:
      return { errors: state.validationErrors.choices, source: 'choices' };
    default:
      return { errors: {}, source: '' };
  }
}

const skipLinkedQuestions = (questionIndex, questions) => {
  if (Array.isArray(questions)) {
    const question = questions[questionIndex];
    let parent = null;
    let indexParent = -1;

    if (question != null && question.parent != null) {
      parent = questions.find(({ id, uid }, index) => {
        const finded =
          (id != null && id === question.parent) ||
          (uid != null && uid === question.parent);
        if (finded) indexParent = index;
        return finded;
      });
    }
    if (parent == null || indexParent === -1 || question.parent == null) {
      parent = question;
      indexParent = questionIndex;
    }
    if (parent != null && indexParent !== -1) {
      const { id, uid } = parent;
      const parentId = id || uid;

      const numberOfLinkedQuestions = questions.filter(
        ({ parent }) => parent != null && parent === parentId,
      ).length;
      return numberOfLinkedQuestions !== 0
        ? indexParent + numberOfLinkedQuestions
        : indexParent;
    }
  }

  return questionIndex;
};

// eslint-disable-next-line no-unused-vars
const persistConfig = {
  key: 'survey-list',
  storage,
  blacklist: [
    'fetching',
    'fetched',
    'deleting',
    'deleted',
    'items',
    'error',
    'pageSize',
    'paginationPage',
    'nbResult',
    'lockedQuestions',
    'lockedDimensions',
  ],
  transforms: [editorStateTransform],
};
// export const surveysReducer = persistReducer(persistConfig, reducer);

export const surveysReducer = reducer;

// Selectors
export const selectCurrentQuestion = ({ survey, current }) => {
  const { currentDimensionIndex, currentQuestionIndex } = current;

  if (
    currentDimensionIndex !== -1 &&
    currentQuestionIndex !== -1 &&
    Array.isArray(survey.dimensions)
  ) {
    const { questions } = survey.dimensions[currentDimensionIndex];
    if (Array.isArray(questions)) {
      return questions[currentQuestionIndex];
    }
  }

  return null;
};
