export const setAuth = auth => ({
  type: 'SET_AUTH',
  auth,
});

export const setExpiresIn = expiresIn => ({
  type: 'SET_EXPIRES_IN',
  expiresIn,
});

export const setTokenCreatedAt = createdAt => ({
  type: 'SET_TOKEN_CREATED_AT',
  createdAt,
});

export const setAuthUser = user => ({
  type: 'SET_AUTH_USER',
  user,
});

export const fetchAuthUser = user => ({
  type: 'FETCH_AUTH_USER',
  payload: user,
});

export const setAuthToken = token => ({
  type: 'SET_AUTH_TOKEN',
  token,
});
export const setAppToken = appToken => ({
  type: 'SET_APP_TOKEN',
  appToken,
});

export const setAuthLoggedAs = loggedAs => ({
  type: 'SET_AUTH_LOGGED_AS',
  loggedAs,
});

export const setIsUserVisitor = isVisitor => ({
  type: 'SET_IS_USER_VISITOR',
  isVisitor,
});

export const setCurrentClient = client => ({
  type: 'SET_CURRENT_CLIENT',
  payload: client,
});

export const setCurrentsUser = token => ({
  type: 'SET_CURRENT_USER',
  token,
});

export const fetchAnonymousAuthUser = user => ({
  type: 'FETCH_ANONYMOUS_AUTH_USER',
  payload: user,
});

export const updateSurveyClientsAuthorization = payload => ({
  type: 'UPDATE_SURVEY_CLIENTS_AUTHORIZATION',
  payload
});

export const switchToDetachedMode = () => ({
  type: 'SET_DETACHED_FROM_CLIENT',
});

export const fetchAppToken = (appToken) => ({
  type: 'FETCH_APP_TOKEN',
  payload: appToken,
});