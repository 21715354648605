import React from 'react';
import _ from 'i18n';
import { useHistory } from 'react-router-dom';
import { TTPSelector } from '../common/TTPSelector';

export default function EditorSelectorTab(props) {
  const { currentTab } = props;
  const history = useHistory();

  return (
    <div className={`tab-pane row align-center boxes centered ${currentTab == 'CODE_OWN' && 'active'}`}>
      <TTPSelector
        tabName={_('Editor')}
        tabLabel={_('satisfactionSurvey')}
        icon="satisfaction"
        tabDescription={_('Create your custom satisfaction survey.')}
        onClick={() => history.push(`/surveys/create/satisfaction${location.search}`)}
      />
      <TTPSelector
        tabName={_('Editor')}
        tabLabel={_('opinionSurvey')}
        icon="opinion"
        tabDescription={_('Create your custom poll survey.')}
        onClick={() => history.push(`/surveys/create/opinion${location.search}`)}
      />
      <TTPSelector
        tabName={_('Editor')}
        tabLabel={_('Quiz')}
        icon="quiz"
        tabDescription={_('Create your custom quiz survey.')}
        onClick={() => history.push(`/surveys/create/quiz${location.search}`)}
      />
      <TTPSelector
        tabName={_('Editor')}
        tabLabel={_('Portal question')}
        icon="portal"
        tabDescription={_('Create your custom portal question.')}
        onClick={() => history.push(`/surveys/create/portal${location.search}`)}
      />
    </div>
  );
}
