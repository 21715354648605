import React, { Component } from 'react';
import autosize from 'autosize';
import { connect } from 'react-redux';
import { addValidationErrors, removeValidationErrors } from 'actions';
import ReactTooltip from 'react-tooltip';
import shortId from 'shortid';
import { getOptions } from 'SurveyConfig';
import { getErrorMessageByCode } from 'surveyUtils';
import DisplayErrors from 'preview-items/DisplayErrors';

export class HeaderLabel extends Component {
  constructor(props) {
    super(props);

    this.timer = null;
    this.idleTime = 0;

    this.state = {
      focused: false,
      value: props.value ? props.value : '',
      language: props.language,
      updated: false,
    };
    this.tooltipId = `tooltip-HeaderLabel-${shortId.generate()}`;
  }

  static defaultProps = {
    onBlur: () => {},
    onEnter: () => {},
  };

  componentDidMount() {
    if (this.props.focusOnMount) {
      this.textarea.focus();
    }

    autosize(this.textarea);
  }

  onChange = ({ target: { value } }) => {
    const { removeValidationErrors, errors, maxChars, disabled } = this.props;
    const { resourceType, property, key } = this.props.validationParams;

    if (disabled) return;

    // control the maximum of chars in input
    if (!maxChars || value.length <= maxChars) {
      this.props.onTextChange(value);
    } else {
      this.props.onTextChange(value.slice(0, maxChars));
    }

    if (value.length >= 2 && errors) {
      removeValidationErrors(key, resourceType, property);
    }
  };

  onFocus = () => {
    this.setState({
      focused: true,
    });
    this.props.onFocus();
  };

  onBlur = () => {
    this.validateLength();
    this.props.onBlur();

    this.setState(prevState => ({
      ...prevState,
      focused: !this.props.hasFocus ? false : prevState.hasFocus,
      updated: false,
    }));
  };

  onKeyDown = e => {
    if (e.keyCode === 8 || e.keyCode == 46) {
      if (!e.target.value) {
        e.preventDefault();
        this.props.onKeyDown();
      }
    }
    if (e.key == 'Enter' && !e.nativeEvent.shiftKey) {
      e.preventDefault();
      this.props.onEnter();
    }
  };

  validateLength() {
    const { addValidationErrors, value } = this.props;
    const { resourceType, key, property } = this.props.validationParams;

    if (value.length < 2) {
      addValidationErrors(key, resourceType, property, { code: 150302 });
    }
  }

  render() {
    const { focused } = this.state;
    const {
      classNames,
      helpText,
      showOrder,
      language,
      required,
      order,
      lng,
      isNps,
      errors,
      value,
      maxChars,
      disabled,
    } = this.props;
    const options = this.props.options || getOptions('DEFAULT');
    let style;
    const styleLogo = {
      backgroundColor: options.color,
    };

    if (isNps) {
      style = {
        opacity: 1,
        borderRight: '5px solid transparent',
        borderTop: '5px solid transparent',
        background: `
        linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)),
        url(/img/survey/nps-logo-2.png)right top / contain no-repeat,
        linear-gradient(${options.color}33, ${options.color}33)`,
        backgroundSize: '30px 30px',
      };
    }

    let className = 'header-label';
    if (this.props.hasFocus || focused) {
      className = `${className} editor-block__focused`;
    }

    return (
      <div className={className}>
        <div
          className={`editor-block__background editor-block__filter ${(classNames &&
            classNames.background) ||
            ''}`}
          style={style}
        />
        <div className="editor-block__pulse" />
        <div className="editor-block__icon">
          <div className="block-icon" style={styleLogo}>
            <div className="block-icon__icon">
              <span>
                <i className={options.icon} />
              </span>
            </div>
            <div className="block-icon__order">
              {order != null && showOrder ? order + 1 : '-'}
            </div>
          </div>
          {maxChars && focused && (
            <div className="block-number ">
              {maxChars - ((value && value.length) || 0)}
            </div>
          )}
          {required && <div className="block-badge">*</div>}
        </div>
        <div className="editor-block editor-block__content">
          <div className="greetings" data-tip data-for={this.tooltipId}>
            <textarea
              className="transparent-input top"
              ref={c => {
                this.textarea = c;
              }}
              onKeyDown={this.onKeyDown}
              placeholder={helpText || options.label}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
              value={value}
              disabled={disabled}
            />
          </div>
          {helpText && helpText != '' && lng != language ? (
            <ReactTooltip
              id={this.tooltipId}
              multiline={true}
              className="react-tooltip"
            >
              <span className="tooltip-content">{helpText}</span>
            </ReactTooltip>
          ) : null}
          {errors &&
            errors.map(
              error =>
                error && (
                  <DisplayErrors
                    message={getErrorMessageByCode(error.code)}
                    key={`error-${error.code}`}
                  />
                ),
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.surveys.list.language,
  lng: state.params.lng,
});

const mapDispatchToProps = { addValidationErrors, removeValidationErrors };

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLabel);
