import React, { Component } from 'react';
import { addRequiredQuestionId } from 'actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextQuestionPreview from './TextQuestionPreview';
import MultipleChoiceQuestionPreview from './MultipleChoiceQuestionPreview';
import RatingQuestionPreview from './RatingQuestionPreview';
import NumberQuestionPreview from './NumberQuestionPreview';
import BooleanQuestionPreview from './BooleanQuestionPreview';
import OpinionScaleQuestionPreview from './OpinionScaleQuestionPreview';
import Description from './Description';

class DimensionPreview extends Component {
  constructor(props) {
    super(props);

    this.scrollToNext = this.scrollToNext.bind(this);
  }

  componentWillUnmount() {
    this.props.removeRef(this.dimensionRef);
  }

  addRef = (ref) => {
    this.dimensionRef = ref;
    this.props.addRef(ref, this.props.dimension.questions ? this.props.dimension.questions.length : null);
  }

  scrollToNext() {
    this.props.scrollToNext(this.dimensionRef);
  }

  renderQuestion() {
    if (this.props.dimension) {
      const { questions } = this.props.dimension;
      if (questions) {
        let order = 0;

        return questions.map((question, key) => {
          const type = question ? question.type : '';
          const uniqueKey = question.id != null ? question.id : question.uid || key;

          if (question.required) {
            this.props.addRequiredQuestionId(question.id || question.uid);
          }

          const props = {
            question,
            key: uniqueKey,
            addRef: this.props.addRef,
            scrollToNext: this.props.scrollToNext,
            dimensionOrder: this.props.order,
            order: question.parent == null ? order++ : order,
          };

          switch (type) {
            case 'TEXT':
              return <TextQuestionPreview {...props} />;
            case 'SINGLE_CHOICE':
            case 'MULTIPLE_CHOICE':
            case 'RATING_CHOICE':
              return <MultipleChoiceQuestionPreview {...props} />;
            case 'NUMBER':
              return <NumberQuestionPreview {...props} />;
            case 'TRUE_FALSE':
            case 'YES_NO':
              return <BooleanQuestionPreview {...props} />;
            case 'RATING':
              return <RatingQuestionPreview {...props} />;
            case 'OPINION_SCALE':
              return <OpinionScaleQuestionPreview {...props} />;
            default:
              return <TextQuestionPreview {...props} />;
          }
        });
      }
    }
    return null;
  }

  renderDimension() {
    const { dimension, language, surveyType } = this.props;
    const { title, description } = dimension;
    const label = title ? title[language] : '';

    if (surveyType == 'PORTAL') {
      return null;
    }

    return (
      description
        ? (
          <div className="preview__container dim__wrapper" ref={ref => this.props.addRef(ref)}>
            <div className="dim__description">
              <div className="dim__label dim__header survey-main-content-background-color ">{label}</div>
              <div className="dim__description__content">
                <Description description={description} scrollToNext={this.scrollToNext} />
              </div>
            </div>
          </div>
        )
        : (
          <div className="preview__container dim__title__wrapper" ref={ref => this.props.addRef(ref)}>
            <div className="dim__header survey-main-content-background-color ">{label}</div>
          </div>
        )
    );
  }

  render() {
    return (
      <div ref={this.addRef}>
        {this.renderDimension()}
        {this.renderQuestion()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.surveys.list.language,
  surveyType: state.surveys.list.survey.type,
});
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addRequiredQuestionId }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(DimensionPreview);
