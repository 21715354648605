import React from 'react';

export default function MenuItem({ href, icon, renderIcon, children, count, className = '' }) {
  return (
    <li className={`social-links ${children ? 'expandable' : ''} ${className}`}>
      <a href={href}>
        {typeof renderIcon == 'function' ? renderIcon() : <i className={`icomoon ${icon}`} />}
      </a>
      {children}
      {count ? <span className="badge">{count > 99 ? '99+' : count }</span> : null}
    </li>
  );
}
