import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Users from 'settings/Users';
import FilterBar from 'settings/FilterBar';
import Bubbles from 'settings/Bubbles';
import Sidebar from 'settings/Sidebar';
import { setCurrentNavPage } from 'actions';
import { TTP_HOME_URL, TTP_SURVEY_URL } from 'Config';
import _ from 'i18n';

class Settings extends Component {
  componentDidMount() {
    this.props.setCurrentNavPage('SETTINGS');
    window.addEventListener('click', this.handleClickeventListener);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickeventListener);
  }

  handleClickeventListener = (e) => {
    const rolesList = document.getElementsByClassName('role-list').item(0).classList;
    const mask = document.getElementsByClassName('mask').item(0);

    if (!rolesList.contains('hide') && e.target.id != 'role-list-display') {
      rolesList.add('hide');
    } else if (e.target == mask) {
      mask.classList.add('hide');
      document.getElementById('authorisation-settings__sidebar').classList.remove('display');
      document.getElementById('controls').classList.remove('display');
    }
  }

  renderNewUserLink() {
    const { location: { pathname, search } } = this.props.router;
    const addUserLink = `${TTP_HOME_URL}/users/create`;
    const returnto = encodeURI(TTP_SURVEY_URL + pathname + search);

    return <a href={`${addUserLink}?returnto=${returnto}`} className="authorisation-settings__newuser-link">{_('Add new user')}</a>;
  }

  render() {
    return (
      <div className="authorisation-settings">
        <div className="authorisation-settings__filter-bar row align-center">
          <FilterBar />
        </div>
        <div id="authorisation-setting-users" className="row align-center">
          <Users />
        </div>
        <div className="row align-center">
          <div className="authorisation-settings__newuser small-8 row align-center">
            {this.renderNewUserLink()}
          </div>
        </div>
        <Bubbles />
        <Sidebar />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  router: state.router,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCurrentNavPage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
