// ranking clients

export const initializedParticipantOrganization = () => ({
  type: 'INITIALIZED_PARTICIPANT_ORGANIZATION',
});

export const fetchParticipantOrganizations = resp => ({
  type: 'FETCH_PARTICIPANT_ORGANIZATION',
  payload: resp,
});

export const fetchParticipantOrganizationsOptions = resp => ({
  type: 'FETCH_PARTICIPANT_ORGANIZATION_OPTIONS',
  payload: resp,
});

// Filters:

export const setParticipantOrganizationPaginationPage = pageNumber => ({
  type: 'SET_PARTICIPANT_ORGANIZATION_PAGINATION_PAGE',
  pageNumber,
});

export const setParticipantOrganizationPageSize = pageSize => ({
  type: 'SET_PARTICIPANT_ORGANIZATION_PAGE_SIZE',
  pageSize,
});

export const setParticipantOrganizationFilterCountry = country => ({
  type: 'SET_PARTICIPANT_ORGANIZATION_FILTER_COUNTRY',
  country,
});

export const setParticipantOrganizationFilterCity = city => ({
  type: 'SET_PARTICIPANT_ORGANIZATION_FILTER_CITY',
  city,
});

export const setParticipantOrganizationFilterOrganizationName = organizationName => ({
  type: 'SET_PARTICIPANT_ORGANIZATION_FILTER_ORGANIZATION_NAME',
  organizationName,
});

// SORT:
export const setParticipantsSortConfig = (columnKey, sortDirection) => ({
  type: 'SET_PARTICIPANT_ORGANIZATION_LIST_SORT_CONFIG',
  config: {
    columnKey,
    sortDirection,
  },
});
