import * as actions from 'actions';
import { getSurveys, postParticipate, postCancelParticipate, postTransformToSectorialTemplate } from 'TamtamAPI';
import { defaultTheme } from 'themes';

// export const fetchTemplates = type => (dispatch, getState) => {
//   const { token, currentClient } = getState().auth;
//   return dispatch(actions.fetchTemplates(
//     getSurveys(token, null, {
//       isTemplate: true,
//       type,
//       currentClient: currentClient ? currentClient.id : null,
//     }),
//   ));
// };

export const fetchTemplates = type => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  const {
    filter: {
      status,
      title,
      owner,
      env,
      pageSize, paginationPage
    },
  } = getState().templates;
  const { sort: { sortConfig } } = getState().surveys;

  const data = {
    isTemplate: true,
    isSectorial: false,
    paginationPage,
    pageSize,
    sortConfig,
    status,
    type,
    title,
    owner,
    env,
    currentClient: currentClient ? currentClient.id : null,
  };

  const mode = (paginationPage == 1) ? 'FETCH' : 'PUSH';

  return dispatch(actions.fetchTemplates(
    getSurveys(token, null, data),
    mode
  ));
};

export const fetchTemplate = slug => (dispatch, getState) => {
  const { token } = getState().auth;
  return dispatch(actions.fetchTemplate(getSurveys(token, slug).then((res) => {
    const survey = res.data.data;
    const theme = survey && survey.theme ? survey.theme : defaultTheme;
    dispatch(actions.setSurveyTheme(theme));
    return res;
  })));
};

export const participate = ({ ...survey } = {}, accepted) => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  return dispatch(actions.participate(
    postParticipate(
      token,
      survey,
      currentClient ? currentClient.id : null,
      accepted,
    )
  ));
};

export const cancelParticipate = surveyId => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  return dispatch(actions.cancelParticipate(
    postCancelParticipate(
      token,
      surveyId,
      currentClient ? currentClient.id : null,
    )
  ));
};

export const transformToSectorialTemplate = slug => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  return dispatch(actions.transformToSectorialTemplate(
    postTransformToSectorialTemplate(
      token,
      slug,
      currentClient ? currentClient.id : null,
    )
  ));
};
