import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Open from '../icons/Open';
import Play from '../icons/Play';
import _ from 'i18n';
import { getSurveyLinkPreview } from 'surveyUtils';

export default memo(function PreviewWarning({ survey, organizationId }) {
  return (
    <div className="preview__ctrlPanel__infos survey-main-content-background-color">
      <span>{_('This is only a preview.')}</span>
      &nbsp;{_('See the')}&nbsp;
      <Link
        to={getSurveyLinkPreview(survey, true, organizationId)}
        target="_blank"
      >
        <u>{_('published version')}</u>
        &nbsp;
        {survey.type === 'QUIZ' ? (
          <Play fill="#F1F2F4" />
        ) : (
          <Open fill="#F1F2F4" />
        )}
      </Link>
    </div>
  );
});
