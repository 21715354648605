import React, { Component } from 'react';
import _ from 'i18n';

export default class Popup extends Component {
  constructor(props) {
    super(props);

    this.closePopup = this.closePopup.bind(this);
    this.action = this.action.bind(this);
  }

  closePopup() {
    const popupId = `#${this.props.popupId}`;
    $(popupId).foundation('close');
  }

  action() {
    this.props.action();
    this.closePopup();
  }

  render() {
    const { label } = this.props;
    const className = `button btn-popup ${label.className}`;
    return (
      <div className="reveal srv-reveal" id={this.props.popupId} data-reveal>
        <div className="modal-title">
          {' '}
          <h5>{this.props.message}</h5>
        </div>
        <div className="row float-right">
          <button className="button btn-popup btn-popup--default" type="button" onClick={this.closePopup}>{label.inaction ? label.inaction : _('Close')}</button>
          <button type="button" className={className} onClick={this.action}>{label.action ? label.action : _('Ok')}</button>
        </div>
        <button className="close-button" type="button" onClick={this.closePopup}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}
