import React, { Component } from 'react';
import shortId from 'shortid';

class CheckBox extends Component {
  render() {
    const { onChange, disabled, checked } = this.props;
    const id = this.props.id || shortId.generate();

    return (
      <span>
        <input
          className="checkbox-hidden"
          type="checkbox"
          id={id}
          checked={checked}
          disabled={disabled}
          onChange={!disabled ? e => onChange(e.target.checked) : null}
        />
        <label htmlFor={id} className="check-box" />
      </span>
    );
  }
}

export default CheckBox;
