import React, { Component } from 'react';
import _ from 'i18n';

export default class PreviewControlButton extends Component {
  goBack = () => this.props.onClick(true);

  goNext = () => this.props.onClick();

  render() {
    const { disabled, show } = this.props;

    return (
      <div className="qst__navigation">
        <div className="navigation-button vertical">
          <button
            type="button"
            onClick={this.goBack}
            className="survey-main-content-gradient-background-hover"
          >
            <span className="flex-column-center text">{_('previous')}</span>
            <span
              className={`flex-column-center icon-wrapper survey-main-color survey-main-content-gradient-background-hover ${
                !show ? 'survey-main-color' : ''
              }`}
            >
              <i className="icon-arrow-up" />
            </span>
          </button>
          <button
            type="button"
            className={`survey-main-content-gradient-background-hover survey-main-color ${
              show
                ? 'show survey-main-content-gradient-background-color survey-main-gradient-color'
                : ''
            }`}
            onClick={this.goNext}
            disabled={disabled}
          >
            <span className="flex-column-center text">
              {show ? _('validate') : _('next')}
            </span>
            <span
              className={`flex-column-center icon-wrapper survey-main-content-gradient-background-hover ${
                !show ? 'survey-main-color' : ''
              }`}
            >
              <i className="icon-arrow-down" />
            </span>
          </button>
        </div>
        {show && (
          <button
            className="mobile-only survey-main-content-gradient-background-color survey-main-gradient-color"
            type="button"
            onClick={this.goNext}
            disabled={disabled}
          >
            <span className="flex-column-center text">{_('validate')}</span>
          </button>
        )}
      </div>
    );
  }
}
