import React from 'react';
import { COPYRIGHT_YEAR } from 'Common';
import _ from 'i18n';

const Footer = () => (
  <footer id="footer">
    <div className="main-footer align-center p-y-m">
      <div className="row">
        <div className="column small-2">
          <img alt="" src="/img/pictos/full-logo.svg" />
        </div>
        <div className="column small-3 footer__contact">
          <ul>
            <li><i className="icomoon icon-tt-facebook" /></li>
            <li><i className="icomoon icon-tt-linkedin" /></li>
            <li><i className="icomoon icon-tt-instagram" /></li>
            <li><i className="icomoon icon-tt-contact" /></li>
          </ul>
        </div>
        <div className="column small-7 footer__about">
          <p className="p-x-s">
            <b>Tamtam.pro </b>
            {_('about_us_content')}
          </p>
        </div>
      </div>
    </div>
    <div className="copyright row">
      <p>
        © Copyright -
        {COPYRIGHT_YEAR}
        {' '}
        <strong>Tamtam International</strong>
      </p>
    </div>
  </footer>
);

export default Footer;
