import axios from 'axios';
import { throwCatchedError } from 'utils';
import { TTP_API_URL } from 'Config';

export const getGenericEmailSettingsStats = (token, organizationId, surveyId) => {
  const requestUrl = `${TTP_API_URL}/mailing/generic-email-settings-stats`;

  const params = {
    access_token: token,
    targetApp: 'SURVEY',
    targetId: surveyId,
    organizationId,
  };

  return axios.get(requestUrl, {
    params
  }).catch(function (thrown) { throwCatchedError(thrown); }); // eslint-disable-line
};
