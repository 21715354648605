import React, { Component } from 'react';

export default class CountDown extends Component {
  state = { time: this.props.start || 30 };

  handleCountDown = () => {
    if (this.state.time <= 0 && this.interval) {
      clearInterval(this.interval);
      this.props.onTimeOut();
      return;
    }

    this.setState(prevState => ({ time: --prevState.time }));
  }

  componentDidMount() {
    this.interval = setInterval(this.handleCountDown, 1000);
  }

  render() {
    let color;
    const { time } = this.state;

    if (time < 5) {
      color = 'red';
    } else if (time < 10) {
      color = 'orange';
    } else {
      color = 'blue';
    }

    return <div className={`count-down ${color}`}>{this.state.time}</div>;
  }
}
