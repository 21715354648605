import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { closeCurrentQuestion, fetchQuestionStats, fetchTopTen } from 'thunks';

const withResult = Wrapper => class WithResult extends Component {
  state = { showStats: false, statsData: {}, fetching: false, tryStats: 3, tryResult: 3 };

  setStatsData = (data, nbResponse) => {
    const statsData = data.reduce(
      (prevValue, { choice, count }) => ({ ...prevValue, ...{ [choice.id]: nbResponse < 1 ? 0 : (count / nbResponse) * 100 } }),
      {}
    );

    this.setState({ statsData, fetching: false });
  }

  fetchQuestionStats() {
    const { instance, question } = this.props;

    this.setState({ fetching: true });
    this.props.setIsPending(true);
    this.props.fetchQuestionStats(instance.id, question.id)
      .then(({ data: { data: { data, nbResponse } } }) => {
        this.setStatsData(data, nbResponse);
        this.props.setIsPending(false);
      }).catch(() => {
        if (this.state.tryStats > 0) {
          this.fetchQuestionStats();
          this.setState(prevState => ({ tryStats: prevState.tryStats - 1 }));
        } else {
          this.setState({ fetching: false });
          this.props.setIsPending(false);
        }
      });
  }

  fetchResult = () => this.props
    .fetchTopTen(this.props.instance.id)
    .catch(() => {
      if (this.state.tryResult > 0) {
        this.fetchResult();
        this.setState(prevState => ({ tryResult: prevState.tryResult - 1 }));
      }
    });

  onTimeOut = () => {
    const { question, lastQuestionId } = this.props;

    this.notifyParticipants();
    this.fetchQuestionStats();

    if (question.id != lastQuestionId) {
      this.fetchResult();
    }

    this.setState({ showStats: true });
  }

  notifyParticipants = () => {
    const { instance } = this.props;

    this.props.closeCurrentQuestion(instance.pin);
  }

  render() {
    const { showStats, statsData, fetching } = this.state;
    const props = { showStats, statsData, fetching, ...this.props };

    return <Wrapper {...props} onTimeOut={this.onTimeOut} />;
  }
};


const mapDispatchToProps = { closeCurrentQuestion, fetchQuestionStats, fetchTopTen };

const composedWithResult = compose(
  connect(null, mapDispatchToProps),
  withResult
);

export default composedWithResult;
