import React, { Component } from 'react';
import { connect } from 'react-redux';
import { initializeCampaignStats, openCampaignViewer } from 'actions';
import { fetchCampaignStats, fetchGenericEmailSettingsStats } from 'thunks';
import __ from 'lodash';
import _ from 'i18n';
import CampaignCreatorButton from './CampaignCreatorButton';

const AvailableLanguages = ({ title, languages, isValid }) => (
  <div className="column note-item">
    <div className="text-center">
      {isValid && <i className="icomoon icon-tt-correct is-valid" />}
      <span>{title}</span>
    </div>
    <div className="available-lngs-wrapper">
      {
        languages.map(lng => <div className="lng-icon" key={`available-lng-${lng}`}><img alt="" src={`/img/flags/${lng}.svg`} /></div>)
      }
    </div>
  </div>
);

class SurveyCampaignStats extends Component {
  componentDidMount() {
    const { surveySlug, survey: { slug, latestInstance } } = this.props;
    if (slug && surveySlug == slug) {
      this.props.initializeCampaignStats();
      this.props.fetchCampaignStats(latestInstance ? latestInstance.id : 0);
      this.props.fetchGenericEmailSettingsStats();
    }
  }

  componentDidUpdate(prevProps) {
    const { slug, latestInstance } = this.props.survey;
    const prevSurvey = prevProps.survey;

    if (slug != prevSurvey.slug && slug != null) {
      this.props.fetchCampaignStats(latestInstance ? latestInstance.id : 0);
      this.props.fetchGenericEmailSettingsStats();
    }
  }

  handleClick = (data = {}) => {
    this.props.openCampaignViewer({ isOpen: true, ...data });
  }

  renderNote = () => {
    const { stats, fetching, fetched } = this.props.campaignStats;
    let data = {
      revivalFrCount: 0,
      revivalNlCount: 0,
      revivalEnCount: 0,
      partialRevivalFrCount: 0,
      partialRevivalNlCount: 0,
      partialRevivalEnCount: 0,
    };

    if (!fetching && fetched && stats.organizationInstance) {
      const {
        revivalFrCount,
        revivalNlCount,
        revivalEnCount,
        partialRevivalFrCount,
        partialRevivalNlCount,
        partialRevivalEnCount,
      } = stats.organizationInstance;
      data = {
        revivalFrCount,
        revivalNlCount,
        revivalEnCount,
        partialRevivalFrCount,
        partialRevivalNlCount,
        partialRevivalEnCount,
      };
    }

    const count = __.reduce(data, (res, item) => res + item, 0);

    if (fetching || count == 0) {
      return null;
    }

    const revival1Langauges = [];
    if (data.revivalFrCount == 1) {
      revival1Langauges.push('fr');
    }
    if (data.revivalNlCount == 1) {
      revival1Langauges.push('nl');
    }
    if (data.revivalEnCount == 1) {
      revival1Langauges.push('en');
    }

    const revival2Langauges = [];
    if (data.revivalFrCount > 1) {
      revival2Langauges.push('fr');
    }
    if (data.revivalNlCount > 1) {
      revival2Langauges.push('nl');
    }
    if (data.revivalEnCount > 1) {
      revival2Langauges.push('en');
    }

    const partialRevival1Langauges = [];
    if (data.partialRevivalFrCount == 1) {
      partialRevival1Langauges.push('fr');
    }
    if (data.partialRevivalNlCount == 1) {
      partialRevival1Langauges.push('nl');
    }
    if (data.partialRevivalEnCount == 1) {
      partialRevival1Langauges.push('en');
    }

    const partialRevival2Langauges = [];
    if (data.partialRevivalFrCount > 1) {
      partialRevival2Langauges.push('fr');
    }
    if (data.partialRevivalNlCount > 1) {
      partialRevival2Langauges.push('nl');
    }
    if (data.partialRevivalEnCount > 1) {
      partialRevival2Langauges.push('en');
    }

    return (
      <div className="note-wrapper m-b-s">
        <div className="row title">
          <span><i className="icomoon icon-tt-pin" /></span>
          <span className="m-l-xs">{_('The reminders sent')}</span>
        </div>
        <div className="row content">
          {
            revival1Langauges.length > 0 && (
              <AvailableLanguages languages={revival1Langauges} title={`${_('Revival')} 1`} />
            )
          }
          {
            revival2Langauges.length > 0 && (
              <AvailableLanguages languages={revival2Langauges} title={`${_('Revival')} 2`} />
            )
          }
          {
            partialRevival1Langauges.length > 0 && (
              <AvailableLanguages languages={partialRevival1Langauges} title={`${_('PartialRevival')} 1`} />
            )
          }
          {
            partialRevival2Langauges.length > 0 && (
              <AvailableLanguages languages={partialRevival2Langauges} title={`${_('PartialRevival')} 2`} />
            )
          }
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="column small-12">
          {this.renderNote()}
        </div>
        <div className="column small-12 m-b-s">
          <CampaignCreatorButton />
        </div>
      </>
    );
  }
}


const mapStateToProps = state => ({
  campaignStats: state.campaignStats,
});

const mapDispatchToProps = { fetchCampaignStats, initializeCampaignStats, fetchGenericEmailSettingsStats, openCampaignViewer };

export default connect(mapStateToProps, mapDispatchToProps)(SurveyCampaignStats);
