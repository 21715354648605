import React, { Component } from 'react';

export default class TTPDropDownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, isHover: false };
    this.refMenu = React.createRef();

    this.onMouseEnter = () => this.handleMouseEvent(true);
    this.onMouseLeave = () => this.handleMouseEvent(false);
  }

  static defaultProps = {
    className: '',
    type: 'hover'
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleOpenMenu = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }), () => {
      const { onActive } = this.props;
      if (typeof onActive === 'function') onActive(this.state.isOpen);
    });
  }

  handleMouseEvent(isHover) {
    this.setState({ isHover }, () => {
      const { onActive } = this.props;
      if (typeof onActive === 'function') onActive(isHover);
    });
  }

  handleClickOutside = (event) => {
    if (this.state.isOpen && this.refMenu && !this.refMenu.current.contains(event.target) && !event.target.closest('.reveal')) {
      this.setState({ isOpen: false });
    }
  }

  render() {
    const { children, type, activeClassName, className, style } = this.props;
    const { isOpen, isHover } = this.state;

    return (
      <div
        ref={this.refMenu}
        onClick={type === 'click' ? this.handleOpenMenu : null}
        onMouseEnter={type === 'hover' ? this.onMouseEnter : null}
        onMouseLeave={type === 'hover' ? this.onMouseLeave : null}
        className={`ttp-drop-down-menu menu text-center ${(isOpen || isHover) && (activeClassName ?? 'active')} ${className} ${type}`}
        style={style}
      >
        {
          typeof this.props.renderIcon === 'function'
            ? this.props.renderIcon()
            : <i className="icon-options" />
        }
        {
          (type === 'hover' || (type === 'click' && isOpen)) && (
            <ul>
              {children}
            </ul>
          )
        }
      </div>
    );
  }
}
