import React from 'react';
import _ from 'i18n';


const ParticipantsViewFetching = () => (
  <div className="medium-12 columns">
    <table className="unstriped hover">
      <thead>
        <tr>
          <th className="text-center email greetings"><label>{_('Email')}</label></th>
          <th className="text-center"><label>{_('Invited')}</label></th>
          <th className="text-center"><label>{_('Revival')}</label></th>
          <th className="text-center"><label>{_('PartialRevival')}</label></th>
          <th className="text-center"><label>{_('Participated')}</label></th>
          <th className="text-center"><label>{_('Thanks')}</label></th>
          <th className="text-center"><label>{_('Results')}</label></th>
        </tr>
      </thead>
      <tbody className="list-fetching">
        {
          Array.from(Array(5).keys()).map(item => (
            <tr key={item}>
              <td className="text-left"><h5 className="gradiant" /></td>
              <td className="text-center"><h5 className="gradiant" /></td>
              <td className="text-center"><h5 className="gradiant" /></td>
              <td className="text-center"><h5 className="gradiant" /></td>
              <td className="text-center"><h5 className="gradiant" /></td>
              <td className="text-center"><h5 className="gradiant" /></td>
              <td className="text-center"><h5 className="gradiant" /></td>
            </tr>
          ))
        }
      </tbody>
    </table>
  </div>
);

export default ParticipantsViewFetching;
