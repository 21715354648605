import React from 'react';

export default function PageHeader({ icon, title, subTitle, children }) {
  return (
    <div className="page-header m-b-xl">
      <div className="page-header__icon flex-column-center">
        <span className="flex-column-center"><i className={icon} /></span>
      </div>
      <div className="page-header__info">
        <div className="title">{title}</div>
        {subTitle && String(subTitle).trim() !== '' ? <div className="title sub-title">{subTitle}</div> : null}
      </div>
      <div className="page-header__actions">{children}</div>
    </div>
  );
}
