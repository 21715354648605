import axios from 'axios';
import { TTP_API_URL } from 'Config';
import _ from 'lodash';
import { throwCatchedError } from 'utils';

export const saveSurveyResponse = (token, language, surveyResponse, answers, autoSave = false) => {
  const requestUrl = `${TTP_API_URL}/survey/response`;
  const formData = new FormData();
  formData.append('access_token', token);

  if (surveyResponse.userParticipant) {
    formData.append('userParticipant', surveyResponse.userParticipant);
  }

  if (surveyResponse.instance) {
    formData.append('instance', surveyResponse.instance);
  }
  if (surveyResponse.organization) {
    formData.append('organization', surveyResponse.organization);
  }
  if (surveyResponse.client) {
    formData.append('client', surveyResponse.client);
  }
  if (autoSave) {
    formData.append('auto-save', 1);
  }
  if (surveyResponse.id) {
    formData.append('id', surveyResponse.id);
  }
  if (surveyResponse.subscribedToStats != null) {
    formData.append('subscribedToStats', surveyResponse.subscribedToStats ? 1 : 0);
  }
  if (language) {
    formData.append('language', language);
  }

  formData.append('isAnonymous', surveyResponse.isAnonymous ? 1 : 0);

  let i = 0;
  _.forEach(answers, (answer) => {
    if (answer.id) {
      formData.append(`answers[${i}][id]`, answer.id);
    }
    formData.append(`answers[${i}][question]`, answer.question);
    formData.append(`answers[${i}][resourceType]`, answer.resourceType);
    formData.append(`answers[${i}][isAutoFilled]`, answer.isAutoFilled ? 1 : 0);
    if (answer.hasOwnProperty('answerValue')) {
      formData.append(`answers[${i}][answerValue]`, answer.answerValue);
    }
    if (answer.hasOwnProperty('choice') && answer.choice) {
      for (let j = 0; j < answer.choice.length; j++) {
        formData.append(`answers[${i}][choices][${j}]`, answer.choice[j]);
      }
    } else {
      formData.append(`answers[${i}][choices]`, '');
    }
    if (answer.hasOwnProperty('otherValue')) {
      formData.append(`answers[${i}][otherValue]`, answer.otherValue || '');
    }
    ++i;
  });

  return axios.post(requestUrl, formData);
};

export const removeAnswer = (token, answerId) => {
  const requestUrl = `${TTP_API_URL}/survey/answer/${answerId}`;

  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('_method', 'delete');

  return axios.post(requestUrl, formData);
};

export const subscribeToStats = (token, responseId) => {
  const requestUrl = `${TTP_API_URL}/survey/response/stats-subscribe`;

  const formData = new FormData();

  formData.append('id', responseId);
  formData.append('access_token', token);
  formData.append('_method', 'post');

  return axios.post(requestUrl, formData);
};

export const getSurveyResponse = (token, instanceId, organization, client) => {
  const requestUrl = `${TTP_API_URL}/survey/response/${instanceId}`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization,
      client
    },
  }).catch((thrown) => { throwCatchedError(thrown); });
};

export const getResponses = (token, instanceId, organizationId, data) => {
  const requestUrl = `${TTP_API_URL}/survey/response`;
  const { pageSize, paginationPage, sortConfig, status, fields } = data;
  const sort = [];
  const filter = [
    {
      property: 'instance.id',
      value: instanceId,
      operator: 'eq',
    },
    {
      property: 'organization',
      value: organizationId,
      operator: 'eq',
    }
  ];

  if (status) {
    filter.push({
      property: 'status',
      value: status,
      operator: 'eq',
    });
  }

  if (sortConfig) {
    sort.push({
      property: sortConfig.columnKey,
      dir: sortConfig.sortDirection,
    });
  }

  const params = {
    access_token: token,
    limit: pageSize || 10,
    start: (!paginationPage) ? 0 : ((paginationPage - 1) * pageSize),
    fields: (fields ?? []).join(','),
  };

  params.filter = JSON.stringify(filter);
  params.sort = JSON.stringify(sort);

  return axios.get(requestUrl, { params });
};
