export const themeTemplate = {
  font: true,
  name: true,
  colors: {
    dimension: true,
    question: true,
    answer: true,
    button: true,
    background: true,
  },
  background: {
    isTransparent: true,
    image: true,
  }
};

export const defaultTheme = {
  font: 'Karla',
  name: 'Plain Blue',
  colors: {
    dimension: '#020C2F',
    question: '#3D3D3D',
    answer: '#5081be',
    button: '#020c2f',
    background: '#FAFBFB'
  },
};

export const fontsList = [
  'Acme', 'Arial', 'Arvo', 'Bangers', 'Cabin', 'Cabin Condensed', 'Courier', 'Karla'
];

export const validConfigValue = (section, sectionKey) => {
  if (!themeTemplate[section]) {
    console.warn(`Survey theme error: "${section}" not a valid config section. These values will not be set. Please use The template: `, themeTemplate);
    return false;
  }

  if (!themeTemplate[section][sectionKey]) {
    console.warn(`Survey theme error: "${sectionKey}" not a valid config key. This value will not be set. Please use The template: `, themeTemplate);
    return false;
  }

  return true;
};

export const rgbToHex = (r, g, b) => '#' + [r, g, b].map((x) => { // eslint-disable-line prefer-template
  const hex = x.toString(16);
  return hex.length === 1 ? `'0' + ${hex}` : hex;
}).join('');

export const hexToRgb = hex => hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,
  (m, r, g, b) => '#' + r + r + g + g + b + b) // eslint-disable-line prefer-template
  .substring(1).match(/.{2}/g)
  .map(x => parseInt(x, 16));
