import React, { Component } from 'react';
import _ from 'i18n';
import { onError, onSuccess, getMomentDiff } from 'utils';
import moment from 'moment';
import { TTPDropDownMenu, MenuItem } from 'common/TTPDropDownMenu';

const MIN_DAYS_REMINDER = 5;

export default class ParticipantAction extends Component {
  handleTransactionMail = (type) => {
    this.props.handleTransactionMail(type, [this.props.participant.id])
      .then((res) => {
        onSuccess(res);
      }, err => onError(err));
  }

  getTextRemainingDays(date1, date2) {
    const { value, mainProperty, diff } = getMomentDiff(date1, date2);

    if (value == 1) {
      return _(diff);
    }
    return `${value} ${_(mainProperty)}`;
  }

  getResultsAuthorization() {
    const { isResultsGEAvailable, participant } = this.props;
    let resultsDisabledMsg;

    switch (participant.resultsStatus) {
      case 'NOT_ASKED':
        resultsDisabledMsg = _('Results not asked');
        break;
      case 'RECEIVED':
        resultsDisabledMsg = _('User has already received results');
        break;
      default: break;
    }

    let resultsDisabled = ['NOT_ASKED', 'RECEIVED'].indexOf(participant.resultsStatus) !== -1;

    if (!resultsDisabled && !isResultsGEAvailable) {
      resultsDisabled = true;
      resultsDisabledMsg = `${_('Missing generic emailing of language')} ${participant.language && participant.language.toUpperCase()}`;
    }

    return {
      disabled: resultsDisabled,
      message: resultsDisabledMsg
    };
  }

  getRevivalAuthorization() {
    const { isRevivalGEAvailable, participant } = this.props;
    let revivalDisabledMsg;

    if (participant.participated) {
      revivalDisabledMsg = _('User has already completed the survey');
    } else if ((participant.revival1 && participant.revival2)) {
      revivalDisabledMsg = _('The maximum number of reminders has been exceeded');
    }

    let revivalDisabled = (participant.revival1 && participant.revival2) || participant.participated;

    if (!revivalDisabled && participant.createdAt) {
      const minDate = moment().subtract(MIN_DAYS_REMINDER, 'd');
      const createdAtDate = moment(participant.createdAt);

      if (createdAtDate > minDate) {
        revivalDisabled = true;
        revivalDisabledMsg = `${_('User was invited at')} ${createdAtDate.format('DD/MM/YYYY')}, ${_('you can send reminder after')} ${this.getTextRemainingDays(createdAtDate, minDate)}`;
      }
    }
    if (!revivalDisabled && participant.latestRevivalAt) {
      const minDate = moment().subtract(MIN_DAYS_REMINDER, 'd');
      const latestRevivalAtDate = moment(participant.latestRevivalAt);

      if (latestRevivalAtDate > minDate) {
        revivalDisabled = true;
        revivalDisabledMsg = `${_('User already received a reminder at')} ${latestRevivalAtDate.format('DD/MM/YYYY')}, ${_('you can send reminder after')} ${this.getTextRemainingDays(latestRevivalAtDate, minDate)}`;
      }
    }

    if (!revivalDisabled && !isRevivalGEAvailable) {
      revivalDisabled = true;
      revivalDisabledMsg = `${_('Missing generic emailing of language')} ${participant.language && participant.language.toUpperCase()}`;
    }

    return {
      disabled: revivalDisabled,
      hide: participant.responseStatus != 'INVITED',
      message: revivalDisabledMsg
    };
  }

  getPartialRevivalAuthorization() {
    const { isPartialRevivalGEAvailable, participant } = this.props;
    let partialRevivalDisabledMsg;

    if (participant.participated) {
      partialRevivalDisabledMsg = _('User has already completed the survey');
    } else if (participant.partialRevival1 && participant.partialRevival2) {
      partialRevivalDisabledMsg = _('The maximum number of reminders has been exceeded');
    }

    let partialRevivalDisabled = (participant.partialRevival1 && participant.partialRevival2) || participant.participated;

    if (!partialRevivalDisabled && participant.createdAt) {
      const minDate = moment().subtract(MIN_DAYS_REMINDER, 'd');
      const createdAtDate = moment(participant.createdAt);

      if (createdAtDate > minDate) {
        partialRevivalDisabled = true;
        partialRevivalDisabledMsg = `${_('User was invited at')} ${createdAtDate.format('DD/MM/YYYY')}, ${_('you can send reminder after')} ${this.getTextRemainingDays(createdAtDate, minDate)}`;
      }
    }
    if (!partialRevivalDisabled && participant.latestPartialRevivalAt) {
      const minDate = moment().subtract(MIN_DAYS_REMINDER, 'd');
      const latestPartialRevivalAtDate = moment(participant.latestPartialRevivalAt);

      if (latestPartialRevivalAtDate > minDate) {
        partialRevivalDisabled = true;
        partialRevivalDisabledMsg = `${_('User already received a reminder at')} ${latestPartialRevivalAtDate.format('DD/MM/YYYY')}, ${_('you can send reminder after')} ${this.getTextRemainingDays(latestPartialRevivalAtDate, minDate)}`;
      }
    }

    if (!partialRevivalDisabled && !isPartialRevivalGEAvailable) {
      partialRevivalDisabled = true;
      partialRevivalDisabledMsg = `${_('Missing generic emailing of language')} ${participant.language && participant.language.toUpperCase()}`;
    }

    return {
      disabled: partialRevivalDisabled,
      hide: participant.responseStatus == 'INVITED',
      message: partialRevivalDisabledMsg
    };
  }

  render() {
    const resultsAuthorization = this.getResultsAuthorization();
    const revivalAuthorization = this.getRevivalAuthorization();
    const partialRevivalAuthorization = this.getPartialRevivalAuthorization();

    return (
      <TTPDropDownMenu activeClassName="" className="vertical">
        <MenuItem
          text={_('Send results email')}
          disabled={resultsAuthorization.disabled}
          disabledTooltip={{ type: 'warning', text: resultsAuthorization.message }}
          onClick={() => this.handleTransactionMail('SURVEY_RESULTS')}
        />
        {
          !revivalAuthorization.hide
          && (
            <MenuItem
              text={_('Send a reminder email')}
              disabled={revivalAuthorization.disabled}
              disabledTooltip={{ type: 'warning', text: revivalAuthorization.message }}
              onClick={() => this.handleTransactionMail('SURVEY_REMINDER')}
            />
          )
        }
        {
          !partialRevivalAuthorization.hide
          && (
            <MenuItem
              text={_('Send a partial reminder email')}
              disabled={partialRevivalAuthorization.disabled}
              disabledTooltip={{ type: 'warning', text: partialRevivalAuthorization.message }}
              onClick={() => this.handleTransactionMail('SURVEY_PARTIAL_REMINDER')}
            />
          )
        }
      </TTPDropDownMenu>
    );
  }
}
