import React, { Component } from 'react';

export default class GroupsCell extends Component {
  state = { viewMoreGroups: false };

  render() {
    const { groups } = this.props;
    const { viewMoreGroups } = this.state;
    const groupsBlock = [];
    const groupsInLength = groups?.length ?? 0;

    for (let i = 0; i < groupsInLength && i < 3; i++) {
      groupsBlock.push(<span key={i} className="list__row-group-cell-tag">{groups[i]}</span>);
    }
    if (groupsInLength > 3) {
      for (let i = 3; i < groupsInLength; i++) {
        groupsBlock.push(<span key={i} className={viewMoreGroups ? 'list__row-group-cell-tag' : 'list__row-group-cell-tag hide'}>{groups[i]}</span>);
      }
    }

    return (
      <div className="list__row-groups-cell-wrapper">
        {
          groupsInLength < 4 && (
            <div className="groups-block groups-block-position">{groupsBlock}</div>
          )
        }
        {
          groupsInLength > 3 && (
            <div className="groups-block">
              {groupsBlock}
              <div className="more-groups-container" onClick={() => this.setState(prevState => ({ viewMoreGroups: !prevState.viewMoreGroups }))}>
                <img src="img/icons/ChevronDown.svg" className={viewMoreGroups ? 'flip' : ''} alt="" />
              </div>
            </div>
          )
        }
      </div>
    );
  }
}
