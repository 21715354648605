import React, { Component } from 'react';
import _ from 'i18n';
import __ from 'lodash';
import { TAMTAM_API_LANGUAGES_MAPPING } from 'Common';

export default class PopupSurveyTranslationStatus extends Component {
  constructor(props) {
    super(props);

    this.closePopup = this.closePopup.bind(this);
  }

  closePopup() {
    const popupId = `#${this.props.popupId}`;
    $(popupId).foundation('close');
  }

  showTranslationScore() {
    const { translationScore } = this.props.survey;
    if (translationScore != null) {
      return (
        <table>
          <tbody>
            {
            __.map(translationScore, (score, lng) => (
              <tr key={lng}>
                <td>{TAMTAM_API_LANGUAGES_MAPPING[lng]}</td>
                <td>
                  <div
                    className="progress"
                    role="progressbar"
                    tabIndex="0"
                    aria-valuenow={score}
                    aria-valuemin="0"
                    aria-valuetext={`${score} percent`}
                    aria-valuemax="100"
                  >
                    <span className="progress-meter" style={{ width: `${score}%` }}>
                      <p className={`progress-meter-text ${parseInt(score).toString().length == 1 ? 'srv-reveal__prg-meter-txt' : ''}`}>
                        {`${parseInt(score)}%`}
                      </p>
                    </span>
                  </div>
                </td>
              </tr>
            ))
          }
          </tbody>
        </table>
      );
    }
    return null;
  }

  render() {
    const { translationStatus } = this.props.survey;
    const classNameTrStatus = translationStatus === 'VALIDATED' ? 'label srv-reveal__label--success' : 'label warning';
    return (
      <div className="reveal srv-reveal" id={this.props.popupId} data-reveal>
        <div className="modal-title">
          <h5>
            {_('Survey status')}
            {' :'}
          </h5>
        </div>
        <div>
          <div className="row srv-reveal__block">
            <div className="column small-4">
              <strong>{_('Translation status')}</strong>
            </div>
            <div className="column small-8">
              <span className={`srv-reveal__label ${classNameTrStatus}`}>
                <strong>{translationStatus === 'VALIDATED' ? _('ACCOMPLISHED') : _('IN PROGRESS') }</strong>
              </span>
            </div>
          </div>
          <div className="row column srv-reveal__block"><strong>{_('Languages score')}</strong></div>
          <div className="row column srv-reveal__block">
            {
              this.showTranslationScore()
            }
          </div>
        </div>

        <div className="row float-right">
          <button className="button btn-popup btn-popup--default" type="button" onClick={this.closePopup}>{_('Close')}</button>
        </div>
        <button className="close-button" type="button" onClick={this.closePopup}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}
