import React, { Component } from 'react';
import CampaignStatsListFetching from 'fetching/stats/CampaignStatsListFetching';
import { fetchSurveyCampaign } from 'thunks';
import { initializeSurveyCampaign } from 'actions';
import { connect } from 'react-redux';
import _ from 'i18n';
import moment from 'moment';

class SurveyCampaignList extends Component {
  componentDidMount() {
    const { surveySlug, survey: { slug, latestInstance } } = this.props;
    if (slug && surveySlug == slug) {
      this.props.initializeSurveyCampaign();
      this.props.fetchSurveyCampaign(latestInstance ? latestInstance.id : 0);
    }
  }

  componentDidUpdate(prevProps) {
    const { slug, latestInstance } = this.props.survey;
    const prevSurvey = prevProps.survey;

    if (slug != prevSurvey.slug && slug != null) {
      this.props.fetchSurveyCampaign(latestInstance ? latestInstance.id : 0);
    }
  }

  render() {
    const { surveyCampaign: { items, fetching }, surveyFetching } = this.props;
    if (fetching || surveyFetching) {
      return <CampaignStatsListFetching />;
    }

    return (
      <div className="campaign-stats__list">
        <div className="row small-12 list-header">
          <span className="greetings column text-center">{_('Date')}</span>
          <span className="greetings column text-center">{_('Type')}</span>
          <span className="greetings column text-center small-3">{_('Object')}</span>
          <span className="greetings column text-center">{_('Language')}</span>
          <span className="greetings column text-center">{_('Targets')}</span>
          <span className="column text-center small-3">
            <div>{_('Initial stats')}</div>
            <div className="sub-header">{`${_('complete')} / ${_('incomplete')} / ${_('population')}`}</div>
          </span>
        </div>
        {
          items && items.length != 0
            ? items.map(item => (
              <div className="row small-12 item" key={item.id}>
                <span className="column text-center">{moment(item.createdAt).format('YYYY/MM/DD')}</span>
                <span className="column text-center">{_(`${item.campaign.surveyType}_CAMPAIGN`)}</span>
                <span className="column text-center small-3">{item.campaign.object}</span>
                <span className="column text-center">{item.campaign.language}</span>
                <span className="cible column text-center">
                  {item.campaign.totalRecipients}
                </span>
                <span className="column text-center small-3">{`${item.completedResponsesNb} / ${item.incompleteResponsesNb} / ${item.population}`}</span>
              </div>
            ))
            : (
              <div className="row item">
                <div className="no-data small-12">{_('No campaign was created yet!')}</div>
              </div>
            )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  surveyCampaign: state.surveyCampaign,
  surveyFetching: state.surveys.list.fetching,
});

const mapDispatchToProps = { fetchSurveyCampaign, initializeSurveyCampaign };

export default connect(mapStateToProps, mapDispatchToProps)(SurveyCampaignList);
