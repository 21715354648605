import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import HomePage from 'HomePage';
import NotFoundPage from 'NotFoundPage';
import SurveyListPage from 'SurveyListPage';
import SurveyBuilder from 'SurveyBuilder';
import SurveyPreview from 'SurveyPreview';
import SurveyResponse from 'SurveyResponse';
import QuizPreview from 'QuizPreview';
import QuizPinPreview from 'QuizPinPreview';
import Stats from 'Stats';
import SubscriberQ from 'SubscriberQ';
import QuizResults from 'quiz-results/QuizResults';
import Library from 'Library';
import AnonymousUser from 'AnonymousUser';
import Settings from 'settings/Settings';
import TypeSelector from 'type-selector/TypeSelector';
import RouteIfRole from './RouteIfRole';
import RouteWithToken from './RouteWithToken';

export const urls = {
  index: '/',
  survey_type: '/surveys/type/selector',
  survey_builder: '/surveys/:surveySlug?/create/:type?',
  survey_list: '/surveys',
  library: '/library',
  survey_preview: '/survey/preview/:slug',
  survey_public: '/survey/:slug/:organizationInstanceId?',
  quiz_preview: '/quiz/:slug',
  quiz_pin_preview: '/quiz/pin/:pin',
  stats: '/stats/:slug',
  quiz_results: '/results/:surveyId',
  anonymous_user: '/anonymous/:slug/:organizationInstanceId?',
  settings: '/settings',
  subscribe: '/subscribe',
  notFound: '*',
};

export const getRoutes = store => {
  const { auth } = store.getState();

  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <RouteIfRole
        path={urls.survey_type}
        component={TypeSelector}
        roles={['AUTHOR', 'REDACTOR', 'CHIEF_EDITOR']}
        isSurveyRole={true}
        auth={auth}
      />
      <RouteIfRole path={urls.subscribe} component={SubscriberQ} auth={auth} />
      <RouteIfRole
        path={urls.survey_list}
        component={SurveyListPage}
        exact={true}
        auth={auth}
      />
      <RouteIfRole
        path={urls.survey_preview}
        component={SurveyPreview}
        auth={auth}
      />
      <RouteWithToken
        path={urls.survey_public}
        component={SurveyResponse}
        auth={auth}
      />
      <RouteIfRole
        path={urls.quiz_preview}
        component={QuizPreview}
        exact={true}
        auth={auth}
      />
      <Route path={urls.quiz_pin_preview} component={QuizPinPreview} exact />
      <RouteIfRole
        path={urls.survey_builder}
        component={SurveyBuilder}
        roles={['AUTHOR', 'REDACTOR', 'CHIEF_EDITOR']}
        isSurveyRole={true}
        auth={auth}
      />
      <Route path={urls.stats} component={Stats} />
      <RouteIfRole
        path={urls.quiz_results}
        component={QuizResults}
        auth={auth}
      />
      <Route
        path={urls.anonymous_user}
        render={
          /** Must be not connected to enter this route */
          props =>
            auth?.fetched && auth?.user ? (
              <Redirect
                to={{ pathname: '/', state: { from: props.location } }}
              />
            ) : (
              <AnonymousUser {...props} />
            )
        }
      />
      <RouteIfRole
        path={urls.settings}
        component={Settings}
        roles={['ADMIN', 'LEGAL_REPRESENTATIVE', 'MANAGER']}
        auth={auth}
      />
      <RouteIfRole path={urls.library} component={Library} auth={auth} />
      <Route path={urls.notFound} component={NotFoundPage} />
    </Switch>
  );
};
