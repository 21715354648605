import React, { Component } from 'react';

export default class Piechart extends Component {
  getChartDetails = () => {
    const { onStatusClick, scrolInto } = this.props;
    const scrolIntoElem = document.getElementById(scrolInto || 'recipient-list');
    if (scrolIntoElem) {
      scrolIntoElem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
    onStatusClick();
  }

  static defaultProps = {
    max: 100,
    symbole: '%'
  }

  render() {
    const { rate, label, pieColor, max, symbole } = this.props;
    const radius = 85;
    const perimeter = 2 * Math.PI * radius;
    const strokeDashoffset = ((max - rate) / max) * perimeter;

    return (
      <div className="piechart">
        <div className="piechart-wrapper">
          <div className="piechart-heading">
            {label}
          </div>
          <div className="piechart-container">
            <div className="piechart__percentage">
              <span className="piechart__percentage-number">{rate}</span>
              <span className="piechart__percentage-symbol">{symbole}</span>
            </div>
            <svg width="200" height="200">
              <circle className="piechart-circle__empty" r={radius} cy="100" cx="100" stroke="#eeeeee" fill="#ffffff" />
              <circle style={{ strokeDashoffset, strokeDasharray: perimeter }} className="piechart-circle__fill" r={radius} cy="100" cx="100" stroke={pieColor || '#47c9e5'} fill="none" />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}
