import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCurrentNavPage } from 'actions';
import _ from 'i18n';
import { getTextByLanguage } from 'surveyUtils';
import ReactTooltip from 'react-tooltip';
import { STATS_COLORS } from 'SurveyConfig';
import MultipleChoiceChart from '../charts/MultipleChoiceChart';
import InputStatsList from './InputStatsList';
import NoData from '../charts/NoDataChart';

export class MultipleChoiceStats extends Component {
  state = { showOther: false };

  handleShowOther = () => this.setState(prevState => ({ showOther: !prevState.showOther }));

  render() {
    const { lng, data, nbResponses } = this.props;
    const { showOther } = this.state;
    const { nbResponse } = data.stats;
    const { otherLabel, id, text, hasOther } = data.question;
    const count = data.stats.other ? data.stats.other.count : 0;
    const idTooltip = `responses-qst-${id}`;
    const iconOther = showOther ? 'icomoon-icon icon-circle-up' : 'icomoon-icon icon-circle-down';
    return (
      <div className="column column-block">
        <div className="summary__qst with-others">
          <div className="column">
            <div className="title">{getTextByLanguage(text, lng)}</div>
            <div className="responses">
              <span data-tip data-for={idTooltip}>
                {nbResponse}
                {'/'}
                {nbResponses}
              </span>
              <ReactTooltip id={idTooltip} multiline={true} className="react-tooltip">
                <span className="tooltip-content">
                  {`${nbResponse} ${_('out of')} ${nbResponses} ${_('people answered this question')}`}
                </span>
              </ReactTooltip>
            </div>
            {
              nbResponse != 0
                ? <MultipleChoiceChart data={data.stats.data} startColorIndex={id % STATS_COLORS.length} />
                : <NoData />
            }
          </div>
          {
            hasOther
            && (
              <>
                <div className="other header column">
                  <div className="text" onClick={this.handleShowOther}>
                    <span>{getTextByLanguage(otherLabel, lng)}</span>
                    <span><i className={iconOther} /></span>
                  </div>
                </div>
                <div className={`other column collapse ${showOther ? 'show' : ''}`}>
                  <>
                    <div className="responses">
                      <span>
                        {count}
                        {'/'}
                        {nbResponse}
                      </span>
                    </div>
                    <div className="list">
                      {
                        data.stats.other && data.stats.other.data
                          ? <InputStatsList data={data.stats.other.data} />
                          : <div className="no-data">{_('...')}</div>
                      }
                    </div>
                  </>
                </div>
              </>
            )
          }
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCurrentNavPage }, dispatch);
}

const mapStateToProps = state => ({
  lng: state.params.lng,
});

export default connect(mapStateToProps, mapDispatchToProps)(MultipleChoiceStats);
