import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { selectRole, selectSearchWord } from 'actions';
import { connect } from 'react-redux';
import _ from 'i18n';

class FilterBar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const rolesList = document.getElementsByClassName('role-list').item(0).classList;
    setTimeout(() => {
      rolesList.add('hide');
    }, 300);
    const selected = document.getElementsByClassName('role-selected').item(0);
    selected.textContent = e.target.textContent;
    this.props.selectRole(e.target.id);
  }

  setSearchWord(e) {
    const { selectSearchWord } = this.props;
    const word = e.target.value;
    if (word && word.length >= 3) {
      selectSearchWord(word);
    } else {
      selectSearchWord('');
    }
  }

  toggleRolesList() {
    const rolesList = document.getElementsByClassName('role-list').item(0).classList;
    setTimeout(() => {
      rolesList.toggle('hide', !rolesList.contains('hide'));
    }, 300);
  }

  render() {
    return (
      <div className="filter-bar small-8 row">
        <div className="column small-4 filter-bar__roles">
          <span className="role-selected">{_('ALL')}</span>
          <i className="icon icon-arrow-down" id="role-list-display" onClick={this.toggleRolesList.bind(this)} />
          <div className="role-list hide">
            <ul>
              <li id="ALL" onClick={this.handleClick} className="block-role all">{_('ALL')}</li>
              <li className="block-role">
                <h5>{_('Survey roles')}</h5>
                <ul>
                  <li id="1ADMIN" onClick={this.handleClick}>{_('Admin')}</li>
                  <li id="1USER" onClick={this.handleClick}>{_('user')}</li>
                </ul>
              </li>
              <li className="block-role">
                <h5>{_('Organization roles')}</h5>
                <ul>
                  <li id="2LEGAL_REPRESENTATIVE" onClick={this.handleClick}>{_('Legal Reprentative')}</li>
                  <li id="2MANAGER" onClick={this.handleClick}>{_('Manager')}</li>
                  <li id="2OFFICIAL" onClick={this.handleClick} className="role-border">{_('Official')}</li>
                  <li id="2EXTERNAL" onClick={this.handleClick} className="role-border">{_('External')}</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="column small-8 filter-bar__search">
          <span className="search-icon">
            <i className="icon icon-magnifier" />
          </span>
          <input type="text" placeholder={_('Search by name...')} title="Search text" onChange={e => this.setSearchWord(e)} />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ selectRole, selectSearchWord }, dispatch);
}

export default connect(null, mapDispatchToProps)(FilterBar);
