import React from 'react';
import styles from './Library.module.scss';

export default function LibraryListFetching() {
  const styleLogo = {
    backgroundColor: '#D7DBE0',
  };

  return (
    <div className={styles.toolbar_content}>
      <div className={styles.content}>
        {Array.from(Array(5).keys()).map(index => (
          <div className={styles.block} key={index}>
            <div className={styles.block__logo}>
              <span style={styleLogo}>
                <i className="icomoon-icon icon-paragraph-left" />
              </span>
            </div>
            <div className={styles.block__content}>
              <h5
                className="gradiant blue no-content"
                style={{ width: '90%' }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
