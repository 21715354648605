import React, { Component } from 'react';
import TabListItemFetching from 'fetching/stats/statsTabsFetching';
import { NavLink } from 'react-router-dom';
import { OPINION_TABS, SATISFACTION_TABS, CUSTOM_TABS, QUIZ_TABS, PORTAL_TABS } from 'constants';
import controlAccess from './controlAccess';

class Tab extends Component {
  getAuthorizations = () => {
    const hasAuthorizations = true;
    return hasAuthorizations;
  }

  isTabActive = (match, location, menuName) => {
    if (!match) {
      return false;
    }
    const { search } = location;
    const urlParams = new URLSearchParams(search);
    const tab = urlParams.get('tab');

    return menuName === tab;
  }

  render() {
    const { fetching, title, goTo, survey, menuName, childs, forceActive, hide, disabled } = this.props;

    if (hide) {
      return null;
    }

    if (fetching) {
      return <TabListItemFetching />;
    }

    // TODO Move this conditions to controlAccess
    if (survey.type == 'OPINION' && OPINION_TABS.indexOf(menuName) === -1) {
      return null;
    }
    if (survey.type == 'SATISFACTION' && SATISFACTION_TABS.indexOf(menuName) === -1) {
      return null;
    }
    if (survey.type == 'CUSTOM' && CUSTOM_TABS.indexOf(menuName) === -1) {
      return null;
    }
    if (survey.type == 'PORTAL' && PORTAL_TABS.indexOf(menuName) === -1) {
      return null;
    }
    if (survey.type == 'QUIZ' && QUIZ_TABS.indexOf(menuName) === -1) {
      return null;
    }

    // const className = disabled ? 'disabled-link' : forceActive && 'active';
    const className = `${disabled && 'disabled-link'} ${forceActive && 'active'}`;
    return (
      <>
        <NavLink
          to={!disabled ? goTo : '#'}
          disabled={disabled}
          activeClassName="active"
          className={`stats__menu-item ${className}`}
          isActive={(match, location) => this.isTabActive(match, location, menuName)}
        >
          {title}
        </NavLink>
        {childs && childs.map(tab => (
          <NavLink
            to={!disabled ? tab.goTo : '#'}
            activeClassName="active"
            className="stats__menu-item child greetings"
            key={tab.key}
            isActive={(match, location) => this.isTabActive(match, location, tab.key)}
          >
            {tab.label}
          </NavLink>
        ))}
      </>
    );
  }
}

const mapStateToProps = state => ({
  survey: state.surveys.list.survey,
  fetching: state.surveys.list.fetching,
});

export default controlAccess(mapStateToProps)(Tab);
