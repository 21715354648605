import React, { memo, useState } from 'react';
import { SURVEY_STATUS } from 'Common';
import { onSuccess, onError } from 'utils';
import ReactSelect from 'react-select';
import { saveOrganizationInstance } from 'thunks';
import { setSurveyOrganizationInstance } from 'actions';
import _ from 'i18n';
import { connect } from 'react-redux';

const InstanceStatus = memo(
  ({
    survey,
    value,
    saveOrganizationInstance,
    setSurveyOrganizationInstance,
  }) => {
    const [loading, setLoading] = useState(false);

    const setInstanceStatus = ({ value: status }) => {
      const { id, organizationInstance, latestInstance } = survey;
      const organizationInstanceData = { status };

      if (status == null) return null;

      if (organizationInstance?.id) {
        organizationInstanceData.id = organizationInstance.id;
      } else {
        organizationInstanceData.instance = latestInstance?.id ?? 0;
      }

      setLoading(true);
      return saveOrganizationInstance(organizationInstanceData)
        .then(
          res => {
            const organizationInstance = res.value.data.data;
            if (organizationInstance) {
              setSurveyOrganizationInstance(id, organizationInstance);
            }
            onSuccess(res, { body: _('edit_survey_status') });
          },
          err => onError(err),
        )
        .finally(() => setLoading(false));
    };

    const options = SURVEY_STATUS.map(value => ({
      value,
      label: _(value),
    }));

    return (
      <div className="settings__block">
        <div className="settings__container">
          <div className="settings__container__label">{_('Status')}</div>
          <div className="settings__container__item">
            <ReactSelect
              isLoading={loading}
              isSearchable={false}
              options={options}
              value={options.filter(o => o.value === value)}
              onChange={setInstanceStatus}
              className="ttp-select filled-box uppercase min-width-200"
              classNamePrefix="ttp-select"
            />
          </div>
        </div>
      </div>
    );
  },
);

export default connect(null, {
  saveOrganizationInstance,
  setSurveyOrganizationInstance,
})(InstanceStatus);
