import React, { useState } from 'react';
import _ from 'i18n';
import { DateRangePicker } from 'react-dates';
import { momentsContains } from 'utils';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const DateRange = (props) => {
  const [focusedInput, setFocusInput] = useState(); // startDate|endDate|null

  const handleDateRangeChange = ({ startDate, endDate }) => {
    if (startDate) {
      props.addOption('startedAt', startDate.startOf('day').format());
    }
    if (endDate) {
      props.addOption('endedAt', endDate.endOf('day').format());
    }
  };

  const { startDate, endDate, blockedDates } = props;

  const isDayBlocked = (day) => {
    if (focusedInput === 'endDate') {
      const blockedDayAfterSelectedStarteDate = blockedDates.find(({ startedAt }) => startedAt.isSameOrAfter(startDate));
      if (blockedDayAfterSelectedStarteDate) {
        return (
          day.isSameOrAfter(blockedDayAfterSelectedStarteDate?.startedAt)
          || day.isBefore(startDate)
          || momentsContains(blockedDates, day)
        );
      }
    }

    return momentsContains(blockedDates, day);
  };

  return (
    <div className="settings__block">
      <div className="settings__container__label">
        <span>{_('Start date')}</span>
        <span className="m-x-xs">&</span>
        <span>{_('End date')}</span>
      </div>
      <DateRangePicker
        startDate={startDate}
        startDateId="your_unique_start_date_id"
        endDate={endDate}
        endDateId="your_unique_end_date_id"
        onDatesChange={handleDateRangeChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusInput}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        startDatePlaceholderText={_('Start date')}
        endDatePlaceholderText={_('End date')}
        displayFormat="DD/MM/YYYY"
        isDayBlocked={blockedDates?.length > 0 ? isDayBlocked : undefined}
        minimumNights={0}
      />
    </div>
  );
};

export default DateRange;
