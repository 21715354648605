import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCurrentNavPage } from 'actions';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, ResponsiveContainer
} from 'recharts';
import { STATS_COLORS } from 'SurveyConfig';

export class ScaleChart extends Component {
  renderList = () => this.props.data.map(elem => ({
    name: elem.value,
    count: elem.count,
  }));

  render() {
    const { color } = this.props;
    return (
      <ResponsiveContainer width="100%" height="auto" aspect={5 / 3}>
        <BarChart
          data={this.renderList()}
          margin={{
            top: 35, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill={color || STATS_COLORS[0]} minPointSize={5}>
            <LabelList dataKey=" name" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCurrentNavPage }, dispatch);
}

export default connect(null, mapDispatchToProps)(ScaleChart);
