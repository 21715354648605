import React, { Component } from 'react';
import {
  setCurrentNavPage,
  initSurvey, setSurveyType,
  resetValidationErrors,
  changeLanguage,
  initSurveyTheme,
  setSettingsCurrentTab,
  addSurveyOptions
} from 'actions';
import { connect } from 'react-redux';
import { fetchSurvey, fetchSurveyExtraData, fetchUsedSurveysDates } from 'thunks';
import EditorPreview from 'survey-builder/EditorPreview';
import Editor from 'survey-builder/Editor';
import EditorToolbar from 'survey-builder/EditorToolbar';
import { SURVEY_TYPE } from 'Common';
import NotFoundPage from 'NotFoundPage';
import { SURVEY_BUILDER_TABS } from 'constants';
import { momentsContains, getFreeDayAfter } from 'utils';
import moment from 'moment';
import { getAuthorizations } from '../services/authorizations';
import { preventBackspaceBehaviour } from '../services/utils';

class SurveyBuilder extends Component {
  constructor(props) {
    super(props);

    this.state = { editAllowed: true };
  }

  componentDidMount() {
    const { match: { params }, appLanguage, auth: { user, currentClient, loggedAs } } = this.props;
    const { surveySlug } = params;

    let { type } = params;
    type = SURVEY_TYPE.indexOf(String(type).toUpperCase()) !== -1 ? String(type).toUpperCase() : undefined;

    this.props.setCurrentNavPage('SURVEY_BUILDER');
    this.props.initSurvey(type, appLanguage);
    this.props.resetValidationErrors();
    this.props.changeLanguage(appLanguage);

    if (surveySlug) {
      this.props.fetchSurvey(surveySlug).then((res) => {
        const survey = res.value.data.data;
        const { EDITOR, MAILS } = SURVEY_BUILDER_TABS;
        const { edit: { editSurvey } } = getAuthorizations(survey, user, currentClient, loggedAs);
        let { languages } = survey;
        languages = (languages && languages.split(',')) || [];

        this.props.changeLanguage(languages[0] || appLanguage);
        this.setState({ editAllowed: editSurvey });

        if (editSurvey) {
          this.props.setSettingsCurrentTab(EDITOR);
        } else {
          this.props.setSettingsCurrentTab(MAILS);
        }

        this.props.fetchSurveyExtraData(survey.id);

        if (survey.type == 'PORTAL') {
          this.props.fetchUsedSurveysDates('PORTAL');
        }
      });
    } else if (type) {
      this.props.setSurveyType(type);
      if (type === 'PORTAL') {
        this.props.fetchUsedSurveysDates(type);
      }
    }

    window.addEventListener('keydown', preventBackspaceBehaviour);
    window.addEventListener('beforeunload', this.preventRefresh);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.match.params.surveySlug) {
      const { usedDates } = this.props;
      if (usedDates?.length !== prevProps.usedDates?.length) {
        let now = moment();
        if (momentsContains(usedDates, now)) {
          now = getFreeDayAfter(usedDates, now);
        }

        this.props.addSurveyOptions({ startedAt: now.startOf('day') });
        this.props.addSurveyOptions({ endedAt: now.endOf('day') });
      }
    }
  }

  componentWillUnmount() {
    this.props.initSurvey();
    this.props.initSurveyTheme();
    window.removeEventListener('keydown', preventBackspaceBehaviour);
    window.removeEventListener('beforeunload', this.preventRefresh);
  }

  preventRefresh = (event) => {
    const confirmationMessage = 'Are you sure you want to leave?';
    event.returnValue = confirmationMessage;
    return confirmationMessage;
  }

  renderTab() {
    const { currentTab } = this.props;
    const { EDITOR, PREVIEW, THEMES, SURVEY_THEMES, SETTINGS, SURVEY_SETTINGS, LIBRARY } = SURVEY_BUILDER_TABS;

    switch (currentTab) {
      case THEMES:
      case SURVEY_THEMES:
      case PREVIEW: return <EditorPreview fullWidth={true} />;
      case SETTINGS:
      case SURVEY_SETTINGS:
      case LIBRARY:
      case EDITOR: return <Editor />;
      default: return null;
    }
  }

  render() {
    const { match: { params }, currentTab } = this.props;
    const { surveySlug, type } = params;
    const { editAllowed } = this.state;
    const { MAILS, TEMPLATES } = SURVEY_BUILDER_TABS;

    if (surveySlug == null && SURVEY_TYPE.indexOf(String(type).toUpperCase()) === -1) {
      return <NotFoundPage />;
    }
    const className = [MAILS, TEMPLATES].indexOf(currentTab) !== -1 ? 'row' : 'editor-preview';

    return (
      <div className="survey-builder survey-font survey-font__body">
        <EditorToolbar editAllowed={editAllowed} activeTab={currentTab} />
        <div className={className}>
          {
            this.renderTab()
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  appLanguage: state.params.lng,
  auth: state.auth,
  currentTab: state.surveys.list.toolbarTab,
  usedDates: state.surveys.list.usedDates,
});

const mapDispatchToProps = {
  setCurrentNavPage,
  initSurvey,
  fetchSurvey,
  setSurveyType,
  resetValidationErrors,
  changeLanguage,
  initSurveyTheme,
  setSettingsCurrentTab,
  fetchSurveyExtraData,
  fetchUsedSurveysDates,
  addSurveyOptions
};


export default connect(mapStateToProps, mapDispatchToProps)(SurveyBuilder);
