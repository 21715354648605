import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  updateAnswer,
  addLastUpdatedAnswer,
  removeAnswerByQuestionId,
} from 'actions';
import { deleteAnswer } from 'thunks';
import { isDisplayed } from 'surveyUtils';
import { selectAnswerByQuestion } from 'reducers';
import { QuestionHeader } from './QuestionHeader';
import PreviewControlButton from './PreviewControlButton';

class RatingQuestionPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: true,
      answerValue: props.answer ? props.answer.answerValue : null,
      tempRating: props.answer ? props.answer.answerValue : null,
    };
  }

  componentDidUpdate(prevProps) {
    const { parentAnswer, question } = this.props;

    if (
      isDisplayed(question, parentAnswer) !==
      isDisplayed(question, prevProps.parentAnswer)
    ) {
      this.setState({ answerValue: null, tempRating: null });
      this.props.removeAnswerByQuestionId(question?.id ?? question?.uid);
    }
  }

  updateAnswer() {
    const {
      updateAnswer,
      question,
      addLastUpdatedAnswer,
      deleteAnswer,
    } = this.props;
    const { answerValue } = this.state;

    const answer = {
      ...this.props.answer,
      question: question.id || question.uid,
      answerValue,
      resourceType: 'rating',
    };

    updateAnswer(answer);

    if (answer.answerValue != null && answer.answerValue.length !== 0) {
      addLastUpdatedAnswer(question.id || question.uid);
    } else if (answer.id) {
      deleteAnswer(answer.id, question.id);
    }
  }

  rate(rating) {
    this.setState(
      prevState => ({
        ...prevState,
        answerValue: rating === prevState.answerValue ? null : rating,
        tempRating: rating,
        isHover: rating === prevState.answerValue,
      }),
      () => {
        this.updateAnswer();
        if (this.state.answerValue !== null) {
          setTimeout(() => {
            this.props.scrollToNext(this.ref);
          }, 0);
        }
      },
    );
  }

  starHover(rating) {
    this.setState(prevState => ({
      ...prevState,
      tempRating: rating,
      isHover: true,
    }));
  }

  starOut() {
    this.setState(prevState => ({
      ...prevState,
      tempRating: prevState.answerValue,
      isHover: false,
    }));
  }

  renderRatingItems() {
    const { isHover, tempRating } = this.state;
    const { surveys, question } = this.props;
    const { steps, type } = surveys.survey;

    const range =
      type == 'SATISFACTION' ? steps || question.steps : question.steps;
    const stars = [];

    for (let i = 1; i <= range; i++) {
      let classLabel = 'star-rating__star survey-main-color-light';
      let classIcon = `icomoon-icon icon-${question.shape}`;

      if (tempRating !== null && tempRating >= i) {
        classLabel += `${
          isHover
            ? ' is-hover survey-main-color-light'
            : ' is-selected survey-main-color'
        }`;
        classIcon = `icomoon-icon icon-${question.shape}-full`;
      }

      stars.push(
        <label
          key={i}
          className={classLabel}
          onClick={this.rate.bind(this, i)}
          onMouseOver={this.starHover.bind(this, i)}
          onMouseOut={this.starOut.bind(this)}
        >
          <span className={classIcon} />
        </label>,
      );
    }

    return stars;
  }

  goToNext = goBack => this.props.scrollToNext(this.ref, goBack);

  render() {
    const { surveys, question, parentAnswer, order } = this.props;
    const { text } = question;
    const isShowed = isDisplayed(question, parentAnswer);
    const hasParent = question.parent != null;

    return (
      <div
        className="preview__container qst__wrapper"
        style={{ display: isShowed ? 'block' : 'none' }}
        ref={ref => {
          this.ref = ref;
          this.props.addRef(ref, null, question);
        }}
      >
        {/* <div className="preview__container__bg" /> */}
        <QuestionHeader
          id={`question-label-${question.id || question.uid}`}
          text={text[surveys.language]}
          order={order}
          isRequired={question.required == '1'}
          hasOrder={!hasParent}
        />
        <div className="preview__container__content qst__content">
          <div className="star-rating">{this.renderRatingItems()}</div>
          <PreviewControlButton
            className="srv-button-color--fill"
            onClick={this.goToNext}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { question } = props;
  const questionId = question.id || question.uid;

  return {
    surveys: state.surveys.list,
    answer: state.surveyResponse.answers.hasOwnProperty(questionId)
      ? state.surveyResponse.answers[questionId]
      : null,
    parentAnswer: selectAnswerByQuestion(question.parent)(state.surveyResponse),
  };
};

const mapDispatchToProps = {
  updateAnswer,
  addLastUpdatedAnswer,
  deleteAnswer,
  removeAnswerByQuestionId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RatingQuestionPreview);
