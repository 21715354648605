import React, { Component } from 'react';
import _ from 'i18n';
import TTButton from 'common/TTButton';

export default class QuizResultFetching extends Component {
  render() {
    return (
      <div className="quiz-preview__result row">
        <div className="small-4 ranking">
          <div className="result-header">{_('Top ten')}</div>
          <div className="ranking-content">
            {
              Array.from(Array(5).keys()).map(key => (
                <div className="participant fetching no-box-shadow" id={`participant-fetching-${key}`}>
                  <div className="name flex-column-center"><h4 className="gradiant blue no-content" style={{ width: '90%' }} /></div>
                </div>
              ))
            }
          </div>
        </div>
        <div className="small-8 result-winners">
          <div className="podium-wrapper">
            <div className="podium">
              <img alt="" src="/img/loaders/tamtam.svg" style={{ width: '15rem' }} />
            </div>
          </div>
          <TTButton
            text={_('exit')}
            className="p-x-xxl"
            fontSize="20px"
            color="blue"
            upperCase={true}
            disabled={true}
          />
        </div>
      </div>
    );
  }
}
