const initial = {
  cities: [],
  countries: [],
  fetching: false,
  fetched: false,
};

const initialStateForRankingFilter = {
  list: { ...initial },
  filter: {
    sortDirection: 'desc',
    clientName: '',
    country: 'all',
    city: 'all',
    pageSize: 10,
    paginationPage: 1,
  },
  error: null,
};

export const rankingClientsFilterReducer = (state = initialStateForRankingFilter, action) => {
  switch (action.type) {
    case 'INITIALIZED_RANKING_CLIENTS': {
      return initialStateForRankingFilter;
    }
    case 'SET_RANKING_CLIENTS_PAGINATION_PAGE': {
      return { ...state, filter: { ...state.filter, paginationPage: action.pageNumber } };
    }
    case 'SET_RANKING_CLIENTS_PAGE_SIZE': {
      return { ...state, filter: { ...state.filter, pageSize: action.pageSize } };
    }
    case 'SET_RANKING_CLIENTS_FILTER_CLIENT_NAME': {
      return { ...state, filter: { ...state.filter, clientName: action.clientName } };
    }
    case 'SET_RANKING_CLIENTS_FILTER_CITY': {
      return { ...state, filter: { ...state.filter, city: action.city } };
    }
    case 'SET_RANKING_CLIENTS_FILTER_COUNTRY': {
      return { ...state, filter: { ...state.filter, country: action.country } };
    }
    case 'SET_RANKING_CLIENTS_LIST_SORT': {
      return { ...state, filter: { ...state.filter, sortDirection: action.sortDirection } };
    }
    case 'FETCH_RANKING_CLIENTS_OPTIONS_PENDING': {
      return { ...state, list: { ...state.list, fetching: true } };
    }
    case 'FETCH_RANKING_CLIENTS_OPTIONS_FULFILLED': {
      const { data } = action.payload;

      return {
        ...state,
        list: {
          ...state.list,
          fetching: false,
          fetched: true,
          error: null,
          cities: data.cities,
          countries: data.countries,
        },
      };
    }
    case 'FETCH_RANKING_CLIENTS_OPTIONS_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
      };
    }
    default:
      return state;
  }
};
