import * as Sentry from '@sentry/browser';

const SENTRY_URL = 'sentry.tamtam.pro';

/**
* Utility function to observe redux store updates
* @see https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
*
* @param store redux store
* @param select a selector function to access a specific part of the store
* @param onChange a callback that receive as first parameter the selected part of the store
*/
const observeStore = (store, select, onChange) => {
  let currentState;

  const handleChange = () => {
    const nextState = select(store.getState());
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  };

  const unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
};

/**
* Init and Configure Sentry Crash Reporting
*
* @param store Redux store
* @param config Configuration
*/
const doInitSentry = (store, config) => {
  const {
    APP_ENV,
    APP_NAME,
    APP_RELEASE,
    APP_SENTRY_KEY,
    APP_SENTRY_ID
  } = config;

  // Disable Sentry in the following envs
  if (['local', 'develop', 'uat'].indexOf(APP_ENV) !== -1) {
    return;
  }

  // Initial Configuration
  Sentry.init({
    dsn: `https://${APP_SENTRY_KEY}@${SENTRY_URL}/${APP_SENTRY_ID}`,
    debug: APP_ENV !== 'production',
    environment: APP_ENV,
    release: `${APP_NAME}@${APP_RELEASE}`,
    transport: Sentry.Transports.XHRTransport,
    integrations: (defaultIntegrations) => {
      // Remove default ReportingObserver integration
      const customIntegrations = defaultIntegrations.filter(integration => integration.name !== 'ReportingObserver');
      // For now, disable reporting type "deprecation" and "intervention"
      return [...customIntegrations, new Sentry.Integrations.ReportingObserver({
        types: ['crash']
      })];
    }
  });

  // Observe user
  observeStore(store, state => state.auth.user, (user) => {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user && user.id,
        username: user && user.mainEmail,
        email: user && user.mainEmail,
      });
    });
  });
  // Observe language
  observeStore(store, state => state.params.lng, (lng) => {
    Sentry.configureScope((scope) => {
      scope.setTag('ttp:lng', lng);
    });
  });
  // Observe Community
  observeStore(store, state => state.auth.currentClient, (currentClient) => {
    Sentry.configureScope((scope) => {
      scope.setTag('ttp:client.id', currentClient && currentClient.id);
      scope.setTag('ttp:client.name', currentClient && (currentClient.abbreviation || currentClient.name));
    });
  });
};

/**
* Init and Configure Sentry Crash Reporting
*
* @param store Redux store
* @param config {
*      APP_ENV: The app env (local, dev, ...)
*      APP_NAME: The app name
*      APP_RELEASE: The current app release
*      APP_SENTRY_KEY: The project key in sentry
*      APP_SENTRY_ID: The project id in sentry
* }
*/
export const initSentry = (store, config) => {
  try {
    doInitSentry(store, config);
  } catch (e) {
    Sentry.captureException(e);
  }
};
