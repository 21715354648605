import React, { Component } from 'react';
import autosize from 'autosize';
import { connect } from 'react-redux';
import {
  updateAnswer,
  setCurrentQuestion,
  addAnswerValidationErrors,
  resetAnswerValidationErrors,
  addLastUpdatedAnswer,
  removeAnswerByQuestionId,
} from 'actions';
import { deleteAnswer } from 'thunks';
import _ from 'i18n';
import shortId from 'shortid';
import { isDisplayed } from 'surveyUtils';
import { selectAnswerByQuestion } from 'reducers';
import DisplayErrors from './DisplayErrors';
import { getErrorMessageByCode } from '../../services/surveyUtils';
import PreviewControlButton from './PreviewControlButton';
import { QuestionHeader } from './QuestionHeader';

class TextQuestionPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerValue: props.answer ? props.answer.answerValue : '',
      isAnswerValid: true,
      answerValidated: true,
    };
  }

  componentDidMount() {
    autosize(this.textarea);
  }

  componentDidUpdate(prevProps) {
    const { parentAnswer, question } = this.props;

    if (
      isDisplayed(question, parentAnswer) !==
      isDisplayed(question, prevProps.parentAnswer)
    ) {
      this.setState({
        answerValue: '',
        isAnswerValid: true,
        answerValidated: true,
      });
      this.props.removeAnswerByQuestionId(question?.id ?? question?.uid);
    }
  }

  onKeyDown = e => {
    if (e.key == 'Enter' && !e.nativeEvent.shiftKey) {
      e.preventDefault();
      this.onClick(true);
    }
  };

  onChange(text) {
    this.setState({
      answerValue: text,
      isAnswerValid: true,
      answerValidated: false,
    });

    if (text == '') this.onClick();
  }

  onClick = (withScroll = false) => {
    const { question } = this.props;
    const { answerValue } = this.state;
    const isAnswerValid = this.validateAnswer();

    if (isAnswerValid || answerValue == '') {
      const answer = {
        ...this.props.answer,
        question: question.id || question.uid,
        answerValue: this.state.answerValue,
        resourceType: 'text',
      };

      this.setState({ isAnswerValid: true });
      this.props.updateAnswer(answer);

      if (answer.answerValue != null && answer.answerValue.length !== 0) {
        this.props.addLastUpdatedAnswer(question.id || question.uid);
      } else if (answer.id) {
        this.props.deleteAnswer(answer.id, question.id);
      }

      if (withScroll) {
        this.props.scrollToNext(this.ref);
        this.setState({ answerValidated: true });
      }
    } else {
      this.setState({ isAnswerValid: false });
    }
  };

  validateAnswer() {
    const { answerValue } = this.state;
    const {
      addAnswerValidationErrors,
      resetAnswerValidationErrors,
      question,
    } = this.props;
    const value = answerValue.trim();
    let isValid = true;
    if (value && value.length < 3) {
      addAnswerValidationErrors(question.id || question.uid, { code: 150302 });
      isValid = false;
    } else if (answerValue.length >= 1 && value.length === 0) {
      addAnswerValidationErrors(question.id || question.uid, { code: 150301 });
      isValid = false;
    } else {
      resetAnswerValidationErrors(question.id || question.uid);
    }

    return isValid;
  }

  goToNext = goBack => {
    if (goBack || this.state.answerValue == '' || this.validateAnswer()) {
      this.props.scrollToNext(this.ref, goBack);
      this.setState({ answerValidated: true });
    }
  };

  showErrors() {
    const { errors, question } = this.props;
    if (
      errors != null &&
      errors.answers != null &&
      errors.answers.hasOwnProperty(question.id)
    ) {
      const validationErrors = errors.answers[question.id];
      return validationErrors.map(error => (
        <DisplayErrors
          message={getErrorMessageByCode(error.code)}
          key={`error-${shortId.generate()}`}
        />
      ));
    }
    return null;
  }

  render() {
    const { question, language, parentAnswer, order } = this.props;
    const { answerValue, isAnswerValid, answerValidated } = this.state;
    const { text } = question;
    const isShowed = isDisplayed(question, parentAnswer);
    const hasParent = question.parent != null;

    return (
      <div
        className="preview__container qst__wrapper"
        style={{ display: isShowed ? 'block' : 'none' }}
        ref={ref => {
          this.ref = ref;
          this.props.addRef(ref, null, question);
        }}
      >
        {/* <div className="preview__container__bg" /> */}
        <QuestionHeader
          id={`question-label-${question.id || question.uid}`}
          text={text[language]}
          order={order}
          isRequired={question.required == '1'}
          hasOrder={!hasParent}
        />
        <div className="preview__container__content qst__content">
          <div className="preview-input srv-secondary-color">
            <textarea
              className="transparent-input srv-secondary-border-color--medium-opacity srv-secondary-color"
              ref={ref => {
                this.textarea = ref;
              }}
              placeholder={_('Answer_input')}
              onChange={e => this.onChange(e.target.value)}
              onKeyDown={this.onKeyDown}
              onBlur={() => this.onClick(false)}
              value={answerValue}
            />
            <p className="float-right help">
              <b>SHIFT + ENTER</b> {_('to make a line break')}
            </p>
          </div>
          {!isAnswerValid && this.showErrors()}
          <PreviewControlButton
            onClick={this.goToNext}
            show={answerValue != '' && !answerValidated}
            className="srv-button-color--fill"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { question } = props;
  const questionId = question.id || question.uid;

  return {
    language: state.surveys.list.language,
    errors: state.surveyResponse.error,
    answer: state.surveyResponse.answers.hasOwnProperty(questionId)
      ? state.surveyResponse.answers[questionId]
      : null,
    parentAnswer: selectAnswerByQuestion(question.parent)(state.surveyResponse),
  };
};

const mapDispatchToProps = {
  updateAnswer,
  setCurrentQuestion,
  addAnswerValidationErrors,
  resetAnswerValidationErrors,
  addLastUpdatedAnswer,
  deleteAnswer,
  removeAnswerByQuestionId,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TextQuestionPreview);
