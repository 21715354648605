import React from 'react';
import styles from './Library.module.scss';
import { getOptions } from 'SurveyConfig';
import { getTextByLanguage, generateDimension } from 'surveyUtils';
import { duplicateQuestion } from 'question';
import { useDispatch } from 'react-redux';
import { addQuestion, addDimension, setCurrentQuestion } from 'actions';

export default function LibraryList({
  surveyQuestions,
  language,
  renderActions,
  isProfile,
  survey,
  current,
}) {
  const dispatch = useDispatch();
  const handleClick = question => {
    if (isProfile) {
      const dimensionProfile = survey.dimensions.find(
        ({ isProfile }) => isProfile,
      );
      let questionExit = false;
      let { currentDimensionIndex } = current;

      if (!dimensionProfile) {
        const title = {
          fr: 'Profilage',
          nl: 'Profilering',
          en: 'Profiling',
        };

        dispatch(setCurrentQuestion(-1, -1));
        dispatch(
          addDimension({
            ...generateDimension(survey.type),
            isProfile: true,
            title,
            questions: [],
          }),
        );
        currentDimensionIndex = -1;
      } else {
        questionExit = dimensionProfile.questions.some(
          ({ profilingType }) => profilingType === question.profilingType,
        );
      }

      if (questionExit) return;

      if (currentDimensionIndex != 0) {
        dispatch(setCurrentQuestion(0, -1));
      }
    }

    dispatch(addQuestion(duplicateQuestion(question)));
  };

  const firstDimension = survey.dimensions?.[0];
  const selectedProfilingTypes = firstDimension?.isProfile
    ? firstDimension.questions.map(({ profilingType }) => profilingType)
    : [];

    return (
    <div className={styles.toolbar_content}>
      <div className={styles.content}>
        {surveyQuestions.map(
          ({ question, question: { id, type, text, profilingType } }) => {
            const isSelected = selectedProfilingTypes.includes(profilingType);
            const options = getOptions(type);
            const styleLogo = {
              backgroundColor: options.color,
            };

            return (
              <div
                key={`question-library-${id}`}
                className={`${styles.block} ${isSelected ? styles.selected : ''}`}
                onClick={() => handleClick(question)}
              >
                <div className={styles.block__logo}>
                  <span style={styleLogo}>
                    <i className={options.icon} />
                  </span>
                </div>
                <div className={styles.block__content}>
                  {getTextByLanguage(text, language)}
                </div>
                <div className={styles.block__icon}>
                  <i className={`icomoon icon-tt-${isSelected ? 'correct' : 'plus'}`} />
                </div>
              </div>
            );
          },
        )}
        {renderActions && renderActions()}
      </div>
    </div>
  );
}
