import shortId from 'shortid';
import _ from 'i18n';
import __ from 'lodash';
import { EditorState, convertFromRaw } from 'draft-js';
import moment from 'moment';
import { TTP_SURVEY_URL, TTP_EMAILING_URL } from 'Config';
import { MOVE_UP, MOVE_DOWN, MOVE_TO_START, MOVE_TO_END } from 'constants';

export const orderToChar = number => {
  if (number != null) {
    if (number < 26) {
      return String.fromCharCode(65 + number);
    }
    return `${String.fromCharCode(65 + (number % 26))}${String.fromCharCode(
      65 + (number % 26),
    )}`;
  }
  return null;
};

export const generateSurvey = (type, language = 'fr') => {
  let survey = {
    steps: 5,
    languages: language,
    dimensions: [generateDimension(type)],
    allowThirds: 1,
    meta: {
      obligatoryRanking: 0,
      consolidatedResults: 0,
    },
  };

  if (type === 'PORTAL') {
    survey = {
      ...survey,
      startedAt: moment()
        .startOf('day')
        .format(),
      endedAt: moment()
        .endOf('day')
        .format(),
      scope: 'ALL',
    };
  }

  if (type === 'SATISFACTION') {
    survey = {
      ...survey,
      scope: 'COLLABORATORS',
    };
  }

  return survey;
};

export const generateDimension = surveyType => {
  let questions;
  let title = '';
  switch (surveyType) {
    case 'SATISFACTION':
      questions = [generateQuestion('RATING', surveyType)];
      break;
    case 'PORTAL':
      questions = [generateQuestion('SINGLE_CHOICE', surveyType)];
      title = {
        fr: 'Dimension ...',
        nl: 'Dimensie ...',
        en: 'Dimension ...',
      };
      break;
    default:
      questions = [generateQuestion('SINGLE_CHOICE', surveyType)];
      break;
  }

  return {
    uid: shortId.generate(),
    title,
    questions,
  };
};

const QUESTION = {
  text: '',
  required: '0',
  questionOrder: 0,
  timeLimit: 30,
};

export const generateQuestion = (type = 'TEXT', surveyType) => {
  switch (type) {
    case 'MULTIPLE_CHOICE':
    case 'SINGLE_CHOICE':
      return {
        ...QUESTION,
        type,
        uid: shortId.generate(),
        choices: [generateChoice(surveyType == 'QUIZ')],
        resourceType: 'multipleChoice',
      };
    case 'RATING_CHOICE':
      return {
        ...QUESTION,
        type,
        uid: shortId.generate(),
        choices: generateRankingChoices(5),
        resourceType: 'multipleChoice',
        isNps: 0,
      };
    case 'RATING':
      return {
        ...QUESTION,
        type,
        uid: shortId.generate(),
        steps: 5,
        shape: 'star',
        resourceType: 'rating',
        isNps: 0,
      };
    case 'NUMBER':
      return {
        ...QUESTION,
        type,
        uid: shortId.generate(),
        resourceType: 'number',
      };
    case 'OPINION_SCALE':
      return {
        ...QUESTION,
        type,
        uid: shortId.generate(),
        maxNumber: 10,
        resourceType: 'number',
        isNps: 0,
      };
    case 'TRUE_FALSE':
    case 'YES_NO':
      return {
        ...QUESTION,
        type,
        uid: shortId.generate(),
        steps: 5,
        sharp: 'star',
        resourceType: 'boolean',
      };
    default:
      return {
        ...QUESTION,
        type,
        uid: shortId.generate(),
        resourceType: 'text',
      };
  }
};

export const generateChoice = (isCorrect = false) => ({
  text: { fr: '' },
  uid: shortId.generate(),
  isCorrect,
});

export const generateRankingChoices = number => {
  const choices = [];
  for (let i = 0; i < number; i++) {
    choices.push({
      value: number - i,
      text: { fr: '' },
      uid: shortId.generate(),
      choiceOrder: i,
    });
  }

  return choices;
};

export const getResourceType = type => {
  switch (type) {
    case 'MULTIPLE_CHOICE':
    case 'SINGLE_CHOICE':
    case 'RATING_CHOICE':
      return 'multipleChoice';
    case 'RATING':
      return 'rating';
    case 'OPINION_SCALE':
    case 'NUMBER':
      return 'number';
    case 'TRUE_FALSE':
    case 'YES_NO':
      return 'boolean';
    default:
      return 'text';
  }
};

export const addResourceTypeToQuestions = survey => {
  if (Array.isArray(survey.dimensions)) {
    survey.dimensions.forEach(dimension => {
      if (Array.isArray(dimension.questions)) {
        dimension.questions.forEach((question, indexQ, arrayQuestion) => {
          arrayQuestion[indexQ] = {
            ...question,
            resourceType: getResourceType(question.type),
          };
        });
      }
    });
  }

  return survey;
};

export const serializeAnswers = answers => {
  const serializedAnswers = [];

  if (Array.isArray(answers)) {
    answers.forEach(({ ...answer }) => {
      let serializedAnswer = {};

      if (answer.hasOwnProperty('choices')) {
        serializedAnswer.choice = [];

        if (Array.isArray(answer.choices)) {
          answer.choices.forEach(({ ...choice }) => {
            serializedAnswer.choice.push(choice.id);
          });
        }
      } else if (answer.hasOwnProperty('answerValue')) {
        serializedAnswer.answerValue = answer.answerValue;
      }

      const resourceType = getResourceType(answer.question.type);
      delete answer.choices;

      serializedAnswer = {
        ...answer,
        ...serializedAnswer,
        resourceType,
        question: answer.question.id,
      };
      serializedAnswers.push(serializedAnswer);
    });

    return serializedAnswers;
  }

  return [];
};

export const serializeSurvey = (survey, isParsed = false) => {
  if (survey == null) return survey;

  const description = { ...survey.description };
  const welcomeMessage = { ...survey.welcomeMessage };
  let serializedSurvey = { ...survey };

  if (!__.isEmpty(description)) {
    const dsc = {};
    __.forEach(description, (content, key) => {
      dsc[key] = EditorState.createWithContent(
        convertFromRaw(isParsed ? content : JSON.parse(content)),
      );
    });
    serializedSurvey = { ...serializedSurvey, description: dsc };
  }

  if (!__.isEmpty(welcomeMessage)) {
    const dsc = {};
    __.forEach(welcomeMessage, (content, key) => {
      dsc[key] = EditorState.createWithContent(
        convertFromRaw(isParsed ? content : JSON.parse(content)),
      );
    });
    serializedSurvey = { ...serializedSurvey, welcomeMessage: dsc };
  }

  return serializedSurvey;
};

// TODO highly required tests
export const getFirstNotAnsweredQuestion = (survey, answers) => {
  const mappedAnswers = __.mapKeys(answers, answer => answer.question.id);
  if (Array.isArray(survey.dimensions)) {
    for (let i = 0; i < survey.dimensions.length; i++) {
      const dimension = survey.dimensions[i];
      if (Array.isArray(dimension.questions)) {
        const missing = dimension.questions.find(question => {
          const { parent, condition } = question;
          if (parent != null && condition != null) {
            const { value, choice } = condition;
            if (value != null) {
              if (
                !mappedAnswers.hasOwnProperty(question.id) &&
                mappedAnswers.hasOwnProperty(parent) &&
                mappedAnswers[parent].answerValue == value
              ) {
                return true;
              }
            } else if (choice != null) {
              if (
                !mappedAnswers.hasOwnProperty(question.id) &&
                mappedAnswers.hasOwnProperty(parent) &&
                mappedAnswers[parent].choices
              ) {
                return (
                  mappedAnswers[parent].choices.find(
                    elem => elem.id == choice,
                  ) != null
                );
              }
            }
          } else if (!mappedAnswers.hasOwnProperty(question.id)) {
            return true;
          }
          return false;
        });
        if (missing != null) return missing;
      }
    }
  }

  return null;
};

export const getErrorMessageByCode = code => {
  switch (code) {
    case 150302:
    case 150121:
      return _('length_3_invalid_message');
    case 150301:
      return _('invalid_answer_message');
    default:
      return 'Error';
  }
};

export const isAdmin = loggedAs =>
  ['ADMIN', 'ADMIN_SUPERVISOR'].indexOf(loggedAs) !== -1;

export const isListQuestion = ({ type }) =>
  ['MULTIPLE_CHOICE', 'SINGLE_CHOICE', 'RATING_CHOICE'].indexOf(type) !== -1;

export const mapLinkedQuestions = ({ ...survey }) => {
  if (Array.isArray(survey.dimensions)) {
    survey.dimensions.forEach(dimension => {
      if (Array.isArray(dimension.questions)) {
        const questions = [];
        const linkedQuestions = dimension.questions.filter(question => {
          const filter = question.parent != null && question.id == null;

          if (!filter) {
            questions.push({ ...question });
          }
          return filter;
        });

        linkedQuestions.forEach(linkedQuestion => {
          const { parent } = linkedQuestion;
          const questionParent = questions.find(
            ({ uid, id }) =>
              (id != null && id == parent) || (uid != null && uid == parent),
          );
          if (questionParent != null) {
            if (isListQuestion(questionParent)) {
              const { choice } = linkedQuestion.condition;
              const choiceParent = questionParent.choices.find(
                ({ uid, id }) =>
                  (id != null && id == choice) ||
                  (uid != null && uid == choice),
              );

              if (choiceParent.linkedQuestions != null) {
                choiceParent.linkedQuestions.push(linkedQuestion);
              } else {
                choiceParent.linkedQuestions = [linkedQuestion];
              }
            } else if (questionParent.linkedQuestions != null) {
              questionParent.linkedQuestions.push(linkedQuestion);
            } else {
              questionParent.linkedQuestions = [linkedQuestion];
            }
          }
        });

        dimension.questions = questions;
      }
    });
  }

  return { ...survey };
};

export const filterQuestion = (question, omitProperties = []) => {
  if (question.text != null) {
    const { text } = question;
    __.forEach(text, (value, language) => {
      if (value == null || value == '') {
        delete text[language];
      }
    });
  }

  delete question.parent;
  delete question.condition;

  if (question.resourceType === 'multipleChoice') {
    const { choices } = question;
    if (Array.isArray(choices)) {
      choices.forEach(choice => {
        if (choice.text != null) {
          const { text } = choice;
          __.forEach(text, (value, language) => {
            if (value == null || value == '') {
              delete text[language];
            }
          });
        }
        if (Array.isArray(choice.linkedQuestions)) {
          choice.linkedQuestions.forEach(linkedQuestion => {
            filterQuestion(linkedQuestion, ['condition']);
          });
        }
      });
    }
  }

  if (Array.isArray(question.linkedQuestions)) {
    question.linkedQuestions = question.linkedQuestions.map(linkedQuestion => ({
      condition: linkedQuestion.condition,
      question: filterQuestion(linkedQuestion, ['condition']),
    }));
  }

  omitProperties.forEach(property => {
    delete question[property];
  });

  return question;
};

export const isDisplayed = (question, parentAnswer) => {
  const { parent, condition } = question;

  if (parent == null) {
    return true;
  }
  if (parent != null && condition != null && parentAnswer != null) {
    const { value, choice } = condition;
    const { answerValue } = parentAnswer;
    const parentAnswerChoices = parentAnswer.choice;

    if (value != null && answerValue != null) {
      return value == answerValue;
    }
    if (
      choice != null &&
      choice != null &&
      Array.isArray(parentAnswerChoices)
    ) {
      return parentAnswerChoices.find(choiceId => choiceId == choice) != null;
    }
  }
  return false;
};

export const getTextByLanguage = (text, language, strict = false) => {
  if (text) {
    if (text[language]) return text[language];
    if (!strict) {
      return text.fr || text.nl || text.en || '';
    }
  }
  return '';
};

export const getMediaByLanguage = (media, language, strict = false) => {
  if (media) {
    if (media[language]) return media[language];
    if (!strict) {
      return media.fr || media.nl || media.en || '';
    }
  }
  return null;
};

export const getColorByStatus = status => {
  switch (status) {
    case 'PENDING':
      return 'color-draft';
    case 'STARTED':
      return 'color-success';
    case 'CLOSED':
      return 'color-warning';
    case 'ENDED':
      return 'color-warning';
    default:
      return null;
  }
};

export const getAllQuestionsOfSurvey = survey => {
  if (survey != null && Array.isArray(survey.dimensions)) {
    return survey.dimensions.reduce(
      (prevQuestions, dimension) =>
        Array.isArray(dimension.questions)
          ? [...prevQuestions, ...dimension.questions]
          : prevQuestions,
      [],
    );
  }
  return [];
};

export const removeOrphanAnswers = (answers, questions) => {
  let filteredAnswers = { ...answers };
  if (!__.isEmpty(filteredAnswers)) {
    __.forEach(answers, (answer, questionId) => {
      let filtered = false;
      const question = questions.find(({ id }) => id == questionId);
      if (question != null && question.parent != null) {
        const questionParent = questions.find(
          ({ id }) => id == question.parent,
        );
        if (questionParent != null) {
          const answerParent = answers.hasOwnProperty(questionParent.id)
            ? answers[questionParent.id]
            : null;
          if (answerParent == null) {
            filtered = true;
          } else if (question.condition != null) {
            const { answerValue, choice } = question.condition;
            if (
              answerValue != null &&
              answerParent.answerValue != answerValue
            ) {
              filtered = true;
            } else if (
              choice != null &&
              answerParent.choice != null &&
              answerParent.choice.indexOf(choice) === -1
            ) {
              filtered = true;
            }
          }
        }
      }

      if (filtered) {
        filteredAnswers = __.omit(filteredAnswers, questionId);
      }
    });
  }

  return filteredAnswers;
};

export const getProfilingQuestions = survey => {
  if (survey != null && Array.isArray(survey.dimensions)) {
    return survey.dimensions.reduce((prevQuestions, dimension) => {
      if (Array.isArray(dimension.questions)) {
        const profilingQuestion = dimension.questions.filter(
          ({ isProfile }) => isProfile == 1,
        );
        return [...prevQuestions, ...profilingQuestion];
      }

      return prevQuestions;
    }, []);
  }

  return [];
};

export const getSurveyLinkPreview = (
  { type, slug, organizationInstance } = {},
  isRelative,
  organizationId
) => {
  const link = isRelative ? '' : TTP_SURVEY_URL;

  if (type == 'QUIZ') {
    return `${link}/quiz/${slug}`;
  }

  return `${link}/survey/${slug}${
    organizationInstance ? `/${organizationInstance.id}` : `?organization=${organizationId}`
  }`;
};

export const getSurveyStatsLink = (
  { type, id, slug } = {},
  organizationId,
  language,
  isRelative = true
) => {
  const link = isRelative ? '' : TTP_SURVEY_URL;

  if (type == 'QUIZ') {
    return `${link}/results/${id}?lng=${language}`;
  }

  return `${link}/stats/${slug}?tab=stats&organization=${organizationId}&lng=${language}`;
};

export const getEmailingCampaignLink = ({
  communityId = 0,
  targetType,
  targetId = 0,
  language,
}) => {
  const campaignUrl = `${TTP_EMAILING_URL}/campaign?type=text&targetId=${targetId}&communityId=${communityId}&targetApp=SURVEY`;

  return `${campaignUrl}&targetType=${targetType}&language=${language}`;
};

export const moveElement = (immutableArray, index, direction) => {
  if (
    !Array.isArray(immutableArray) ||
    immutableArray.length < 1 ||
    index >= immutableArray.length ||
    index < 0
  ) {
    return immutableArray;
  }

  const array = [...immutableArray];
  const element = array[index];

  switch (direction) {
    case MOVE_UP:
      if (index > 0) {
        [array[index - 1], array[index]] = [array[index], array[index - 1]];
      }
      break;
    case MOVE_DOWN:
      if (index < array.length - 1) {
        [array[index], array[index + 1]] = [array[index + 1], array[index]];
      }
      break;
    case MOVE_TO_START:
      if (index > 0) {
        array.splice(index, 1);
        array.unshift(element);
      }
      break;
    case MOVE_TO_END:
      if (index < array.length - 1) {
        array.splice(index, 1);
        array.push(element);
      }
      break;
    default:
      break;
  }

  return array;
};
