const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  nbResult: 0,
};

export const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_STATS_PENDING': {
      return { ...state, fetching: true };
    }
    case 'FETCH_STATS_FULFILLED': {
      const { stats, nbResponses } = action.payload.data.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: stats,
        nbResult: nbResponses,
      };
    }
    case 'FETCH_STATS_REJECTED': {
      let error = action.payload;
      switch (error && error.status && error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
      };
    }
    default:
      return state;
  }
};
