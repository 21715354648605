import axios from 'axios';
import { TTP_API_URL } from 'Config';

export const getNotifications = (token, clientId) => {
  const requestUrl = `${TTP_API_URL}/organization/notification`;

  const filter = [];
  const sort = [];

  filter.push({
    property: 'appName',
    value: 'SURVEY',
    operator: 'eq'
  });

  sort.push({
    property: 'createdAt',
    dir: 'desc'
  });

  const params = {
    access_token: token,
    limit: 5
  };

  if (clientId) {
    params.organization_id = clientId;
  }

  params.sort = JSON.stringify(sort);
  params.filter = JSON.stringify(filter);


  return axios.get(requestUrl, { params });
};

export const markSurveyNotificationAsRead = (token, notificationId) => {
  const requestUrl = `${TTP_API_URL}/organization/notification-user/mark-notification-as-read/${notificationId}`;
  const formData = new FormData();
  formData.append('access_token', token);

  return axios.post(requestUrl, formData);
};
