import React, { Component } from 'react';

export default class SurveyListGridFetching extends Component {
  renderList() {
    const rows = [];
    for (let i = 0; i < 5; i++) {
      rows.push(
        <div className="column column-block fetching" key={i}>
          <div className="srv-card">
            <div className="srv-card__container">
              <div className="srv-card__title list-fetching srv-card__title--fetching">
                <h5 className="gradiant srv__font-bold srv__font-bold--fetching" />
              </div>
            </div>
            <div className="srv-card__info no-content flex-container flex-dir-row-reverse" />
          </div>
        </div>
      );
    }
    return rows;
  }

  render() {
    return (
      <div className="small-12 srv-list-list">
        <div className="row small-up-5">
          {this.renderList()}
        </div>
      </div>
    );
  }
}
