const initial = {
  cities: [],
  countries: [],
  fetching: false,
  fetched: false,
};

const initialStateForRankingFilter = {
  list: { ...initial },
  filter: {
    country: 'all',
    city: 'all',
  },
  profiling: {
    language: 'all',
    questions: []
  },
  showAll: false,
  error: null,
};

export const statsFilterReducer = (state = initialStateForRankingFilter, action) => {
  switch (action.type) {
    case 'SET_STATS_FILTER_CITY': {
      return { ...state, filter: { ...state.filter, city: action.city } };
    }
    case 'SET_STATS_FILTER_COUNTRY': {
      return { ...state, filter: { ...state.filter, country: action.country } };
    }
    case 'FETCH_STATS_OPTIONS_PENDING': {
      return { ...state, list: { ...state.list, fetching: true } };
    }
    case 'FETCH_STATS_OPTIONS_FULFILLED': {
      const { data } = action.payload;

      return {
        ...state,
        list: {
          ...state.list,
          fetching: false,
          fetched: true,
          error: null,
          cities: data.cities,
          countries: data.countries,
        },
      };
    }
    case 'FETCH_STATS_OPTIONS_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
      };
    }
    case 'SET_STATS_PROFILING_FILTER_LANGUAGE': {
      return { ...state, profiling: { ...state.profiling, language: action.language } };
    }
    case 'SET_STATS_FILTER_SHOW_ALL': {
      return { ...state, showAll: action.showAll };
    }
    case 'SET_STATS_PROFILING_FILTER_QUESTION': {
      const { questionId, resourceType, value, property, operator } = action;
      const questions = state.profiling.questions.filter(item => item.questionId != questionId);

      return {
        ...state,
        profiling: {
          ...state.profiling,
          questions: value == null || value == 'all' || value == ''
            ? questions
            : [...questions, { questionId, resourceType, value, property, operator }]
        }
      };
    }

    case 'RESET_STATS_PROFILING_FILTER': {
      return {
        ...state,
        profiling: {
          language: 'all',
          questions: []
        }
      };
    }

    default:
      return state;
  }
};

// Selectors
export const selectQuestionFilter = ({ profiling: { questions } }, questionId) => (
  questions.find(item => item.questionId == questionId)
);
