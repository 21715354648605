import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setAuth, setLanguage } from 'actions';
import { fetchNotifications, markNotificationAsRead } from 'thunks';
import { TTP_HOME_URL, TTP_SURVEY_URL, TTP_EMAILING_URL } from 'Config';
import _ from 'i18n';
import { bindActionCreators } from 'redux';
import { ReactComponent as PortalIcon } from 'icons/portal.svg';
import { ReactComponent as ProfileIcon } from 'icons/profile.svg';
import { ReactComponent as EboxIcon } from 'icons/closed-envelop.svg';
import Notifs from './right-side/Notifs';
import Apps from './right-side/Apps';
import ProfileMenu from './right-side/ProfileMenu';
import MenuItem from './MenuItem';

class RightSide extends Component {
  componentDidMount() {
    const { currentClient, user } = this.props;
    if (currentClient && user) {
      this.props.fetchNotifications();
    }
  }

  componentDidUpdate(nextProps) {
    const { currentClient, user, notifications } = nextProps;

    if ((currentClient != this.props.currentClient || user != this.props.user) && !notifications.fetching) {
      this.props.fetchNotifications();
    }
  }

  changeLng(e) {
    if (e.target.classList.contains('selected')) {
      return false;
    }
    const { setLanguage } = this.props;
    setLanguage(e.target.id);
    window.location.reload();
    return null;
  }

  handleNotificationClick = (notificationId) => {
    this.props.markNotificationAsRead(notificationId);
  }

  render() {
    const { user, lng, router: { location: { pathname, search } }, notifications, currentClient } = this.props;
    const appsState = currentClient?.appsState ?? [];

    const goto = encodeURI(TTP_SURVEY_URL + pathname + search);
    const portalConfig = appsState.find(app => app.code === 'portal');

    if (user == undefined || user == null || user.id == null) {
      return (
        <div className="top-bar-right menu__off">
          <div className="menu__language no-padding">
            <ul>
              <li id="fr" className={lng == 'fr' ? 'selected' : ''} onClick={this.changeLng.bind(this)}>FR</li>
              <li id="nl" className={lng == 'nl' ? 'selected' : ''} onClick={this.changeLng.bind(this)}>NL</li>
              <li id="en" className={lng == 'en' ? 'selected' : ''} onClick={this.changeLng.bind(this)}>EN</li>
            </ul>
          </div>
          <a className="sign-in" href={`${TTP_HOME_URL}?goto=${goto}`}>{_('signIn')}</a>
        </div>
      );
    }

    return (
      <div className="top-bar-right">
        <ul className="menu buttons notifications">
          {portalConfig?.activated && (
            <MenuItem
              renderIcon={() => <PortalIcon />}
              href={portalConfig.url}
            />
          )}
          <MenuItem
            renderIcon={() => <ProfileIcon />}
            href={`${TTP_HOME_URL}/profile`}
          />
          <MenuItem
            renderIcon={() => <EboxIcon />}
            className="ebox"
            href={`${TTP_EMAILING_URL}/e_box`}
            count={0}
          />
          <Notifs
            notifications={notifications}
            lng={lng}
            onClick={this.handleNotificationClick}
          />
          <Apps lng={lng} apps={appsState.filter(({ code }) => code !== 'portal')} />
        </ul>
        <ProfileMenu />
      </div>
    );
  }
}

const mapStateToProps = store => ({
  user: store.auth.user,
  lng: store.params.lng,
  router: store.router,
  currentClient: store.auth.currentClient,
  notifications: store.notifications,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setAuth,
    setLanguage,
    fetchNotifications,
    markNotificationAsRead
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RightSide);
