import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { getUserNameForAvatar } from 'utils';
import { setCurrentClient } from 'thunks';
import _ from 'i18n';
import __ from 'lodash';

class Clients extends Component {
  state = {
    show: false,
  };

  handleShow = () => this.setState(prevState => ({ show: !prevState.show }));

  handleSetCurrentClient(client) {
    this.props.setCurrentClient(client);
    this.props.history.push('/');
  }

  getUserAvatar() {
    const { avatarUrl, firstName, lastName } = this.props.auth.user;

    let avatarDiv = null;
    if (avatarUrl) {
      avatarDiv = (
        <div
          className="menu__img-wrap m-b-s"
          style={{ backgroundImage: `url(${avatarUrl})` }}
        />
      );
    } else {
      avatarDiv = (
        <div className="menu__img-wrap empty-avatar m-b-s">
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
        </div>
      );
    }

    return avatarDiv;
  }

  renderClients() {
    const {
      currentClient,
      user: { surveyClients },
    } = this.props.auth;
    const clients = Array.isArray(surveyClients)
      ? __.compact(surveyClients)
      : [];

    if (clients.length < 1) {
      return null;
    }

    return (
      <div className="menu__dropdown">
        <ul>
          {__.uniqBy(clients, 'id').map(client => {
            const isCurrent = client.id === currentClient?.id;
            const { avatarUrl, abbreviation, id } = client;
            const logo = avatarUrl ?? 'img/default-logo.png';

            const logoBlock = (
              <span
                className="boxes__box__logo"
                style={{ backgroundImage: `url(${logo})` }}
              />
            );
            let clientName = name;
            if (abbreviation) {
              clientName = abbreviation;
            } else if (clientName.length > 30) {
              clientName = `${clientName.substr(0, 30)}...`;
            }
            return (
              <li className="menu__sub-child" key={`client-${id}`}>
                <a
                  onClick={
                    isCurrent
                      ? null
                      : this.handleSetCurrentClient.bind(this, client)
                  }
                >
                  {logoBlock}
                  {clientName}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  render() {
    const { currentClient } = this.props.auth;

    let navText = _('Communities');

    if (currentClient) {
      navText =
        currentClient.abbreviation ||
        (currentClient.name.length <= 20
          ? currentClient.name
          : currentClient.name.substring(0, 20));
    }

    return (
      <li
        className="menu__has-child menu__community flex-container p-x-m"
        onMouseEnter={this.handleShow}
        onMouseLeave={this.handleShow}
      >
        <span
          className="menu__link no-padding--left"
          style={{ cursor: 'pointer' }}
        >
          <span>{navText}</span>
          {this.state.show ? (
            <i className="icon icon-arrow-up" />
          ) : (
            <i className="icon icon-arrow-down" />
          )}
        </span>
        {this.renderClients()}
      </li>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  currentPage: state.params.currentNavPage,
});

const mapDispatchToProps = { setCurrentClient };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(Clients);
