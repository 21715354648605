import __ from 'lodash';

export const filterQuestion = (question, omitProperties = []) => {
  if (question.text != null) {
    const { text } = question;
    __.forEach(text, (value, language) => {
      if (value == null || value == '') {
        delete text[language];
      }
    });
  }

  delete question.parent;
  delete question.condition;

  if (question.resourceType === 'multipleChoice') {
    const { choices } = question;
    if (Array.isArray(choices)) {
      choices.forEach((choice) => {
        if (choice.text != null) {
          const { text } = choice;
          __.forEach(text, (value, language) => {
            if (value == null || value == '') {
              delete text[language];
            }
          });
        }
        if (Array.isArray(choice.linkedQuestions)) {
          choice.linkedQuestions.forEach((linkedQuestion) => {
            filterQuestion(linkedQuestion, ['condition']);
          });
        }
      });
    }
  }

  if (Array.isArray(question.linkedQuestions)) {
    question.linkedQuestions = question.linkedQuestions.map(linkedQuestion => ({
      condition: linkedQuestion.condition,
      question: filterQuestion(linkedQuestion, ['condition']),
    }));
  }

  omitProperties.forEach((property) => {
    delete question[property];
  });

  return question;
};

/**
 * @returns object : {
 *  key
 *  errors: { text : array }
 * }
 */
export const validateQuestion = ({ id, uid, text }, validationResults) => {
  const key = id || uid;
  const textErrors = [];

  if (text) {
    __.forEach(text, (value, language) => {
      if (value == null || String(value).trim() == '') {
        delete text[language];
      } else if (value.length < 2) {
        textErrors.push({ code: 150302, language });
      }
    });
  } else {
    textErrors.push({ code: 150302 });
  }

  if (textErrors.length == 0 && __.isEmpty(text)) {
    textErrors.push({ code: 150302 });
  }

  let exist = false;
  const code = 150302;
  if (validationResults.text.hasOwnProperty(key) && !__.isEmpty(validationResults.text[key])) {
    validationResults.text[key].forEach((propertyError) => {
      if (propertyError.code === code) {
        exist = true;
      }
    });
  }
  if (!exist) {
    validationResults.text[key] = textErrors.length != 0 ? [textErrors[0]] : [];
  }

  return validationResults;
};
