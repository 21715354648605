import React from 'react';

const DimensionResultsFetching = () => (
  <div className="row wrapper">
    <div className="medium-12 columns">
      <div className="row small-up-1 medium-up-2 large-up-3 list-fetching">
        {
          Array.from(Array(9).keys()).map(item => (
            <div className="column column-block" key={item}>
              <div className="result-card">
                <h2 className="card-title gradiant" />
                <div className="card-score">
                  <div className="score-item">
                    <div className="score-label">
                      <label className="no-content" />
                    </div>
                    <div className="score-score">--</div>
                  </div>
                  <div className="score-item">
                    <div className="score-label"><label className="no-content" /></div>
                    <div className="score-score">--</div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  </div>
);

export default DimensionResultsFetching;
