import __ from 'lodash';

/**
 * @returns object : {
 *  key
 *  errors: { text : array }
 * }
 */
export const validateChoice = ({ id, uid, text }, validationResults) => {
  const key = id || uid;
  const textErrors = [];

  if (text) {
    __.forEach(text, (value, language) => {
      if (value == null || String(value).trim() == '') {
        delete text[language];
      } else if (value.length < 2) {
        textErrors.push({ code: 150302, language });
      }
    });
  } else {
    textErrors.push({ code: 150302 });
  }

  if (textErrors.length == 0 && __.isEmpty(text)) {
    textErrors.push({ code: 150302 });
  }

  let exist = false;
  const code = 150302;
  if (validationResults.text.hasOwnProperty(key) && !__.isEmpty(validationResults.text[key])) {
    validationResults.text[key].forEach((propertyError) => {
      if (propertyError.code === code) {
        exist = true;
      }
    });
  }

  if (!exist) {
    validationResults.text[key] = textErrors.length != 0 ? [textErrors[0]] : [];
  }

  return validationResults;
};
