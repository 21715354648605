import React, { Component } from 'react';
import autosize from 'autosize';
import { connect } from 'react-redux';
import {
  setCurrentQuestion,
  addValidationErrors,
  removeValidationErrors,
  setSettingsCurrentTab
} from 'actions';
import { SURVEY_BUILDER_TABS } from 'constants';
import _ from 'i18n';
import { getErrorMessageByCode } from 'surveyUtils';
import DisplayErrors from 'preview-items/DisplayErrors';
import ReactTooltip from 'react-tooltip';
import shortId from 'shortid';

export class QuestionOtherChoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      isFocused: false
    };
    this.tooltipId = `tooltip-choice-text-${shortId.generate()}`;
  }

  componentDidMount() {
    autosize(this.textarea);
    if (this.props.focusOnMount) {
      this.textarea.focus();
    }
  }

  onKeyDown = (e) => {
    // TODO
    if (e.keyCode === 8 || e.keyCode == 46) {
      if (!e.target.value) {
        // this.handleDeleteChoice();
      }
    }
  };

  onChange(text) {
    const { maxChars, validationParams: { key, resourceType, property } } = this.props;

    if (!maxChars || text.length <= maxChars) {
      this.setState({ value: text });
      this.props.onTextChange(text);
    } else {
      const editedValue = text.slice(0, maxChars);

      this.setState({ value: editedValue });
      this.props.onTextChange(editedValue);
    }

    if (text.length >= 3) {
      this.props.removeValidationErrors(key, resourceType, property);
    }
  }

  onFocus = () => {
    const {
      setCurrentQuestion,
      orderDimension,
      orderQuestion,
      currentTab
    } = this.props;
    const { SURVEY_SETTINGS, SETTINGS } = SURVEY_BUILDER_TABS;

    setCurrentQuestion(orderDimension, orderQuestion);
    this.setState({ isFocused: true });

    if (currentTab === SURVEY_SETTINGS) {
      this.props.setSettingsCurrentTab(SETTINGS);
    }
  }

  onBlur = () => {
    this.setState({ isFocused: false });
    this.validateLength();
  }

  validateLength() {
    const { addValidationErrors, validationParams } = this.props;
    const { key, resourceType, property } = validationParams;
    const { value } = this.props;

    if (value.length < 3) {
      addValidationErrors(key, resourceType, property, { code: 150302 });
    }
  }

  render() {
    const { value, helpText, language, lng, maxChars } = this.props;
    const { errors } = this.props;

    return (
      <div className="question-choice">
        <div className="question-choice__container">
          <span className="question-choice__container__badge" contentEditable={false}>+</span>
          <span className="question-choice__container__content" contentEditable={false}>
            <div className="text">
              <textarea
                className="transparent-input"
                ref={(c) => { this.textarea = c; }}
                placeholder={helpText != '' ? helpText : _('other')}
                onKeyDown={this.onKeyDown}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={e => this.onChange(e.target.value)}
                value={value || ''}
                data-tip
                data-for={this.tooltipId}
              />
              {maxChars && <div className="counter">{this.state.isFocused ? maxChars - ((value && value.length) || 0) : null}</div>}
            </div>
            {helpText && helpText != '' && lng != language
              ? (
                <ReactTooltip id={this.tooltipId} multiline={true} className="react-tooltip">
                  <span className="tooltip-content">{helpText}</span>
                </ReactTooltip>
              )
              : null
            }
            {
              errors
              && errors.map(error => <DisplayErrors message={getErrorMessageByCode(error.code)} key={`error-${error.code}`} />)
            }
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentTab: state.surveys.list.toolbarTab,
});
const mapDispatchToProps = { addValidationErrors,
  removeValidationErrors,
  setCurrentQuestion,
  setSettingsCurrentTab
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionOtherChoice);
