import { generateSurvey, serializeSurvey } from 'surveyUtils';

const initialState = {
  fetching: false,
  fetched: false,
  started: false,
  starting: false,
  updating: false,
  resultFetching: false,
  resultFetched: false,
  language: 'fr',
  languages: ['fr'],
  quiz: generateSurvey(),
  instance: null,
  items: [],
  result: [],
  error: null,
  nbResult: 0,
};

export const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_QUIZ': {
      return initialState;
    }
    case 'CHANGE_QUIZ_LANGUAGE': {
      const authorizedLng = ['fr', 'en', 'nl'];
      const lng = authorizedLng.indexOf(action.language) !== -1 ? action.language : 'fr';
      return {
        ...state,
        language: lng,
      };
    }
    case 'SET_QUIZ_LANGUAGES': {
      return {
        ...state,
        languages: action.languages,
      };
    }
    case 'FETCH_QUIZ_PENDING': {
      return { ...state, fetching: true, fetched: false };
    }
    case 'FETCH_QUIZ_FULFILLED': {
      const quiz = action.payload.data.data;
      const items = state.items.map((item) => {
        if (item.id == quiz.id) {
          return quiz;
        }

        return item;
      });
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        quiz: serializeSurvey(quiz),
        items,
      };
    }
    case 'FETCH_QUIZ_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        quiz: {},
        error,
      };
    }
    case 'FETCH_QUIZ_TOP_TEN_PENDING': {
      return { ...state, resultFetching: true, resultFetched: false };
    }
    case 'FETCH_QUIZ_TOP_TEN_FULFILLED': {
      const result = action.payload.data.data;

      return {
        ...state,
        resultFetching: false,
        resultFetched: true,
        error: null,
        result,
      };
    }
    case 'FETCH_QUIZ_TOP_TEN_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        resultFetching: false,
        result: [],
        error,
      };
    }

    case 'CREATE_QUIZ_INSTANCE_PENDING': {
      return { ...state, started: false, starting: true };
    }
    case 'CREATE_QUIZ_INSTANCE_FULFILLED': {
      return {
        ...state,
        starting: false,
        started: true,
        error: null,
        instance: action.payload.data.data
      };
    }
    case 'CREATE_QUIZ_INSTANCE_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        starting: false,
        started: false,
        error,
      };
    }
    case 'SET_INSTANCE_CURRENT_QUESTION_PENDING': {
      return { ...state, updating: true };
    }
    case 'SET_INSTANCE_CURRENT_QUESTION_FULFILLED': {
      return {
        ...state,
        updating: false,
        updated: true,
        error: null,
        instance: action.payload.data.data
      };
    }
    case 'SET_INSTANCE_CURRENT_QUESTION_REJECTED': {
      let error = action.payload;
      switch (error && error.response && error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        updating: false,
        updated: false,
        error,
      };
    }
    default:
      return state;
  }
};

// Selectors

export const lastQuestionId = ({ quiz }) => {
  if (!quiz || !Array.isArray(quiz.dimensions)) {
    return 0;
  }
  const { dimensions } = quiz;

  return dimensions.slice(-1)[0].questions.slice(-1)[0].id;
};
