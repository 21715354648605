const initialState = {
  fetching: false,
  fetched: false,
  stats: [],
  error: null,
  nbResult: 0,
};

export const participantUserStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIALIZE_PARTICIPANT_USER_STATS': {
      return initialState;
    }
    case 'FETCH_PARTICIPANT_USER_STATS_PENDING': {
      return { ...state, fetching: true };
    }
    case 'FETCH_PARTICIPANT_USER_STATS_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        stats: action.payload.data.data,
      };
    }
    case 'FETCH_PARTICIPANT_USER_STATS_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
      };
    }
    default:
      return state;
  }
};
