import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  changeLanguage,
  setCurrentQuestion,
  updateTitle,
  completeSurvey,
  startSurvey,
  setSettingsCurrentTab,
} from 'actions';
import { SURVEY, SURVEY_BUILDER_TABS } from 'constants';
import HeaderLabel from 'HeaderLabel';
import { getOptions } from 'SurveyConfig';
import { getTextByLanguage } from 'surveyUtils';
import withHover from 'common/HOC/withHover';
import CreateBlock from './CreateBlock';

export class SurveyTitle extends Component {
  constructor(props) {
    super(props);

    this.removeTitle = this.removeTitle.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
  }

  onFocus = () => {
    const { currentTab } = this.props;
    const { SURVEY_SETTINGS, SETTINGS } = SURVEY_BUILDER_TABS;
    this.props.startSurvey(false);
    this.props.completeSurvey(false);
    this.props.setCurrentQuestion(-1, -1);
    if (currentTab === SETTINGS) {
      this.props.setSettingsCurrentTab(SURVEY_SETTINGS);
    }
  };

  onTextChange(text) {
    const { title, surveyLanguage } = this.props;

    this.props.updateTitle({ ...title, [surveyLanguage]: text });
  }

  removeTitle = () => {
    const { dimension, updateTitle } = this.props;
    if (dimension) {
      updateTitle(null);
    }
  };

  changeLanguage(lng) {
    this.props.changeLanguage(lng);
  }

  render() {
    const {
      title,
      appLanguage,
      surveyLanguage,
      surveyType,
      languages,
      focused,
      onMouseEnter,
      onMouseLeave,
      hovering,
    } = this.props;
    const errors = this.props.errors[this.props.surveyId];
    const value = getTextByLanguage(title, surveyLanguage, true);
    const helpText = getTextByLanguage(title, appLanguage, true);
    const active = focused || hovering;
    return (
      <div
        className="editor-block__container"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="editor-block__root srv__Title">
          <HeaderLabel
            options={getOptions('TITLE')}
            value={value}
            hasFocus={active}
            onFocus={this.onFocus}
            onKeyDown={this.removeTitle}
            onTextChange={this.onTextChange}
            helpText={helpText}
            validationParams={{
              resourceType: SURVEY,
              key: this.props.surveyId,
              property: 'title',
            }}
            errors={errors}
          />
          <select
            className="srv__select__lng"
            onChange={e => this.changeLanguage(e.target.value)}
            value={surveyLanguage}
          >
            <optgroup>
              {languages.map(lng => (
                <option value={lng} key={lng}>
                  {lng.toUpperCase()}
                </option>
              ))}
            </optgroup>
          </select>
        </div>
        <div className={`editor-block__actions ${focused ? 'visible' : ''}`}>
          {active && surveyType !== 'PORTAL' && (
            <CreateBlock
              order={-1}
              orderDimension={-1}
              blocks={['SURVEY_DESCRIPTION', 'DIMENSION']}
              unfocus={onMouseLeave}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  surveyLanguage: state.surveys.list.language,
  appLanguage: state.params.lng,
  currentTab: state.surveys.list.toolbarTab,
  errors: state.surveys.list.validationErrors.survey.title,
});

const mapDispatchToProps = {
  completeSurvey,
  startSurvey,
  setCurrentQuestion,
  updateTitle,
  changeLanguage,
  setSettingsCurrentTab,
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHover,
)(SurveyTitle);
