import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import configureStore from 'configureStore';
import {
  getLocalStorageAuth,
  setLocalStorageAuth,
  getLocalStorageRedirectUrl,
  setLocalStorageRedirectUrl,
} from 'SurveyAPI';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router-dom';

import { setAuthUser, loginApp } from 'thunks';
import { setAuth, setAppToken, setLanguage } from 'actions';
import Layout from './components/Layout';
import { getHistory } from './router/getHistory';
import { getRoutes } from './router';
import { tokenHasExpired } from 'auth';
import { getCookie } from 'utils';
import { APP_ENV } from 'Config';
import { PersistGate } from 'redux-persist/integration/react';
import Modal from 'react-modal';
import { initSentry } from './sentry';
import isoCountries from 'i18n-iso-countries';
import { LANGUAGES } from 'Common';

// App css
import 'applicationStyles';
import 'draft-js-inline-toolbar-plugin/lib/plugin.css';
import 'draft-js-side-toolbar-plugin/lib/plugin.css';

// load foundation js
import 'foundation-sites/dist/js/plugins/foundation.core';
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard';
import 'foundation-sites/dist/js/plugins/foundation.util.triggers';
import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery';
import 'foundation-sites/dist/js/plugins/foundation.util.motion';
import 'foundation-sites/dist/js/plugins/foundation.util.box';
import 'foundation-sites/dist/js/plugins/foundation.util.nest';
import 'foundation-sites/dist/js/plugins/foundation.reveal';
import 'foundation-sites/dist/js/plugins/foundation.tooltip';
import 'foundation-sites/dist/js/plugins/foundation.offcanvas';
import 'foundation-sites/dist/js/plugins/foundation.dropdownMenu';

$(document).foundation();

// Configure Store
const { store, persistor } = configureStore();

// Init Sentry
initSentry(store, {
  APP_ENV,
  APP_NAME: 'survey',
  APP_RELEASE: '0.11.0',
  APP_SENTRY_KEY: '884c5485edf246b49885e77db095fe32',
  APP_SENTRY_ID: 9,
});

// configure Modal
Modal.setAppElement('#app');

// Configure axios
axios.interceptors.request.use(
  config => {
    // Set language in headers before request is sent
    const { lng } = store.getState().params;
    config.headers = {
      ...config.headers,
      'Content-Language': lng || 'fr',
      'Accept-Language': lng || 'fr',
    };
    return config;
  },
  error => error,
);

// Register country Languages
isoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
isoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
isoCountries.registerLocale(require('i18n-iso-countries/langs/nl.json'));

const now = Math.floor(new Date().getTime() / 1000);
const cookieName = `ttp_auth${APP_ENV != '' ? '_' : ''}${APP_ENV}`;
const userCookie = getCookie(cookieName);
const auth = getLocalStorageAuth();
const params = new URLSearchParams(location.search);
const languageParams = LANGUAGES.indexOf(params.get('lng')) !== -1 ? params.get('lng') : null;

// Authenticate the User
if (userCookie && userCookie.length > 0) {
  const authInfos = JSON.parse(userCookie);
  if (
    authInfos &&
    typeof authInfos === 'object' &&
    now < parseInt(authInfos.createdAt) + parseInt(authInfos.expiresIn)
  ) {
    if (auth && auth.user != null && authInfos.token === auth.token) {
      store.dispatch(setAuth(auth));
    } else {
      store.dispatch(setAuthUser(authInfos)).then(res => {
        const { language } = res.value;
        store.dispatch(setLanguage(languageParams ?? language ?? 'fr'));
        const uri = getLocalStorageRedirectUrl();
        if (uri) {
          history.push(uri);
        }
        setLocalStorageRedirectUrl(null);
      });
    }
  }
} else {
  setLocalStorageAuth(auth && auth.appToken ? { appToken: auth.appToken } : null);
}

// Authenticate the App
if (!auth || tokenHasExpired(auth.appToken)) {
  store.dispatch(loginApp());
} else {
  store.dispatch(setAppToken(auth.appToken));
}

let lng = languageParams ?? localStorage.getItem('lng');
if (!lng) {
  if (store.auth && store.auth.user != null) {
    lng = store.auth.user.language;
  } else {
    const navigatorLanguage = navigator.language || navigator.userLanguage;
    lng =
      ['fr', 'en', 'nl'].indexOf(navigatorLanguage) > -1
        ? navigatorLanguage
        : 'fr';
  }
}

setTimeout(() => store.dispatch(setLanguage(lng)), 0);

const App = () => <Layout>{getRoutes(store)}</Layout>;
const history = getHistory();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <Route component={App} />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('app'),
);
