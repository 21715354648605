import React from 'react';
import shortId from 'shortid';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

export default function Menu({ text, disabled, children, menuDirection = 'left', infoTooltip }) {
  const uid = shortId.generate();

  return (
    <li className={`drop-down-sub-menu ${menuDirection}`}>
      <a data-tip data-for={`tt-menu-${uid}`} className={disabled ? 'disabled-link' : ''}>{text}</a>
      {
        !disabled && (<ul className="nested-menu">{children}</ul>)
      }
      {
        infoTooltip && (
          <ReactTooltip id={`tt-menu-${uid}`} multiline={true} className="react-tooltip" type={infoTooltip?.type}>
            <span className="tooltip-content">{infoTooltip?.text}</span>
          </ReactTooltip>
        )
      }
    </li>
  );
}

Menu.propTypes = {
  menuDirection: PropTypes.oneOf(['auto', 'left', 'right']),
};
