import * as actions from 'actions';
import { getDimensionScore } from 'TamtamAPI';

export const fetchDimensionScore = (instanceId, organization) => (dispatch, getState) => {
  const { token: userToken, appToken, currentClient } = getState().auth;
  const token = userToken != '' ? userToken : appToken.token;
  const organizationId = organization ?? currentClient?.id ?? 0;
  return dispatch(actions.fetchDimensionScore(
    getDimensionScore(token, instanceId, organizationId).then(res => res.data, err => err),
  ));
};
