import React from 'react';
import styles from './SurveyThemes.module.scss';

export default function ThemeItem({ theme, onClick, isSelected }) {
  const {
    background: { backgroundColor, backgroundImage },
  } = theme;

  let style;
  if (backgroundColor) {
    style = { backgroundColor };
  }
  if (backgroundImage) {
    style = { ...style, backgroundImage: `url(${backgroundImage})` };
  }

  return (
    <div
      className={`${styles.survey_themes__item} ${
        styles.with_check
      } ${isSelected && styles.selected} no-margin no-padding`}
      onClick={onClick}
    >
      <div className={styles.wrapper} style={style}>
        {isSelected && <i className="icomoon icon-tt-correct" />}
      </div>
      <div className={styles.title}>{name}</div>
    </div>
  );
}
