import * as actions from 'actions';
import {
  getTTPUser,
  getCurrentsUser,
  getAnonymousUser,
  setSelectedOrganization,
  getClientCredential
} from 'TamtamAPI';
import { LANGUAGES } from 'Common';
import { setCookie } from 'utils';
import { APP_ENV } from 'Config';
import { tokenHasExpired, getTokenFromAuthApp } from 'auth';

export const setAuthUser = user => dispatch => {
  dispatch(actions.setAuthToken(user.token));
  dispatch(actions.setExpiresIn(user.expiresIn));
  dispatch(actions.setTokenCreatedAt(user.createdAt));

  return dispatch(
    actions.fetchAuthUser(
      getTTPUser(user.id, user.token).then(
        res => res.data.data[0],
        err => err,
      ),
    ),
  );
};

export const setCurrentsUser = token => dispatch => {
  dispatch(actions.setAuthToken(token));

  return dispatch(
    actions.fetchAuthUser(
      getCurrentsUser(token).then(res => {
        const user = res.data.data;
        if (
          user.language != null &&
          LANGUAGES.indexOf(user.language.toLowerCase()) !== -1
        ) {
          dispatch(actions.setLanguage(user.language));
        }

        dispatch(actions.setExpiresIn(user.expiresIn));
        dispatch(actions.setTokenCreatedAt(user.createdAt));

        return user;
      }, null),
    ),
  );
};

export const setAnonymousAuthUser = email => dispatch =>
  dispatch(
    actions.fetchAnonymousAuthUser(
      getAnonymousUser(email).then(res => {
        const {
          data: { user },
          token,
        } = res.data;
        dispatch(actions.setAuthToken(token.access_token));
        dispatch(actions.setExpiresIn(token.expires_in));
        dispatch(actions.setTokenCreatedAt(token.createdAt));
        user.email = [user.email];
        return { ...user, email: [user.email] };
      }),
    ),
  );

export const setCurrentClient = client => (dispatch, getState) => {
  const { token } = getState().auth;

  actions.setCurrentClient(
    setSelectedOrganization(token, client).then(
      () => {
        const dtExpire = new Date();
        dtExpire.setTime(dtExpire.getTime() + 86400 * 3);
        setCookie(
          `ttp_community_${APP_ENV}`,
          client.id,
          dtExpire,
          '/',
          'tamtam.pro',
        );
        return client;
      },
      err => err,
    ),
  );

  return dispatch(actions.setCurrentClient(client));
};

export const loginApp = () => (dispatch, getState) => {
  const auth = getState().auth;

  if (tokenHasExpired(auth.appToken)) {
    return dispatch(
      actions.fetchAppToken(
        getClientCredential().then(resp =>
          getTokenFromAuthApp(resp.data.token),
        ),
      ),
    );
  }
};
