import React, { useEffect } from 'react';
import __ from 'lodash';
import { Redirect, Route } from 'react-router-dom';
import { setLocalStorageRedirectUrl } from 'SurveyAPI';

const RouteIfRole = ({
  auth = {},
  path,
  roles,
  isSurveyRole = false,
  component,
  exact,
  renderPropsToComponent,
  location,
}) => {
  const ComponentToRender = component;
  const { fetched, user } = auth;

  useEffect(() => {
    if (!fetched || !user) {
      const { pathname, search } = location;

      setLocalStorageRedirectUrl(`${pathname}${search}`);
    }
  }, [fetched, location, user]);

  const render = props => {
    const componentProps =
      renderPropsToComponent && typeof renderPropsToComponent === 'function'
        ? renderPropsToComponent(props)
        : props;
    const loggedAs = isSurveyRole ? auth?.loggedAs : auth?.loggedAsPost;

    if (auth?.fetched && auth?.user) {
      /** Needs only a connected user */
      if (!roles || __.isEmpty(roles)) {
        return <ComponentToRender {...componentProps} />;
      }

      /** Route with specific role <loggedAs || loggedAsPost> */
      if (
        (Array.isArray(roles) && roles.some(role => role === loggedAs)) ||
        roles === loggedAs
      ) {
        return <ComponentToRender {...componentProps} />;
      }
    }

    return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
  };

  return <Route exact={exact} path={path} render={render} />;
};

export default RouteIfRole;
