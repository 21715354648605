import axios from 'axios';
import { TTP_API_URL } from 'Config';
import { throwCatchedError } from 'utils';

export const getSurveyThemes = (token, isTemplate, userId = null, id = null) => {
  const idUrl = id != null ? `/${id}` : '';
  const requestUrl = `${TTP_API_URL}/survey/theme${idUrl}`;
  const filter = [];
  if (isTemplate) {
    filter.push({
      property: 'isTemplate',
      value: 1,
      operator: 'eq',
    });
  } else if (userId) {
    filter.push({
      property: 'createdBy',
      value: userId,
      operator: 'eq',
    });
  }
  const params = {
    access_token: token,
  };

  params.filter = JSON.stringify(filter);
  return axios.get(requestUrl, { params }).catch((thrown) => { throwCatchedError(thrown); });
};

export const removeSurveyTheme = (token, themeId) => {
  const requestUrl = `${TTP_API_URL}/survey/theme/${themeId}`;

  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('_method', 'delete');

  return axios.post(requestUrl, formData);
};

export const saveSurveyTheme = (token, theme) => {
  const requestUrl = `${TTP_API_URL}/survey/theme`;
  if (theme.createdBy) {
    delete theme.createdBy;
  }

  return axios({
    method: 'post',
    url: requestUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: JSON.stringify(theme)
  });
};

export const setSurveyTheme = (token, themeId, surveyId) => {
  const requestUrl = `${TTP_API_URL}/survey/theme/apply`;

  return axios({
    method: 'post',
    url: requestUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: JSON.stringify({
      theme: themeId,
      survey: surveyId
    })
  });
};
