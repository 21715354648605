import React, { Component } from 'react';

export default class SurveyListFetching extends Component {
  renderList() {
    const rows = [];
    for (let i = 0; i < 5; i++) {
      rows.push(
        <div className="row list-item border-draft" key={i}>
          <div className="medium-3">
            <div className="list-item__header">
              <h5 className="gradiant" />
            </div>
          </div>
          <div className="medium-3 p-r-s">
            <h5 className="gradiant" />
            <h5 className="gradiant" />
          </div>
          <div className="medium-2 flex-container">
            <h5 className="gradiant" />
            <h5 className="gradiant" />
          </div>
          <div className="medium-1"><h5 className="gradiant" /></div>
          <div className="medium-1">
            <div className="list-item__sub-header flex-column-center m-b-xxs">
              <h5 className="gradiant" />
            </div>
          </div>
          <div className="medium-2"><h5 className="gradiant" /></div>
        </div>
      );
    }
    return rows;
  }

  render() {
    return (
      <div className="small-12 columns list-fetching">
        {this.renderList()}
      </div>
    );
  }
}
