import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCurrentNavPage } from 'actions';
import _ from 'i18n';
import { getTextByLanguage } from 'surveyUtils';
import { LANGUAGES } from 'Common';
import ReactTooltip from 'react-tooltip';
import isoCountries from 'i18n-iso-countries';
import InputStatsList from './InputStatsList';
import NoData from '../charts/NoDataChart';

const InputStats = props => {
  const { lng, data, nbResponses } = props;
  const { text, id, profilingType } = data.question;
  const { nbResponse } = data.stats;
  const idTooltip = `responses-qst-${id}`;

  const renderCountry = ({ value } = {}) => {
    const language = LANGUAGES.includes(lng) ? lng : 'fr';

    return isoCountries.getName(value, language);
  };

  return (
    <div className="column column-block">
      <div className="column summary__qst">
        <div className="title">{getTextByLanguage(text, lng)}</div>
        <div className="responses">
          <span data-tip data-for={idTooltip}>
            {nbResponse}
            {'/'}
            {nbResponses}
          </span>
          <ReactTooltip
            id={idTooltip}
            multiline={true}
            className="react-tooltip"
          >
            <span className="tooltip-content">
              {`${nbResponse} ${_('out of')} ${nbResponses} ${_(
                'people answered this question',
              )}`}
            </span>
          </ReactTooltip>
        </div>
        <div className="list m-b-m">
          {nbResponse != 0 ? (
            <InputStatsList
              data={data.stats.data}
              renderItem={profilingType === 'COUNTRY' ? renderCountry : null}
            />
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCurrentNavPage }, dispatch);
}

const mapStateToProps = state => ({
  lng: state.params.lng,
});

export default connect(mapStateToProps, mapDispatchToProps)(InputStats);
