import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setCurrentNavPage, initSurveyPagination } from 'actions';
import _ from 'i18n';
import TTPTab from './common/TTPTab';
import PublicLibrary from './library/PublicLibrary';
import MyLibrary from './library/MyLibrary';

const tabs = [
  { id: 'MYLIBRARY', label: _('My Library') },
  { id: 'PUBLIC', label: _('public') },
];

const Library = (props) => {
  const [currentTab, setCurrentTab] = useState('MYLIBRARY');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = String((params.get('tab') ?? '')).toUpperCase();

    if (tabs.find(t => t.id == tab)) {
      updateCurrentTab(tab);
    }

    props.setCurrentNavPage('LIBRARY');
  }, []);

  const updateCurrentTab = (tab) => {
    props.initSurveyPagination();
    setCurrentTab(tab);
  };

  const goToMyLibrary = () => updateCurrentTab('MYLIBRARY');
  const goToPublicLibrary = () => updateCurrentTab('PUBLIC');

  return (
    <div id="surveyLibrary" className="p-t-m">
      <div className="ttp-tab-container">
        <TTPTab tabs={tabs} activeTab={currentTab} selectTab={updateCurrentTab} />
      </div>
      {currentTab == 'PUBLIC' && <PublicLibrary currentTab={currentTab} goToMyLibrary={goToMyLibrary} goToPublicLibrary={goToPublicLibrary} />}
      {currentTab == 'MYLIBRARY' && <MyLibrary currentTab={currentTab} goToMyLibrary={goToMyLibrary} goToPublicLibrary={goToPublicLibrary} />}
    </div>
  );
};

export default connect(null, { setCurrentNavPage, initSurveyPagination })(Library);
