import React from 'react';
import { defaultTheme } from 'SurveyConfig';
import { hexToRgb } from 'themes';

const SurveyStyle = ({ theme }) => {
  const isWithBackground = theme?.background?.backgroundImage != null;
  let backgroundStyle;
  if (isWithBackground) {
    backgroundStyle = `
      .survey-main-background::after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(41, 57, 77, 0.2), rgba(41, 57, 77, 0.2));
      }
    `;
  }
  const css = `
    .survey-main-background {
      background-image: url(${theme?.background?.backgroundImage ??
        ''}) !important;
      background-color: ${theme?.background?.backgroundColor ??
        defaultTheme?.background?.backgroundColor} !important;
    }
    ${backgroundStyle}
    .survey-main-color-light {
      color: rgba(${hexToRgb(
        theme?.colors?.color ?? defaultTheme.colors.color,
      )}, 0.3) !important
    }
    .survey-main-color-light:hover {
      color: ${theme?.colors?.color ?? defaultTheme.colors.color} !important;
    }

    /* order of survey-main-color-light AND survey-main-color is important do not move those classes */
    .survey-main-color {
      color: ${theme?.colors?.color ?? defaultTheme.colors.color} !important;
    }

    .survey-main-content-color {
      color: ${theme?.colors?.mainColor ??
        defaultTheme.colors.mainColor} !important;
    }
    .survey-main-gradient-color {
      color: ${theme?.colors?.mainGradientColor ??
        defaultTheme.colors.mainGradientColor} !important;
    }
    .survey-main-content-background-color {
      background-color: ${theme?.colors?.mainBackgroundColor ??
        defaultTheme.colors.mainBackgroundColor} !important;
    }
    .survey-main-content-gradient-background-color {
      background: ${theme?.colors?.mainBackgroundGradientColor ??
        defaultTheme.colors.mainBackgroundGradientColor} !important;
    }

    /* HOVER START*/
    .survey-main-content-background-hover:hover {
      background-color: ${theme?.colors?.mainBackgroundColor ??
        defaultTheme.colors.mainBackgroundColor} !important;
      color: ${theme?.colors?.mainColor ??
        defaultTheme.colors.mainColor} !important;
    }
    
    .survey-main-content-background-hover-light:hover {
      background-color: rgba(${hexToRgb(
        theme?.colors?.mainBackgroundColor ??
          defaultTheme.colors.mainBackgroundColor,
      )}, 0.3) !important
    }

    .survey-main-content-gradient-background-hover:hover {
      background-color: ${theme?.colors?.mainBackgroundColor ??
        defaultTheme.colors.mainBackgroundColor} !important;
      color: ${theme?.colors?.mainColor ??
        defaultTheme.colors.mainColor} !important;
    }

    /* TODO remove this */
    .survey-main-content-gradient-background-hover:hover .icon-wrapper {
      color: ${theme?.colors?.mainColor ??
        defaultTheme.colors.mainColor} !important;
    }
    .survey-main-content-gradient-background-color .choice-icon {
      color: ${theme?.colors?.mainColor ??
        defaultTheme.colors.mainColor} !important;
    }
    /* HOVER END*/
  `;
  return <style>{css}</style>;
};

SurveyStyle.defaultProps = {
  theme: defaultTheme,
};

export default SurveyStyle;
