import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchRankingOrganizationsOptions } from 'thunks';
import { getParents } from 'utils';
import {
  setRankingOrganizationsPaginationPage,
  setRankingOrganizationsPageSize,
  setRankingOrganizationsFilterOrganizationName,
  setRankingOrganizationsFilterCity,
  setRankingOrganizationsFilterCountry,
} from 'actions';
import _ from 'i18n';
import { bindActionCreators } from 'redux';

class RankingOrganizationsFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterFormIsVisible: false,
      searchQuery: '',
      oldSearchQuery: '',
    };
    this.handleHideFilterForm = this.handleHideFilterForm.bind(this);
  }

  componentDidMount() {
    this.props.fetchRankingOrganizationsOptions();
    this.handleHideFilterForm();
  }

  handleHideFilterForm() {
    document.getElementById('app').addEventListener('click', (e) => {
      const parents = getParents(e.target, document.getElementById('app'));
      for (let i = 0; i < parents.length; i++) {
        if (parents[i].className.indexOf('filter-wrapper') != -1) {
          return;
        }
      }
      this.setState({ filterFormIsVisible: false });
    });
  }

  handleToggleFilterForm() {
    this.setState(prevState => ({ filterFormIsVisible: !prevState.filterFormIsVisible }));
  }

  handleSelectCountry(event) {
    const country = event.target.value;
    this.props.setRankingOrganizationsFilterCountry(country);
  }

  handleSelectCity(event) {
    const city = event.target.value;
    this.props.setRankingOrganizationsFilterCity(city);
  }

  handleResetFilter() {
    this.props.setRankingOrganizationsFilterCity('all');
    this.props.setRankingOrganizationsFilterOrganizationName('');
    this.props.setRankingOrganizationsFilterCountry('all');

    this.setState({ searchQuery: '', oldSearchQuery: '' });
  }

  handleSearchSubmitClick() {
    const { searchQuery, oldSearchQuery } = this.state;
    if (searchQuery != oldSearchQuery) {
      this.props.setRankingOrganizationsFilterOrganizationName(this.state.searchQuery);
    }
    this.setState({ oldSearchQuery: searchQuery });
  }

  handleSearchInputChange({ target: { value } }) {
    this.setState(prevState => ({ searchQuery: value, oldSearchQuery: prevState.searchQuery }));
  }

  handleSearchInputEnterKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSearchSubmitClick(event);
    }
  }

  render() {
    const { filterFormIsVisible } = this.state;
    const { rankingsFilter, fetched } = this.props;

    if (!fetched) {
      return null;
    }

    return (
      <div className="filter-wrapper">
        <button type="button" className="button filter-button" onClick={this.handleToggleFilterForm.bind(this)}>
          {_('filter')}
          <i className="icon icon-arrow-down" />
        </button>
        <div className={filterFormIsVisible ? 'filter-form show' : ' filter-form hide'}>
          <label>
            {_('city')}
            <select value={rankingsFilter.filter.city} placeholder="filter by city" onChange={this.handleSelectCity.bind(this)}>
              <option value="all">{_('all').toUpperCase()}</option>
              {rankingsFilter.list.cities.map(city => <option key={`city-${city}`} value={city}>{city ? _(city) : 'NaN'}</option>)}
            </select>
          </label>
          <label>
            {_('country')}
            <select value={rankingsFilter.filter.country} placeholder="filter by country" onChange={this.handleSelectCountry.bind(this)}>
              <option value="all">{_('all').toUpperCase()}</option>
              {rankingsFilter.list.countries.map(country => (
                <option key={`country-${country}`} value={country}>
                  {' '}
                  {country ? _(country) : 'Nan'}
                </option>
              ))}
            </select>
          </label>
          <button type="button" className="button secondary" onClick={this.handleResetFilter.bind(this)}>{_('reset')}</button>
        </div>
        {/* <div className="search">
          <input value={searchQuery} placeholder={_('searchByOrganizationName')} className="search-input" onChange={this.handleSearchInputChange.bind(this)} onKeyPress={this.handleSearchInputEnterKeyPress.bind(this)} />
          <button type="button" id="search-btn" className="search-submit" onClick={this.handleSearchSubmitClick.bind(this)}><i className="icon-magnifier" /></button>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rankingsFilter: state.rankingOrganizations.filter,
  fetched: state.rankingOrganizations.filter.list.fetched,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchRankingOrganizationsOptions, setRankingOrganizationsFilterCountry, setRankingOrganizationsFilterCity, setRankingOrganizationsPaginationPage, setRankingOrganizationsPageSize, setRankingOrganizationsFilterOrganizationName,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RankingOrganizationsFilter);
