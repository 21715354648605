export const initSurveyTheme = () => ({
  type: 'INIT_SURVEY_THEME',
});
export const createSurveyTheme = resp => ({
  type: 'SAVE_SURVEY_THEME',
  payload: resp,
});
export const deleteSurveyTheme = themeId => ({
  type: 'DELETE_SURVEY_THEME',
  payload: themeId,
});
export const setSurveyTheme = theme => ({
  type: 'SET_SURVEY_THEME',
  theme,
});
export const setSurveyThemeFont = font => ({
  type: 'SET_SURVEY_THEME_FONT',
  font,
});
export const setSurveyThemeName = name => ({
  type: 'SET_SURVEY_THEME_NAME',
  name,
});
export const setSurveyThemeColors = (key, value) => ({
  type: 'SET_SURVEY_THEME_COLORS',
  key,
  value
});
export const setSurveyThemeBackground = (key, value) => ({
  type: 'SET_SURVEY_THEME_BACKGROUND',
  key,
  value
});
export const setSurveyThemeIsTemplate = isTemplate => ({
  type: 'SET_SURVEY_THEME_IS_TEMPLATE',
  isTemplate
});
export const revertSurveyThemeChanges = () => ({
  type: 'REVERT_SURVEY_THEME_CHANGES',
});
export const fetchSurveyThemes = resp => ({
  type: 'FETCH_SURVEY_THEMES',
  payload: resp,
});
export const fetchPrivateSurveyThemes = resp => ({
  type: 'FETCH_PRIVATE_SURVEY_THEMES',
  payload: resp,
});
export const fetchPublicSurveyThemes = resp => ({
  type: 'FETCH_PUBLIC_SURVEY_THEMES',
  payload: resp,
});
export const applySurveyTheme = resp => ({
  type: 'APPLY_SURVEY_THEME',
  payload: resp,
});
