import React, { useState, useEffect } from 'react';
import _ from 'i18n';
import styles from './Library.module.scss';
import LibraryList from './LibraryList';
import { useDispatch, connect } from 'react-redux';
import { fetchSurveyQuestions } from 'thunks';
import LibraryListFetching from './LibraryListFetching';

const LIBRARY = 'library';
const PROFILE = 'profile';

function Library({ closeSlider, library, profile, language, survey, current }) {
  const [tab, setTab] = useState(PROFILE);
  const dispatch = useDispatch();

  const isProfile = tab === PROFILE;
  const fetching = isProfile ? profile.fetching : library.fetching;
  const fetched = isProfile ? profile.fetched : library.fetched;
  const surveyQuestions = isProfile ? profile.items : library.items;

  useEffect(() => {
    const needRefresh = !fetching && (!fetched || surveyQuestions.length < 1);
    if (needRefresh) {
      dispatch(fetchSurveyQuestions(isProfile));
    }
  }, [isProfile]);

  const renderAddQuestion = () => (
    <div className={styles.block} style={{ color: '#29394c' }}>
      <span className="m-l-xs">
        <i className="icomoon icon-tt-plus" />
      </span>
      <span className="m-l-m">{_('Add question')}</span>
    </div>
  );

  const renderContent = () => {
    if (fetching) {
      return <LibraryListFetching />;
    } else if (!fetched || surveyQuestions.length < 1) {
      return renderAddQuestion();
    }

    return (
      <LibraryList
        isProfile={tab === PROFILE}
        surveyQuestions={surveyQuestions}
        survey={survey}
        current={current}
        language={language}
        renderActions={tab !== PROFILE && renderAddQuestion}
      />
    );
  };

  return (
    <div className={`s-toolbar ${styles.stoolbar}`}>
      <div className="s-toolbar__title separator">
        <div className="s-toolbar__title__label survey-font survey-font--upperCase">
          {_('Profiling')}
        </div>
        <div className="s-toolbar__title__icon" onClick={closeSlider}>
          <span>
            <i className="icomoon-icon icon-cross" />
          </span>
        </div>
      </div>
      <div>
        <div className={styles.tabs} style={{ display: 'none' }}>
          <div
            className={tab === LIBRARY ? styles.selected : ''}
            onClick={() => setTab(LIBRARY)}
          >
            {_('Library')}
          </div>
          <div
            className={tab === PROFILE ? styles.selected : ''}
            onClick={() => setTab(PROFILE)}
          >
            {_('Profiling')}
          </div>
        </div>
        {renderContent()}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  library: state.surveyQuestions.library,
  profile: state.surveyQuestions.profile,
  language: state.surveys.list.language,
  survey: state.surveys.list.survey,
  current: state.surveys.list.current,
});

export default connect(mapStateToProps)(Library);
