import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MENUS } from 'Common';
import { TTP_AMAZON_S3 } from 'Config';
import { Link } from 'react-router-dom';
import _ from 'i18n';
import RightSide from './menu/RightSide';
import Clients from './menu/Clients';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = { showSettings: false };

    this.settingsRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = ({ target }) => {
    if (this.settingsRef && this.settingsRef.current && !this.settingsRef.current.contains(target)) {
      this.setState({ showSettings: false });
    }
  }

  handleShowSettings = () => this.setState(prevState => ({ showSettings: !prevState.showSettings }));

  render() {
    const { loggedAs, loggedAsPost } = this.props.auth;
    const currentMenus = MENUS[loggedAs];

    return (
      <header id="mainMenu">
        <div className="top-bar" id="animated-menu">
          <span className={`menu__logo ${this.state.showSettings ? 'shadow' : ''}`}>
            {
              ['LEGAL_REPRESENTATIVE', 'MANAGER'].indexOf(loggedAsPost) != -1 && (
                <div ref={this.settingsRef}>
                  <span className="icon-options opens-left srv__drop-down--icon" onClick={this.handleShowSettings} />
                  <ul className={`menu__dropdown ${this.state.showSettings ? 'show' : ''}`}>
                    {['LEGAL_REPRESENTATIVE', 'MANAGER'].indexOf(loggedAsPost) != -1 && <li><Link onClick={this.handleShowSettings} to="/settings" className="menu__link">{_('settings')}</Link></li>}
                  </ul>
                </div>
              )
            }
            <img alt="" src={`${TTP_AMAZON_S3}/apps/survey.png`} />
            <Link to="/" className="name m-l-m">Survey</Link>
          </span>
          <nav className="top-bar-left">
            <ul className="menu">
              {currentMenus.indexOf('CLIENTS') !== -1 ? <Clients /> : null}
            </ul>
          </nav>
          <RightSide />
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Menu);
