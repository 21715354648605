import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import LibraryItem from './LibraryItem';
import LibraryItemFooter from './LibraryItemFooter';
import { LibraryItemFetching } from './LibraryListFetching';
import NoDataFound from '../survey/NoDataFound';

const LibraryList = (props) => {
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const {
    surveys,
    fetching,
    nbResult,
    paginationPage,
    currentClientId,
    lng,
    isUsed,
    isActive,
    participate,
    cancelParticipate,
    goToMyLibrary,
    goToPublicLibrary,
  } = props;

  const params = {
    currentClientId,
    lng,
    isUsed,
    participate,
    cancelParticipate,
    goToMyLibrary,
    goToPublicLibrary,
  };


  useEffect(() => {
    setHasMoreItems(!fetching);
  }, [fetching]);

  const loadItems = () => {
    if (!fetching && surveys.length < nbResult) {
      setHasMoreItems(false);
      props.setPaginationPage(paginationPage + 1);
    }
  };

  if (!fetching && (!Array.isArray(surveys) || surveys.length == 0)) {
    return (
      <div className="tab-pane align-center boxes active">
        <div className="columns srv-list-list">
          <NoDataFound />
        </div>
      </div>
    );
  }

  const items = [];
  const renderActions = survey => <LibraryItemFooter survey={survey} {...params} />;

  if (surveys && surveys.length > 0) {
    for (const survey of surveys) {
      items.push(<LibraryItem survey={survey} key={survey.id} renderActions={() => renderActions(survey)} />);
    }
  }
  if (fetching) {
    for (let i = 0; i < 5; i++) {
      items.push(<LibraryItemFetching key={`fetching-${i}`} />);
    }
  }

  return (
    <div className={`tab-pane align-center boxes ${isActive ? 'active' : 'active'}`}>
      <div className="column srv-list-list">
        <InfiniteScroll
          className="row small-up-5"
          pageStart={0}
          loadMore={loadItems}
          hasMore={hasMoreItems}
          initialLoad={false}
        >
          {items}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default LibraryList;
