import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCurrentNavPage } from 'actions';
import {
  PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer
} from 'recharts';
import __ from 'lodash';
import { getTextByLanguage } from 'surveyUtils';
import shortId from 'shortid';
import { STATS_COLORS } from 'SurveyConfig';

const RADIAN = Math.PI / 180;

export class MultipleChoiceChart extends Component {
  constructor(props) {
    super(props);

    this.baseLine = true;
  }

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getColors() {
    const COLORS = [];
    for (let i = 0; i < this.renderList().length; i++) {
      COLORS.push(this.getRandomColor());
    }
    return COLORS;
  }

  renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, startAngle, fill
  }) => {
    let color = 'white';
    let fontSize = '14px';
    let radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    let baseLine = 'central';

    if (midAngle - startAngle < 6) {
      radius = innerRadius + (outerRadius - innerRadius) * 1.15;
      color = fill;
      fontSize = '11px';
      baseLine = this.baseLine ? 'baseline' : 'ideographic';
      this.baseLine = !this.baseLine;
    }

    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fontSize={fontSize} fill={color} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline={baseLine}>
        {`${(percent * 100).toFixed(0) != 0 ? `${(percent * 100).toFixed(0)}%` : ''}`}
      </text>
    );
  };

  renderList() {
    return __.map(this.props.data, elem => ({
      name: getTextByLanguage(elem.choice.text, this.props.lng),
      value: elem.count
    }));
  }

  render() {
    const itemStyle = {
      whiteSpace: 'pre-wrap',
      lineHeight: '1.2',
    };
    const wrapperStyle = {
      maxWidth: '350px',
    };
    return (
      // height = auto used to fix bug in height, when the component is the last item and it has other option
      <ResponsiveContainer width="100%" height="auto" aspect={5 / 3}>
        <PieChart onMouseEnter={this.onPieEnter}>
          <Legend
            align="left"
            layout="vertical"
            verticalAlign="middle"
            iconType="circle"
            wrapperStyle={{ width: '40%', flex: 1, maxHeight: '80%', overflow: 'hidden', overflowY: 'auto' }}
          />
          <Tooltip itemStyle={itemStyle} wrapperStyle={wrapperStyle} />
          <Pie
            data={this.renderList()}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={this.renderCustomizedLabel}
            fill="#8884d8"
            dataKey="value"
          >
            {
              this.renderList().map((entry, index) => <Cell fill={STATS_COLORS[index % STATS_COLORS.length]} key={shortId.generate()} />)
            }
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

const mapStateToProps = state => ({
  lng: state.params.lng,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCurrentNavPage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MultipleChoiceChart);
