import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { MY_RANKING,
  STATS,
  ORGANIZATION_PARTICIPANTS,
  RANKING_ORGANIZATIONS,
  RANKING_CLIENTS,
  RESULTS,
  CAMPAIGN_STATS,
  USER_PARTICIPANTS,
  USER_RESPONSES,
} from 'constants';

const controlAccess = Component => (props) => {
  const { survey, menuName, currentClient, user, organization } = props;
  const { isSectorial, type, scope } = survey;

  const hasClientAuthorization = (survey, currentClient) => {
    if (!survey || (survey && !survey.organization)) {
      return true;
    }
    if (currentClient && currentClient.id == survey.organization.id) {
      return true;
    }
    return false;
  };

  if (!survey) {
    return <Component {...props} />;
  }

  const hasOrganizationRole = () => {
    if (!user || !Array.isArray(user.surveyClients)) {
      return false;
    }
    const organizationId = parseInt(organization ?? currentClient?.id ?? 0);
    return user.surveyClients.some(client => (parseInt(client?.id) === organizationId) && !isNaN(organizationId));
  };

  let disabled = false;
  let hide = false;
  const isOwner = hasClientAuthorization(survey, currentClient);
  const hasRole = hasOrganizationRole();

  // TODO AUTHORIZATIONS
  switch (menuName) {
    case RANKING_ORGANIZATIONS: {
      // disabled = !isOwner;
      // hide = !isOwner || !currentClient || !isSectorial;
      disabled = !isSectorial;
      hide = !isSectorial;
      break;
    }
    case RANKING_CLIENTS: {
      // disabled = !isOwner && !hasAccess && !isPublicStats;
      disabled = false;
      hide = !hasRole || type != 'SATISFACTION' || scope != 'CLIENTS';
      break;
    }
    case MY_RANKING: {
      disabled = false;
      hide = type != 'SATISFACTION';
      break;
    }
    case STATS: {
      disabled = false;
      hide = false;
      break;
    }
    case USER_PARTICIPANTS: {
      disabled = false;
      hide = !hasRole;
      break;
    }
    case USER_RESPONSES: {
      disabled = false;
      hide = !hasRole || type != 'SATISFACTION' || scope != 'COLLABORATORS';
      break;
    }
    case RESULTS: {
      // disabled = !currentClient;
      // hide = !isOwner && !hasAccess && !isPublicStats;
      disabled = false;
      hide = type != 'SATISFACTION';
      break;
    }
    case ((menuName && menuName.match(new RegExp(`${RESULTS}-\\d+`))) || {}).input: {
      // disabled = !currentClient;
      disabled = false;
      // hide = !isOwner && !hasAccess && !isPublicStats;
      hide = type != 'SATISFACTION';
      break;
    }
    case ORGANIZATION_PARTICIPANTS: {
      disabled = !isOwner;
      hide = !isOwner || !survey.isSectorial;
      break;
    }
    case CAMPAIGN_STATS: {
      disabled = false;
      hide = !hasRole || ['QUIZ', 'PORTAL'].indexOf(type) !== -1;
      break;
    }
    default: {
      disabled = false;
      hide = false;
    }
  }

  return <Component {...props} hide={hide} disabled={disabled} />;
};

const mapStateToProps = _mapStateToProps => (state) => {
  const componentState = (_mapStateToProps && _mapStateToProps(state)) || {};
  return ({
    ...componentState,
    user: state.auth?.user,
    currentClient: state.auth?.currentClient,
  });
};

const composedControlAccess = (_mapStateToProps, mapDispatchToProps) => compose(
  connect(mapStateToProps(_mapStateToProps), mapDispatchToProps),
  controlAccess
);

export default composedControlAccess;
