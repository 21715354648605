import * as actions from 'actions';
import * as api from 'TamtamAPI';

/**
 * current client is needed to get the participation infos
 */
export const fetchQuiz = slug => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  const data = {
    currentClient: currentClient ? currentClient.id : null,
  };
  return dispatch(actions.fetchQuiz(api.getSurveys(token, slug, data)));
};

export const createInstance = surveyId => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  const clientId = currentClient ? currentClient.id : 0;

  return dispatch(actions.createInstance(api.createInstance(token, surveyId, clientId)));
};

export const setInstanceCurrentQuestion = (insatnceId, questionId) => (dispatch, getState) => {
  const { token } = getState().auth;

  return dispatch(actions.setInstanceCurrentQuestion(api.setInstanceCurrentQuestion(token, insatnceId, questionId)));
};

export const fetchQuestionStats = (insatnceId, questionId) => (dispatch, getState) => {
  const { token } = getState().auth;

  return api.fetchQuestionStats(token, insatnceId, questionId);
};

export const closeInstance = insatnceId => (dispatch, getState) => {
  const { token } = getState().auth;

  return api.closeInstance(token, insatnceId);
};

export const closeInstanceBeacon = insatnceId => (dispatch, getState) => {
  const { token } = getState().auth;

  return api.closeInstanceBeacon(token, insatnceId);
};

export const fetchTopTen = insatnceId => (dispatch, getState) => {
  const { token } = getState().auth;

  return dispatch(actions.fetchTopTen(api.fetchTopTen(token, insatnceId)));
};

export const startPreviewQuestion = (instancePin, questionOrder, nbQuestions) => () => api.startPreviewQuestion(instancePin, questionOrder, nbQuestions);
export const closeCurrentQuestion = instancePin => () => api.closeCurrentQuestion(instancePin);
export const keepAlive = instancePin => () => api.keepAlive(instancePin);

export const fetchInstances = surveyId => (dispatch, getState) => {
  const { token } = getState().auth;

  return dispatch(actions.fetchInstances(api.fetchInstances(token, surveyId)));
};

export const fetchQuizStats = instanceId => (dispatch, getState) => {
  const { token } = getState().auth;

  // const {
  //   filter: { country, city },
  //   profiling,
  // } = getState().stats.filter;

  const data = { profiling: { questions: [] }, showAll: true };
  return dispatch(actions.fetchStats(
    api.getStats(token, instanceId, null, data),
  ));
};

export const fetchQuizParticipants = instanceId => (dispatch, getState) => {
  const { token } = getState().auth;

  return api.fetchQuizParticipants(token, instanceId);
};

export const calculateQuizResponseScore = instanceId => (dispatch, getState) => {
  const { token } = getState().auth;

  return api.calculateQuizResponseScore(token, instanceId);
};
