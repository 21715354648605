import * as actions from 'actions';
import { getClientCredential, getUsersSettings, saveUsersSettings } from 'TamtamAPI';
import { pushSourceToken } from 'utils';

export const fetchUsers = () => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  const { searchWord, role } = getState().settings.filter;
  const sourceToken = pushSourceToken('FETCH_USERS', dispatch);
  const clientId = currentClient.id;
  if (token == '') {
    return getClientCredential().then((resp) => {
      const clientToken = resp.data.token.access_token;
      dispatch(actions.setAuthToken(clientToken));
      return dispatch(actions.getUsersSettings(
        getUsersSettings(clientToken, searchWord, role, clientId, sourceToken),
      ));
    });
  }
  return dispatch(actions.getUsersSettings(
    getUsersSettings(token, searchWord, role, clientId, sourceToken),
  ));
};

export const updateUsersSettings = () => (dispatch, getState) => {
  const { token } = getState().auth;
  const {
    role, allowPublishSurvey, allowCreateSurvey, allowCloseSurvey, allowAccessResult, selectedUsers
  } = getState().settings;
  const clientId = getState().auth.currentClient.id;
  const userId = getState().auth.user.id;

  const preferences = {
    allowCreateSurvey: allowCreateSurvey ? 1 : 0,
    allowPublishSurvey: allowPublishSurvey ? 1 : 0,
    allowCloseSurvey: allowCloseSurvey ? 1 : 0,
    allowAccessResult: allowAccessResult ? 1 : 0,
  };

  if (token == '') {
    return getClientCredential().then((resp) => {
      const clientToken = resp.data.token.access_token;
      dispatch(actions.setAuthToken(clientToken));
      return dispatch(actions.saveUsers(
        saveUsersSettings(clientToken, allowCreateSurvey ? 1 : 0, allowPublishSurvey ? 1 : 0, allowCloseSurvey ? 1 : 0, allowAccessResult ? 1 : 0, role, selectedUsers, clientId)
          .then(
            () => {
              if (selectedUsers.includes(userId)) {
                dispatch(actions.setAuthLoggedAs(role));
                dispatch(actions.updateSurveyClientsAuthorization({ role, preferences, clientId }));
              }
              return dispatch(actions.updateUsers({ role, ...preferences, selectedUsers }));
            }
          )
      ));
    });
  }

  return dispatch(actions.saveUsers(
    saveUsersSettings(token, role, allowCreateSurvey ? 1 : 0, allowPublishSurvey ? 1 : 0, allowCloseSurvey ? 1 : 0, allowAccessResult ? 1 : 0, selectedUsers, clientId)
      .then(
        () => {
          if (selectedUsers.includes(userId)) {
            dispatch(actions.setAuthLoggedAs(role));
            dispatch(actions.updateSurveyClientsAuthorization({ role, preferences, clientId }));
          }

          return dispatch(actions.updateUsers({ role, ...preferences, selectedUsers }));
        }
      )
  ));
};
