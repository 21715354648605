import React, { Component } from 'react';
import { TAMTAM_API_LANGUAGES } from 'Common';
import _ from 'i18n';
import CountDown from './CountDown';
import withResult from './withResult';

const ICONS = [
  '/img/icons/triangle.svg',
  '/img/icons/polygon.svg',
  '/img/icons/ellipse.svg',
  '/img/icons/rectangle.svg',
];

const COLORS = ['blue', 'orange', 'red', 'green'];

class DoubleQuestionContent extends Component {
  renderChoices = lng => this.props.question.choices.map(({ text, choiceOrder, id }) => (
    <div className={`choice-block ${COLORS[choiceOrder]}`} key={`choice-data-${id}`}>
      <div className="icon-block">
        <div className="wrapper">
          <img alt="" src={ICONS[choiceOrder]} />
        </div>
      </div>
      <div className="text-block">
        <div className="content">{text ? text[lng] : ''}</div>
        <div className="back-ground fw" style={{ width: '100%' }} />
      </div>
    </div>
  ));

  renderStats = lng => this.props.question.choices.map(({ text, choiceOrder, isCorrect, id }) => {
    const { statsData, fetching } = this.props;

    return (
      <div className={`choice-block ${COLORS[choiceOrder]}`} key={`choice-stats-${id}`}>
        <div className={`icon-block ${isCorrect ? 'is-correct' : ''}`}>
          <div className="wrapper stats-percent" style={{ opacity: isCorrect ? 1 : 0.6 }}>
            {
              fetching
                ? <img alt="" src="/img/loaders/tamtam.svg" />
                : (
                  <>
                    {parseInt(statsData[id] || 0)}
                    <span>%</span>
                  </>
                )
            }
          </div>
        </div>
        <div className="text-block" style={{ opacity: isCorrect ? 1 : 0.5 }}>
          <div className="content">{text ? text[lng] : ''}</div>
          <div className="back-ground fw" style={{ width: `${statsData[id] || 0}%` }} />
        </div>
      </div>
    );
  });

  render() {
    const { question: { id, text, type, timeLimit }, languages, showStats } = this.props;

    if (type != 'SINGLE_CHOICE') {
      return 'NOT SUPPORTED QUESTION';
    }

    return (
      <div className="row small-12 question-wrapper fh">
        {
          languages.map((lng, key) => (
            <div className={`small-6 p-x-s ${key == 0 ? 'left-side' : 'right-side'}`} key={`question-${id}-${lng}`}>
              <div className="container bilingual">
                <div className="lng">
                  <img alt="" src={`/img/flags/${lng}.svg`} />
                  <span>{_(TAMTAM_API_LANGUAGES[lng])}</span>
                </div>
                <div className="quiz-preview__question question-content bilingual">
                  <div className="title-block">
                    <div className="title">{text ? text[lng] : ''}</div>
                    {!showStats && key == 0 ? <CountDown start={timeLimit} onTimeOut={this.props.onTimeOut} /> : null}
                  </div>
                  <div className="choices-block">
                    {showStats ? this.renderStats(lng) : this.renderChoices(lng)}
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    );
  }
}

export default withResult(DoubleQuestionContent);
