import React, { Component } from 'react';
import __ from 'lodash';
import _ from 'i18n';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getSurveyQuestion } from 'SurveyConfig';
import QuestionMaker from './QuestionMaker';
import DimensionHeader from './DimensionHeader';
import DimensionDescription from './DimensionDescription';
import CreateBlock from './CreateBlock';
import withHover from 'common/HOC/withHover';

export class DimensionMaker extends Component {
  constructor(props) {
    super(props);

    this.firstRender = true;
    this.newQuestions = [];
  }

  componentDidMount() {
    this.firstRender = false;
  }

  shouldComponentUpdate(nextProps) {
    const { dimension } = this.props;
    const oldQuestionList =
      (Array.isArray(dimension?.questions) && dimension.questions) || [];
    const questionList =
      (Array.isArray(nextProps.dimension?.questions) &&
        nextProps.dimension.questions) ||
      [];
    if (questionList.length != oldQuestionList.length) {
      this.newQuestions = questionList.filter(
        qst => !oldQuestionList.includes(qst),
      );
    } else if (this.newQuestions.length != 0) {
      this.newQuestions = [];
    }
    return true;
  }

  renderListQuestion() {
    const {
      dimension,
      lockedQuestions,
      current: { currentDimensionIndex, currentQuestionIndex },
      order: orderDimension,
    } = this.props;
    const { questions } = dimension;
    const size = __.size(questions);

    if (!Array.isArray(questions) || size === 0) {
      return null;
    }

    let order = 0;

    return questions.map((question, key, currentQuestions) => {
      const isBottom = key === size - 1;

      if (question.questionOrder !== key) {
        question.questionOrder = key;
        question.isUpdated = true;
      }
      let parentValue = null;
      let parent = null;
      const firstChild = currentQuestions.find(
        ({ parent }) =>
          (question.id != null && parent == question.id) ||
          (question.uid != null && parent == question.uid),
      );

      if (question.parent != null) {
        const { condition } = question;
        const { value, choice } = condition;
        parent = currentQuestions.find(
          ({ id, uid }) =>
            (id != null && id == question.parent) ||
            (uid != null && uid == question.parent),
        );

        if (parent) {
          if (value != null) {
            const { type } = parent;
            if (type == 'YES_NO') parentValue = value ? _('Yes') : _('No');
            else if (type == 'TRUE_FALSE')
              parentValue = value ? _('I accept') : _("I don't accept");
            else parentValue = value;
          } else if (choice != null) {
            if (parent != null) {
              const { choices } = parent;
              if (Array.isArray(choices)) {
                const choiceParent = choices.find(
                  ({ id, uid }) =>
                    (id != null && id == choice) ||
                    (uid != null && uid == choice),
                );
                if (choiceParent != null) {
                  const { language } = this.props;
                  parentValue = choiceParent.text[language];
                }
              }
            }
          }
        }
      }

      return (
        <QuestionMaker
          language={this.props.language}
          key={`question-${question.id || question.uid}`}
          orderDimension={orderDimension}
          order={key}
          orderLabel={question.parent == null ? order++ : order}
          question={question}
          parentQuestion={parent}
          firstChild={firstChild}
          isChild={true}
          isBottom={isBottom}
          parentLinkedValue={parentValue}
          focusOnMount={!this.firstRender}
          isNew={this.newQuestions.includes(question)}
          isLocked={lockedQuestions.get(question.id)}
          focused={
            key === currentQuestionIndex &&
            orderDimension === currentDimensionIndex
          }
        />
      );
    });
  }

  render() {
    const {
      dimension,
      order,
      lockedDimensions,
      surveyType,
      focusOnMount,
      current: { currentDimensionIndex, currentQuestionIndex, level },
      onMouseEnter,
      onMouseLeave,
      hovering,
    } = this.props;

    const hiddenStyle = { display: 'none' };
    const focused =
      order === currentDimensionIndex && currentQuestionIndex === -1;

    const active = (focused && level === 'TITLE') || hovering;

    return (
      <div>
        <div
          className="editor-block__container"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div
            className="editor-block__root"
            style={surveyType == 'PORTAL' ? hiddenStyle : null}
          >
            <DimensionHeader
              dimension={dimension}
              order={order}
              isLocked={lockedDimensions.get(dimension.id)}
              focusOnMount={focusOnMount}
              focused={active}
            />
          </div>
          {dimension?.questions?.length > 0 && (
            <div
              className="editor-block__groupLine editor-block__header"
              style={surveyType == 'PORTAL' ? hiddenStyle : null}
            />
          )}
          <div
            className={`editor-block__actions ${
              focused && level === 'TITLE' ? 'visible' : ''
            }`}
          >
            {/* {active && ( */}
            {active && surveyType != 'PORTAL' && !dimension?.isProfile && (
              <CreateBlock
                order={-1}
                orderDimension={order}
                blocks={[
                  'DIMENSION_DESCRIPTION',
                  ...getSurveyQuestion(surveyType),
                ]}
                unfocus={onMouseLeave}
              />
            )}
          </div>
        </div>
        <DimensionDescription
          dimensionId={dimension.id || dimension.uid}
          description={dimension.description}
          order={order}
          focused={focused && level === 'DESCRIPTION'}
        />
        {this.renderListQuestion()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lockedQuestions: state.surveys.list.lockedQuestions,
  lockedDimensions: state.surveys.list.lockedDimensions,
  surveyType: state.surveys.list.survey.type,
  current: state.surveys.list.current,
});

export default compose(connect(mapStateToProps), withHover)(DimensionMaker);
