import * as actions from 'actions';
import {
  getSurveys,
  removeSurvey,
  saveSurvey,
  removeQuestion as rmvQuestion,
  removeDimension as rmvDimension,
  removeChoice as rmvChoice,
  closeSurvey as clSurvey,
  editPenetrationRate,
  duplicateSurvey as dpSurvey,
  createSurveyFromTemplate as createSrvFTemplate,
  editInstanceStatus,
  getSurveyExtraData,
  // eslint-disable-next-line import/no-duplicates
} from 'TamtamAPI';
// eslint-disable-next-line no-duplicate-imports, import/no-duplicates
import * as api from 'TamtamAPI';

import _ from 'lodash';
import moment from 'moment';

export const fetchSurveys = (isTemplate = false) => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  const {
    list: { pageSize, paginationPage },
    filter: { status, type, title, languages, owner, env },
    sort: { sortConfig },
  } = getState().surveys;

  const data = {
    isTemplate,
    paginationPage,
    pageSize,
    sortConfig,
    status,
    type,
    title,
    languages,
    owner,
    env,
    currentClient: currentClient ? currentClient.id : null,
  };

  return dispatch(actions.fetchSurveys(getSurveys(token, null, data)));
};

/**
 * current client is needed to get the participation infos
 */
export const fetchSurvey = slug => (dispatch, getState) => {
  const { token: userToken, currentClient, appToken } = getState().auth;
  const data = {
    currentClient: currentClient ? currentClient.id : null,
  };
  const token = userToken != '' ? userToken : appToken.token;
  return dispatch(actions.fetchSurvey(getSurveys(token, slug, data).then((res) => {
    const survey = res.data.data;
    if (survey && survey.theme) {
      dispatch(actions.setSurveyTheme(survey.theme));
    }
    return res;
  })));
};


/** current client is needed to get the participation infos */
export const fetchSurveyWithToken = (slug, token) => (dispatch, getState) => {
  const { currentClient } = getState().auth;
  const data = {
    currentClient: currentClient ? currentClient.id : null,
  };

  return dispatch(
    actions.fetchSurvey(
      getSurveys(token, slug, data).then(res => {
        const survey = res.data.data;
        if (survey && survey.theme) {
          dispatch(actions.setSurveyTheme(survey.theme));
        }
        return res;
      }),
    ),
  );
};

export const getUsedSurveys = (isTemplate = false) => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  const {
    list: { paginationPage },
    filter: { status, type, title, owner, env },
    sort: { sortConfig },
  } = getState().surveys;

  const data = {
    isTemplate,
    paginationPage,
    pageSize: 15,
    sortConfig,
    status,
    type,
    title,
    owner,
    env,
    currentClient: currentClient ? currentClient.id : null,
  };

  const mode = paginationPage == 1 ? 'FETCH' : 'PUSH';

  return dispatch(actions.fetchSurveys(api.getUsedSurveys(token, data), mode));
};

export const getLibrarySurveys = (isTemplate = false) => (
  dispatch,
  getState,
) => {
  const { token, currentClient } = getState().auth;
  const {
    list: { paginationPage },
    filter: { status, type, title, owner, env },
    sort: { sortConfig },
  } = getState().surveys;

  const data = {
    isTemplate,
    paginationPage,
    pageSize: 15,
    sortConfig,
    status,
    type,
    title,
    owner,
    env,
    currentClient: currentClient ? currentClient.id : null,
  };

  const mode = paginationPage == 1 ? 'FETCH' : 'PUSH';

  return dispatch(
    actions.fetchSurveys(api.getLibrarySurveys(token, data), mode),
  );
};

export const fetchSurveyExtraData = surveyId => (dispatch, getState) => {
  const { token } = getState().auth;

  return dispatch(
    actions.fetchSurveyExtraData(getSurveyExtraData(token, surveyId)),
  );
};

export const fetchUsedSurveysDates = type => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;

  return dispatch(
    actions.fetchUsedSurveysDates(
      api.fetchUsedSurveysDates(
        token,
        type,
        moment().format(),
        currentClient?.id,
      ),
    ),
  );
};

export const persistSurvey = survey => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  const clientId = currentClient ? currentClient.id : null;
  return dispatch(
    actions.createSurvey(saveSurvey(token, _.cloneDeep(survey), clientId)),
  );
};
export const deleteSurvey = id => (dispatch, getState) => {
  const { token } = getState().auth;
  return dispatch(actions.deleteSurvey(removeSurvey(token, id)));
};
export const closeSurvey = slug => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  const clientId = currentClient ? currentClient.id : null;
  return dispatch(actions.closeSurvey(clSurvey(token, slug, clientId)));
};
export const setCurrentQuestion = (
  dimensionOrder,
  questionOrder,
) => dispatch => {
  dispatch(actions.setCurrentQuestion(dimensionOrder, questionOrder));
};
export const deleteDimension = id => (dispatch, getState) => {
  const { token } = getState().auth;
  return dispatch(actions.deleteDimension(rmvDimension(token, id)));
};
export const deleteQuestion = id => (dispatch, getState) => {
  const { token } = getState().auth;
  return dispatch(actions.deleteQuestion(rmvQuestion(token, id)));
};
export const deleteChoice = id => (dispatch, getState) => {
  const { token } = getState().auth;
  return dispatch(actions.deleteChoice(rmvChoice(token, id)));
};
export const refreshCurrentQuestion = () => dispatch => {
  dispatch(actions.refreshCurrentQuestion());
};
export const changeLanguage = language => dispatch => {
  dispatch(actions.changeLanguage(language));
};
export const updatePenetrationRate = (penetrationRate, slug) => (
  dispatch,
  getState,
) => {
  const { token } = getState().auth;
  return dispatch(
    actions.updatePenetrationRate(
      editPenetrationRate(token, penetrationRate, slug),
    ),
  );
};
export const duplicateSurvey = (surveySlug, isTemplate = false) => (
  dispatch,
  getState,
) => {
  const { token, currentClient } = getState().auth;
  return dispatch(
    actions.duplicateSurvey(
      dpSurvey(token, surveySlug, currentClient, isTemplate),
    ),
  );
};
export const createSurveyFromTemplate = surveySlug => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  return dispatch(
    actions.createSurveyFromTemplate(
      createSrvFTemplate(token, surveySlug, currentClient),
    ),
  );
};
export const updateInstanceStatus = (status, instanceId) => (
  dispatch,
  getState,
) => {
  const { token } = getState().auth;
  return dispatch(
    actions.updateInstanceStatus(editInstanceStatus(token, status, instanceId)),
  );
};
