import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SURVEY_VIEW } from 'Common';
import { setSurveyListView } from 'actions';
import { bindActionCreators } from 'redux';

const getIcon = (icon) => {
  switch (icon) {
    case 'LIST': return 'icomoon-icon icon-view-list';
    case 'GRID': return 'icomoon-icon icon-view-grid';
    default: return null;
  }
};

class SurveyViewSelector extends Component {
  handleClick(view) {
    if (this.props.view != view) {
      this.props.setSurveyListView(view);
    }
  }

  render() {
    return (
      <div className={`srv-view-selector ${this.props.classNames}`}>
        {
          SURVEY_VIEW.map((item) => {
            const isActive = item == this.props.view;
            const icon = getIcon(item);
            return (
              <div className={`srv-view-selector__item ${isActive ? 'is-active' : ''}`} key={`VIEW-${item}`} onClick={this.handleClick.bind(this, item)}>
                <span><i className={icon} /></span>
              </div>
            );
          })
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  view: state.params.listView.surveys,
});
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setSurveyListView }, dispatch);
} export default connect(mapStateToProps, mapDispatchToProps)(SurveyViewSelector);
