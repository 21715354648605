import React from 'react';
import _ from 'i18n';

export const NoData = () => (
  <div className="summary__no-data row">
    <div className="column medium-4 small-12 icon">
      <img alt="" src="/img/pictos/no-data.svg" />
    </div>
    <div className="column medium-8 small-12 content">
      <div className="header">{_('Nobody answered this question yet')}</div>
      <div className="message">{_('stats_question_no_data_found_msg')}</div>
    </div>
  </div>
);

export default NoData;
