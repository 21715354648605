import React from 'react';
import _ from 'i18n';

const RankingClientsListFetching = () => (
  <table className="unstriped hover">
    <thead>
      <tr>
        <th width="150" className="text-center"><label>{_('Rank')}</label></th>
        <th><label>{_('Client')}</label></th>
        <th width="200" className="text-center">
          <label>{_('Score')}</label>
        </th>
      </tr>
    </thead>
    <tbody className="list-fetching">
      {
        Array.from(Array(5).keys()).map(item => (
          <tr key={item}>
            <td className="cell-rank text-center"><h5 className="gradiant" /></td>
            <td className="text-left"><h5 className="gradiant" /></td>
            <td className="text-center">--</td>
          </tr>
        ))
      }
    </tbody>
  </table>
);

export default RankingClientsListFetching;
