import { convertToRaw } from 'draft-js';
import _ from 'lodash';

export const getLocalStorageUser = () => {
  const stringUser = localStorage.getItem('user');
  let user = [];

  try {
    user = JSON.parse(stringUser);
  } catch (e) {
    console.error(e);
  }
  return (user instanceof Object) ? user : {};
};

export const getLocalStorageAuth = () => {
  const stringAuth = localStorage.getItem('auth');
  let auth = {};

  try {
    auth = JSON.parse(stringAuth);
  } catch (e) {
    console.error(e);
  }
  return (typeof auth === 'object') ? auth : {};
};

export const setLocalStorageAuth = (auth) => {
  if (typeof auth === 'object') {
    localStorage.setItem('auth', JSON.stringify(auth));
    return auth;
  }
  if (auth == null) {
    localStorage.removeItem('auth');
  }
  return auth;
};

export const getLocalStorageRedirectUrl = () => localStorage.getItem('survey_redirect_url');

export const setLocalStorageRedirectUrl = (redirectUrl) => {
  if (redirectUrl != null) {
    localStorage.setItem('survey_redirect_url', redirectUrl);
  }
  if (redirectUrl == null) {
    localStorage.removeItem('survey_redirect_url');
  }
};

export const getLocalStorageSurvey = () => {
  const stringSurvey = localStorage.getItem('survey');
  let survey = {};

  try {
    survey = JSON.parse(stringSurvey);
  } catch (e) {
    console.error(e);
  }
  return (typeof survey === 'object') ? survey : {};
};

export const setLocalStorageSurvey = (survey) => {
  if (typeof survey === 'object') {
    const { description } = survey;

    if (description != null && !_.isEmpty(description)) {
      const dsc = {};

      _.forEach(description, (editorState, key) => {
        if (!_.isEmpty(key)) {
          dsc[key] = convertToRaw(editorState.getCurrentContent());
        }
      });

      survey.description = dsc;
    }

    localStorage.setItem('survey', JSON.stringify(survey));
    return survey;
  }
  if (survey == null) {
    localStorage.removeItem('survey');
  }
  return survey;
};
