import * as actions from 'actions';
import * as api from 'TamtamAPI';

export const fetchParticipantUsers = (instanceId, organization) => (dispatch, getState) => {
  const { token: userToken, appToken, currentClient } = getState().auth;
  const { filter } = getState().participantUser;

  const token = userToken != '' ? userToken : appToken.token;
  const organizationId = organization ?? currentClient?.id ?? 0;
  return dispatch(actions.fetchParticipantUsers(
    api.getParticipantUsers(token, instanceId, organizationId, filter),
  ));
};

export const sendTransactionalMails = (type, participants) => (dispatch, getState) => {
  const { token: userToken, appToken } = getState().auth;

  const token = userToken != '' ? userToken : appToken.token;
  return dispatch(actions.sendTransactionalMails(
    api.sendTransactionalMails(token, type, participants),
  ));
};
