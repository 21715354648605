import axios from 'axios';
import { TTP_API_URL } from 'Config';

export const postParticipate = (token, { id, organization } = {}, clientId, isRulesAccepted) => {
  const requestUrl = `${TTP_API_URL}/survey/organization-survey/participate`;

  const formData = new FormData();

  if ([token, id, clientId, isRulesAccepted].includes(undefined)) {
    throw 'Please add the required fields';
  }

  formData.append('access_token', token);
  formData.append('_method', 'post');
  formData.append('organization', clientId);
  formData.append('survey', id);
  formData.append('owner', organization?.id ?? 0);
  formData.append('isUsed', 1);
  formData.append('isConditionalRulesAccepted', isRulesAccepted ? 1 : 0);

  return axios.post(requestUrl, formData);
};

export const postCancelParticipate = (token, surveyId, clientId) => {
  const requestUrl = `${TTP_API_URL}/survey/organization-survey/cancel-participate`;

  const formData = new FormData();

  if ([token, surveyId, clientId].includes(undefined)) {
    throw 'Please add the required fields';
  }

  formData.append('access_token', token);
  formData.append('_method', 'post');
  formData.append('organization', clientId);
  formData.append('survey', surveyId);
  formData.append('isUsed', 0);

  return axios.post(requestUrl, formData);
};

export const postTransformToSectorialTemplate = (token, slug, clientId) => {
  const requestUrl = `${TTP_API_URL}/survey/survey/${slug}/to-sectorial`;

  if ([token, slug, clientId].includes(undefined)) {
    throw 'Please add the required fields';
  }

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('_method', 'post');
  formData.append('client', clientId);

  return axios.post(requestUrl, formData);
};
