import React, { useState } from 'react';

const withHover = WrappedComponent => ({ ...props }) => {
  const [hovering, setHovering] = useState(false);
  return (
    <WrappedComponent
      hovering={hovering}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      {...props}
    />
  );
};

export default withHover;
