import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setQuizLanguages } from 'actions';
import { TYPE_MONOLINGUAL, TYPE_BILINGUAL } from 'constants';
import __ from 'lodash';
import { FullScreenButton } from 'common/FullScreen';
import LanguageSetter from './LanguageSetter';
import QuizIntroduction from './QuizIntroduction';

class PreviewConfig extends Component {
  state = { availableLanguages: ['fr'], disableStartSurvey: false, selectedLanguages: [], previewType: null };

  componentDidMount() {
    this.setAvailableLanguages();
  }

  componentDidUpdate(prevProps) {
    const { quiz } = this.props.quiz;
    if (prevProps.quiz && quiz && prevProps.quiz.id != quiz.id) {
      this.setAvailableLanguages();
    }
  }

  setAvailableLanguages = () => {
    const { quiz } = this.props;
    const translationScore = quiz ? quiz.translationScore : [];
    const currentLanguage = (quiz.languages && quiz.languages.split(','))[0] || 'fr';
    let availableLanguages = [currentLanguage];

    if (translationScore) {
      availableLanguages = ['fr', 'nl', 'en'].filter(lng => parseInt(translationScore[lng]) === 100);
    }

    this.setState({ availableLanguages, disableStartSurvey: availableLanguages.length > 1 });

    if (availableLanguages.length == 1) {
      this.setState({ selectedLanguages: availableLanguages, previewType: TYPE_MONOLINGUAL });
      this.props.setQuizLanguages(availableLanguages);
    }
  }

  setPreviewType = (previewType) => {
    if (this.state.previewType != previewType) {
      this.setState(prevState => ({
        previewType,
        selectedLanguages: previewType == TYPE_MONOLINGUAL ? __.reject([prevState.selectedLanguages[0]], __.isEmpty) : prevState.selectedLanguages
      }), this.handleStatusButtonStart);
    }
  }

  handleSelectLanguage = (lng, checked) => {
    const isMonolingual = this.state.previewType == TYPE_MONOLINGUAL;
    if (checked) {
      this.setState(prevState => ({ selectedLanguages: isMonolingual ? [lng] : __.uniq([...prevState.selectedLanguages, lng]) }), this.handleStatusButtonStart);
    } else {
      this.setState(prevState => ({ selectedLanguages: prevState.selectedLanguages.filter(sl => sl != lng) }), this.handleStatusButtonStart);
    }
  }

  handleStatusButtonStart = () => {
    const { previewType, selectedLanguages } = this.state;
    let disableStartSurvey = false;

    if (previewType == TYPE_MONOLINGUAL) {
      disableStartSurvey = selectedLanguages.length < 1;
    } else if (previewType == TYPE_BILINGUAL) {
      disableStartSurvey = selectedLanguages.length < 2;
    }

    this.setState({ disableStartSurvey });
    this.props.setQuizLanguages(selectedLanguages);
  }

  render() {
    const { quiz, showPin, fullScreenEnabled } = this.props;
    const { availableLanguages, disableStartSurvey, selectedLanguages, previewType } = this.state;
    const isBilingual = availableLanguages.length > 1;

    return (
      <>
        <div className="column quiz-bilingual left-side">
          <LanguageSetter
            languages={availableLanguages}
            showPin={showPin}
            isBilingual={isBilingual}
            previewType={previewType}
            selectedLanguages={selectedLanguages}
            handleSelectLanguage={this.handleSelectLanguage}
            setPreviewType={this.setPreviewType}
            setShowPin={this.props.setShowPin}
          />
        </div>
        <div className="column quiz-bilingual right-side">
          <span onClick={this.props.handleFullScreen} className="fs-previw-config fs-wrapper"><FullScreenButton enabled={fullScreenEnabled} /></span>
          <QuizIntroduction disabled={disableStartSurvey} quiz={quiz} startSurvey={this.props.startSurvey} languages={selectedLanguages} />
        </div>
      </>
    );
  }
}

export default connect(null, { setQuizLanguages })(PreviewConfig);
