import React, { Component } from 'react';
import _ from 'i18n';
import { withRouter } from 'react-router-dom';
import { closeInstance, fetchTopTen } from 'thunks';
import { connect } from 'react-redux';
import { compose } from 'redux';
import TTButton from '../common/TTButton';
import QuizResultFetching from '../fetching/quiz/QuizResultFetching';

const MEDALS = ['gold', 'silver', 'bronze'];

class QuizResult extends Component {
  state = { retry: 3 };

  componentDidMount() {
    const { instanceId } = this.props;

    this.fetchResult();
    this.props.closeInstance(instanceId).catch(() => {
      if (this.state.retry) {
        this.props.closeInstance(instanceId);
      }
    });
  }

  fetchResult = () => {
    if (this.state.retry > 0) {
      this.props
        .fetchTopTen(this.props.instanceId)
        .catch(() => {
          this.setState(prevState => ({ retry: prevState.retry - 1 }));
          this.fetchResult();
        });
    }
  }

  retryFetchResult = () => {
    this.setState({ retry: 1 }, this.fetchResult);
  }

  render() {
    const { fetching, result, fetched, history } = this.props;

    if (fetching) {
      return <QuizResultFetching />;
    }

    if (!fetched) {
      return (
        <div className="quiz-preview__result row flex-column-center">
          <TTButton
            text={_('retry')}
            className="p-x-xxl"
            onClick={this.retryFetchResult}
            fontSize="20px"
            color="blue"
            upperCase={true}
            disabled={false}
          />
        </div>
      );
    }

    const data = Array.isArray(result) ? result : [];

    return (
      <div className="quiz-preview__result row">
        <div className="small-4 ranking">
          <div className="result-header">{_('Top ten')}</div>
          <div className="ranking-content">
            {
              data.map(({ user, score }, key) => (
                <div className="participant" id={`participant-${user.id}-${key}`}>
                  <div className="name">{user.fullName}</div>
                  <div className="score">{score || 0}</div>
                </div>
              ))
            }
          </div>
        </div>
        <div className="small-8 result-winners">
          {/* <div className="result-header">WINNERS</div> */}
          <div className="podium-wrapper">
            <div className="podium">
              {
                data.slice(0, 3).map(({ user, score }, key) => (
                  <div className="podium-block">
                    <div className="podium-block__header">
                      <div className="podium-block__name"><span>{user.fullName}</span></div>
                      <img className="podium-block__medal" alt="" src={`/img/medals/${MEDALS[key]}.svg`} />
                    </div>
                    <div className="podium-block__content">
                      <div className="container">
                        <div className="score">
                          <span>{score || 0}</span>
                          {' '}
                          {_('points')}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <TTButton
            text={_('exit')}
            className="p-x-xxl"
            onClick={() => history.push('/surveys?is-template=0')}
            fontSize="20px"
            color="blue"
            upperCase={true}
            disabled={false}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  instanceId: state.quiz.instance.id,
  fetching: state.quiz.resultFetching,
  fetched: state.quiz.resultFetched,
  result: state.quiz.result
});

export default compose(
  connect(mapStateToProps, { closeInstance, fetchTopTen }),
  withRouter
)(QuizResult);
