import axios from 'axios';
import { TTP_API_URL } from 'Config';
import { updateSourceToken, throwCatchedError } from 'utils';

let getUsersSettingsSourceToken;

export const getUsersSettings = (
  token,
  word,
  role,
  organizationId,
  sourceToken = null,
) => {
  getUsersSettingsSourceToken = updateSourceToken(
    getUsersSettingsSourceToken,
    sourceToken,
  );

  const fields = [
    'id',
    'firstName',
    'lastName',
    'avatar',
    'role',
    'surveyAuthorisation',
  ];

  let roleGroup = '1';
  if (role !== 'ALL') {
    roleGroup = role.slice(0, 1);
    role = role.slice(1);
  }

  let requestUrl = `${TTP_API_URL}/organization/user${
    roleGroup == 2 ? '' : '/survey-authorisation'
  }`;
  const filter = [];

  if (roleGroup == '2') {
    filter.push({
      property: 'role.type',
      value: role,
      operator: 'eq',
    });
  } else if (role !== 'ALL') {
    requestUrl += `?surveyRole=${role}`;
  }
  if (word !== '' && word.length >= 3) {
    filter.push({
      property: 'name',
      value: word,
      operator: 'like',
    });
  }

  const params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(','),
    limit: 100,
    organization_id: organizationId,
  };

  return axios
    .get(requestUrl, {
      cancelToken: getUsersSettingsSourceToken.token,
      params,
    })
    .catch(thrown => {
      throwCatchedError(thrown);
    });
};

export const saveUsersSettings = (
  token,
  role,
  allowCreateSurvey,
  allowPublishSurvey,
  allowCloseSurvey,
  allowAccessResult,
  usersIds,
  clientId,
) => {
  const requestUrl = `${TTP_API_URL}/survey/authorization/multi-save?access_token=${token}`;
  const formData = new FormData();
  for (const userId of usersIds) {
    formData.append('users_id[]', userId);
  }
  formData.append('client_id', clientId);
  formData.append('role', role);
  formData.append('preferences[allowCreateSurvey]', allowCreateSurvey);
  formData.append('preferences[allowPublishSurvey]', allowPublishSurvey);
  formData.append('preferences[allowCloseSurvey]', allowCloseSurvey);
  formData.append('preferences[allowAccessResult]', allowAccessResult);
  return axios.post(requestUrl, formData);
};

export const saveContact = (token, data = {}) => {
  const requestUrl = `${TTP_API_URL}/mailing/contact?access_token=${token}`;
  const { email, firstName, lastName, organizationId, hasAgreedTerms } = data;

  const formData = new FormData();
  formData.append('client', organizationId);
  formData.append('email', email);
  formData.append('firstName', firstName);
  formData.append('lastName', lastName);
  formData.append('hasAgreedTerms', hasAgreedTerms ? 1 : 0);
  return axios.post(requestUrl, formData);
};
