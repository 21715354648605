import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { TTP_EMAILING_URL } from 'Config';
import { closeCampaignViewer } from 'actions';
import Content from '../Content';
import CloseViewer from '../CloseViewer';

const customStyles = {
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    overflow: 'none',
    padding: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
};

class CampaignViewer extends PureComponent {
  handleAfterOpenModal() { }

  handleCloseModal = () => {
    const { onCloseCallback } = this.props.viewer;

    if (onCloseCallback) {
      onCloseCallback();
    }

    this.props.closeCampaignViewer();
  }

  getUrl() {
    const { viewer } = this.props;
    let url = `${TTP_EMAILING_URL}/text-model`;
    const params = [{
      key: 'targetApp',
      value: 'SURVEY'
    }];

    if (viewer.communityId) {
      params.push({
        key: 'communityId',
        value: viewer.communityId
      });
    }

    if (viewer.id) {
      url = `${url}/${viewer.id}`;
    }
    if (params.length != 0) {
      url = `${url}?`;
      let param;
      // eslint-disable-next-line no-cond-assign
      while (param = params.pop()) {
        url = `${url}${param.key}=${param.value}`;
        if (params.length != 0) {
          url = `${url}&`;
        }
      }
    }

    return url;
  }

  render() {
    const { viewer } = this.props;
    const url = this.getUrl();

    if (!viewer.isOpen) {
      return false;
    }
    return (
      <Modal
        isOpen={viewer.isOpen}
        onAfterOpen={this.handleAfterOpenModal}
        onRequestClose={this.handleCloseModal.bind(this)}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        contentLabel="Model Editor Viewer"
      >
        <div className="rmodal reveal-viewer" id="emailingViewer">
          <CloseViewer closeViewer={this.handleCloseModal} />
          <Content url={url} title="Model Editor" showHeader={true} />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  viewer: state.emailingViewer.campaign
});

const mapDispatchToProps = { closeCampaignViewer };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignViewer);
