import React, { PureComponent } from 'react';

export default class CloseViewer extends PureComponent {
  render() {
    return (
      <div className="rmodal__close">
        <button type="button" className="back" onClick={this.props.closeViewer} />
      </div>
    );
  }
}
