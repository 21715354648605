import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchStatsOptions } from 'thunks';
import { setStatsProfilingFilterQuestion } from 'actions';
import _ from 'i18n';
import { getTextByLanguage } from 'surveyUtils';
import { selectQuestionFilter } from 'reducers';
import ReactTooltip from 'react-tooltip';
import shortId from 'shortid';

const getChoices = (type) => {
  switch (type) {
    case 'TRUE_FALSE': return [
      {
        text: _('I accept'),
        value: 1
      },
      {
        text: _('I don\'t accept'),
        value: 0
      },
    ];
    case 'YES_NO': return [
      {
        text: _('Yes'),
        value: 1
      },
      {
        text: _('No'),
        value: 0
      },
    ];
    default: return [];
  }
};

class BooleanFilter extends Component {
  constructor(props) {
    super(props);
    this.handleFilter = this.handleFilter.bind(this);
    this.tooltipId = `tooltip-stats-${shortId.generate()}`;
  }

  handleFilter({ target: { value } }) {
    const { question: { id } } = this.props;

    this.props.setStatsProfilingFilterQuestion(id, 'boolean', 'answerValue', value, 'eq');
  }

  render() {
    const { question: { text, type }, value } = this.props;
    return (
      <div className="profiling-item column column-block">
        <div className="title srv-tooltip">
          <div className="greetings" data-tip data-for={this.tooltipId}>{getTextByLanguage(text, this.props.language)}</div>
          <ReactTooltip id={this.tooltipId} multiline={true} className="react-tooltip">
            <span className="tooltip-content">{getTextByLanguage(text, this.props.language)}</span>
          </ReactTooltip>
        </div>
        <div className="content">
          <select value={value} onChange={this.handleFilter}>
            <option value="all">{_('all').toUpperCase()}</option>
            {
              getChoices(type).map((choice, index) => (
                <option value={choice.value} key={index}>{choice.text}</option>
              ))
            }
          </select>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, { question: { id } }) => {
  const filter = selectQuestionFilter(state.stats.filter, id);

  return ({
    value: filter != null ? filter.value : 'all',
  });
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchStatsOptions, setStatsProfilingFilterQuestion }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BooleanFilter);
