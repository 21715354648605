import axios from 'axios';
import { TTP_API_URL } from 'Config';
import { throwCatchedError } from 'utils';

export const getRankingOptions = (token) => {
  const requestUrl = `${TTP_API_URL}/survey/ranking/filter-options`;

  const params = {
    access_token: token,
  };

  return axios.get(requestUrl, { params }).catch((thrown) => { throwCatchedError(thrown); });
};
