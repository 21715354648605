import axios from 'axios';
import { TTP_API_URL } from 'Config';

export const getDimensionScore = (token, instanceId, organizationId) => {
  const requestUrl = `${TTP_API_URL}/survey/result/dimension`;

  const filter = [];

  const params = {
    access_token: token,
    instance: instanceId || 0,
    organization: organizationId || 0,
  };

  params.filter = JSON.stringify(filter);

  return axios.get(requestUrl, { params });
};
