import * as actions from 'actions';
import * as api from 'TamtamAPI';
import _ from 'lodash';
import { throwCatchedError } from 'utils';
import { getAllQuestionsOfSurvey } from 'question';

export const persistSurveyResponse = (surveyResponse, answers) => (dispatch, getState) => {
  const { token } = getState().auth;
  const { language } = getState().surveys.list;
  const { lng } = getState().params;

  return dispatch(actions.createSurveyResponse(
    api.saveSurveyResponse(token, language || lng, surveyResponse, answers)
      .then(res => res.data.data)
      .catch((thrown) => { throwCatchedError(thrown); })
  ));
};
export const autoSaveSurveyResponse = () => (dispatch, getState) => {
  const { token } = getState().auth;
  const { language, survey } = getState().surveys.list;
  const { lng } = getState().params;

  const {
    surveyResponse, answers, lastUpdatedAnswers, error, saving
  } = getState().surveyResponse;
  const updatedAnswers = [];

  if (!_.isEmpty(lastUpdatedAnswers) && !saving) {
    lastUpdatedAnswers.forEach((questionId) => {
      if (answers.hasOwnProperty(questionId) && (error == null || _.isEmpty(error.answers) || !error.answers.hasOwnProperty(questionId))) {
        updatedAnswers.push(answers[questionId]);
      }
    });

    if (error && error.code != 404) {
      const { answers, ...rest } = error;
      if (!_.isEmpty(rest)) return null; // Not empty used to fix the rest={} after the first autosave (response without id)
    }

    return dispatch(actions.autoSaveResponse(
      api.saveSurveyResponse(
        token,
        language || lng,
        { ...surveyResponse, survey: survey != null ? survey.id : 0 },
        updatedAnswers,
        true
      ).then((res) => {
        dispatch(actions.resetLastUpdatedAnswers(getUpdatedAnswers(res.data.data)));
        return res.data.data;
      })
        .catch((thrown) => { throwCatchedError(thrown); })
    ));
  }

  return null;
};

export const deleteAnswer = (id, questionId) => (dispatch, getState) => {
  const { token } = getState().auth;

  return dispatch(actions.deleteAnswer(api.removeAnswer(token, id).then(() => {
    const { survey } = getState().surveys.list;
    const questions = getAllQuestionsOfSurvey(survey);
    const childQuestions = questions
      .filter(({ parent }) => parent == questionId)
      .map(({ id }) => id);

    childQuestions.forEach(id => dispatch(actions.removeAnswerByQuestionId(id)));
  })));
};

export const subscribeToStats = () => (dispatch, getState) => {
  const { token } = getState().auth;
  const { id } = getState().surveyResponse.surveyResponse;
  return dispatch(actions.subscribeToStats(api.subscribeToStats(token, id || 0)));
};

const getUpdatedAnswers = (surveyResponse) => {
  if (Array.isArray(surveyResponse.answers)) {
    return surveyResponse.answers.map(answer => answer.question.id);
  }
  return [];
};

export const fetchSurveyResponse = (instanceId, organization, client) => (dispatch, getState) => {
  const { token } = getState().auth;
  return dispatch(actions.fetchSurveyResponse(
    api.getSurveyResponse(token, instanceId, organization, client),
  ));
};

export const fetchResponses = (instanceId, organization, fields = []) => (dispatch, getState) => {
  const { token: userToken, appToken, currentClient } = getState().auth;
  const { pageSize, paginationPage, sortConfig, status } = getState().filters.response;

  const data = { pageSize, paginationPage, sortConfig, fields, status };
  const organizationId = organization ?? currentClient?.id ?? 0;
  const token = userToken != '' ? userToken : appToken.token;

  return dispatch(actions.fetchResponses(
    api.getResponses(token, instanceId, organizationId, data),
  ));
};
