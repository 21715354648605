export default {
  home: 'Tamtam Survey',
  signIn: 'Login / Sign up',
  'About us': '',
  owrApps: 'Owr Apps',
  pageNotFound: 'Oops, This Page Could Not Be Found!',
  goBackToHome: 'Go back to the Home page',
  logout: '',
  help: '',
  Communities: '',
  campaigns: 'Campaigns',
  createCampaign: 'Create campaign',
  webPage: 'Web page',
  previous: 'Previous',
  next: 'Next',
  filter: 'Filter',
  status: 'Status',
  DRAFT: '',
  SENT: '',
  CREATED: '',
  language: 'Language',
  reset: 'Reset',
  searchBySubject: 'Search by subject',
  subject: 'Subject',
  cancel: '',
  save: '',
  codeOwn: 'Code your own',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
  endedAt: 'Ended at',
  startedAt: 'Started at',
  groups: 'Groups',
  sent: 'Sending',
  enterText: 'Enter some text...',
  deleteCampaignConfirm: 'Are you sure you want to delete the campaign?',
  delete: '',
  fromEmail: 'From email',
  fromName: 'From name',
  replyTo: 'REPLY TO',
  recipientType: 'RECIPIENT TYPE',
  test: '',
  parameters: '',
  typeTestEmail: 'Type the emails address you want to send the test to',
  warning: 'warning',
  groupsIncluded: 'GROUPS INCLUDED',
  excludedGroups: 'EXCLUDED GROUPS',
  selectGroups: 'Select Groups',
  'Select event': '',
  'Select activities': '',
  'Exclude activities': '',
  'Select status': '',
  'Exclude status': '',
  excludeGroups: 'Exclude Groups',
  sendTitle: "You're about to send a campaign to",
  tested: '',
  attachments: 'Attachments',
  confirm: '',
  confirmSendCampaign: 'Are you sure you want to send this campaign?',
  noNotNow: 'NO, NOT NOW',
  yesSend: 'YES, SEND',
  sendTestTo: 'Send test to',
  sendTest: 'SEND TEST',
  send: '',
  PLAIN_TEXT: 'PLAIN TEXT',
  WEB_PAGE: 'WEB PAGE',
  selectTarget: 'Select targeted audiences',
  campaignInfo: 'Campaign info',
  invalidObject:
    'object must be at least 5 characters and no more than 200 characters',
  invalidFromName: 'from name invalid, Please enter more than 4 character',
  invalidEmail: 'invalid email address',
  emailAdd: 'Add emails and type ENTER',
  userAdd: 'Add user and type ENTER',
  deleteCampaignSuccess: 'Successful deletion',
  campaignDeleted: 'The campaign was successfully deleted',
  campaignTestSent: 'A test email has been successfully sent',
  campaignTestSuccess: 'Successful test',
  campaignSent: 'The campaign was sent successfully',
  campaignSentSuccess: 'Successful sending',
  campaignAdded: 'The campaign was saved successfully',
  addCampaignSuccess: 'Successful adding',
  successfulAction: 'Successful action',
  operationCompleted: 'action performed successfully',
  errorOccured: 'An error occured, Please retry later.',
  incompleteOperation:
    'Unable to complete operation, incorrect data was submited',
  invalidUrl: 'Unable to complete operation, invalid url was submited',
  error: '',
  enterUrl: 'Enter URL',
  search: 'Search',
  deliveredTo: 'Delivered to',
  emailsOpen: 'Emails open',
  linkClicks: 'Link clicks',
  Bounce: '',
  Spam: '',
  recipientsMessage: 'this filter takes into consideration the language :',
  pauseCampaign:
    'pausing the campaign will prevent any furthers <br> emails from getting sent until it is reactivated',
  pauseSending: 'pause sending any furthers emails',
  stopCampaign: 'stop definitely campaign sending',
  resume: '',
  register_from_email:
    'This address is not valid for sending via Amazon, Click on VALIDATE to start the registration process.',
  reply_aws_email: 'An email was sent to you by Amazon, Reply and Refresh',
  replyToError:
    'Please enter a valid email or text in the format : name <email@example.com>',
  GUEST: '',
  SPEAKER: '',
  CONTACT: '',
  PROSPECT: '',
  PARTNER: '',
  validate: '',
  refresh: '',
  templateChoices: 'Please select a template',
  stopCampaignConfirm: 'Do you really want to stop this campaign definitely?',
  stop: 'STOP',
  'first name': '',
  'last name': '',
  Event: '',
  'Included activities': '',
  'Excluded activities': '',
  'Included status': '',
  'Excluded status': '',
  Formulas: '',
  Slots: '',
  Options: '',
  All: 'ALL',
  templates: '',
  NEXT: '',
  nothing_found: 'Oops, No results found for your search',
  yes: '',
  no: '',
  french: '',
  english: '',
  dutch: '',
  overview: '',
  links: '',
  recipients: '',
  approval_preferences: 'Approval preferences for an emailing campaign',
  'Send your campaign immediately': '',
  'Schedule sending of your campaign for a specific time': '',
  'send me': '',
  'send to approvers': '',
  'the approvers': '',
  'the test is obligatory': '',
  'the minimum number of acceptance': '',
  'automatic sending': '',
  automatic_sending_paragraph:
    'allow the automatic sending of the campaign when reaching the limit number without any rejection',
  test_obligatory_paragraph:
    'the campaign will not be sent until a test is sent',
  minimum_acceptance_paragraph:
    'the minimum number of validation is required for automatic sending',
  no_approver_found: 'you have not yet defined the approvers',
  'Add an approver': '',
  'delivery date': 'date and time of sending',
  'Drag and drop some files here, or click to select files to upload.': '',
  Attachments: '',
  about_us_content:
    'is a suite of tools that enable a community to communicate, share, organise or collaborate efficiently. The platform is equipped with various applications assembled as required by the customer. Thanks to our mutuality tools, our customers have the latest technologies without having to develop them and invest in their core business.',
  preview: '',
  Edit: '',
  Preview: '',
  Duplicate: '',
  Published: '',
  Results: '',
  Delete: '',
  remove_block_message: 'Are you sure you want to delete this block?',
  save_survey_success: 'The survey was successfully saved',
  close_survey_message: 'Are you sure you want to close this survey?',
  close_survey_success: 'The survey has been successfully closed',
  delete_survey_message:
    'Are you sure you want to permanently delete this survey?',
  delete_survey_success: 'The survey was successfully deleted',
  delete_question_message:
    'Are you sure you want to permanently delete this question?',
  delete_question_success: 'The Question was successfully deleted',
  delete_dimension_message:
    'Are you sure you want to permanently delete this dimension?',
  delete_dimension_success: 'The Dimension was successfully deleted',
  delete_choice_message:
    'Are you sure you want to permanently delete this choice?',
  delete_choice_success: 'The Choice was successfully deleted',
  delete_survey_theme_message:
    'Are you sure you want to permanently delete this theme?',
  delete_survey_theme_success: 'The Theme was successfully deleted',
  survey_closed_message:
    'Thank you for your time, unfortunately this survey has been closed / Ended',
  survey_not_started_message:
    'Thank you for your time, unfortunately this survey is not available yet',
  close_survey: 'Close the survey',
  language_not_available:
    'Your language is not supported yet. Please select a language in the given list',
  Question_input: 'Type your question here...',
  Survey_title: 'Survey Title...',
  min_number_error: 'Please enter a number greater than',
  max_number_error: 'Please enter a number lower than',
  between_number_error: 'Please enter a number between',
  length_invalid_message:
    'This value is too short. It should have 2 characters or more.',
  length_3_invalid_message:
    'This value is too short. It should have 3 characters or more.',
  invalid_answer_message: 'Answer not valid',
  required_answers_message: 'Please fill out the required fields',
  answers_not_valid_message: 'please correct the highlighted fields',
  Answer_input: 'Type your answer here',
  searchByOrganizationName: 'Search by organization name',
  ResultsByOrganization: 'Results by organization',
  edit_penetration_rate: 'The penetration rate has been changed successfully',
  edit_survey_status: 'The status of survey has been changed successfully',
  Survey_Template: 'Survey Template',
  create_survey: 'Create survey',
  create_template: 'Generate template',
  create_template_from_survey: 'Integrate the library',
  duplicate_survey_success: 'The survey has been duplicated successfully',
  create_survey_from_template_success:
    'The questionnaire of this template has been successfully created',
  user_has_already_responded_title: "You've already responded",
  user_has_already_responded_message:
    'You can only fill in this form once. Try contacting the owner of the form if you think that this is a mistake.\n',
  user_not_client_of_organization_title: 'You are not a client of any fiducary',
  user_not_client_of_organization_message:
    'This survey is sent to client of organization. You are not concerned by this survey. Try contacting the owner of the form if you think that this is a mistake.\n',
  Describe_survey_tamtam_app:
    'TamtamSurvey is the eiaset way to create surveys and get answers.TamtamSurvey lets you whip up a survey quickly and get target answers from the audience you want to ask.',
  not_valid_email: 'This email address is not valid',
  popup_survey_status_message: 'Change the status of survey',
  srv_start: 'Continue',
  msg_model_not_exist: 'This type of mofele are not yet available',
  opinionSurvey: 'Opinion survey',
  satisfactionSurvey: 'Measured survey',
  customSurvey: 'Custom Survey',
  nbResponses: 'Number of responses',
  template_picker_infos:
    'Pick a template or click Start from scratch to create a brand new survey.',
  CHIEF_EDITOR: 'CHIEF EDITOR',
  AUTHOR: '',
  REDACTOR: '',
  LEGAL_REPRESENTATIVE: 'LEGAL REPRESENTATIVE',
  MANAGER: '',
  createSurvey: 'create survey',
  editableResponse: 'The answer is modifiable',
  no_survey_data_msg: 'No matching items found',
  no_survey_data_info: 'Check the filter settings',
  penetration_rate: 'Penetration rate',
  isTemplate: 'Template survey',
  isSectorial: 'Consolidated survey',
  isFixed: 'Non-personalized survey',
  obligatoryRanking: 'Compulsory ranking',
  consolidatedResults: 'Consolidated results',
  participation_msg_success: 'You are participated successfully',
  cancel_participate_msg_success:
    'The participation has been canceled successfully',
  cancel_participate_msg: 'Do you really want to cancel your participation?',
  participate_msg: 'Are you sure you want to participate in this survey?',
  participate_opinion_msg:
    'Do you agree to share the results of your organization?',
  participate_satisfaction_msg:
    'Do you accept the ranking of your organization?',
  cancel_participate: 'Cancel participation',
  create_template_modal_msg: 'Choose an operation',
  transform_to_sectorial_template_success:
    'The template was successfully created',
  duplicate_into_library: 'Duplicate in the library',
  stats_question_no_data_found_msg:
    "If you're thirsty for some answers, make this a required question in the question settings",
  stats_survey_no_data_found_msg: 'This survey doesn’t have any responses yet.',
  SURVEY_INVITATION: 'Invitation template',
  SURVEY_REMINDER: 'Reminder template',
  SURVEY_PARTIAL_REMINDER: 'Partial reminder template',
  SURVEY_THANK: 'Thank template',
  SURVEY_RESULTS: 'Results Template',
  subscribe_to_stats_msg:
    'Would you like to receive statistics as soon as they become available ?',
  Revival: 'Stimulate',
  revival: 'stimulate',
  PartialRevival: 'Partial stimulate',
  STATUS_RESULTS_NOT_ASKED: 'Not asked',
  STATUS_RESULTS_ASKED: 'Asked',
  STATUS_RESULTS_RECEIVED: 'Received',
  from_email_not_registred: 'This address is not valid for sending via Amazon',
  cannot_delete_answered_question:
    'You can not delete questions that have been answered.',
  EXTRA_CROSS: 'Extra Cross',
  INTRA_CROSS: 'Intra Cross',
  EXTRA_SHARE: 'Extra Share',
  INTRA_SHARE: 'Intra Share',
  members_only_message: 'This survey is available to members only',
  anonymous_message:
    'You are going to participate in an investigation whose anonymity is absolutely guaranteed. The purpose of the email is to guarantee uniqueness of response (1 response per participant). It is never possible to identify who answered what.',
  anonymous_thanks_message: 'Thank you for your participation!',
  profiling_anonymous_message:
    'This survey is anonymous. The declared profiling is used only for statistical purposes',
  INVITATION_CAMPAIGN: 'INVITATION',
  REVIVAL_CAMPAIGN: 'REMINDER',
  PARTIAL_REVIVAL_CAMPAIGN: 'PARTIAL REMINDER',
  SURVEY_RESULTS_CAMPAIGN: 'RESULT',
  PARTICIPATIONS_CAMPAIGN: 'PARTICIPATION',
};
