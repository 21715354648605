import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TTP_API_URL } from 'Config';
import _ from 'i18n';
import { getTextByLanguage, getMediaByLanguage } from 'surveyUtils';

class LibraryItem extends Component {
  constructor(props) {
    super(props);
    this.background = 'url("/img/pictos/analysis.svg") center no-repeat';
  }

  render() {
    const { survey, lng } = this.props;
    const { title, welcomeScreen } = survey;
    const imageBackground = getMediaByLanguage(welcomeScreen, lng);
    const background = (imageBackground && `url('${`${TTP_API_URL}/${imageBackground}`}') center center / cover`) || this.background;
    const style = {
      background,
      backgroundRepeat: 'no-repeat',
    };

    return (
      <div className="column column-block">
        <div className="srv-card srv-card--disabled">
          <div className="status-wrapper">
            {
              survey.isSectorial
              && <div><span className="status greetings">{_('Consolidated survey')}</span></div>
            }
          </div>
          <div className="srv-card__container" style={style}>
            <div className="srv-card__title">
              <h5 className="srv__font-bold">{getTextByLanguage(title, lng)}</h5>
            </div>
          </div>
          <div className="srv-card__info flex-container flex-dir-row-reverse">
            {this.props.renderActions(survey)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lng: state.params.lng,
});

export default connect(mapStateToProps)(LibraryItem);
