import React, { Component } from 'react';
import { TAMTAM_API_LANGUAGES } from 'Common';
import _ from 'i18n';

export default class DimensionPreview extends Component {
  render() {
    const { dimension: { title }, languages, isBilingual } = this.props;
    if (isBilingual) {
      return (
        <div className="row small-12 fh">
          {
            languages.map(lng => (
              <div className="column small-6">
                <div className="quiz-preview__dimension bilingual">
                  <label>
                    <div className="lng">
                      <img alt="" src={`/img/flags/${lng}.svg`} />
                      <span>{_(TAMTAM_API_LANGUAGES[lng])}</span>
                    </div>
                  </label>
                  <div className="text">{title ? title[lng] : ''}</div>
                </div>
              </div>
            ))
          }
        </div>
      );
    }

    return (
      <div className="quiz-preview__dimension row">
        <div className="small-12 flex-column-center">
          <div className="text no-margin--top">{title ? title[languages[0]] : ''}</div>
        </div>
      </div>
    );
  }
}
