import React from 'react';

export default function TTRoundedButton({ icon, color = 'blue', type, onClick, text, disabled, upperCase, fontSize }) {
  return (
    <button type="button" className={`tt-button rounded ${color} ${type} ${disabled ? 'disabled' : ''}`} onClick={disabled ? null : onClick}>
      {icon && <span className="tt-button__icon"><i className={icon} /></span>}
      <span style={fontSize ? { fontSize } : null} className={`tt-button__text ${upperCase ? 'text-uppercase' : ''}`}>{text}</span>
    </button>
  );
}
