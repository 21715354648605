import React, { Component } from 'react';
import Select, { components } from 'react-select';

export default class TTPSelect extends Component {
  onInputChange = inputValue => {
    const { onChange, simple } = this.props;
    let selected = null;
    if (simple) {
      selected = inputValue != null ? String(inputValue.value) : null;
    } else {
      selected = inputValue.map(({ value }) => value);
    }

    onChange(selected);
  };

  render() {
    const {
      placeholder,
      isDisabled,
      disabled,
      options,
      values,
      simple,
      notClearable,
      customOption,
      cssClass,
    } = this.props;

    const items = options.map(({ id, name, label, ...details }) => ({
      label: label || name,
      value: String(id),
      isDisabled: disabled && disabled.includes(String(id)),
      ...details,
    }));

    const value = items.filter(
      ({ value }) =>
        values &&
        (Array.isArray(values) ? values.includes(value) : value == values),
    );
    return (
      <Select
        isMulti={!simple}
        isClearable={!notClearable}
        isSearchable
        isDisabled={isDisabled}
        value={value}
        placeholder={placeholder || ''}
        onChange={this.onInputChange}
        options={items}
        components={{ Option: customOption || DefaultOption }}
        className={`ttp-select ${cssClass} ${(!value || !value.length) &&
          'empty-box'}`}
        classNamePrefix="ttp-select"
      />
    );
  }
}

const DefaultOption = props => {
  const { data } = props;

  if (data.content) {
    return (
      <components.Option {...props}>
        <div className="select-option">
          <span className="select-option__label">{data.label}</span>
          <span className="select-option__content">{data.content}</span>
        </div>
      </components.Option>
    );
  } else if (data.noContent) {
    return (
      <components.Option {...props}>
        <span>{data.label}</span>
      </components.Option>
    );
  }

  return <components.Option {...props} />;
};
