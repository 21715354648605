import React, { Component } from 'react';
import shortId from 'shortid';

export default class Number extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNumberInput: this.props.value != null,
      number: this.props.value,
    };
  }

  onNumberChange(value) {
    const { attribute } = this.props;
    if (value == '') {
      value = null;
    }

    this.setState(prevState => ({
      ...prevState,
      number: value,
    }));
    this.props.addOption(attribute, value);
  }

  showNumberInput(checked) {
    const { attribute } = this.props;

    this.setState(prevState => ({
      ...prevState,
      showNumberInput: checked,
    }), () => {
      let value = this.inputNumber ? this.inputNumber.value : '';
      if (value == '' || !checked) {
        value = null;
        this.props.addOption(attribute, value);
      }
    });
  }

  render() {
    const id = shortId.generate();

    return (
      <div className="settings__block">
        <div className="settings__container">
          <div className="settings__container__label">{this.props.label}</div>
          <div className="settings__container__item">
            <div className="switch tiny settings__switch">
              <input
                className="switch-input"
                id={id}
                type="checkbox"
                name={id}
                onChange={e => this.showNumberInput(e.target.checked)}
                checked={this.state.showNumberInput}
              />
              <label className="switch-paddle" htmlFor={id}>
                <span className="show-for-sr">{this.props.msgSR}</span>
              </label>
            </div>
          </div>
        </div>
        {
          this.state.showNumberInput

          && (
          <div className="settings__container">
            <div className="input-group settings__input">
              <span className="input-group-label">#</span>
              <input
                className="input-group-field"
                type="number"
                placeholder="0-999999999"
                defaultValue={this.state.number}
                onChange={e => this.onNumberChange(e.target.value)}
                ref={(ref) => { this.inputNumber = ref; }}
              />
            </div>
          </div>
          )
        }
      </div>
    );
  }
}
