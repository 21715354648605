import * as actions from 'actions';
import { getSurveyQuestions } from 'TamtamAPI';

export const fetchSurveyQuestions = isProfile => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  const organizationId = currentClient?.id ?? 0;

  if (isProfile) {
    return dispatch(actions.fetchProfileSurveyQuestions(
      getSurveyQuestions(token, isProfile),
    ));
  }

  return dispatch(actions.fetchLibrarySurveyQuestions(
    getSurveyQuestions(token, isProfile, organizationId),
  ));
};