import _ from 'i18n';
import __ from 'lodash';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { pushSourceToken as pushSourceTokenAction } from 'actions';
import { LANGUAGES } from 'Common';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

export const htmlDecode = strData => {
  if (strData == null) {
    return '';
  }

  return strData.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));
};

export const unescapeHtml = safe =>
  safe
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'");

export const purifyString = string => htmlDecode(unescapeHtml(string));

export const generateHTMLFile = (data, filename) => {
  const blob = new Blob([data], { type: 'text/plain' });

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename);
    return;
  }

  const csvURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', filename);
  tempLink.setAttribute('target', '_blank');
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

export const getDateLabel = date => {
  const d = new Date(date);

  const result = d.toDateString().split(' ');

  const hours = parseInt(d.getHours()) < 10 ? `0${d.getHours()}` : d.getHours();
  const minutes =
    parseInt(d.getMinutes()) < 10 ? `0${d.getMinutes()}` : d.getMinutes();

  return `${result[2]} ${result[1]} ${result[3]}, at ${hours}:${minutes}`;
};

export const getDateInMSecond = stringDate => {
  const parts = stringDate.split(' ');
  const datePart = parts[0].split('-');
  const timePart = parts[1].split(':');

  const d = new Date(
    datePart[0],
    datePart[1] - 1,
    datePart[2],
    timePart[0],
    timePart[1],
    timePart[2],
  );

  return d.getTime();
};

export const getUserNameForAvatar = (firstName = '', lastName = '') => {
  const fName = firstName.split(' ');

  if (fName.length >= 3) {
    return extractFirstLetter(fName, 3);
  }

  const lName = lastName.split(' ');
  return extractFirstLetter(fName.concat(lName), 3);
};

function extractFirstLetter(arrayStr) {
  let result = '';

  for (let i = 0; i < arrayStr.length; i++) {
    if (arrayStr[i] !== undefined) {
      result += arrayStr[i].substring(0, 1);
    }
  }

  return result.toUpperCase();
}

export function extractResourcesId(resources) {
  const ids = [];

  for (const resource of resources) {
    ids.push(resource.id);
  }

  return ids;
}

export function onSuccess(res, message = null) {
  if (!message) {
    message = { body: 'operationCompleted', title: 'successfulAction' };
  }
  if (res.value instanceof Error) {
    NotificationManager.error(_('incompleteOperation'), _('error'));
  } else {
    NotificationManager.success(_(message.body), _(message.title));
  }
}

export function onError() {
  NotificationManager.error(_('errorOccured'), _('error'));
}

export const EMAIL_VALIDATION_REGEX = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

export const URL_REGEX = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})$/;

export function convertReplyTo(tags) {
  const replyTo = [];
  const mailPattern = new RegExp(EMAIL_VALIDATION_REGEX);

  for (let i = 0; i < tags.length; i++) {
    const matches = tags[i].match(/^(.*)<\s*(.*)\s*>$/i);
    if (matches && matches.length === 3 && mailPattern.test(matches[2])) {
      replyTo.push({ name: matches[1].trim(), email: matches[2].trim() });
    } else if (mailPattern.test(tags[i])) {
      replyTo.push({ email: tags[i].trim() });
    }
  }

  return JSON.stringify(replyTo);
}

export function convertToStringTag(replyTo = []) {
  const tags = [];

  for (let i = 0; i < replyTo.length; i++) {
    let tag = replyTo[i].email;
    if (replyTo[i].name) {
      tag = `${replyTo[i].name} <${replyTo[i].email}>`;
    }
    tags.push(tag);
  }

  return tags;
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
}

export function setCookie(name, value, expires, path, domain, secure) {
  document.cookie = `${name} = ${escape(value)}  ${
    expires === undefined ? '' : `; expires = ${expires.toGMTString()}`
  }${path === undefined ? '' : `; path = ${path}`}${
    domain === undefined ? '' : `; domain = ${domain}`
  }${secure === true ? '; secure' : ''}`;
}

export const modalStyle = {
  overlay: {
    zIndex: 3,
    backgroundColor: 'rgba(62, 60, 60, 0.8)',
  },
  content: {
    top: '50%',
    left: '50%',
    width: '60%',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    transition: 'opacity 0.4s ease-out 0s',
    border: 'none',
    borderRadius: 0,
    paddingBottom: 0,
    overflow: 'hidden',
  },
};

export function getSourceToken() {
  const { CancelToken } = axios;
  return CancelToken.source();
}

export function updateSourceToken(oldSourceToken, newSourceToken) {
  if (oldSourceToken) {
    oldSourceToken.cancel('Operation canceled by the user.');
  }

  return newSourceToken;
}

export function throwCatchedError(thrown) {
  if (axios.isCancel(thrown)) {
    throw { response: { status: 700 } };
  }
  throw thrown;
}

export function pushSourceToken(sourceName, dispatch) {
  const sourceToken = getSourceToken();

  dispatch(pushSourceTokenAction(sourceName, sourceToken));

  return sourceToken;
}
export function getUrlParams() {
  const search = location.search.substring(1);
  if (!search) {
    return null;
  }
  return JSON.parse(
    `{"${decodeURI(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')}"}`,
  );
}

export function getRate(n, total) {
  const rate = total == 0 ? 0 : (n * 100) / total;
  return !rate ? 0 : Math.round(rate).toFixed(2);
}

export function getLanguages() {
  return [
    { key: 'en', value: 'english' },
    { key: 'fr', value: 'french' },
    { key: 'nl', value: 'dutch' },
  ];
}

export function getParents(el, parentSelector) {
  const parents = [];
  let p = el.parentNode;

  while (p !== parentSelector) {
    const o = p;
    parents.push(o);
    p = o.parentNode;
  }
  parents.push(parentSelector);

  return parents;
}

export const sortLanguages = array =>
  array
    .filter(lng => !isEmpty(lng))
    .sort((a, b) => LANGUAGES.indexOf(a) - LANGUAGES.indexOf(b));

/**
 * TODO use moment.local example in Notifs.js
 */
export const getMomentDiff = (date1, date2) => {
  const maxDate = date1 > date2 ? date1 : date2;
  const minDate = date1 < date2 ? date1 : date2;
  const diff = maxDate.from(minDate, true);
  let property = '';
  let value = 0;

  const diffDate = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  switch (diff) {
    case 'a year':
      diffDate.years = 1;
      value = 1;
      property = 'years';
      break;
    case 'a month':
      diffDate.months = 1;
      value = 1;
      property = 'months';
      break;
    case 'a day':
      diffDate.days = 1;
      value = 1;
      property = 'days';
      break;
    case 'an hour':
      diffDate.hours = 1;
      value = 1;
      property = 'hours';
      break;
    case 'a minute':
      diffDate.minutes = 1;
      value = 1;
      property = 'minutes';
      break;
    case 'in seconds':
    case 'a few seconds':
      diffDate.seconds = 1; // random :/
      value = 1;
      property = 'seconds';
      break;
    default:
      [value, property] = diff.split(' ');
      diffDate[property] = value = parseInt(value); // eslint-disable-line no-multi-assign
      break;
  }

  const { years, months, days, hours, minutes, seconds } = diffDate;

  return {
    toArray: [years, months, days, hours, minutes, seconds],
    diff,
    mainProperty: property,
    value,
  };
};

export const reactSelectCustomStyles = (height = 40) => ({
  control: provided => ({
    ...provided,
    borderRadius: '0',
    backgroundColor: '#fff',
    height,
    minHeight: height,
    overflow: 'hidden',
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: '1rem',
    color: '#bbb',
    fontWeight: 300,
  }),
  menuList: base => ({
    ...base,
    paddingTop: '0',
    paddingBottom: '0',
  }),
  menu: base => ({
    ...base,
    borderRadius: '0',
  }),
  option: base => ({
    ...base,
    textAlign: 'left',
  }),
  dropdownIndicator: base => ({
    ...base,
    transition: 'all .2s ease',
  }),
  input: base => ({
    ...base,
    height,
    minHeight: height,
    overflowY: 'auto',
    margin: 0,
  }),
  container: base => ({
    ...base,
    height,
    minHeight: height,
  }),
  valueContainer: base => ({
    ...base,
    height,
    minHeight: height,
    overflowY: 'auto',
    margin: 0,
  }),
  group: base => ({
    ...base,
    borderBottom: '2px solid #d9d9d9',
  }),
  groupHeading: base => ({
    ...base,
    backgroundColor: '#e6f7ff',
    color: '#000',
    textAlign: 'center',
    padding: '15px 0',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '0.75rem',
  }),
  // multiValue: (base, { data }) => {
  //   if (data.color) {
  //     const color = chroma(data.color); // TODO
  //     return {
  //       ...base,
  //       backgroundColor: color.alpha(0.1).css(),
  //     };
  //   }

  //   return base;
  // },
  multiValueLabel: (base, { data }) => ({
    ...base,
    color: data.color,
  }),
  multiValueRemove: (base, { data }) => ({
    ...base,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
});

/**
 * @param {array} moments of {startedAt: moment, endedAt: moment}
 */
export const mergeMoments = moments =>
  moments?.reduce((dates, date) => {
    if (dates.length == 0) return [date];
    const lastDate = dates.pop();
    if (
      lastDate?.endedAt?.isSameOrAfter(date.startedAt) ||
      date.startedAt.diff(lastDate.endedAt, 'days', true) <= 1
    ) {
      return [
        ...dates,
        {
          startedAt: lastDate.startedAt,
          endedAt: moment.max(lastDate.endedAt, date.endedAt),
        },
      ];
    }

    return [...dates, lastDate, date];
  }, []);

/**
 * @param {array} moments of {startedAt: moment, endedAt: moment}
 * @param date => moment object
 */
export const momentsContains = (moments, date) => {
  for (const { startedAt, endedAt } of moments) {
    if (date.isBetween(startedAt, endedAt, null, '[]')) return true;
  }
  return false;
};

/** make sure that {blockedMoments} are already merged by mergeMoments(m) */
export const getFreeDayAfter = (blockedMoments, date) => {
  if (
    !Array.isArray(blockedMoments) ||
    !momentsContains(blockedMoments, date)
  ) {
    return date;
  }

  for (const { endedAt } of blockedMoments) {
    const freeMoment = endedAt.startOf('day').add(1, 'days');
    if (freeMoment.isAfter(date)) {
      return freeMoment;
    }
  }

  /** never reached code ^^ */
  const lastBlockedMoment = blockedMoments[blockedMoments.length - 1];
  return lastBlockedMoment.endedAt.startOf('day').add(1, 'days');
};

/** Used in the builder to prevent the default behaviour of firefox: pressing the backspace return back to the previous page */
export const preventBackspaceBehaviour = event => {
  if (event.keyCode === 8) {
    let doPrevent = true;
    const types = [
      'text',
      'password',
      'file',
      'search',
      'email',
      'number',
      'date',
      'color',
      'datetime',
      'datetime-local',
      'month',
      'range',
      'search',
      'tel',
      'time',
      'url',
      'week',
    ];
    const d = $(event.srcElement || event.target);
    const disabled = d.prop('readonly') || d.prop('disabled');
    if (!disabled) {
      if (d[0].isContentEditable) {
        doPrevent = false;
      } else if (d.is('input')) {
        let type = d.attr('type');
        if (type) {
          type = type.toLowerCase();
        }
        if (types.indexOf(type) > -1) {
          doPrevent = false;
        }
      } else if (d.is('textarea')) {
        doPrevent = false;
      }
    }
    if (doPrevent) {
      event.preventDefault();
    }
  }
};

export const hideEmail = email => {
  if (typeof email !== 'string') {
    return email;
  }

  const [firstPart, secondPart] = email.split('@');
  let hiddenPart;

  if (firstPart.length < 4) {
    hiddenPart = firstPart.charAt(0) + __.repeat('*', firstPart.length - 1);
  } else if (firstPart.length < 6) {
    hiddenPart = firstPart.slice(0, 2) + __.repeat('*', firstPart.length - 2);
  } else {
    hiddenPart = firstPart.slice(0, 3) + __.repeat('*', firstPart.length - 3);
  }

  return `${hiddenPart}@${secondPart}`;
};

/**
 * Doesn't support nested objects
 */
export const getFormData = (object) => Object.keys(object).reduce((formData, key) => {
  formData.append(key, object[key]);
  return formData;
}, new FormData());