import React from 'react';

const InputStatsList = ({ data, renderItem }) =>
  data.map((elem, key) => (
    <div key={key} className="stats-list-item">
      <div className="text">
        {typeof renderItem === 'function' ? renderItem(elem) : elem.value}
      </div>
    </div>
  ));

export default InputStatsList;
