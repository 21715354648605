import React, { Component } from 'react';

export default class Switch extends Component {
  handleChange = ({ target: { checked }}) => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(checked);
    }
  }

  render() {
    const { name, isChecked } = this.props;

    return (
      <div className="switch-container">
        <label>
          <input
            name={name}
            checked={isChecked}
            onChange={this.handleChange}
            className="ttp-switch"
            type="checkbox"
          />
          <div>
            <span><g className="icon icon-toolbar grid-view" /></span>
            <span><g className="icon icon-toolbar ticket-view" /></span>
            <div />
          </div>
        </label>
      </div>
    );
  }
}
