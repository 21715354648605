import React, { Component } from 'react';
import { fetchResponses } from 'thunks';
import {
  setResponsesPaginationPage,
  setResponsesPageSize,
  initializeResponsesFilter,
  setResponsesSortConfig,
} from 'actions';
import _ from 'i18n';
import { LIST_PAGE_SIZES } from 'Common';
import NotFoundPage from 'NotFoundPage';
import controlAccess from './controlAccess';
import UserResponsesFetching from './user-responses/UserResponsesFetching';
import TTPPaginator from '../common/TTPPaginator';

const FIELDS = [
  'id',
  'user',
  'status',
  'language',
  'score',
  'createdAt',
  'isAnonymous',
];

class UserResponsesView extends Component {
  componentDidMount() {
    const {
      surveySlug,
      survey: { slug, latestInstance },
      organization,
    } = this.props;
    if (slug && surveySlug == slug) {
      this.props.initializeResponsesFilter();
      this.props.fetchResponses(latestInstance?.id ?? 0, organization, FIELDS);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      survey: { slug, latestInstance },
      filter,
      organization,
    } = this.props;
    const prevSurvey = prevProps.survey;

    if (
      prevProps.filter !== filter ||
      (slug != prevSurvey.slug && slug != null)
    ) {
      this.props.fetchResponses(latestInstance?.id ?? 0, organization, FIELDS);
    }
  }

  handleListPageClick = data => {
    const selectedPage = data.selected;
    this.renderRankingPage(selectedPage + 1);
  };

  handleSelectListPageSize = event => {
    const pageSize = event.target.value;
    this.renderRankingPage(1, pageSize);
  };

  renderRankingPage(paginationPage, pageSize = null) {
    this.props.setResponsesPaginationPage(paginationPage);
    if (pageSize) {
      this.props.setResponsesPageSize(pageSize);
    }
  }

  onSortChange(columnKey) {
    const { sortConfig } = this.props.filter;
    let sortDir = 'asc';
    if (sortConfig.columnKey === columnKey) {
      sortDir = this.reverseSortDirection(sortConfig.sortDirection);
    }
    this.props.setResponsesSortConfig(columnKey, sortDir);
  }

  reverseSortDirection(sortDir) {
    return sortDir === 'desc' ? 'asc' : 'desc';
  }

  renderSortDirection(columnKey) {
    const { filter } = this.props;
    if (columnKey === filter.sortConfig.columnKey) {
      const isDesc = filter.sortConfig.sortDirection === 'desc';
      const className = `icomoon-icon ${
        isDesc ? 'icon-sort-numberic-desc' : 'icon-sort-numeric-asc'
      }`;

      return (
        <span
          className="ranking-list__sort__icon"
          onClick={() => this.onSortChange(columnKey)}
        >
          <i className={className} />
        </span>
      );
    }
    return (
      <span
        className="ranking-list__sort__icon"
        onClick={() => this.onSortChange(columnKey)}
      >
        <i className="icomoon-icon icon-tab-sort" />
      </span>
    );
  }

  renderItemsList() {
    const { paginationPage, pageSize, sortConfig } = this.props.filter;
    const { items, nbResult } = this.props.surveyResponse;
    const { isAnonymous: isSurveyAnonymous } = this.props.survey;

    return items.map((item, index) => {
      let rank = 0;
      if (sortConfig.sortDirection === 'desc') {
        rank = (paginationPage - 1) * pageSize + index + 1;
      } else {
        rank = nbResult - (paginationPage - 1) * pageSize - index;
      }

      const userName = `${item?.user?.firstName ?? ''} ${item?.user?.lastName ?? ''}`.trim();

      return (
        <tr key={item.id}>
          <td className="cell-rank text-center">{rank}</td>
          <td className="text-center">
            {item.isAnonymous || isSurveyAnonymous
              ? `-- ${_('Hidden name')} --`
              : userName != '' ? userName : _('Anonymous User')}
          </td>
          <td className="text-center">
            <span className="language">
              <img alt="" src={`/img/flags/${item.language}.svg`} />
            </span>
          </td>
          <td className="text-center">
            {item.score ? `${item.score} %` : '--'}
          </td>
        </tr>
      );
    });
  }

  renderResponses() {
    const { surveyResponse, fetchingSurvey } = this.props;

    if (surveyResponse.fetching || fetchingSurvey) {
      return <UserResponsesFetching />;
    }

    if (surveyResponse.error != null && surveyResponse.error.code == 404) {
      return <NotFoundPage mainClass="small-10" />;
    }

    return (
      <table className="unstriped hover">
        <thead>
          <tr>
            <th width="150" className="text-center">
              <label>{_('Rank')}</label>
            </th>
            <th className="text-center">
              <label>{_('Client')}</label>
            </th>
            <th className="text-center">
              <label>{_('language')}</label>
            </th>
            <th className="text-center">
              <label>{_('Score')}</label>
              {this.renderSortDirection('score')}
            </th>
          </tr>
        </thead>
        <tbody>{this.renderItemsList()}</tbody>
      </table>
    );
  }

  renderPaginate() {
    const { surveyResponse, filter } = this.props;
    if (surveyResponse.error != null && surveyResponse.error.code == 404) {
      return '';
    }

    return (
      <TTPPaginator
        onPageChange={this.handleListPageClick}
        pageSize={filter.pageSize}
        nbResult={surveyResponse.nbResult}
        paginationPage={filter.paginationPage}
      />
    );
  }

  render() {
    const { filter, hide, disabled, fetchingSurvey } = this.props;

    if ((hide || disabled) && !fetchingSurvey) {
      return <NotFoundPage />;
    }

    return (
      <div className="row wrapper">
        <div className="ranking-list">
          <div className="row">
            <div className="medium-1 columns">
              <select
                className="filter-select"
                value={filter.pageSize}
                onChange={this.handleSelectListPageSize}
              >
                {LIST_PAGE_SIZES.map(pagesize => (
                  <option key={`page-size-${pagesize}`} value={pagesize}>
                    {pagesize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="medium-12 columns">{this.renderResponses()}</div>
          </div>
          {this.renderPaginate()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  surveyResponse: state.surveyResponse,
  fetchingSurvey: state.surveys.list.fetching,
  filter: state.filters.response,
});

const mapDispatchToProps = {
  fetchResponses,
  setResponsesPaginationPage,
  setResponsesPageSize,
  initializeResponsesFilter,
  setResponsesSortConfig,
};

export default controlAccess(
  mapStateToProps,
  mapDispatchToProps,
)(UserResponsesView);
