import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'i18n';
import __ from 'lodash';
import { changeQuizLanguage } from 'actions';
import { stateToHTML } from 'draft-js-export-html';
import ReactHtmlParser from 'react-html-parser';
import TTRoundedButton from '../common/TTRoundedButton';

class QuizIntroduction extends Component {
  constructor(props) {
    super(props);
    this.handleEnterButton = this.handleEnterButton.bind(this);
  }

  componentDidMount() {
    const element = document.querySelector('.survey-builder');
    if (element == null) {
      document.addEventListener('keydown', this.handleEnterButton, false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEnterButton, false);
  }

  addEnterListener = () => {
    document.addEventListener('keydown', this.handleEnterButton, false);
  }

  removeEnterListener = () => {
    document.removeEventListener('keydown', this.handleEnterButton, false);
  }

  startSurvey = () => {
    this.props.startSurvey();
  }

  handleEnterButton(e) {
    if (e.key == 'Enter' && !this.props.disabled) {
      this.startSurvey();
    }
  }

  renderLogo() {
    return (
      <div className="preview__container__title--image">
        <img style={{ width: '100%' }} alt="" src="/img/pictos/logo.svg" className="img-meduim" />
      </div>
    );
  }

  render() {
    const { language, quiz: { title, description }, disabled, languages } = this.props;
    const showDescription = !__.isEmpty(description) && !__.isEmpty(description[language]) && false; // remove false to display description

    return (
      <div className="flex-column-center quiz-introduction" onMouseEnter={this.addEnterListener} onMouseLeave={this.removeEnterListener}>
        <div className="preview__container__title">
          {this.renderLogo()}
          {
            languages.map(lng => (
              <h1 key={lng} className="preview__container__title--text survey-font__title survey-font--center">
                {title ? title[lng] : ''}
              </h1>
            ))
          }
        </div>
        <span className="tt-button-group m-t-m">
          <TTRoundedButton
            text={_('Start')}
            type="full"
            upperCase={true}
            onClick={this.startSurvey}
            fontSize="20px"
            disabled={disabled}
          />
          <span className="tt-button-help">
            {_('press')}
            {' '}
            <strong>[ENTER]</strong>
          </span>
        </span>
        {
          showDescription
          && (
            <div className="survey-description-wrapper m-t-l">
              <div className="survey-desc">
                {ReactHtmlParser(stateToHTML(description[language].getCurrentContent()))}
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.quiz.language,
});

export default connect(mapStateToProps, { changeQuizLanguage })(QuizIntroduction);
