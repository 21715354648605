import React, { Component } from 'react';
import { addOptions } from 'actions';
import shortId from 'shortid';
import { connect } from 'react-redux';
import __ from 'lodash';
import { bindActionCreators } from 'redux';
import _ from 'i18n';
import { selectCurrentQuestion } from 'reducers';
import { getOptions } from '../../config/SurveyConfig';
import Boolean from './settings-items/Boolean';
import Steps from './settings-items/Steps';
import Number from './settings-items/Number';
import Selection from './settings-items/Selection';
import Select from './settings-items/Select';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.addOption = this.addOption.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.question == null || this.props.question == null) return true;

    const { uid, id, required, isProfile } = this.props.question;
    return (
      id != nextProps.question.id
      || (uid != nextProps.question.uid && uid)
      || required != nextProps.question.required
      || isProfile != nextProps.question.isProfile
    );
  }

  addOption = (attributeName, value) => {
    const { addOptions } = this.props;

    const options = {
      [attributeName]: value
    };

    addOptions(options);
  };

  addRequiredOption = (attributeName, value) => {
    if (value == 0) {
      this.addOption('isProfile', 0);
    }
    this.addOption(attributeName, value);
  };

  renderQuestionOptions(options, question) {
    if (!__.isEmpty(options)) {
      const addOption = __.debounce(this.addOption, 200);
      const { steps, type } = this.props.survey;

      return __.map(options, (value, key) => {
        switch (key) {
          case 'required':
            return <Boolean attribute={key} label={_('Required')} value={question.required == '1'} key={shortId.generate()} addOption={this.addRequiredOption} />;
            // TODO not implement yet
            // case 'verticalAlignment':
            // return <Boolean attribute={key} label={_('vertical Alignment')} value={question.verticalAlignment == '1'} key={shortId.generate()} addOption={this.addOption} />;
          case 'timeLimit': {
            if (type !== 'QUIZ') {
              return null;
            }
            const steps = [5, 10, 20, 30, 60, 120];
            return <Steps attribute={key} label={_('time')} value={question.timeLimit} values={steps} key={shortId.generate()} addOption={this.addOption} />;
          }
          case 'minNumber': {
            if (question.type === 'OPINION_SCALE') {
              return null;
            }
            return <Number attribute={key} label={_('Min Number')} value={question.minNumber} key={shortId.generate()} addOption={addOption} />;
          }
          case 'maxNumber': {
            if (question.type === 'NUMBER') {
              return <Number attribute={key} label={_('Max Number')} value={question.maxNumber} key={shortId.generate()} addOption={addOption} />;
            }
            if (question.type === 'OPINION_SCALE') {
              const disabled = type == 'SATISFACTION';
              const value = disabled ? steps : question.steps || question.maxNumber;
              return <Steps attribute={key} label={_('Steps')} value={value} key={shortId.generate()} addOption={this.addOption} disabled={disabled} />;
            }
            break;
          }
          case 'shape':
            return <Selection attribute={key} options={value} label={_('Shape')} value={question.shape} key={shortId.generate()} addOption={this.addOption} />;
          case 'steps': {
            const disabled = type == 'SATISFACTION';
            const value = disabled ? steps : question.steps || question.maxNumber;
            return <Steps attribute={key} label={_('Steps')} value={value} key={shortId.generate()} addOption={this.addOption} disabled={disabled} />;
          }
          case 'isNps':
            return <Boolean attribute={key} label={_('NPS')} value={question.isNps == '1'} key={shortId.generate()} addOption={this.addOption} />;
          case 'hasOther':
            if (type == 'QUIZ') return null;
            return <Boolean attribute={key} label={_('Add "Other" option')} value={question.hasOther == '1'} key={shortId.generate()} addOption={this.addOption} />;
          // case 'isOtherOverwritten': {
          //   if (question.hasOther != '1') return null;
          //   return <Boolean attribute={key} label={_('Overwrite "Other" option')} value={question.isOtherOverwritten == '1'} key={shortId.generate()} addOption={this.addOption} />;
          // }
          // case 'isProfile':
          //   return <Boolean attribute={key} label={_('Profiling')} disabled={question.required == 0} value={question.isProfile == '1'} key={shortId.generate()} addOption={this.addOption} />;
          case 'type': {
            const TYPES = ['SINGLE_CHOICE', 'MULTIPLE_CHOICE'];
            if (TYPES.indexOf(question.type) === -1) return null;

            const options = TYPES.map(value => ({
              value,
              label: value == 'MULTIPLE_CHOICE' ? _('Multiple selection') : _('Single Selection')
            }));

            return <Select label={_('Type')} attribute={key} value={question.type} key={key} options={options} addOption={this.addOption} />;
          }
          default: return null;
        }
        return null;
      });
    }
    return null;
  }

  renderSettings() {
    const { question } = this.props;

    if (question == null || !__.isEmpty(question.profilingType)) {
      return null;
    }
    
    const { options } = getOptions(question.type);
    return this.renderQuestionOptions(options, question);
  }

  render() {
    return (
      <div className="s-toolbar">
        <div className="s-toolbar__title separator">
          <div className="s-toolbar__title__label survey-font survey-font--upperCase">{ _('Settings')}</div>
          <div className="s-toolbar__title__icon" onClick={this.props.closeSlider}>
            <span><i className="icomoon-icon icon-cross" /></span>
          </div>
        </div>
        <div className="s-toolbar__settings settings">
          {this.renderSettings()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  question: selectCurrentQuestion(state.surveys.list),
  loggedAs: state.auth.loggedAs,
  survey: state.surveys.list.survey,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addOptions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
