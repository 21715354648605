import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  removeChoice,
  setCurrentChoice,
  addChoice,
  updateChoice,
  addValidationErrors,
  removeValidationErrors,
  setCorrectChoice,
  setSettingsCurrentTab,
} from 'actions';
import { CHOICE, SURVEY_BUILDER_TABS } from 'constants';
import { deleteChoice } from 'thunks';
import { NotificationManager } from 'react-notifications';
import _ from 'i18n';
import __ from 'lodash';
import { TOOLBAR_ITEMS } from 'SurveyConfig';
import { generateChoice, getTextByLanguage } from 'surveyUtils';
import ChoiceText from './ChoiceText';
import Modal from '../../survey/Modal';
import CheckBox from '../../common/CheckBox';
import ChoiceAgeSetter from './ChoiceAgeSetter';

export class QuestionChoice extends Component {
  constructor(props) {
    super(props);

    this.deleteChoice = this.deleteChoice.bind(this);
    this.onTextChange = __.debounce(this.onTextChange, 150);

    this.state = { showDeletePopup: false, showSettings: false };
    this.hideDeletePopup = () => this.setState({ showDeletePopup: false });
  }

  onSuccess(res) {
    if (res.value instanceof Error) {
      this.onFailure();
    } else {
      NotificationManager.success(_('delete_choice_success'), _('Delete'));
    }
  }

  onFailure() {
    NotificationManager.error(_('errorOccured'), _('error'));
  }

  onKeyDown = e => {
    const { addChoice, hasRank, disableNewChoice } = this.props;
    this.onTextChange.flush();

    if (e.key == 'Enter' && !e.nativeEvent.shiftKey && !hasRank) {
      e.preventDefault();
      if (!disableNewChoice) {
        addChoice(generateChoice());
      }
    }
    if (e.keyCode === 8 || e.keyCode == 46) {
      if (!e.target.value && !hasRank) {
        e.preventDefault();
        this.handleDeleteChoice();
      }
    }
  };

  onBlurAction = text => {
    const { updateChoice, language, choice } = this.props;
    updateChoice({ ...choice, text: { ...choice?.text, [language]: text } });
  };

  onTextChange = text => {
    const { choice } = this.props;
    const id = choice?.id || choice?.uid;
    const label = document.querySelector(`#choice-label-${id}`);
    if (label) {
      label.innerHTML = text;
    }
  };

  onFocus = () => {
    const {
      setCurrentChoice,
      orderDimension,
      orderQuestion,
      order,
      currentTab,
    } = this.props;
    const { SURVEY_SETTINGS, SETTINGS } = SURVEY_BUILDER_TABS;

    setCurrentChoice(orderDimension, orderQuestion, order);

    if (currentTab === SURVEY_SETTINGS) {
      this.props.setSettingsCurrentTab(SETTINGS);
    }
  };

  handleShowSettings = () => {
    this.setState({ showSettings: true });
  };

  handleHideSettings = () => {
    this.setState({ showSettings: false });
  };

  handleDeleteChoice = () => {
    if (!this.props.isLocked) {
      this.setState({ showDeletePopup: true });
    }
  };

  deleteChoice = () => {
    const { choice, removeChoice, deleteChoice } = this.props;

    if (choice?.id) {
      deleteChoice(choice?.id).then(
        res => this.onSuccess(res),
        err => this.onFailure(err),
      );
    } else {
      try {
        removeChoice();
      } catch (e) {
        this.onFailure();
      }
    }
  };

  handleSelectCorrectChoice = () => {
    const { choice, orderDimension, orderQuestion, order } = this.props;

    if (!choice?.isCorrect) {
      this.props.setCorrectChoice(orderDimension, orderQuestion, order);
    }
  };

  handleUpdateOptions = (min, max) => {
    const { choice } = this.props;

    const options = {
      range: {
        type: '[]',
        value: [min, max],
      },
    };

    this.props.updateChoice({ ...choice, options });
    this.handleHideSettings();
  };

  renderIcon() {
    const { hasRank, choice, isQuiz } = this.props;
    if (hasRank) {
      const { value } = choice;
      const style = {
        color: TOOLBAR_ITEMS.RATING_CHOICE.color,
      };
      const className = `icomoon-icon question-choice__icon-big icon-stats-bars${value}`;
      return (
        <span
          style={style}
          className="question-choice__container__badge"
          contentEditable={false}
        >
          <i className={className} />
        </span>
      );
    }
    if (isQuiz) {
      return (
        <span
          className="question-choice__container__badge"
          contentEditable={false}
        >
          <CheckBox
            checked={choice?.isCorrect}
            onChange={this.handleSelectCorrectChoice}
          />
        </span>
      );
    }
    return (
      <span
        className="question-choice__container__badge"
        contentEditable={false}
      >
        –
      </span>
    );
  }

  render() {
    const {
      choice,
      language,
      lng,
      focusOnMount,
      maxChars,
      disabled,
      withSettings,
      orderDimension,
      orderQuestion,
      order,
      current: {
        currentDimensionIndex,
        currentQuestionIndex,
        currentChoiceIndex,
      },
    } = this.props;
    const { showDeletePopup, showSettings } = this.state;
    const value = getTextByLanguage(choice?.text, language, true);
    const message = _('delete_choice_message');
    const errors = this.props.errors[choice?.id || choice?.uid];
    const helpText = getTextByLanguage(choice?.text, lng, true);
    const focused =
      currentDimensionIndex === orderDimension &&
      currentQuestionIndex === orderQuestion &&
      currentChoiceIndex === order;

    return (
      <div className="question-choice">
        <div className="question-choice__container">
          {this.renderIcon()}
          {withSettings && focused && (
            <div className="choice_settings" onClick={this.handleShowSettings}>
              <i className="icomoon icon-tt-settings" />
            </div>
          )}
          <span
            className="question-choice__container__content"
            contentEditable={false}
          >
            <ChoiceText
              onKeyDown={this.onKeyDown}
              value={value}
              onFocus={this.onFocus}
              onBlurAction={this.onBlurAction}
              onTextChange={this.onTextChange}
              updateOptions={this.handleUpdateOptions}
              helpText={helpText}
              focusOnMount={focusOnMount}
              validationParams={{
                resourceType: CHOICE,
                key: choice?.id || choice?.uid,
                property: 'text',
              }}
              errors={errors}
              maxChars={maxChars}
              disabled={disabled}
            />
          </span>
          {showSettings && (
            <ChoiceAgeSetter
              onClick={this.handleUpdateOptions}
              onClose={this.handleHideSettings}
              minValue={choice?.options?.range?.value?.[0]}
              maxValue={choice?.options?.range?.value?.[1]}
            />
          )}
        </div>
        {showDeletePopup && (
          <Modal
            labels={{ action: _('Delete') }}
            isOpen={showDeletePopup}
            action={this.deleteChoice}
            onCloseModal={this.hideDeletePopup}
            title={_('Delete choice')}
            classNames={{ action: 'btn-popup--alert' }}
          >
            <h5>{message}</h5>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.surveys.list.language,
  lng: state.params.lng,
  errors: state.surveys.list.validationErrors.choices.text,
  currentTab: state.surveys.list.toolbarTab,
  current: state.surveys.list.current,
});

const mapDispatchToProps = {
  removeChoice,
  setCurrentChoice,
  addChoice,
  updateChoice,
  deleteChoice,
  addValidationErrors,
  removeValidationErrors,
  setCorrectChoice,
  setSettingsCurrentTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionChoice);
