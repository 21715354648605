import React from 'react';
import { connect } from 'react-redux';
import __ from 'lodash';
import _ from 'i18n';

const TopBar = ({ dimension, language, nbAnswers, nbQuestions }) => {
  const title = dimension ? dimension.title : null;
  const label = title ? title[language] : '';

  return (
    <div className="preview__header__dimension back fw">
      <div className="preview__header">
        <div className="preview-top-bar preview__container">
          <span className="title-wrapper greetings">{label}</span>
          <span className="progression-info">
            <div className="progress-bar m-r-l" style={{ width: '150px' }}>
              <div className="progress-bar-meter survey-main-content-background-color" style={{ width: `${(nbAnswers / nbQuestions) * 100}%` }} />
            </div>
            <span className="progress-bar-info survey-main-color">
              <span>{nbAnswers}</span>
              <span> / </span>
              <span>{nbQuestions}</span>
              <span>{` ${_('answers')} `}</span>
            </span>
          </span>
          <span className="progression-info mb set-size">
            <div className={`pie-wrapper progress-${parseInt((nbAnswers / nbQuestions) * 100)}`}>
              <span className="label">
                <span style={{ verticalAlign: 'super' }}>{nbAnswers}</span>
                <span>/</span>
                <span>{nbQuestions}</span>
              </span>
              <div className="pie">
                <div className="left-side half-circle" />
                <div className="right-side half-circle" />
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, { childQuestions = [] }) => {
  const { dimensions } = state.surveys.list.survey;
  const { currentDimensionIndex } = state.surveys.list.current;
  const dimension = __.size(dimensions) !== 0 && currentDimensionIndex !== -1
    ? dimensions[currentDimensionIndex] : null;

  return ({
    dimension,
    language: state.surveys.list.language,
    nbAnswers: __.difference(__.keys(state.surveyResponse.answers), childQuestions).length
  });
};
export default connect(mapStateToProps)(TopBar);
