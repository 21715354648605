export const closeGenericEmailingViewer = viewer => ({
  type: 'CLOSE_GENERIC_EMAILING_VIEWER',
  viewer
});

export const openGenericEmailingViewer = viewer => ({
  type: 'OPEN_GENERIC_EMAILING_VIEWER',
  viewer
});

export const closeCampaignViewer = viewer => ({
  type: 'CLOSE_EMAILING_CAMPAIGN_VIEWER',
  viewer
});

export const openCampaignViewer = viewer => ({
  type: 'OPEN_EMAILING_CAMPAIGN_VIEWER',
  viewer
});
