import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentNavPage, setTemplateFilterType } from 'actions';
import _ from 'i18n';
import PageHeader from 'common/PageHeader';
import EditorSelectorTab from './EditorSelectorTab';
import GalleryTab from './GalleryTab';
import TemplatePreview from './TemplatePreview';
import TTPTab from '../common/TTPTab';

const TEMPLATES_TYPES = ['SATISFACTION', 'OPINION'];
const TABS = [
  { id: 'CODE_OWN', label: 'codeOwn' },
  // { id: 'OPINION', label: 'opinionSurvey' },
  // { id: 'SATISFACTION', label: 'satisfactionSurvey' }
];

class TypeSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 'CODE_OWN', // CODE_OWN || OPINION || SATISFACTION
      previousTab: 'CODE_OWN',
      showPreview: false,
    };

    this.handleShowPreview = () => this.setState({ showPreview: true });
    this.handleClosePreview = () => this.setState({ showPreview: false });
  }

  componentDidMount() {
    this.props.setCurrentNavPage('TYPE_SELECTOR');
  }

  switchCurrentTab = (currentTab) => {
    const { previousTab } = this.state;
    this.setState({ currentTab });
    if (currentTab != 'CODE_OWN' && previousTab != currentTab) {
      this.setState({ previousTab: currentTab });
      if (TEMPLATES_TYPES.indexOf(currentTab) !== -1) {
        this.props.setTemplateFilterType(currentTab);
      }
    }
  }

  render() {
    const { currentTab, showPreview } = this.state;
    const { template } = this.props;

    return (
      <div>
        <TemplatePreview dispatch={this.props.dispatch} template={template} onCloseModal={this.handleClosePreview} isOpen={showPreview} />
        <div id="home-page" className={`survey-type__selector ${showPreview ? 'hide' : ''}`}>
          <PageHeader
            icon="icomoon icon-tt-stats"
            title={_('Surveys')}
            subTitle={_('CREATE, MANAGE AND SEND YOUR SURVEYS')}
          />
          {/* <div className="ttp-tab-container">
            <TTPTab tabs={TABS} activeTab={currentTab} selectTab={this.switchCurrentTab} />
          </div> */}
          <EditorSelectorTab currentTab={currentTab} />
          <GalleryTab currentTab={currentTab} handleShowPreview={this.handleShowPreview} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  template: state.templates.survey,
});

const mapDispatchToProps = { setCurrentNavPage, setTemplateFilterType };

export default connect(mapStateToProps, mapDispatchToProps)(TypeSelector);
