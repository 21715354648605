/* eslint-disable prefer-template */
import { pushSourceToken as pushSourceTokenAction } from 'actions';

import axios from 'axios';
import {
  COMMUNITY_DEFAULT_LOGO_URL,
  COMMUNITY_NAME_MAX_LENGTH,
} from '../config/Common';

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
}

export function setCookie(name, value, expires, path, domain, secure) {
  document.cookie =
    name +
    ' = ' +
    escape(value) +
    '  ' +
    (expires === undefined ? '' : '; expires = ' + expires.toGMTString()) +
    (path === undefined ? '' : '; path = ' + path) +
    (domain === undefined ? '' : '; domain = ' + domain) +
    (secure === true ? '; secure' : '');
}

export function getSourceToken() {
  const CancelToken = axios.CancelToken;
  return CancelToken.source();
}

export function updateSourceToken(oldSourceToken, newSourceToken) {
  if (oldSourceToken) {
    oldSourceToken.cancel('Operation canceled by the user.');
  }

  return newSourceToken;
}

export function pushSourceToken(sourceName, dispatch) {
  const sourceToken = getSourceToken();

  dispatch(pushSourceTokenAction(sourceName, sourceToken));

  return sourceToken;
}

export function getCommunityDisplayName(community) {
  return (
    community.abbreviation ||
    (community.name.length <= COMMUNITY_NAME_MAX_LENGTH
      ? community.name
      : community.name.substring(0, COMMUNITY_NAME_MAX_LENGTH) + ' ...')
  );
}

export function getCommunityLogoUrl(community) {
  return community && community.avatarUrl
    ? community.avatarUrl
    : COMMUNITY_DEFAULT_LOGO_URL;
}

export const tokenHasExpired = token => {
  const now = Math.floor(new Date().getTime() / 1000);

  return (
    !token ||
    typeof token !== 'object' ||
    !token.createdAt ||
    !token.expiresIn ||
    now >= parseFloat(token.createdAt) + parseFloat(token.expiresIn)
  );
};

export const getTokenFromAuthApp = authInfos => ({
  token: authInfos?.access_token,
  expiresIn: authInfos?.expires_in,
  createdAt: authInfos?.createdAt,
  scope: authInfos?.scope,
  tokenType: authInfos?.token_type,
});
