import { setLocalStorageAuth } from 'SurveyAPI';

const initialState = {
  fetching: false,
  fetched: false,
  token: '',
  expiresIn: null,
  createdAt: null,
  user: null,
  error: null,
  detached: false,
  currentClient: null,
  preference: [],
  loggedAs: 'GUEST',
  loggedAsPost: null,
  isVisitor: false,
  appTokenFetching: false,
  appTokenFetched: false,
  appToken: {
    token: '',
    expiresIn: undefined,
    createdAt: undefined,
    scope: '',
    tokenType: '',
  },
};
export const authReducer = (state = initialState, action) => {
  let result = { ...state };

  switch (action.type) {
    case 'SET_AUTH': {
      result =
        action.auth == null
          ? { ...initialState, appToken: state.appToken }
          : { ...action.auth };
      break;
    }
    case 'SET_AUTH_USER': {
      result = { ...state, user: action.user };
      break;
    }
    case 'SET_CURRENT_USER': {
      result = { ...state, user: action.user };
      break;
    }
    case 'SET_AUTH_TOKEN': {
      result = { ...state, token: action.token };
      break;
    }
    case 'SET_APP_TOKEN': {
      result = { ...state, appToken: action.appToken };
      break;
    }

    case 'UPDATE_SURVEY_CLIENTS_AUTHORIZATION': {
      const { role, preferences, clientId } = action.payload;

      if (!state.user) return state;
      if (!state.user.surveyClients) return state;
      return {
        ...state,
        user: {
          ...state.user,
          surveyClients: state.user.surveyClients.map(item =>
            clientId === item.id
              ? {
                  ...item,
                  authorisation: {
                    id: item.authorisation && item.authorisation.id,
                    role,
                    preferences,
                  },
                }
              : item,
          ),
        },
      };
    }
    case 'SET_CURRENT_CLIENT_PENDING': {
      result = { ...state, updating: true, updated: false };
      break;
    }
    case 'SET_CURRENT_CLIENT':
    case 'SET_CURRENT_CLIENT_FULFILLED': {
      let loggedAs = 'CONTACT';
      let loggedAsPost = null;
      const { user } = state;
      const currentClient = action.payload;

      if (currentClient) {
        if (currentClient.authorisation && currentClient.authorisation.role) {
          loggedAs = currentClient.authorisation.role;
        }

        if (user && Array.isArray(user.roles)) {
          const role = user.roles.find(
            ({ organization: { id } }) => id == currentClient.id,
          );
          if (role && role.type) {
            loggedAsPost = role.type;
          }
        }
      }

      result = {
        ...state,
        updating: false,
        updated: true,
        loggedAs,
        loggedAsPost,
        detached: false,
        preference: currentClient.authorisation.preferences || [],
        currentClient,
      };
      break;
    }
    case 'SET_CURRENT_CLIENT_REJECTED': {
      let error = action.payload;
      switch (error && error.response && error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        updating: false,
        updated: false,
        error,
      };
    }
    case 'SET_DETACHED_FROM_CLIENT': {
      result = {
        ...state,
        loggedAs: 'CONTACT',
        loggedAsPost: null,
        detached: true,
        preference: [],
        currentClient: null,
      };
      break;
    }
    case 'SET_EXPIRES_IN': {
      result = { ...state, expiresIn: action.expiresIn };
      break;
    }
    case 'SET_TOKEN_CREATED_AT': {
      result = { ...state, createdAt: action.createdAt };
      break;
    }
    case 'SET_AUTH_LOGGED_AS': {
      result = { ...state, loggedAs: action.loggedAs };
      break;
    }
    case 'SET_IS_USER_VISITOR': {
      return { ...state, isVisitor: action.isVisitor };
    }
    case 'FETCH_AUTH_USER_PENDING': {
      result = {
        ...state,
        fetching: true,
        fetched: false,
        fetchingAnonymous: false,
      };
      break;
    }
    case 'FETCH_AUTH_USER_FULFILLED': {
      const user = action.payload;
      const { surveyClients } = user;
      let loggedAs = 'GUEST';
      let currentClient = null;
      let loggedAsPost = null;
      const hasClients = surveyClients && surveyClients.length > 0;
      let preference = [];

      if (hasClients) {
        loggedAs = 'CONTACT';
        currentClient = surveyClients.find(({ isSelected }) => isSelected);

        if (currentClient) {
          if (currentClient.authorisation && currentClient.authorisation.role) {
            loggedAs = currentClient.authorisation.role;
            preference = currentClient.authorisation.preferences;
          }

          if (user && Array.isArray(user.roles)) {
            const role = user.roles.find(
              ({ organization: { id } }) => id == currentClient.id,
            );
            if (role && role.type) {
              loggedAsPost = role.type;
            }
          }
        }
      }

      result = {
        ...state,
        fetching: false,
        fetched: true,
        fetchingAnonymous: false,
        loggedAs,
        loggedAsPost,
        preference,
        user,
        currentClient,
      };
      break;
    }
    case 'FETCH_AUTH_USER_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetchingAnonymous: false,
        error: action.payload,
      };
    }
    case 'FETCH_ANONYMOUS_AUTH_USER_PENDING': {
      result = {
        ...state,
        fetching: true,
        fetched: false,
        fetchingAnonymous: true,
      };
      break;
    }
    case 'FETCH_ANONYMOUS_AUTH_USER_FULFILLED': {
      const user = action.payload;
      const loggedAs = 'GUEST';

      user.firstName = 'Anonymous';
      user.lastName = 'User';
      user.isAnonymous = true;

      result = {
        ...state,
        fetching: false,
        fetched: true,
        fetchingAnonymous: true,
        loggedAs,
        user,
      };
      break;
    }
    case 'FETCH_ANONYMOUS_AUTH_USER_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetchingAnonymous: true,
        error: action.payload,
      };
    }
    case 'FETCH_APP_TOKEN_PENDING': {
      return { ...state, appTokenFetching: true };
    }
    case 'FETCH_APP_TOKEN_FULFILLED': {
      result = { ...state, appToken: action.payload, appTokenFetching: false, appTokenFetched: true };
      break;
    }
    case 'FETCH_APP_TOKEN_REJECTED': {
      return { ...state, appTokenFetching: false, appTokenFetched: false };
    }
    default:
      return state;
  }
  setLocalStorageAuth(result);
  return result;
};
