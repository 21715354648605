import React, { useState, useEffect } from 'react';
import _ from 'i18n';
import TTPActionModal from 'common/reveal/TTPActionModal';

export default function SurveyPenetrationRateModal({ onAsyncAction, penetrationRate, ...props }) {
  const [number, setNumber] = useState(penetrationRate);

  const onChange = ({ target: { value } }) => {
    if (value == '') {
      value = null;
    }
    setNumber(value);
  };

  useEffect(() => {
    setNumber(penetrationRate);
  }, [penetrationRate]);

  return (
    <TTPActionModal
      labels={{ ok: _('Validate') }}
      title={_('Set Penetration Rate')}
      icon="icomoon icon-tt-settings"
      onAsyncAction={() => onAsyncAction(number)}
      {...props}
    >
      <div className="m-x-xl p-y-s">
        <div className="input-group settings__input">
          <span className="input-group-label">#</span>
          <input
            className="input-group-field"
            type="number"
            placeholder="0-100"
            value={number}
            onChange={onChange}
          />
        </div>
      </div>
    </TTPActionModal>
  );
}
