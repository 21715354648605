import React, { Component } from 'react';
import { fetchQuestionScore } from 'thunks';
import { getTextByLanguage } from 'surveyUtils';
import NotFoundPage from 'NotFoundPage';
import controlAccess from 'stats/controlAccess';
import QuestionResultsFetching from 'fetching/stats/results/QuestionResultsFetching';
import shortId from 'shortid';

export class QuestionResults extends Component {
  componentDidMount() {
    const { surveySlug, survey: { slug, latestInstance }, organization, dimension } = this.props;
    if (slug && surveySlug == slug) {
      this.props.fetchQuestionScore(latestInstance ? latestInstance.id : 0, dimension, organization);
    }
  }


  componentDidUpdate(prevProps) {
    const { survey: { latestInstance }, organization, dimension } = this.props;
    const prevSurvey = prevProps.survey;

    if (latestInstance != prevSurvey.latestInstance || organization != prevProps.organization || dimension != prevProps.dimension) {
      this.props.fetchQuestionScore(latestInstance ? latestInstance.id : 0, dimension, organization);
    }
  }

  renderResults() {
    const { items, lng } = this.props;

    return items.map(item => (
      <div className="row result-row border" key={(item.question && item.question.id) || shortId.generate()}>
        <div className="column medium-8 result-row__header-wrapper">
          <div className="result-row__header">{getTextByLanguage(item.question.text, lng)}</div>
        </div>
        <div className="column medium-2 separator">
          <div className="result-row__score-item">
            <div className="score-label"><label>SCORE</label></div>
            <div className="score-score">{item.score ? `${item.score} %` : '--'}</div>
          </div>
        </div>
        <div className="column medium-2">
          <div className="result-row__score-item">
            <div className="score-label"><label>NPS</label></div>
            <div className="score-score">{item.nps ? `${item.nps} %` : '--'}</div>
          </div>
        </div>
      </div>
    ));
  }

  render() {
    const { items, fetching, error, hide, disabled, fetchingSurvey } = this.props;

    if (fetching || fetchingSurvey) {
      return <QuestionResultsFetching />;
    }
    
    if (hide || disabled) {
      return <NotFoundPage mainClass="small-10" />;
    }
    if ((error != null && error.code == 404) || !items || items.length == 0) {
      return <NotFoundPage mainClass="small-10" />;
    }

    return (
      <div className="row wrapper">
        <div className="medium-12 columns">
          <div className="medium-12 columns">
            {this.renderResults()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  items: state.questionScore.items,
  fetching: state.questionScore.fetching,
  fetchingSurvey: state.surveys.list.fetching,
  error: state.questionScore.error,
  lng: state.params.lng
});

const mapDispatchToProps = { fetchQuestionScore };

export default controlAccess(mapStateToProps, mapDispatchToProps)(QuestionResults);
