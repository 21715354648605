import React, { Component } from 'react';
import { setInstanceCurrentQuestion, startPreviewQuestion } from 'thunks';
import { connect } from 'react-redux';
import { lastQuestionId } from 'reducers';
import { TAMTAM_API_LANGUAGES } from 'Common';
import _ from 'i18n';
import QuestionContent from './QuestionContent';
import DoubleQuestionContent from './DoubleQuestionContent';

const TIME_PREVIEW = 8; // seconds
const TIME_SET_QUESTION = 1.5; // seconds,  0 <= TIME_SET_QUESTION <= 1.5
const INITIAL_STATE = { time: TIME_PREVIEW, style: { width: '0%' } };

const RETRY_SET_CURRENT_QUESTION_TIME = 1 * 1000;

class QuestionPreview extends Component {
  state = INITIAL_STATE;

  handleCountDown = () => {
    if (this.state.time <= 0 && this.interval) {
      clearInterval(this.interval);
      return;
    }

    if (this.state.time - TIME_SET_QUESTION - 0.5 == 0) {
      this.setInstanceCurrentQuestion();
    }

    this.setState(prevState => ({ time: prevState.time - 0.5 }));
  }

  componentDidMount() {
    this.startQuestionPreview();
    this.props.setIsPending(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.question !== this.props.question) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(INITIAL_STATE);
      this.startQuestionPreview();
    }
  }

  setInstanceCurrentQuestion = () => {
    const { question, instance } = this.props;
    this.props.setInstanceCurrentQuestion(instance.id, question.id)
      .catch(() => setTimeout(this.setInstanceCurrentQuestion, RETRY_SET_CURRENT_QUESTION_TIME));
  }

  startQuestionPreview = () => {
    this.interval = setInterval(this.handleCountDown, 500);
    this.notifyParticipants();

    setTimeout(() => this.setState({
      style: {
        width: '100%',
        transition: `width ${TIME_PREVIEW}s linear`
      }
    }), 0); // To activate progress bar meter animation
  }

  notifyParticipants = () => {
    const { instance, questionOrder, nbQuestions } = this.props;

    this.props.startPreviewQuestion(instance.pin, questionOrder, nbQuestions);
  }

  renderPreview = () => {
    const { question: { text }, languages, isBilingual } = this.props;

    if (isBilingual) {
      return (
        <>
          <div className="row small-12 question-wrapper">
            {
              languages.map(lng => (
                <div className="small-6 column">
                  <div className="container bilingual">
                    <div className="lng">
                      <img alt="" src={`/img/flags/${lng}.svg`} />
                      <span>{_(TAMTAM_API_LANGUAGES[lng])}</span>
                    </div>
                    <div className="quiz-preview__question question-preview bilingual">
                      <div key={lng} className="text">{text ? text[lng] : ''}</div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="row small-12 quiz-bilingual">
            <div className="progression-info" style={{ width: '100%' }}>
              <div className="progress-bar" style={{ height: '1rem' }}>
                <div className="progress-bar-meter" style={this.state.style} />
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <div className="quiz-preview__question question-preview row">
        <div className="text">{text ? text[languages[0]] : ''}</div>
        <div className="progression-info p-x-xl m-y-l" style={{ width: '100%' }}>
          <div className="progress-bar" style={{ height: '1rem' }}>
            <div className="progress-bar-meter" style={this.state.style} />
          </div>
        </div>
      </div>
    );
  }

  renderQuestionContent = () => {
    const { question, languages, instance, lastQuestionId, isBilingual } = this.props;

    return isBilingual
      ? (
        <DoubleQuestionContent
          question={question}
          languages={languages}
          isBilingual={isBilingual}
          setIsPending={this.props.setIsPending}
          instance={instance}
          lastQuestionId={lastQuestionId}
        />
      )
      : (
        <QuestionContent
          question={question}
          language={languages[0]}
          isBilingual={isBilingual}
          setIsPending={this.props.setIsPending}
          instance={instance}
          lastQuestionId={lastQuestionId}
        />
      );
  }

  render() {
    const { updating, updated } = this.props;

    return (this.state.time > TIME_SET_QUESTION || updating || !updated)
      ? this.renderPreview()
      : this.renderQuestionContent();
  }
}

const mapStateToProps = state => ({
  updating: state.quiz.updating,
  updated: state.quiz.updated,
  instance: state.quiz.instance,
  lastQuestionId: lastQuestionId(state.quiz)
});

export default connect(mapStateToProps, { setInstanceCurrentQuestion, startPreviewQuestion })(QuestionPreview);
