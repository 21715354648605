import React, { Component } from 'react';
import shortId from 'shortid';

export default class Steps extends Component {
  constructor(props) {
    super(props);
    const defaultValues = [5, 7, 10];

    this.state = {
      value: this.props.value ? this.props.value : 5,
      values: this.props.values ? this.props.values : defaultValues,
    };
  }

  changeSteps({ target: { value } }) {
    if (!this.props.disabled) {
      const { attribute } = this.props;

      this.setState({ value });

      this.props.addOption(attribute, value);
    }
  }

  render() {
    const { value, values } = this.state;
    const { disabled, label } = this.props;

    return (
      <div className="settings__block">
        <div className="settings__container">
          <div className="settings__container__label">{label}</div>
          <div className="settings__container__item">
            <div className={`steps-list ${disabled ? 'disabled-link' : ''}`}>
              <ul>
                {
                  values.map(item => (
                    <li
                      value={item}
                      className={value === item ? 'selected' : ''}
                      key={`step-${shortId.generate()}`}
                      onClick={this.changeSteps.bind(this)}
                    >
                      {item}
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
