import React, { Component } from 'react';
import PropTypes from 'prop-types';
import fscreen from 'fscreen';

export const FullScreenButton = ({ enabled }) => <i className={`icomoon-icon icon-${enabled ? 'exit-' : ''}full-screen`} />;

export default class FullScreen extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    // eslint-disable-next-line react/require-default-props
    enabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    enabled: false,
  };

  constructor(props) {
    super(props);

    this.detectFullScreen = this.detectFullScreen.bind(this);
    this.node = React.createRef();
  }

  componentDidMount() {
    fscreen.addEventListener('fullscreenchange', this.detectFullScreen);
  }

  componentWillUnmount() {
    fscreen.removeEventListener('fullscreenchange', this.detectFullScreen);
  }

  componentDidUpdate() {
    this.handleProps(this.props);
  }

  handleProps(props) {
    const enabled = fscreen.fullscreenElement === this.node.current;
    if (enabled && !props.enabled) {
      this.leaveFullScreen();
    } else if (!enabled && props.enabled) {
      this.enterFullScreen();
    }
  }

  detectFullScreen() {
    if (this.props.onChange) {
      this.props.onChange(fscreen.fullscreenElement === this.node.current);
    }
  }

  enterFullScreen() {
    if (fscreen.fullscreenEnabled) {
      fscreen.requestFullscreen(this.node.current);
    }
  }

  leaveFullScreen() {
    if (fscreen.fullscreenEnabled) {
      fscreen.exitFullscreen();
    }
  }

  render() {
    const className = ['fullscreen'];
    if (this.props.enabled) {
      className.push('fullscreen-enabled');
    }

    return (
      <div
        className={`${this.props.className} ${className.join(' ')}`}
        ref={this.node}
        style={
          this.props.enabled ? { height: '100%', width: '100%' } : undefined
        }
      >
        {this.props.children}
      </div>
    );
  }
}
