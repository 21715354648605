import React from 'react';
import Editor from 'draft-js-plugins-editor';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import createSideToolbarPlugin from 'draft-js-side-toolbar-plugin';
import { getDefaultKeyBinding } from 'draft-js';
import shortId from 'shortid';
import _ from 'i18n';
import DisplayErrors from './preview-items/DisplayErrors';
import { getErrorMessageByCode } from '../services/surveyUtils';
import Popup from './Popup';

export default class TextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.inlineToolbarPlugin = createInlineToolbarPlugin();
    this.sideToolbarPlugin = createSideToolbarPlugin({ position: 'right' });

    this.plugins = [
      this.inlineToolbarPlugin,
      this.sideToolbarPlugin
    ];

    this.state = {
      editorState: props.editorState,
      options: props.options,
    };

    this.popupDeleteId = `popup-dlt-srv-dsc-${shortId.generate()}`;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { description } = nextProps;
    const isFr = nextProps.language == 'fr';
    return {
      ...prevState,
      editorState: nextProps.editorState,
      frText: !isFr && description ? description.fr : '',
      order: nextProps.order,
    };
  }

  componentDidMount() {
    $(`#${this.popupDeleteId}`).foundation();
  }

  onChange = (editorState) => {
    this.props.onTextChange(editorState);
  }

  onFocus = () => {
    this.props.onFocus();
  }

  focus = () => {
    this.editor.focus();
    this.props.onFocus();
  };

  keyBindingFn = (e) => {
    const hasText = this.state.editorState.getCurrentContent().hasText();

    if ((e.keyCode === 8 || e.keyCode == 46) /* backspace key */ && !hasText) {
      return 'remove-editor';
    }
    return getDefaultKeyBinding(e);
  }

  handleKeyCommand = (command) => {
    if (command === 'remove-editor') {
      $(`#${this.popupDeleteId}`).foundation('open');
      return 'handled';
    }
    return 'not-handled';
  }

  render() {
    const {
      options, order, required, editorState,
    } = this.state;
    const { focused } = this.props;
    const style = { backgroundColor: options.color, };
    let className = 'header-label';

    if (focused) {
      className = `${className} editor-block__focused`;
    }

    const { InlineToolbar } = this.inlineToolbarPlugin;
    const { SideToolbar } = this.sideToolbarPlugin;

    return (
      <div className="editor-block__container">
        <div className="editor-block__root">
          <div className={className}>
            <div className="editor-block__background editor-block__filter" style={style} />
            <div className="editor-block__icon">
              <div className="block-icon" style={style}>
                <div className="block-icon__icon">
                  <span><i className={options.icon} /></span>
                </div>
                <div className="block-icon__order">{order != null ? order + 1 : ''}</div>
              </div>
              {
                required
                && <div className="block-badge">*</div>
              }
            </div>
            <div className="editor-block editor-block__content p-t-m">
              <div onClick={this.focus} className={`text-editor ${focused ? 'text-editor--focused' : ''}`}>
                <Editor
                  editorState={editorState}
                  onChange={this.onChange}
                  plugins={this.plugins}
                  placeholder={this.props.placeholder}
                  ref={(element) => { this.editor = element; }}
                  onFocus={this.onFocus}
                  keyBindingFn={this.keyBindingFn}
                  handleKeyCommand={this.handleKeyCommand}
                />
                <InlineToolbar />
                <SideToolbar />
              </div>
              {
                this.props.errors
                && this.props.errors.map(error => <DisplayErrors message={getErrorMessageByCode(error.code)} key={`error-${error.code}`} />)
              }
            </div>
            <Popup popupId={this.popupDeleteId} action={this.props.onDelete} message={_('remove_block_message')} label={{ action: _('Delete'), className: 'btn-popup--alert' }} />
          </div>
        </div>
      </div>
    );
  }
}

const placeholder = 'In this editor a toolbar shows up once you select part of the text …';

TextEditor.defaultProps = {
  placeholder
};
