import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getLibrarySurveys, participate, cancelParticipate } from 'thunks';
import { setSurveyPaginationPage } from 'actions';
import LibraryList from './LibraryList';

const PublicLibrary = (props) => {
  const {
    currentTab,
    getLibrarySurveys,
    surveys: { items, fetching, fetched, nbResult, paginationPage },
    currentClientId,
    participate,
    cancelParticipate,
    goToMyLibrary,
    goToPublicLibrary,
    lng,
  } = props;

  useEffect(() => {
    fetchLibrarySurveys();
    return cleanSurveys;
  }, [currentTab, paginationPage]);

  const cleanSurveys = () => { };
  const fetchLibrarySurveys = () => {
    getLibrarySurveys();
  };

  const params = {
    surveys: items,
    fetching,
    fetched,
    nbResult,
    paginationPage,
    currentClientId,
    currentTab,
    lng,
    isActive: currentTab == 'PUBLIC',
    participate,
    cancelParticipate,
    goToMyLibrary,
    goToPublicLibrary,
    setPaginationPage: props.setSurveyPaginationPage
  };

  return <LibraryList {...params} />;
};

const mapStateToProps = state => ({
  surveys: state.surveys.list,
  lng: state.params.lng,
  currentClientId: state.auth.currentClient?.id ?? 0,
});

const mapDispatchToProps = {
  getLibrarySurveys,
  participate,
  cancelParticipate,
  setSurveyPaginationPage
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicLibrary);
