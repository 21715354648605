import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchUsers } from 'thunks';
import NotFoundPage from 'NotFoundPage';
import { clearSourcesTokens } from 'actions';
import User from './User';
import AvatarFetching from '../fetching/AvatarFetching';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0, // eslint-disable-line react/no-unused-state
      requestsTokens: [] // eslint-disable-line react/no-unused-state
    };
  }

  componentDidMount() {
    const { fetchUsers } = this.props;
    fetchUsers();
  }

  componentDidUpdate(prevProps, prevState) { // eslint-disable-line no-unused-vars
    const {
      role, searchWord, fetchUsers, usersError
    } = this.props;
    if (prevProps.role !== role || prevProps.searchWord !== searchWord) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        currentPage: 0 // eslint-disable-line react/no-unused-state
      });
      fetchUsers();
    }

    const classList = document.getElementById('authorisation-setting-users').classList; // eslint-disable-line prefer-destructuring
    if (usersError != null && usersError.code == 404) {
      classList.remove('row');
      document.getElementById('authorisation-setting-users').style.padding = 0;
    } else if (!classList.contains('row')) {
      classList.add('row');
      document.getElementById('authorisation-setting-users').style.padding = '4rem 0';
    }
    document.getElementsByClassName('bubble__select-all').item(0).classList.add('active');
  }

  componentWillUnmount() {
    const { _requests } = this.props;

    _requests.forEach(
      req => req.sourceToken.cancel('Operation canceled by the user.')
    );
    clearSourcesTokens();
  }

  render() {
    const { fetched, users, usersError } = this.props;

    if (usersError != null && usersError.code == 404) {
      return <NotFoundPage mainClass="small-8" />;
    }

    if (!fetched) {
      return (
        <div className="setting-fetching">
          <AvatarFetching />
          <AvatarFetching />
          <AvatarFetching />
        </div>
      );
    }
    if (users == undefined || users.length == 0) {
      return null;
    }

    return (
      <div className="small-8 row">
        {users.map(user => <User {...user} key={`user-${user.id}`} />)}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users.items,
  fetched: state.users.fetched,
  usersError: state.users.error,
  role: state.settings.filter.role,
  searchWord: state.settings.filter.searchWord,
  _requests: state.params.sourcesTokens
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchUsers }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
