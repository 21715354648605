import React, { Component } from 'react';
import _ from 'i18n';
import { connect } from 'react-redux';
import { setSurveyTheme, addMetaOption } from 'actions';
import styles from './SurveyThemes.module.scss';
import SurveyThemes from './SurveyThemes';
import SurveyBackgroundList from './SurveyBackgroundList';
import {
  Themes,
  backgroundTextures,
  backgroundColors,
  backgroundArchitecture,
  backgroundNature,
} from 'SurveyConfig';
import { TTP_BACKGROUNDS } from 'Config';

const getBackgroundUrl = (type, object) =>
  `${TTP_BACKGROUNDS}/${type}/${object}`;

const BACKGROUNDS_TEXTURES = backgroundTextures.map(item =>
  getBackgroundUrl('textures', item),
);
const BACKGROUNDS_COLORS = backgroundColors.map(item =>
  getBackgroundUrl('colors', item),
);
const BACKGROUNDS_ARCHITECTURE = backgroundArchitecture.map(item =>
  getBackgroundUrl('architecture', item),
);
const BACKGROUNDS_NATURE = backgroundNature.map(item =>
  getBackgroundUrl('nature', item),
);

class SurveyDesign extends Component {
  handleThemeSelected = theme => {
    this.props.addMetaOption('theme', theme);
  };

  onBackgroundSelected = urlBackground => {
    const surveyTheme = this.props.survey?.meta?.theme ?? {};
    const background = surveyTheme?.background ?? {};
    const baseTheme =
      Themes.find(theme => theme.name === surveyTheme.name) ?? {};
    const theme =
      urlBackground == null
        ? baseTheme
        : {
            ...surveyTheme,
            background: { ...background, backgroundImage: urlBackground },
          };

    this.props.addMetaOption('theme', theme);
  };

  render() {
    const surveyTheme = this.props.survey?.meta?.theme ?? {};
    const backgroundUrl =
      surveyTheme?.background?.backgroundImage ?? 'NOTE_A_BACKGROUND';

    return (
      <div className={`s-toolbar ${styles.stoolbar}`}>
        <div className="s-toolbar__title separator">
          <div className="s-toolbar__title__label survey-font survey-font--upperCase">
            {_('design')}
          </div>
          <div
            className="s-toolbar__title__icon"
            onClick={this.props.closeSlider}
          >
            <span>
              <i className="icomoon-icon icon-cross" />
            </span>
          </div>
        </div>
        <div className={styles.toolbar_content}>
          <div className={styles.block_title}>Themes</div>
          <SurveyThemes
            handleThemeSelected={this.handleThemeSelected}
            surveyTheme={surveyTheme}
          />
          <div className={styles.block_title}>Backgrounds</div>
          <SurveyBackgroundList
            data={BACKGROUNDS_TEXTURES}
            title="Textures"
            surveyTheme={surveyTheme}
            isOpen={backgroundTextures.some(
              item => getBackgroundUrl('textures', item) === backgroundUrl,
            )}
            onBackgroundSelected={this.onBackgroundSelected}
          />
          <SurveyBackgroundList
            data={BACKGROUNDS_COLORS}
            title="Colors"
            surveyTheme={surveyTheme}
            isOpen={backgroundColors.some(
              item => getBackgroundUrl('colors', item) === backgroundUrl,
            )}
            onBackgroundSelected={this.onBackgroundSelected}
          />
          <SurveyBackgroundList
            data={BACKGROUNDS_ARCHITECTURE}
            title="Architecture"
            surveyTheme={surveyTheme}
            isOpen={backgroundArchitecture.some(
              item => getBackgroundUrl('architecture', item) === backgroundUrl,
            )}
            onBackgroundSelected={this.onBackgroundSelected}
          />
          <SurveyBackgroundList
            data={BACKGROUNDS_NATURE}
            title="Nature"
            surveyTheme={surveyTheme}
            isOpen={backgroundNature.some(
              item => getBackgroundUrl('nature', item) === backgroundUrl,
            )}
            onBackgroundSelected={this.onBackgroundSelected}
          />
          <div className={styles.survey_background_list}></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  survey: state.surveys.list.survey,
});

export default connect(mapStateToProps, { setSurveyTheme, addMetaOption })(
  SurveyDesign,
);
