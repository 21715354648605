import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  setSurveyListFilterStatus,
  setSurveyListFilterType,
  setSurveyListFilterOwner,
  setSurveyListFilterTitle,
  setSurveyPageSize,
  setSurveyPaginationPage,
  initSurveyListFilter,
  setSurveyListFilterLanguages,
} from 'actions';
import { SURVEY_TYPE, LANGUAGES, TAMTAM_API_LANGUAGES } from 'Common';
import _ from 'i18n';
import TTPFilterHorizontalBar from '../common/filter/TTPFilterHorizontalBar';
import SurveyViewSelector from './SurveyViewSelector';

class SurveyListFilter extends Component {
  constructor(props) {
    super(props);

    this.typeOptions = SURVEY_TYPE.map(value => ({ label: _(value), value }));
    this.ownerOptions = [{ label: _('My surveys').toUpperCase(), value: props.userId }];
    this.languageOptions = LANGUAGES.map(value => ({ label: _(TAMTAM_API_LANGUAGES[value]), value }));
  }

  handleSelectStatus(value) {
    this.props.setSurveyListFilterStatus(value);
  }

  handleSelectType = (value) => {
    this.props.setSurveyListFilterType(value);
  }

  handleSelectOwner = (value) => {
    this.props.setSurveyListFilterOwner(value);
  }

  handleResetFilter = () => {
    this.props.initSurveyListFilter();
  }

  handleSearchInputChange = (word) => {
    this.props.setSurveyListFilterTitle(word);
  };

  handleSelectLanguage = (language) => {
    this.props.setSurveyListFilterLanguages(language);
  };

  handleSearchInputEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSearchSubmitClick();
    }
  }

  handleSurveyListPageSizeChanged = (pageSize) => {
    this.props.setSurveyPageSize(pageSize);
    this.props.setSurveyPaginationPage(1);
  }

  render() {
    const { surveysFilter, pageSize, currentClient } = this.props;
    const isDetached = !(currentClient?.id);

    return (
      <div className="medium-12">
        <TTPFilterHorizontalBar
          filters={[
            { placeholder: _('type'), options: this.typeOptions, values: surveysFilter.type, action: this.handleSelectType },
            { placeholder: _('language'), options: this.languageOptions, values: surveysFilter.languages, action: this.handleSelectLanguage },
            { placeholder: _('owner'), options: this.ownerOptions, values: surveysFilter.owner, action: this.handleSelectOwner },
          ]}
          pageSize={pageSize}
          handleListPageSizeChange={this.handleSurveyListPageSizeChanged}
          searchInputValue={surveysFilter.title}
          handleSearchInputChange={this.handleSearchInputChange}
          handleResetFilter={this.handleResetFilter}
          searchPlaceholder="search by survey title"
          cssClass="medium-12"
        >
          {
            !isDetached && <SurveyViewSelector classNames="m-r-m" />
          }
        </TTPFilterHorizontalBar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  surveysFilter: state.surveys.filter,
  pageSize: state.surveys.list.pageSize,
  userId: state.auth.user.id,
  currentClient: state.auth.currentClient
});

const mapDispatchToProps = {
  setSurveyListFilterStatus,
  setSurveyListFilterType,
  setSurveyListFilterOwner,
  setSurveyListFilterTitle,
  setSurveyPageSize,
  setSurveyPaginationPage,
  initSurveyListFilter,
  setSurveyListFilterLanguages,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyListFilter);
