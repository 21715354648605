import React, { Component } from 'react';
import _ from 'i18n';
import { TAMTAM_API_LANGUAGES } from 'Common';
import { TYPE_MONOLINGUAL, TYPE_BILINGUAL } from 'constants';
import TTButton from '../common/TTButton';

export default class LanguageSetter extends Component {
  render() {
    const { languages, showPin, isBilingual, previewType, selectedLanguages } = this.props;

    return (
      <div className="quiz-language-setter">
        <h1>{_('please configure quiz display')}</h1>
        <h2>
          <span><i className="icon icon-arrow-right" /></span>
          <span>{_('Show game PIN to public')}</span>
        </h2>
        <div className="mode-language">
          <TTButton
            text={_('Yes')}
            className={`m-r-s blue2 ${showPin ? 'full active' : ''}`}
            upperCase={true}
            onClick={() => this.props.setShowPin(true)}
            fontSize="20px"
          />
          <TTButton
            text={_('No')}
            className={`m-r-s blue2 ${!showPin ? 'full active' : ''}`}
            upperCase={true}
            onClick={() => this.props.setShowPin(false)}
            fontSize="20px"
          />
        </div>
        {
          isBilingual && (
            <>
              <h2>
                <span><i className="icon icon-arrow-right" /></span>
                <span>{_('select the display type')}</span>
              </h2>
              <div className="mode-language">
                <TTButton
                  text={_('monolingual')}
                  className={`m-r-s blue2 ${previewType == TYPE_MONOLINGUAL ? 'full active' : ''}`}
                  upperCase={true}
                  onClick={() => this.props.setPreviewType(TYPE_MONOLINGUAL)}
                  fontSize="20px"
                />
                <TTButton
                  text={_('bilingual')}
                  className={`m-r-s blue2 ${previewType == TYPE_BILINGUAL ? 'full active' : ''}`}
                  upperCase={true}
                  onClick={() => this.props.setPreviewType(TYPE_BILINGUAL)}
                  fontSize="20px"
                  disabled={!isBilingual}
                />
              </div>
              {
                previewType && (
                  <>
                    <h2>
                      <span><i className="icon icon-arrow-right" /></span>
                      <span>
                        {_('select the languages to display')}
                        : max 2
                        {' '}
                        {_('Languages')}
                      </span>
                    </h2>
                    <div className="language-selector">
                      {
                        ['fr', 'nl', 'en'].map((lng) => {
                          const selected = selectedLanguages.indexOf(lng) != -1;
                          const maxSelections = previewType == TYPE_MONOLINGUAL ? 3 : 2; // monolingual doesn't need max
                          const disabled = languages.indexOf(lng) == -1 || (selectedLanguages.length >= maxSelections && !selected);

                          return (
                            <TTButton
                              text={_(TAMTAM_API_LANGUAGES[lng])}
                              icon="icomoon icon-tt-correct"
                              showIcon={selected}
                              isSymmetric={true}
                              className={`blue2 p-x-s ${selected ? 'full active' : ''}`}
                              upperCase={true}
                              onClick={() => this.props.handleSelectLanguage(lng, !selected)}
                              disabled={disabled}
                              fontSize=".9rem"
                              key={`display-language-${lng}`}
                            />
                          );
                        })
                      }
                    </div>
                  </>
                )
              }
            </>
          )
        }
      </div>
    );
  }
}
