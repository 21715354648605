import React from 'react';
import ReactPaginate from 'react-paginate';
import _ from 'i18n';

export default function TTPPaginator(props) {
  const { onPageChange, pageSize, nbResult, cssClass, paginationPage } = props;
  const pageCount = Math.ceil(nbResult / pageSize);

  if (pageCount == 1) {
    return <div style={{ margin: '2.5rem' }} />;
  }

  return (
    <ReactPaginate
      previousLabel={_('previous')}
      nextLabel={_('next')}
      breakLabel={<a>...</a>}
      breakClassName=""
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={5}
      onPageChange={onPageChange}
      containerClassName="pagination"
      subContainerClassName={`pages pagination ${cssClass}`}
      activeClassName="current"
      pageClassName="page"
      forcePage={paginationPage - 1}
    />
  );
}
