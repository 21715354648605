
import _ from 'i18n';
import { isListQuestion, getTextByLanguage } from 'surveyUtils';
import shortId from 'shortid';

export const getAllQuestionsOfSurvey = (survey) => {
  if (survey != null && Array.isArray(survey.dimensions)) {
    return survey.dimensions.reduce(
      (prevQuestions, dimension) => (Array.isArray(dimension.questions) ? [...prevQuestions, ...dimension.questions] : prevQuestions),
      []
    );
  }
  return [];
};

export const getLinkedToOptions = (question, surveyType, surveySteps, language) => {
  let options = [];

  if (isListQuestion(question)) {
    options = question.choices.map(choice => ({
      label: getTextByLanguage(choice.text, language),
      choiceId: choice.id,
      choiceUid: choice.uid,
      value: choice.id || choice.uid,
    }));
  } else if (question.type === 'YES_NO') {
    options = [0, 1].map(value => ({
      label: value ? _('Yes') : _('No'),
      value
    }));
  } else if (question.type === 'TRUE_FALSE') {
    options = [0, 1].map(value => ({
      label: value ? _('I accept') : _('I don\'t accept'),
      value
    }));
  } else if (surveyType === 'SATISFACTION') {
    for (let i = 1; i <= surveySteps; i++) {
      options.push({
        label: i,
        value: i
      });
    }
  } else {
    const steps = question.steps || question.maxNumber;

    for (let i = 1; i <= steps; i++) {
      options.push({
        label: i,
        value: i
      });
    }
  }

  return options;
};

export const duplicateQuestion = question => {
  if (question == null) {
    throw new Error('question must not be null');
  }

  const newQuestion = { ...question, uid: shortId.generate() };
  delete newQuestion.id;

  if (newQuestion?.choices?.length > 0) {
    newQuestion.choices = newQuestion.choices.map(({ ...choice }) => {
      delete choice.id;
      return { ...choice, uid: shortId.generate()};
    });
  }

  return newQuestion;
};