import React, { useState, useEffect } from 'react';
import styles from './Profiling.module.scss';
import ProfilingField from './ProfilingField';
import { getFirstNotAnsweredQuestion } from 'answer';

export default function ProfilingForm({ questions, language, answers }) {
  const [active, setActive] = useState(-1);

  useEffect(() => {
    const missedQuestion = getFirstNotAnsweredQuestion(questions, answers);
    if (missedQuestion) {
      setActive(missedQuestion.id ?? missedQuestion.uid);
    } else if (active !== -1) {
      setActive(-1);
    }
  }, [questions, answers]);

  let countryAnswer = 'BE';
  const countryQuestion = questions.find(({ profilingType }) => profilingType === 'COUNTRY');

  if (countryQuestion) {
    const {id, uid } = countryQuestion;
    const answer = answers?.[id] ?? answers?.[uid];
    countryAnswer = answer?.answerValue ?? 'BE';
  }

  return (
    <div className={styles.profile_form}>
      <div className="row">
        {questions.map(question => (
          <ProfilingField
            key={question.id ?? question.uid}
            question={question}
            language={language}
            active={[question.id, question.uid].includes(active)}
            country={countryAnswer}
          />
        ))}
      </div>
    </div>
  );
}
