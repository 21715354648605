import React from 'react';

export const QuestionHeader = ({ hasOrder, order, id, text, isRequired }) => (
  <div className="qst__header">
    {
      hasOrder
        ? <div className="qst__header__index flex-column-center survey-main-content-background-color">{order + 1}</div>
        : null
    }
    <h1 className="qst__header__text survey-main-content-background-color">
      <span id={id}>{text}</span>
      <span className="qst__required" style={{ visibility: isRequired ? 'visible' : 'hidden' }}>*</span>
    </h1>
  </div>
);

QuestionHeader.defaultProps = {
  hasOrder: true
};

export default QuestionHeader;
