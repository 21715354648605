import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'i18n';
import __ from 'lodash';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { getOptions, getSurveyQuestion } from 'SurveyConfig';
import {
  updateSurveyDescription,
  removeSurveyDescription,
  updateWelcomeMessage,
  removeWelcomeMessage,
  setCurrentQuestion,
} from 'actions';
import { LANGUAGES } from 'Common';
import isEmpty from 'lodash/isEmpty';
import { sortLanguages } from 'utils';
import { EditorState } from 'draft-js';
import SurveyTitle from './editor-items/SurveyTitle';
import DimensionMakerList from './editor-items/DimensionMakerList';
import TextEditor from '../TextEditor';
import CreateBlock from './editor-items/CreateBlock';

class Editor extends Component {
  componentDidUpdate() {
    $('#surveyEditor').foundation();
  }

  onSuccess(res) {
    if (res.value instanceof Error) {
      this.onFailure();
    } else {
      NotificationManager.success(_('save_survey_success'), _('Saved'));
    }
  }

  onFailure() {
    NotificationManager.error(_('errorOccured'), _('error'));
  }

  showPreview() {
    const { slug } = this.props.surveys.survey;
    const link = slug ? `/survey/${slug}` : null;
    this.props.history.push(link);
  }

  onDescriptionUpdate = editorState => {
    const {
      surveys: { language },
    } = this.props;
    if (!__.isEmpty(editorState) && language) {
      this.props.updateSurveyDescription(language, editorState);
    }
  };

  onWelcomeMessageUpdate = editorState => {
    const {
      surveys: { language },
    } = this.props;
    if (!__.isEmpty(editorState) && language) {
      this.props.updateWelcomeMessage(language, editorState);
    }
  };

  onFocus = level => {
    this.props.setCurrentQuestion(-1, -1, level);
  };

  render() {
    const {
      survey,
      survey: { description, welcomeMessage, languages, dimensions, type },
      current: { currentDimensionIndex, currentQuestionIndex, level },
      language,
    } = this.props.surveys;
    const descriptionES = description
      ? description[language] || EditorState.createEmpty()
      : null;
    const welcomeMessageES = welcomeMessage
      ? welcomeMessage[language] || EditorState.createEmpty()
      : null;
    const arrayLanguages = sortLanguages(
      (!isEmpty(languages) && languages.split(',')) || LANGUAGES,
    );
    const lastDimension = dimensions?.[dimensions?.length - 1];
    const addBlockOptions = {
      orderDimension: dimensions?.length - 1 ?? -1,
      order: lastDimension?.questions?.length - 1 ?? -1,
    };

    return (
      <div className="editor column medium-12" id="surveyEditor">
        <div className="question-maker-list s-toolbar__items editor_container">
          <SurveyTitle
            title={survey.title}
            surveyId={survey.id || survey.uid}
            surveyType={survey.type}
            languages={arrayLanguages}
            focused={
              currentDimensionIndex === -1 &&
              currentQuestionIndex === -1 &&
              level === 'TITLE'
            }
          />
          {welcomeMessageES && (
            <TextEditor
              editorState={welcomeMessageES}
              language={language}
              options={getOptions('WELCOME_MESSAGE')}
              onFocus={this.onFocus.bind(this, 'WELCOME_MESSAGE')}
              onDelete={this.props.removeWelcomeMessage}
              onTextChange={this.onWelcomeMessageUpdate}
              placeholder={_('Welcome message')}
              focused={
                currentDimensionIndex === -1 &&
                currentQuestionIndex === -1 &&
                level === 'WELCOME_MESSAGE'
              }
            />
          )}
          {descriptionES && (
            <TextEditor
              editorState={descriptionES}
              language={language}
              options={getOptions('DESCRIPTION')}
              onFocus={this.onFocus.bind(this, 'DESCRIPTION')}
              onDelete={this.props.removeSurveyDescription}
              onTextChange={this.onDescriptionUpdate}
              placeholder={_('Survey description')}
              focused={
                currentDimensionIndex === -1 &&
                currentQuestionIndex === -1 &&
                level === 'DESCRIPTION'
              }
            />
          )}
          <DimensionMakerList
            dimensions={survey.dimensions}
            language={language}
          />
          <div className="editor-block__container">
            <div className="editor-block__actions block">
              <CreateBlock
                orderDimension={addBlockOptions.orderDimension}
                order={addBlockOptions.order}
                blocks={['DIMENSION', ...getSurveyQuestion(type)]}
                disableAddDimension={
                  survey.type === 'PORTAL' && survey?.dimensions?.length > 0
                }
                disableAddQuestion={
                  addBlockOptions.orderDimension < 0 ||
                  (survey.type === 'PORTAL' && survey?.dimensions?.[0]?.questions?.length > 0)
                }
              />
              <span>{_('Add question')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  surveys: state.surveys.list,
  auth: state.auth,
});

const mapDispatchToProps = {
  updateSurveyDescription,
  removeSurveyDescription,
  updateWelcomeMessage,
  removeWelcomeMessage,
  setCurrentQuestion,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(Editor);
