import React, { Component } from 'react';

export default class AvatarFetching extends Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <div className="user-fetching">
        <div className="user-avatar gradiant" />
        <h3 className="gradiant" />
        <h4 className="gradiant" />
        <span />
        <h5 className="gradiant" />
      </div>
    );
  }
}
