export const initQuiz = () => ({
  type: 'INIT_QUIZ',
});

export const fetchQuiz = resp => ({
  type: 'FETCH_QUIZ',
  payload: resp,
});

export const createInstance = resp => ({
  type: 'CREATE_QUIZ_INSTANCE',
  payload: resp,
});

export const setInstanceCurrentQuestion = resp => ({
  type: 'SET_INSTANCE_CURRENT_QUESTION',
  payload: resp,
});

export const fetchTopTen = resp => ({
  type: 'FETCH_QUIZ_TOP_TEN',
  payload: resp,
});

export const changeQuizLanguage = language => ({
  type: 'CHANGE_QUIZ_LANGUAGE',
  language
});

export const setQuizLanguages = languages => ({
  type: 'SET_QUIZ_LANGUAGES',
  languages
});

export const fetchInstances = resp => ({
  type: 'FETCH_SURVEY_INSTANCES',
  payload: resp
});
