import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GENERIC_EMAIL_TYPES } from 'constants';
import { getEmailingCampaignLink } from 'surveyUtils';
import ReactTooltip from 'react-tooltip';
import __ from 'lodash';
import _ from 'i18n';

class CampaignCreatorButton extends Component {
  renderButton = (type, text, genericEmailSettings) => {
    const { suggestedCampaign, organizationInstance } = this.props.campaignStats.stats;
    const { currentClient, survey } = this.props;
    if (suggestedCampaign && suggestedCampaign[type]) {
      const { total, ...res } = suggestedCampaign[type];
      return (
        <li className={`${(total > 0) ? 'child-menu' : ''}`}>
          <a>{text}</a>
          <span className="number m-l-m">{total}</span>
          {
            !__.isEmpty(res) ? (
              <ul>
                {
                  ['fr', 'nl', 'en'].map((lng) => {
                    let msg;
                    let disabled;
                    const revivalCount = organizationInstance ? organizationInstance[`revival${__.capitalize(lng)}Count`] : 0;
                    const partialRevivalCount = organizationInstance ? organizationInstance[`partialRevival${__.capitalize(lng)}Count`] : 0;
                    const genericEmail = genericEmailSettings?.languages?.[lng];
                    const genericEmailType = genericEmailSettings?.id ?? GENERIC_EMAIL_TYPES.SURVEY_INVITATION;

                    if (
                      (type == 'revival1' && revivalCount > 0)
                      || (type == 'revival2' && revivalCount > 1)
                      || (type == 'partialRevival2' && partialRevivalCount > 1)
                      || (type == 'partialRevival1' && partialRevivalCount > 0)
                    ) {
                      disabled = true;
                      msg = _('Revival has already been sent');
                    }

                    if (!disabled && (!genericEmail)) {
                      disabled = true;
                      msg = `${_('Missing generic emailing of language')} ${lng.toUpperCase()}`;
                    }

                    if (res[lng]) {
                      const href = disabled
                        ? null
                        : getEmailingCampaignLink({ communityId: currentClient?.id, targetType: genericEmailType, targetId: survey.id, language: lng });
                      return (
                        <>
                          <li className={disabled ? 'disabled-link' : ''} data-tip data-for={`campaign-creator-menu-item-${type}-${lng}`}>
                            <a href={href} target="_blanc">
                              {_('campaign')}
                              :
                              <span className="language m-l-m">{lng}</span>
                              <span className="number m-l-xs">{res[lng]}</span>
                            </a>
                          </li>
                          {
                            disabled && (
                              <ReactTooltip id={`campaign-creator-menu-item-${type}-${lng}`} multiline={true} className="react-tooltip" type="warning">
                                <span className="tooltip-content">{msg}</span>
                              </ReactTooltip>
                            )
                          }
                        </>
                      );
                    }

                    return null;
                  })
                }
              </ul>
            ) : null
          }
        </li>
      );
    }

    return <li><a>{text}</a></li>;
  }

  renderInvitationButton = () => {
    const { survey, currentClient } = this.props;
    return (
      <li className="child-menu">
        <a>{_('Invitation')}</a>
        <ul>
          {
            (survey.language?.split(',') ?? ['fr']).map(language => (
              <li key={language}>
                <a target="_blanc" href={getEmailingCampaignLink({ targetId: survey.id, communityId: currentClient?.id, targetType: GENERIC_EMAIL_TYPES.SURVEY_INVITATION, language })}>
                  {_('campaign')}
                  :
                  <span className="language m-l-m">{language}</span>
                </a>
              </li>
            ))
          }
        </ul>
      </li>
    );
  }

  render() {
    const { campaignStats, genericEmail } = this.props;
    // const invitation = <li onClick={this.handleClick}><a>{_('Invitation')}</a></li>;
    if (campaignStats.fetching || !campaignStats.fetched || genericEmail.fetching) {
      return (
        <div className="campaign-creator">
          <button type="button" className="button-menu">
            <span>{_('New campaign')}</span>
            <ul>
              {this.renderInvitationButton()}
            </ul>
          </button>
        </div>
      );
    }

    const reminderGE = genericEmail.settings.find(({ id }) => id === 'SURVEY_REMINDER');
    const partialreminderGE = genericEmail.settings.find(({ id }) => id === 'SURVEY_PARTIAL_REMINDER');
    const resultsGE = genericEmail.settings.find(({ id }) => id === 'SURVEY_RESULTS');

    return (
      <div className="campaign-creator">
        <button type="button" className="button-menu">
          <span>{_('New campaign')}</span>
          <ul>
            {this.renderInvitationButton()}
            {this.renderButton('revival1', `${_('Revival')} 1`, reminderGE)}
            {this.renderButton('revival2', `${_('Revival')} 2`, reminderGE)}
            {this.renderButton('partialRevival1', `${_('PartialRevival')} 1`, partialreminderGE)}
            {this.renderButton('partialRevival2', `${_('PartialRevival')} 2`, partialreminderGE)}
            {this.renderButton('resultsAsked', _('Results'), resultsGE)}
          </ul>
        </button>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  campaignStats: state.campaignStats,
  genericEmail: state.genericEmail,
  survey: state.surveys.list.survey,
  currentClient: state.auth.currentClient,
});

export default connect(mapStateToProps)(CampaignCreatorButton);
