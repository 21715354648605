import React, { Component } from 'react';
import { setCurrentNavPage, initQuiz } from 'actions';
import { fetchQuiz } from 'thunks';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'i18n';
import { getTextByLanguage } from 'surveyUtils';
import SurveyTitlePreviewFetching from 'fetching/SurveyTitlePreviewFetching';
import NotFoundPage from 'NotFoundPage';
import FullPageMessage from 'preview-items/FullPageMessage';
import withFullScreen from 'common/withFullScreen';
import Preview from './quiz/Preview';

class QuizPreview extends Component {
  componentDidMount() {
    const { setCurrentNavPage } = this.props;
    const { params: { slug } } = this.props.match;
    const params = new URLSearchParams(location.search);
    this.organization = params.get('organization');
    this.client = params.get('client');

    setCurrentNavPage('SURVEY_QUIZ');

    if (slug) {
      this.props.fetchQuiz(slug);
    }
  }

  componentWillUnmount() {
    this.props.initQuiz();
  }

  hasClientAuthorization() {
    const { quiz: { quiz }, auth: { currentClient } } = this.props;
    if (!quiz || !quiz.organization) {
      return false;
    }
    if (currentClient && currentClient.id == quiz.organization.id) {
      return true;
    }

    return false;
  }

  render() {
    const {
      quiz: { quiz, fetched, fetching },
      status,
      auth,
      lng,
      fullScreenEnabled
    } = this.props;
    const { user } = this.props.auth;

    if (fetching) {
      return <SurveyTitlePreviewFetching />;
    }

    if (fetched && auth.fetched) {
      const creatorId = quiz.createdBy ? quiz.createdBy.id : null;
      const userId = user ? user.id : null;
      const isCreator = creatorId === userId && userId;
      const { title } = quiz;
      const hasFullAccess = this.hasClientAuthorization();
      let readOnlyMsg;

      if (!hasFullAccess && !isCreator) {
        return (
          <FullPageMessage title={getTextByLanguage(title, lng)}>
            {_('You have no right to access this survey')}
          </FullPageMessage>
        );
      }

      return (
        <div className="preview preview--withoutScroll srv-background-color--fill survey-preview">
          <Preview
            quiz={quiz}
            status={status}
            scrollBody
            fakeResponse={false}
            organization={this.organization}
            client={this.client}
            readOnly={false}
            readOnlyMsg={readOnlyMsg}
            fullScreenEnabled={fullScreenEnabled}
            handleFullScreen={this.props.handleFullScreen}
          />
        </div>
      );
    }

    return <NotFoundPage />;
  }
}

const mapStateToProps = state => ({
  quiz: state.quiz,
  status: state.surveyResponse.status,
  lng: state.params.lng,
  auth: state.auth,
});

const mapDispatchToProps = {
  setCurrentNavPage,
  fetchQuiz,
  initQuiz,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFullScreen
)(QuizPreview);
