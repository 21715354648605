import React from 'react';
import shortId from 'shortid';
import { Link as RouterLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const Link = ({ isExternal, children, to, ...props }) => (
  isExternal || to == null
    ? <a href={to} {...props}>{children}</a>
    : <RouterLink to={to} {...props}>{children}</RouterLink>
);

export default function MenuItem({
  text,
  textClassName,
  onClick,
  disabled,
  disabledTooltip,
  infoTooltip,
  dataOpen,
  to,
  isOpenInNewTab,
  isExternal,
}) {
  const uid = shortId.generate();
  let params = dataOpen ? { 'data-open': dataOpen } : {};

  if (isOpenInNewTab) {
    params = { ...params, target: '_blank' };
  }

  return (
    <li>
      <Link
        to={to ?? null}
        isExternal={isExternal}
        onClick={!disabled ? onClick : null}
        data-tip
        data-for={`tt-menu-item-${uid}`}
        {...params}
        className={`${textClassName ?? ''} ${disabled ? 'disabled-link' : ''}`}
      >
        {typeof text === 'function' ? text() : text}
      </Link>
      {
        disabled && disabledTooltip && (
          <ReactTooltip id={`tt-menu-item-${uid}`} multiline={true} className="react-tooltip" type={disabledTooltip?.type}>
            <span className="tooltip-content">{disabledTooltip?.text}</span>
          </ReactTooltip>
        )
      }
      {
        !disabled && infoTooltip && (
          <ReactTooltip id={`tt-menu-item-${uid}`} multiline={true} className="react-tooltip" type={infoTooltip?.type}>
            <span className="tooltip-content">{infoTooltip?.text}</span>
          </ReactTooltip>
        )
      }
    </li>
  );
}
