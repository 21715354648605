import * as actions from 'actions';
import { getQuestionScore } from 'TamtamAPI';

export const fetchQuestionScore = (instanceId, dimensionId, organization) => (dispatch, getState) => {
  const { token: userToken, appToken, currentClient } = getState().auth;
  const token = userToken != '' ? userToken : appToken.token;
  const organizationId = organization ?? currentClient?.id ?? 0;
  return dispatch(actions.fetchQuestionScore(
    getQuestionScore(token, instanceId, dimensionId, organizationId).then(res => res.data, err => err),
  ));
};
