import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TTP_LOGO_URL } from 'Common';
import Community from './Community';
import { setCurrentClient } from 'thunks';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import __ from 'lodash';

class SelectCommunity extends PureComponent {
  handleSelectOrganization = client => {
    const { history } = this.props;
    this.props.setCurrentClient(client);
    history.push('/');
  };

  renderOrganizations() {
    const { user } = this.props.auth;
    if (!user) {
      return null;
    }

    const clients = Array.isArray(user.surveyClients)
      ? __.compact(user.surveyClients)
      : [];

    return __.uniqBy(clients, 'id').map(client => (
      <Community
        key={client.id}
        community={client}
        onClick={() => this.handleSelectOrganization(client)}
      />
    ));
  }

  render() {
    return (
      <div id="select-community">
        <div className="sc__container">
          <span className="sc__logo">
            <img src={TTP_LOGO_URL} alt="logo" />
          </span>
          <div className="sc__communities grid-x grid-margin-x">
            {this.renderOrganizations()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  auth: store.auth,
});

export default compose(
  connect(mapStateToProps, { setCurrentClient }),
  withRouter,
)(SelectCommunity);
