import * as actions from 'actions';
import { getRankingOrganizations, getRankingOrganizationsOptions } from 'TamtamAPI';

export const fetchRankingOrganizations = instanceId => (dispatch, getState) => {
  const { token: userToken, appToken } = getState().auth;
  const { pageSize, paginationPage } = getState().rankingOrganizations.filter.filter;
  const { country, city, organizationName } = getState().rankingOrganizations.filter.filter;
  const { sortConfig } = getState().rankingOrganizations.sort;

  const data = {
    pageSize, paginationPage, country, city, organizationName, sortConfig,
  };
  const token = userToken != '' ? userToken : appToken.token;
  return dispatch(actions.fetchRankingOrganizations(
    getRankingOrganizations(token, instanceId, data),
  ));
};

export const fetchRankingOrganizationsOptions = () => (dispatch, getState) => {
  const { token: userToken, appToken } = getState().auth;

  const token = userToken != '' ? userToken : appToken.token;
  return dispatch(actions.fetchRankingOrganizationsOptions(
    getRankingOrganizationsOptions(token).then(res => res.data, err => err),
  ));
};
