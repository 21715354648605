const initialState = {
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  items: [],
  error: null,
  nbResult: 0,
};

// const getUsersItems = (items, newItems) => {
//   newItems = getUsersInfos(newItems);
//   return [items, ...newItems];
// };

/* const getUsersItems = (items, newItems) => {
  newItems = getUsersInfos(newItems);
  return [items, ...newItems];
}; */

const getUsersInfos = users => users.map(user => ({ ...user, title: (`${user.firstName} ${user.lastName}`) }));

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_USERS_SETTINGS_PENDING': {
      return { ...state, fetching: true };
    }
    case 'FETCH_USERS_SETTINGS_FULFILLED': {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: getUsersInfos(data),
        nbResult,
        filter: { ...state.filter },
      };
    }
    case 'FETCH_USERS_SETTINGS_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 'canceled':
          return state;
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }

      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
    }
    case 'SAVE_USERS_PENDING': {
      return { ...state, updated: false, updating: true };
    }
    case 'UPDATE_USERS': {
      return {
        ...state,
        updating: false,
        updated: true,
        items: state.items.map(item => (action.payload.selectedUsers.includes(item.id)
          ? {
            ...item,
            surveyAuthorisation:
                {
                  role: action.payload.role,
                  preferences: {
                    allowCreateSurvey: action.payload.allowCreateSurvey,
                    allowPublishSurvey: action.payload.allowPublishSurvey,
                    allowCloseSurvey: action.payload.allowCloseSurvey,
                    allowAccessResult: action.payload.allowAccessResult,
                  },
                },
          }
          : item)),
      };
    }
    case 'SAVE_USERS_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        updated: false,
        updating: false,
        error,
      };
    }
    case 'INIT_SAVE_USERS': {
      return {
        ...state,
        updating: false,
        updated: false,
      };
    }
    default:
      return state;
  }
};

export default usersReducer;
