import * as actions from 'actions';
import { getStats, getStatsOptions } from 'TamtamAPI';

export const fetchStats = (instanceId, organizationId) => (dispatch, getState) => {
  const { token: userToken, appToken } = getState().auth;

  const {
    filter: { country, city },
    profiling,
    showAll
  } = getState().stats.filter;

  const data = { country, city, profiling, showAll };
  const token = userToken != '' ? userToken : appToken.token;

  return dispatch(actions.fetchStats(
    getStats(token, instanceId, organizationId, data),
  ));
};

export const fetchStatsOptions = () => (dispatch, getState) => {
  const { token: userToken, appToken } = getState().auth;

  const token = userToken != '' ? userToken : appToken.token;
  return dispatch(actions.fetchStatsOptions(
    getStatsOptions(token).then(res => res.data, err => err),
  ));
};
