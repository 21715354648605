import axios from 'axios';
import { TTP_API_URL } from 'Config';
import { throwCatchedError } from 'utils';
import { convertToRaw } from 'draft-js';
import _ from 'lodash';
import { addResourceTypeToQuestions, mapLinkedQuestions, filterQuestion } from 'surveyUtils';

const { CancelToken } = axios;
let cancel;

const isEmptyFilter = filter => (['function', 'object'].indexOf(typeof filter) !== -1 ? _.isEmpty(filter) : (filter == null || filter == ''));

export const getSurveys = (token, slug = null, data = null) => {
  const idUrl = slug != null ? `/${slug}` : '';
  const requestUrl = `${TTP_API_URL}/survey/survey${idUrl}`;
  const filter = [];
  const sort = [];
  const params = { access_token: token };
  const fields = slug
    ? ['*', 'welcomeScreen', 'thankScreen', 'banner', 'organizationSurvey', 'organizationInstance', 'createdBy']
    : ['*', 'welcomeScreen', 'nbResponses', 'latestInstance', 'response', 'organizationInstance', 'createdBy'];

  if (data) {
    const {
      isTemplate,
      isSectorial,
      pageSize,
      paginationPage,
      sortConfig,
      type,
      title,
      languages,
      owner,
      currentClient
    } = data;

    if (!isEmptyFilter(type)) {
      filter.push({
        property: 'type',
        value: type,
        operator: Array.isArray(type) ? 'in' : 'eq',
      });
    }
    if (!isEmptyFilter(owner)) {
      filter.push({
        property: 'createdBy',
        value: owner,
        operator: Array.isArray(owner) ? 'in' : 'eq',
      });
    }

    if (!isEmptyFilter(title)) {
      filter.push({
        property: 'title',
        value: title,
        operator: 'jlike',
      });
    }
    if (!isEmptyFilter(languages)) {
      filter.push({
        property: 'languages',
        value: languages,
        operator: Array.isArray(languages) ? 'rlike' : 'like',
      });
    }

    if (isSectorial != null) {
      filter.push({
        property: 'isSectorial',
        value: isSectorial,
        operator: 'eq',
      });
    }

    if (currentClient != null) {
      filter.push({
        property: 'organization.id',
        value: currentClient,
        operator: 'eq',
      });
    }

    // sort
    if (sortConfig) {
      sort.push({
        property: sortConfig.columnKey,
        dir: sortConfig.sortDirection,
      });
    }

    // pagination
    if (pageSize) {
      params.limit = pageSize;
    }
    if (paginationPage) {
      params.start = ((paginationPage - 1) * pageSize);
    }

    // params
    if (currentClient) {
      params.client = currentClient;
    }
    params.isTemplate = isTemplate ? 1 : 0;
  }

  if (cancel != null) {
    cancel();
  }

  return axios.get(requestUrl, {
    cancelToken: new CancelToken((c) => { cancel = c; }),
    params: {
      ...params,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
      fields: fields.join(','),
    },
  }).then((res) => {
    cancel = null;
    return res;
  }).catch((thrown) => { throwCatchedError(thrown); });
};

const prepareSurveyFilter = (data, filter = [], params = {}, sort = []) => {
  if (data) {
    const {
      isSectorial,
      pageSize,
      paginationPage,
      sortConfig,
      status,
      type,
      title,
    } = data;

    if (type && type !== 'all') {
      filter.push({
        property: 'type',
        value: type,
        operator: 'eq',
      });
    }
    if (status && status !== 'all') {
      filter.push({
        property: 'status',
        value: status,
        operator: 'eq',
      });
    }
    if (title) {
      filter.push({
        property: 'title',
        value: title,
        operator: 'like',
      });
    }

    if (isSectorial != null) {
      filter.push({
        property: 'isSectorial',
        value: isSectorial,
        operator: 'eq',
      });
    }

    // sort
    if (sortConfig) {
      sort.push({
        property: sortConfig.columnKey,
        dir: sortConfig.sortDirection,
      });
    }

    // pagination
    if (pageSize) {
      params.limit = pageSize;
    }
    if (paginationPage) {
      params.start = ((paginationPage - 1) * pageSize);
    }
  }
};

export const getUsedSurveys = (token, data) => {
  const requestUrl = `${TTP_API_URL}/survey/survey/get-used-surveys`;
  const filter = [];
  const sort = [];
  const params = { access_token: token };
  const fields = ['*', 'welcomeScreen'];

  prepareSurveyFilter(data, filter, params, sort);

  const currentClient = data?.currentClient;

  if (currentClient) {
    params.organization_id = currentClient;
  }

  if (cancel != null) {
    cancel();
  }

  return axios.get(requestUrl, {
    cancelToken: new CancelToken((c) => { cancel = c; }),
    params: {
      ...params,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
      fields: fields.join(','),
    },
  }).then((res) => {
    cancel = null;
    return res;
  }).catch((thrown) => { throwCatchedError(thrown); });
};

export const getLibrarySurveys = (token, data) => {
  const requestUrl = `${TTP_API_URL}/survey/survey/get-library-surveys`;
  const filter = [];
  const sort = [];
  const params = { access_token: token };
  const fields = ['*', 'welcomeScreen'];

  prepareSurveyFilter(data, filter, params, sort);

  const currentClient = data?.currentClient;

  if (currentClient) {
    params.organization_id = currentClient;
  }

  if (cancel != null) {
    cancel();
  }

  return axios.get(requestUrl, {
    cancelToken: new CancelToken((c) => { cancel = c; }),
    params: {
      ...params,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
      fields: fields.join(','),
    },
  }).then((res) => {
    cancel = null;
    return res;
  }).catch((thrown) => { throwCatchedError(thrown); });
};

export const getSurveyExtraData = (token, surveyId) => {
  const requestUrl = `${TTP_API_URL}/survey/stats/nbResponses`;

  return axios.get(requestUrl, {
    params: {
      survey: surveyId,
      access_token: token
    },
  });
};

export const fetchUsedSurveysDates = (token, type, date, organizationId) => {
  const requestUrl = `${TTP_API_URL}/survey/survey/get-used-dates`;

  return axios.get(requestUrl, {
    params: {
      type,
      date,
      organizationId,
      access_token: token
    },
  });
};

export const removeSurvey = (token, surveyId) => {
  const requestUrl = `${TTP_API_URL}/survey/survey/${surveyId}`;

  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('_method', 'delete');

  return axios.post(requestUrl, formData);
};

export const closeSurvey = (token, surveyId, clientId) => {
  const requestUrl = `${TTP_API_URL}/survey/survey/${surveyId}/close`;

  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('_method', 'post');
  formData.append('client', clientId);

  return axios.post(requestUrl, formData);
};

export const removeQuestion = (token, questionId) => {
  const requestUrl = `${TTP_API_URL}/survey/question/${questionId}`;

  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('_method', 'delete');

  return axios.post(requestUrl, formData);
};

export const removeDimension = (token, dimensionId) => {
  const requestUrl = `${TTP_API_URL}/survey/dimension/${dimensionId}`;

  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('_method', 'delete');

  return axios.post(requestUrl, formData);
};

export const removeChoice = (token, choiceId) => {
  const requestUrl = `${TTP_API_URL}/survey/choice/${choiceId}`;

  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('_method', 'delete');

  return axios.post(requestUrl, formData);
};

export const saveSurvey = (token, survey, currentClient) => {
  const requestUrl = `${TTP_API_URL}/survey/survey`;

  survey = mapLinkedQuestions(addResourceTypeToQuestions(survey));
  delete survey.createdBy;
  delete survey.createdAt;
  delete survey.updatedAt;
  delete survey.latestInstanceDate;
  delete survey.parent;
  delete survey.organizationSurvey;

  if (survey.theme) {
    delete survey.theme;
  }

  if (_.isEmpty(survey.languages)) {
    delete survey.languages;
  }

  if (survey.title != null) {
    const { title } = survey;
    _.forEach(title, (text, language) => {
      if (text == null || text == '') {
        delete title[language];
      }
    });
  }
  if (survey.id) {
    delete survey.organization;
  } else {
    survey.organization = currentClient;
  }

  if (survey.description != null) {
    const { description } = survey;
    _.forEach(description, (editorState, language) => {
      if (editorState.getCurrentContent().hasText()) {
        const content = editorState.getCurrentContent();
        description[language] = JSON.stringify(convertToRaw(content));
      } else {
        delete description[language];
      }
    });
  }

  if (!survey.description || survey.description?.length === 0) {
    survey.description = false;
  }

  if (survey.welcomeMessage != null) {
    const { welcomeMessage } = survey;
    _.forEach(welcomeMessage, (editorState, language) => {
      if (editorState.getCurrentContent().hasText()) {
        const content = editorState.getCurrentContent();
        welcomeMessage[language] = JSON.stringify(convertToRaw(content));
      } else {
        delete welcomeMessage[language];
      }
    });
  } else {
    survey.welcomeMessage = false;
  }

  // survey.templateState = isTemplate == 0 ? 'OPEN' : survey.templateState;

  if (Array.isArray(survey.dimensions)) {
    const { dimensions } = survey;
    dimensions.forEach((dimension) => {
      if (dimension.title != null) {
        const { title } = dimension;
        _.forEach(title, (text, language) => {
          if (text == null || text == '') {
            delete title[language];
          }
        });
      }
      if (dimension.description != null) {
        const { description } = dimension;
        _.forEach(description, (text, language) => {
          if (text == null || text == '') {
            delete description[language];
          }
        });
      }

      if (!dimension.description || dimension.description?.length === 0) {
        dimension.description = false;
      }

      const { questions } = dimension;
      if (Array.isArray(questions)) {
        questions.forEach((question) => {
          if (survey.type === 'SATISFACTION') {
            question.isNumeric = 1;
          }
          question = filterQuestion(question);
        });
      }
    });
  }

  return axios({
    method: 'post',
    url: requestUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: JSON.stringify({ ...survey, client: currentClient })
  });
};

export const editPenetrationRate = (token, penetrationRate, surveySlug) => {
  const requestUrl = `${TTP_API_URL}/survey/survey/${surveySlug}/penetrationRate`;

  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('penetrationRate', penetrationRate);
  formData.append('_method', 'post');
  return axios.post(requestUrl, formData);
};

export const duplicateSurvey = (token, surveySlug, currentClient, isTemplate) => {
  const requestUrl = `${TTP_API_URL}/survey/survey/${surveySlug}/duplicate`;
  const params = { access_token: token };

  if ([token, surveySlug].includes(undefined)) {
    throw 'Please add the required fields';
  }

  if (currentClient) {
    params.client = currentClient.id || 0;
  }

  if (isTemplate != null) {
    params.isTemplate = isTemplate || 0;
  }

  return axios.get(requestUrl, { params }).catch((thrown) => { throwCatchedError(thrown); });
};

export const createSurveyFromTemplate = (token, surveySlug, currentClient) => {
  const requestUrl = `${TTP_API_URL}/survey/survey/${surveySlug}/duplicate`;
  const params = { access_token: token };

  if (currentClient) {
    params.client = currentClient.id || 0;
  }

  return axios.get(requestUrl, { params }).catch((thrown) => { throwCatchedError(thrown); });
};

export const editInstanceStatus = (token, status, instanceId) => {
  const requestUrl = `${TTP_API_URL}/survey/instance/${instanceId}`;

  if ([token, instanceId, status].includes(undefined)) {
    throw 'Please add the required fields';
  }

  return axios({
    method: 'post',
    url: requestUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: JSON.stringify({
      id: instanceId,
      status
    })
  });
};
