import __ from 'lodash';

/**
 * @returns object : {
 *  key
 *  errors: { title : array }
 * }
 */
export const validateSurvey = ({ id, uid, title }, validationResults) => {
  const key = id || uid;
  const titleErrors = [];
  if (title) {
    __.forEach(title, (value, language) => {
      if (value == null || value == '') {
        delete title[language];
      } else if (value.length < 2) {
        titleErrors.push({ code: 150302, language });
      }
    });
  } else {
    titleErrors.push({ code: 150302 });
  }

  // TODO
  if (titleErrors.length != 0) {
    validationResults.title = { [key]: [titleErrors[0]] };
  }

  return validationResults;
};
