import React, { Component } from 'react';
import {
  fetchParticipantUsers,
  fetchGenericEmailSettingsStats,
  sendTransactionalMails,
} from 'thunks';
import {
  setParticipantUserPaginationPage,
  setParticipantUserPageSize,
  initGenericEmail,
} from 'actions';
import _ from 'i18n';
import { LIST_PAGE_SIZES } from 'Common';
import { hideEmail } from 'utils';
import ParticipantsViewFetching from 'fetching/survey-manager/ParticipantsViewFetching';
import NoDataFound from 'NoDataFound';
import ReactTooltip from 'react-tooltip';
import ParticipantsFilter from './user-participants/ParticipantsFilter';
import ParticipantsStats from './user-participants/ParticipantsStats';
import ParticipantAction from './user-participants/ParticipantAction';
import TTPPaginator from '../common/TTPPaginator';
import controlAccess from './controlAccess';
import NotFoundPage from '../NotFoundPage';

class UserParticipants extends Component {
  componentDidMount() {
    const {
      surveySlug,
      survey: { slug, latestInstance },
      organization,
    } = this.props;
    if (slug && surveySlug == slug) {
      this.props.fetchParticipantUsers(latestInstance ? latestInstance.id : 0, organization);
      /** Fetch Emailing template, inorder to check the ability of sending transactional mails */
      this.props.fetchGenericEmailSettingsStats();
    }

    this.props.initGenericEmail();
  }

  componentDidUpdate(prevProps) {
    const {
      survey: { slug, latestInstance },
      participants: { filter },
      organization,
    } = this.props;
    const prevSurvey = prevProps.survey;

    if (
      prevProps.participants.filter !== filter ||
      (slug != prevSurvey.slug && slug != null)
    ) {
      this.props.fetchParticipantUsers(latestInstance ? latestInstance.id : 0, organization);
      this.props.fetchGenericEmailSettingsStats();
    }
  }

  handleParticipantsPageClick = (data) => {
    const selectedPage = data.selected;
    this.renderParticipantsPage(selectedPage + 1);
  }

  handleSelectParticipantsPageSize = (event) => {
    const pageSize = event.target.value;
    this.renderParticipantsPage(1, pageSize);
  }

  renderParticipantsPage = (paginationPage, pageSize = null) => {
    this.props.setParticipantUserPaginationPage(paginationPage);
    if (pageSize) {
      this.props.setParticipantUserPageSize(pageSize);
    }
  }

  renderPaginate() {
    const { error, nbResult, filter } = this.props.participants;
    if (error != null && error.code == 404) {
      return '';
    }

    return (
      <TTPPaginator
        onPageChange={this.handleParticipantsPageClick}
        pageSize={filter.pageSize}
        nbResult={nbResult}
        paginationPage={filter.paginationPage}
      />
    );
  }

  getIcon = value =>
    value ? (
      <span>
        <i className="icomoon-icon icon-checkmark-full color-success" />
      </span>
    ) : (
      <span>
        <i className="icomoon-icon icon-cross" style={{ fontSize: '13px' }} />
      </span>
    );

  getStatus = status => {
    let className = '';
    switch (status) {
      case 'NOT_ASKED':
        className = 'label secondary';
        break;
      case 'ASKED':
        className = 'label warning';
        break;
      case 'RECEIVED':
        className = 'label success';
        break;
      default:
        className = '';
    }
    return <span className={className}>{_(`STATUS_RESULTS_${status}`)}</span>;
  };

  renderParticipantAction(participant, settings) {
    const surveyResultsGE = settings.find(({ id }) => id === 'SURVEY_RESULTS');
    const surveyReminderGE = settings.find(({ id }) => id === 'SURVEY_REMINDER');
    const surveyPartialReminderGE = settings.find(({ id }) => id === 'SURVEY_PARTIAL_REMINDER');

    const isResultsGEAvailable = surveyResultsGE?.languages?.[participant.language] != null;
    const isRevivalGEAvailable = surveyReminderGE?.languages?.[participant.language] != null;
    const isPartialRevivalGEAvailable = surveyPartialReminderGE?.languages?.[participant.language] != null;

    return (
      <ParticipantAction
        isResultsGEAvailable={isResultsGEAvailable}
        isRevivalGEAvailable={isRevivalGEAvailable}
        isPartialRevivalGEAvailable={isPartialRevivalGEAvailable}
        handleTransactionMail={this.props.sendTransactionalMails}
        participant={participant}
      />
    );
  }

  renderParticipant(participant, genericEmailSettings) {
    const { isAnonymous: isSurveyAnonymous } = this.props.survey;
    const {
      id,
      user,
      invited,
      revival1,
      revival2,
      partialRevival1,
      partialRevival2,
      participated,
      thankReceived,
      resultsStatus,
      isAnonymous: isParticipantAnonymous,
    } = participant;
    const isAnonymous = isParticipantAnonymous || isSurveyAnonymous;

    return (
      <tr key={id}>
        <td className="email greetings text-left">
          <span data-tip data-for={`email-${id}`}>
            {isAnonymous ? hideEmail(user?.mainEmail) : (user?.mainEmail ?? '--')}
          </span>
          {!isAnonymous && user?.mainEmail && (
            <ReactTooltip
              id={`email-${id}`}
              multiline={true}
              className="react-tooltip"
            >
              <span className="tooltip-content">
                {(user && user.mainEmail) || '--'}
              </span>
            </ReactTooltip>
          )}
        </td>
        <td className="text-center">{this.getIcon(invited)}</td>
        <td className="text-center">
          <div>
            <span className="revival">
              {_('revival')}
              <span> 1 :</span>
            </span>
            {this.getIcon(revival1)}
          </div>
          <div>
            <span className="revival">
              {_('revival')}
              <span> 2 :</span>
            </span>
            {this.getIcon(revival2)}
          </div>
        </td>
        <td className="text-center">
          <div>
            <span className="revival">
              {_('revival')}
              <span> 1 :</span>
            </span>
            {this.getIcon(partialRevival1)}
          </div>
          <div>
            <span className="revival">
              {_('revival')}
              <span> 2 :</span>
            </span>
            {this.getIcon(partialRevival2)}
          </div>
        </td>
        <td className="text-center">{this.getIcon(participated)}</td>
        <td className="text-center">{this.getIcon(thankReceived)}</td>
        <td className="text-center">{this.getStatus(resultsStatus)}</td>
        <td>
          {this.renderParticipantAction(participant, genericEmailSettings)}
        </td>
      </tr>
    );
  }

  renderParticipants() {
    const {
      participants: { items, fetching, error },
      genericEmail: { fetching: fetchingTemplates, settings },
    } = this.props;
    let isDataNotFound = false;

    if (fetching || fetchingTemplates) {
      return <ParticipantsViewFetching />;
    }

    if (error != null && error.code == 404) {
      isDataNotFound = true;
    }

    return (
      <div className="medium-12 columns">
        <table className="unstriped hover">
          <thead>
            <tr>
              <th className="text-center email greetings">
                <label>{_('Email')}</label>
              </th>
              <th className="text-center">
                <label>{_('Invited')}</label>
              </th>
              <th className="text-center">
                <label>{_('Revival')}</label>
              </th>
              <th className="text-center">
                <label>{_('PartialRevival')}</label>
              </th>
              <th className="text-center">
                <label>{_('Participated')}</label>
              </th>
              <th className="text-center">
                <label>{_('Thanks')}</label>
              </th>
              <th className="text-center">
                <label>{_('Results')}</label>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {!isDataNotFound &&
              items.map(participant =>
                this.renderParticipant(participant, settings),
              )}
          </tbody>
        </table>
        {isDataNotFound && (
          <NoDataFound
            msg={_('no_survey_data_msg')}
            info={_('no_survey_data_info')}
          />
        )}
      </div>
    );
  }

  render() {
    const { filter } = this.props.participants;
    const { survey, surveySlug, hide, disabled, surveyFetching } = this.props;

    if (!surveyFetching && (hide || disabled)) {
      return <NotFoundPage mainClass="small-10" />;
    }

    return (
      <div className="row wrapper">
        <ParticipantsStats survey={survey} surveySlug={surveySlug} />
        <div className="ranking-list participant-user">
          <div className="row">
            <div className="medium-1 columns">
              <select
                className="filter-select"
                value={filter.pageSize}
                onChange={this.handleSelectParticipantsPageSize}
              >
                {LIST_PAGE_SIZES.map(pagesize => (
                  <option key={`page-size-${pagesize}`} value={pagesize}>
                    {pagesize}
                  </option>
                ))}
              </select>
            </div>
            <div className="medium-6 columns">
              <ParticipantsFilter />
            </div>
          </div>
          {this.renderParticipants()}
          {this.renderPaginate()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  surveyFetching: state.surveys.list.fetching,
  participants: state.participantUser,
  genericEmail: state.genericEmail,
});

const mapDispatchToProps = {
  fetchParticipantUsers,
  setParticipantUserPaginationPage,
  setParticipantUserPageSize,
  initGenericEmail,
  fetchGenericEmailSettingsStats,
  sendTransactionalMails,
};

export default controlAccess(mapStateToProps, mapDispatchToProps)(UserParticipants);
