import React from 'react';
import styles from './SurveyThemes.module.scss';

export default function BackgroundItem({ backgroundUrl, onClick, isSelected }) {
  const style = { backgroundImage: `url(${backgroundUrl})` };;

  return (
    <div
      className={`${styles.survey_themes__item} ${isSelected &&
        styles.selected}`}
      onClick={onClick}
    >
      <div className={styles.wrapper} style={style} />
      <div className={styles.title}>{name}</div>
    </div>
  );
}
