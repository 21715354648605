import React, { Component } from 'react';
import _ from 'i18n';

export default class Controls extends Component {
  componentDidUpdate() {
    const { updated, saveCallback } = this.props;

    if (updated) {
      setTimeout(saveCallback, 1000);
    }
  }

  handleCancel() {
    const { closeDiv, closeDivType, onCancel } = this.props;

    if (typeof onCancel === 'function') {
      onCancel();
    }

    const clDiv = (closeDivType == 'id') ? document.getElementById(closeDiv)
      : document.getElementsByClassName(closeDiv).item(0);
    if (clDiv) {
      clDiv.click();
    }
  }

  handleOk() {
    const { action } = this.props;
    action();
  }

  renderBtnOK() {
    const {
      updating, updated, ok, action, successMessage, progressMessage
    } = this.props;
    if (updating) {
      return (
        <button type="button" className="controls__ok">
          {progressMessage || _('Processing...')}
          <i className="icon icon-refresh" />
        </button>
      );
    }
    if (updated) {
      return (
        <button type="button" className="controls__ok" onClick={() => this.handleCancel()}>
          {successMessage || _('Successfully updated!')}
          <i className="icon icon-like" />
        </button>
      );
    }
    return <button type="button" className="controls__ok" onClick={action}>{_(ok)}</button>;
  }

  render() {
    const { label, controlClass } = this.props;

    return (
      <div id="controls" className={controlClass || ''}>
        <button type="button" className="controls__cancel" onClick={this.handleCancel.bind(this)}>{_('cancel')}</button>
        <div className="controls__label">{label}</div>
        {this.renderBtnOK()}
      </div>
    );
  }
}
