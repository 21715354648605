import React from 'react';

export default function Open({ fill }) {
  return (
    <svg
      width="14"
      height="14"
      fill={fill ?? 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.448 0c0 .7365.597 1.3335 1.3335 1.3335h3.722L4.781 8.0555c.586.586 1.5355.586 2.1215 0l5.764-5.764V5.886c0 .7365.597 1.3335 1.3335 1.3335V0H6.448z"></path>
      <path d="M9 11.288c0 .6655-.5415 1.207-1.207 1.207H2.707c-.6655 0-1.207-.5415-1.207-1.207V6.202c0-.6655.5415-1.207 1.207-1.207h2.4245l1.5-1.5H2.707C1.212 3.495 0 4.707 0 6.202v5.086c0 1.495 1.212 2.707 2.707 2.707h5.086c1.495 0 2.707-1.212 2.707-2.707V7.405L9 8.905v2.383z"></path>
    </svg>
  );
}
