import React from 'react';

export const TTPSelector = ({ cssClass, icon, tabName, tabLabel, tabDescription, onClick }) => (
  <div className={`ttp-selector ${cssClass || ''}`} onClick={onClick}>
    <div className="icon">
      <img alt="" src={`/img/icons/survey-types/${icon}.svg`} />
    </div>
    <div className="labels">
      <h5>{tabName}</h5>
      <h3>{tabLabel}</h3>
      <p>
        {tabDescription}
      </p>
    </div>
    <div className="ellipse" />
  </div>
);
