import React, { PureComponent } from "react";
import { TTP_EMAILING_URL } from "Config";

export default class GenericEmailing extends PureComponent {
  render() {
    const { survey, currentClient } = this.props;
    const url = `${TTP_EMAILING_URL}/email?app=survey&target=${survey.id}&communityId=${currentClient?.id ?? 0}`;
    // const url = `http://uat2.emailing.tamtam.pro/email?app=survey&target=${survey.id}&communityId=${currentClient?.id ?? 0}`;

    return (
      <div className="s-toolbar s-toolbar__emtmp">
        <iframe id="external-url" title="Emailing generic" src={url} />
      </div>
    );
  }
}