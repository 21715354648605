import React, { Component } from 'react';
import _ from 'i18n';
import TTButton from 'common/TTButton';

export default class QuizPinPageFetching extends Component {
  render() {
    return (
      <div className="quiz-pin flex-1">
        <div className="game-pin row flex-dir-column">
          <h1>{_('Join with the Event Mobile app')}</h1>
          <h4>
            {_('with Game PIN')}
            :
          </h4>
          <div><h1 className="pin">??????</h1></div>
        </div>
        <div className="quiz-pin__actions row">
          <div className="total-players">
            <span className="total">?</span>
            <span className="text-label">{_('Players')}</span>
          </div>
          <div className="action">
            <TTButton
              text={_('Start')}
              className="p-x-xxl"
              onClick={this.props.startSurvey}
              fontSize="20px"
              color="blue"
              upperCase={true}
              disabled={false}
            />
          </div>
        </div>
        <div className="quiz-pin__players small-up-4 row">
          {
            Array.from(Array(10).keys()).map(key => (
              <div key={key} className="column"><h3 className="gradiant" style={{ height: '20px' }} /></div>
            ))
          }
        </div>
      </div>
    );
  }
}
