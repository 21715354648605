import React from 'react';
import { defaultTheme } from 'themes';
import { hexToRgb } from '../../services/themes';

const PreviewStyles = (props) => {
  const css = `
    .srv-primary-color {
      color: ${props.theme.colors.question};
    }
    .srv-primary-color--fill{
      background-color: ${props.theme.colors.question};
    }
    .srv-secondary-color {
      color: ${props.theme.colors.answer};
    }
    .srv-secondary-color::placeholder {
      color: rgba(${hexToRgb(props.theme.colors.answer)}, 0.4);
    }
    .srv-secondary-color--fill{
      background-color: rgba(${hexToRgb(props.theme.colors.answer)}, 0.1);
    }
    .srv-secondary-color--fill:hover{
      background-color: rgba(${hexToRgb(props.theme.colors.answer)}, 0.3);
    }
    .srv-secondary-color--fill.is-selected{
      background-color: rgba(${hexToRgb(props.theme.colors.answer)}, 0.2);
    }
    .srv-secondary-color--fill.is-selected.no-background-opacity{
      background-color: ${props.theme.colors.answer};
      color: white;
    }
    .srv-secondary-color--fill--darker{
      background-color: rgba(${hexToRgb(props.theme.colors.answer)}, 0.2);
    }
    .srv-secondary-color--fill--darker.is-selected{
      background-color: ${props.theme.colors.answer};
      color: white;
    }
    .srv-secondary-color.is-hover {
      color: rgba(${hexToRgb(props.theme.colors.answer)}, 0.7);
    }
    .srv-secondary-border{
      border: 1px solid rgba(${hexToRgb(props.theme.colors.answer)}, 0.25);
    }
    .srv-secondary-border-color--medium-opacity{
      border-color: rgba(${hexToRgb(props.theme.colors.answer)}, 0.5);
    }
    .srv-secondary-border.no-border-opacity{
      border-color: ${props.theme.colors.answer};
    }
    .srv-secondary-border.is-selected{
      border: 1px solid rgba(255, 255, 255, 0.25);
    }
    .srv-title-color {
      color: ${props.theme.colors.dimension};
    }
    .srv-title-color--fill{
      background-color: ${props.theme.colors.dimension};
    }
    .srv-button-color {
      color: ${props.theme.colors.button};
    }
    .srv-button-color--fill {
      background-color: ${props.theme.colors.button};
    }
    .srv-button-color--fill:hover {
      background: rgba(${hexToRgb(props.theme.colors.button)}, 0.7);
    }
    .srv-button-color--fill:disabled {
      background: rgba(${hexToRgb(props.theme.colors.button)}, 0.5);
    }
    .srv-background-color {
      color: ${props.theme.colors.background};
    }
    .srv-background-color--fill {
      background-color: ${props.theme.colors.background};
    }
    }`;

  return (
    <style>{css}</style>
  );
};

PreviewStyles.defaultProps = {
  theme: defaultTheme
};

export default PreviewStyles;
