import axios from 'axios';
import { TTP_API_URL, TTP_MERCURE_URL, TTP_MERCURE_API_URL, MERCURE_PUBLISHER_JWT_SECRET } from 'Config';
import { throwCatchedError } from 'utils';

export const createInstance = (token, surveyId, currentClient) => axios({
  method: 'post',
  url: `${TTP_API_URL}/survey/instance/start`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  },
  data: JSON.stringify({ survey: surveyId, organization: currentClient })
});

export const closeInstance = (token, instanceId) => axios({
  method: 'post',
  url: `${TTP_API_URL}/survey/instance/close`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  },
  data: JSON.stringify({ instance: instanceId })
});

export const closeInstanceBeacon = (token, instanceId) => {
  const fd = new FormData();
  fd.append('access_token', token);
  fd.append('instance', instanceId);

  return navigator.sendBeacon(`${TTP_API_URL}/survey/instance/close`, fd);
};

export const setInstanceCurrentQuestion = (token, instanceId, questionId) => axios({
  method: 'post',
  url: `${TTP_API_URL}/survey/instance/current-question/${questionId}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  },
  data: JSON.stringify({ instanceId })
});

export const fetchQuestionStats = (token, instanceId, questionId) => axios
  .get(
    `${TTP_API_URL}/survey/result/question/stats/${questionId}`,
    {
      params: {
        instanceId,
        access_token: token
      },
    }
  );

export const fetchTopTen = (token, instanceId) => axios
  .get(
    `${TTP_API_URL}/survey/instance/quiz-rank/${instanceId}`,
    {
      params: {
        access_token: token
      },
    }
  );

export const fetchInstances = (token, surveyId) => axios
  .get(
    `${TTP_API_URL}/survey/instance`,
    {
      params: {
        access_token: token,
        filter: JSON.stringify([
          {
            property: 'survey',
            value: surveyId,
            operator: 'eq',
          }
        ]),
        sort: JSON.stringify([
          {
            property: 'createdAt',
            dir: 'DESC'
          }
        ]),
        nolimit: 1
      },
    }
  );

export const fetchQuizParticipants = (token, instanceId) => {
  const requestUrl = `${TTP_API_URL}/survey/response`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify([
        {
          property: 'instance.id',
          operator: 'eq',
          value: instanceId
        }
      ]),
      sort: JSON.stringify([
        {
          property: 'score',
          dir: 'DESC'
        }
      ]),
      fields: ['id', 'user', 'score'].join(',')
    },
  }).catch((thrown) => { throwCatchedError(thrown); });
};

export const calculateQuizResponseScore = (token, instance) => axios({
  method: 'post',
  url: `${TTP_API_URL}/survey/response/quiz/calculate-score`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  },
  data: JSON.stringify({ instance })
});

// Mercure

const getCurrentQuestionTopic = instancePin => `${TTP_MERCURE_API_URL}/survey/instance/${instancePin}/current-question`;

const pushMercureData = (topic, data) => {
  const params = new URLSearchParams();

  params.append('topic', topic);
  params.append('target', 'tamtam.pro');
  params.append('data', data);

  return axios({
    method: 'post',
    url: `${TTP_MERCURE_URL}`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${MERCURE_PUBLISHER_JWT_SECRET}`
    },
    data: params
  });
};

/**
 * we sent this event, to force initialize the lastEventId for newcommers
 * [in order to fetch lost events if the connection was closed before the first question is started]
 */
export const keepAlive = (instancePin) => {
  const topic = getCurrentQuestionTopic(instancePin);
  const data = JSON.stringify({ eventType: 'KEEP_ALIVE' });

  return pushMercureData(topic, data);
};

export const closeCurrentQuestion = (instancePin) => {
  const topic = getCurrentQuestionTopic(instancePin);
  const data = JSON.stringify({ eventType: 'QUESTION_TIME_OUT' });

  return pushMercureData(topic, data);
};

export const startPreviewQuestion = (instancePin, questionOrder, nbQuestions) => {
  const topic = getCurrentQuestionTopic(instancePin);
  const data = JSON.stringify({
    eventType: 'QUESTION_PENDING',
    questionOrder,
    nbQuestions
  });

  return pushMercureData(topic, data);
};
