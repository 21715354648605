import React from 'react';
import { Themes } from 'SurveyConfig';
import styles from './SurveyThemes.module.scss';
import ThemeItem from './ThemeItem';

export default function SurveyThemes({ handleThemeSelected, surveyTheme }) {
  return (
    <div className={styles.survey_themes}>
      <div className="flex-container small-up-2">
        {Themes.map((theme, index) => (
          <div className="column column-block">
            <ThemeItem
              key={theme.name + index}
              theme={theme}
              onClick={() => handleThemeSelected(theme)}
              isSelected={theme.name === surveyTheme?.name}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
