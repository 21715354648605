const initialState = {
  status: 'COMPLETED',
  sortDirection: 'desc',
  pageSize: 10,
  paginationPage: 1,
  sortConfig: {
    columnKey: 'score',
    sortDirection: 'desc',
  },
};

export const responseFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIALIZE_RESPONSES_FILTER': {
      return initialState;
    }
    case 'SET_RESPONSES_FILTER_PAGINATION_PAGE': {
      return { ...state, ...state.filter, paginationPage: action.pageNumber };
    }
    case 'SET_RESPONSES_FILTER_PAGE_SIZE': {
      return { ...state, ...state.filter, pageSize: action.pageSize };
    }
    case 'SET_RESPONSES_FILTER_SORT_CONFIG': {
      return { ...state, sortConfig: action.config };
    }
    default: return state;
  }
};
