import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  removeQuestion,
  setCurrentQuestion,
  updateQuestion,
  addQuestion,
  addValidationErrors,
  removeValidationErrors,
  setSettingsCurrentTab
} from 'actions';
import { QUESTION, SURVEY_BUILDER_TABS } from 'constants';
import { deleteQuestion } from 'thunks';
import { bindActionCreators } from 'redux';
import { NotificationManager } from 'react-notifications';
import _ from 'i18n';
import HeaderLabel from 'HeaderLabel';
import { getOptions } from 'SurveyConfig';
import { getTextByLanguage, generateQuestion } from 'surveyUtils';
import Modal from 'survey/Modal';

export class QuestionHeader extends Component {
  constructor(props) {
    super(props);

    this.onFocus = this.onFocus.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);

    this.state = { showDeletePopup: false };
    this.hideDeletePopup = () => this.setState({ showDeletePopup: false });
  }

  onSuccess(res) {
    if (res.value instanceof Error) {
      this.onFailure();
    } else {
      NotificationManager.success(_('delete_question_success'), _('Delete'));
    }
  }

  onFailure() {
    NotificationManager.error(_('errorOccured'), _('error'));
  }

  onFocus() {
    const { currentTab, orderDimension, order } = this.props;

    this.props.setCurrentQuestion(orderDimension || 0, order || 0);
    const { SURVEY_SETTINGS, SETTINGS } = SURVEY_BUILDER_TABS;

    if (currentTab === SURVEY_SETTINGS) {
      this.props.setSettingsCurrentTab(SETTINGS);
    }
  }

  onUpdate = (text) => {
    const {
      question,
      language,
      updateQuestion,
      lng
    } = this.props;

    if (text == '' && language == lng) {
      const { text } = question;
      delete text[language];
      updateQuestion({ ...question, text });
      return;
    }
    updateQuestion({ ...question, text: { ...question.text, [language]: text } });
  }

  /**
   * Old method used to directly update text in preview component
   * instead of updating the store
   */
  // onTextChange = (text) => {
  //   const { question } = this.props;
  //   const id = question.id || question.uid;
  //   const label = document.querySelector(`#question-label-${id}`);
  //   if (label) {
  //     label.innerHTML = text;
  //   }
  // }

  handleDeleteQuestion = () => {
    if (!this.props.isLocked) {
      this.setState({ showDeletePopup: true });
    }
  }

  deleteQuestion = () => {
    const { question, removeQuestion, deleteQuestion } = this.props;

    if (question.id) {
      deleteQuestion(question.id).then(
        res => this.onSuccess(res), err => this.onFailure(err)
      );
    } else {
      try {
        removeQuestion();
      } catch (e) {
        this.onFailure();
      }
    }
  };

  addNewQuestion = () => {
    const { question: { type, profilingType }, surveyType } = this.props;
    if (surveyType == 'PORTAL' || profilingType?.length > 0) {
      return;
    }
    this.props.addQuestion(generateQuestion(type, surveyType));
  }

  render() {
    const message = _('delete_question_message');
    const {
      orderLabel,
      question,
      language,
      hasFocus,
      focusOnMount,
      lng,
      surveyType,
      disabled,
    } = this.props;
    const errors = this.props.errors[question.id || question.uid];
    const showOrder = question.parent == null;
    const value = getTextByLanguage(question.text, language, true);
    const helpText = getTextByLanguage(question.text, lng, true);
    const { showDeletePopup } = this.state;

    return (
      <div>
        <HeaderLabel
          options={getOptions(question.type)}
          onKeyDown={this.handleDeleteQuestion}
          onEnter={this.addNewQuestion}
          value={value}
          order={orderLabel}
          hasFocus={hasFocus}
          required={question.required == 1}
          onFocus={this.onFocus}
          onTextChange={this.onUpdate}
          validationParams={
            {
              resourceType: QUESTION,
              key: question.id || question.uid,
              property: 'text'
            }
          }
          helpText={helpText}
          isNps={question.isNps == '1'}
          errors={errors}
          showOrder={showOrder}
          focusOnMount={focusOnMount}
          classNames={{ background: 'editor-block__filter--child' }}
          maxChars={['PORTAL', 'QUIZ'].indexOf(surveyType) !== -1 ? 190 : null}
          disabled={disabled}
        />
        {
          showDeletePopup && (
            <Modal
              labels={{ action: _('Delete') }}
              isOpen={showDeletePopup}
              action={this.deleteQuestion}
              onCloseModal={this.hideDeletePopup}
              title={_('Delete question')}
              classNames={{ action: 'btn-popup--alert' }}
            >
              <h5>{message}</h5>
            </Modal>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.surveys.list.language,
  errors: state.surveys.list.validationErrors.questions.text,
  lng: state.params.lng,
  surveyType: state.surveys.list.survey.type,
  currentTab: state.surveys.list.toolbarTab,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    removeQuestion,
    setCurrentQuestion,
    updateQuestion,
    deleteQuestion,
    addValidationErrors,
    removeValidationErrors,
    addQuestion,
    setSettingsCurrentTab
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionHeader);
