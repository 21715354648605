import NavPages from 'NavPages';
import { HORIZONTAL, LIST } from 'constants';

// Language reducer
export const languageReducer = (state = null, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE': {
      return action.language;
    }
    default:
      return state;
  }
};

// SubMenuDirection reducer
export const subMenuDirectionReducer = (state = HORIZONTAL, action) => {
  switch (action.type) {
    case 'SET_SUB_MENU_DIRECTION': {
      return action.subMenuDirection;
    }
    default:
      return state;
  }
};

const initialStateListView = {
  surveys: LIST
};

export const listViewReducer = (state = initialStateListView, action) => {
  switch (action.type) {
    case 'SET_SURVEY_LIST_VIEW': {
      return { ...state, surveys: action.view };
    }
    default:
      return state;
  }
};

// Current Navigation Page Reducer
export const currentNavPageReducer = (state = NavPages.HOME_PAGE, action) => {
  switch (action.type) {
    case 'SET_CURRENT_NAV_PAGE': {
      return action.currentNavPage;
    }
    default:
      return state;
  }
};

// SourceToken Reducers
export const sourcesTokensReducer = (state = [], action) => {
  switch (action.type) {
    case 'PUSH_SOURCE_TOKEN': {
      const newItem = { sourceName: action.sourceName, sourceToken: action.sourceToken };
      const filteredItem = state.filter(
        item => item.sourceName === action.sourceName,
      );
      const mappedItems = state.map(
        item => (item.sourceName === action.sourceName ? newItem : item),
      );
      const tab = [...state, newItem];
      return filteredItem.length > 0 ? mappedItems : tab;
    }
    case 'CLEAR_SOURCES_TOKENS': {
      return [];
    }
    default:
      return state;
  }
};

const initialStateForTTPDialogReducer = {
  showDialog: false,
  customData: [],
};
export const dialogReducer = (state = initialStateForTTPDialogReducer, action) => {
  switch (action.type) {
    case 'SET_TTP_DIALOG_SHOW': {
      return { ...state, showDialog: action.showDialog };
    }
    case 'SET_TTP_DIALOG_CUSTOM_DATA': {
      return { ...state, customData: action.customData, showDialog: true };
    }
    default:
      return state;
  }
};
