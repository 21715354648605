import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addSurveyOptions,
  addMetaOption,
  addSurveyLanguage,
  removeSurveyLanguage,
  changeLanguage,
} from 'actions';
import _ from 'i18n';
import { getSurveySettings } from 'SurveyConfig';
import moment from 'moment';
import { CS_SCOPES } from 'Common';
import Boolean from './settings-items/Boolean';
import Steps from './settings-items/Steps';
// import WelcomeScreen from './upload/WelcomeScreen';
// import Banner from './upload/Banner';
import Languages from './settings-items/Languages';
import DateRange from './settings-items/DateRange';
import Select from './settings-items/Select';
import InstanceStatus from './settings-items/InstanceStatus';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.addOption = this.addOption.bind(this);
  }

  addOption = (attributeName, value) => {
    const { addSurveyOptions } = this.props;

    const options = {
      [attributeName]: value,
    };

    addSurveyOptions(options);
  };

  addTemplateOption = (attributeName, value) => {
    if (value == 0) {
      this.addOption('isSectorial', 0);
      this.addOption('isPublic', 0);
      this.addOption('isFixed', 0);
    }
    this.addOption(attributeName, value);
  };

  addMetaOption = (property, value) => {
    this.props.addMetaOption(property, value);
  };

  addLanguage = language => {
    this.props.addSurveyLanguage(language);
  };

  removeLanguage = language => {
    if (this.props.language == language) {
      const { languages } = this.props.surveys.survey;
      const currentValues = ((languages && languages.split(',')) || []).filter(
        lng => lng != language,
      );

      if (currentValues.length > 0) {
        this.props.changeLanguage(currentValues[0]);
      }
    }

    this.props.removeSurveyLanguage(language);
  };

  renderSettings() {
    const {
      surveys: { usedDates, survey },
      loggedAs,
      currentClient,
    } = this.props;

    const {
      id,
      steps,
      type,
      meta,
      isFixed,
      isPublic,
      isPublicStats,
      allowEditResponse,
      isTemplate,
      isSectorial,
      // nbParticipants,
      languages,
      scope,
      csScope,
      isAnonymous,
      allowThirds,
      addThirdsToCommunity,
      startedAt,
      endedAt,
    } = survey;

    const settings = getSurveySettings(type);

    return settings.map(attribute => {
      // const isSectorialDisabled = !(nbParticipants == null || nbParticipants == 0);
      const isSectorialDisabled = false;
      const isSectorialMsgDisabled = _(
        "L'enquête possède des participants, vous ne pouvez plus modifier cette option",
      );

      switch (attribute) {
        case 'steps': {
          return (
            <Steps
              attribute={attribute}
              label={_('Steps')}
              value={steps || 5}
              key={attribute}
              addOption={this.addOption}
            />
          );
        }
        case 'isTemplate':
          return (
            <Boolean
              attribute={attribute}
              disabled={id != null}
              label={_('isTemplate')}
              value={isTemplate == 1}
              key={attribute}
              addOption={this.addTemplateOption}
            />
          );
        case 'isSectorial':
          return loggedAs == 'CHIEF_EDITOR' ? (
            <Boolean
              attribute={attribute}
              disabled={isSectorialDisabled}
              msgDisabled={isSectorialMsgDisabled}
              label={_('isSectorial')}
              value={isSectorial == 1}
              key={attribute}
              addOption={this.addOption}
            />
          ) : null;
        case 'meta': {
          const metaData = [
            <Boolean
              attribute="hideProfiling"
              label={_('Hide profiling if known')}
              value={meta?.hideProfiling ?? 0}
              key={attribute}
              addOption={this.addMetaOption}
            />,
          ];

          // if (isSectorial && type == 'SATISFACTION') {
          //   const value = meta ? meta.obligatoryRanking : 0;
          //   metaData.push(<Boolean attribute="obligatoryRanking" label={_('obligatoryRanking')} value={value} key={attribute} addOption={this.addMetaOption} />);
          // }
          // if (isSectorial && type == 'OPINION') {
          //   const value = meta ? meta.consolidatedResults : 0;
          //   metaData.push(<Boolean attribute="consolidatedResults" value={value} label={_('consolidatedResults')} key={attribute} addOption={this.addMetaOption} />);
          // }

          return metaData;
        }
        case 'isFixed': {
          return !isSectorial ? (
            <Boolean
              attribute={attribute}
              label={_('isFixed')}
              value={isFixed == 1}
              key={attribute}
              addOption={this.addOption}
            />
          ) : null;
        }
        case 'isPublic': {
          return isTemplate && loggedAs == 'CHIEF_EDITOR' ? (
            <Boolean
              attribute={attribute}
              label={_('public')}
              value={isPublic == 1}
              key={attribute}
              addOption={this.addOption}
            />
          ) : null;
        }
        case 'isPublicStats': {
          return loggedAs == 'CHIEF_EDITOR' ? (
            <Boolean
              attribute={attribute}
              label={_('Public stats')}
              value={isPublicStats == 1}
              key={attribute}
              addOption={this.addOption}
            />
          ) : null;
        }
        case 'isAnonymous': {
          return (
            <Boolean
              attribute={attribute}
              label={_('Anonymous survey')}
              value={isAnonymous == 1}
              key={attribute}
              addOption={this.addOption}
            />
          );
        }
        case 'allowThirds': {
          return (
            <Boolean
              attribute={attribute}
              label={_('Participation of non-members')}
              value={allowThirds == 1}
              key={attribute}
              addOption={this.addOption}
            />
          );
        }
        case 'addThirdsToCommunity': {
          return (
            <Boolean
              attribute={attribute}
              label={_('Add the non-member to community')}
              value={addThirdsToCommunity == 1}
              key={attribute}
              addOption={this.addOption}
            />
          );
        }
        case 'allowEditResponse': {
          return !isTemplate || isSectorial ? (
            <Boolean
              attribute={attribute}
              label={_('editableResponse')}
              value={allowEditResponse == 1}
              key={attribute}
              addOption={this.addOption}
            />
          ) : null;
        }
        case 'languages': {
          return (
            <Languages
              label={_('Languages')}
              value={languages}
              key={attribute}
              addLanguage={this.addLanguage}
              removeLanguage={this.removeLanguage}
            />
          );
        }
        case 'scope': {
          let scopes = [];

          if (type == 'SATISFACTION') {
            scopes = ['COLLABORATORS', 'CLIENTS'];
          } else {
            scopes = ['ALL', 'COLLABORATORS', 'CLIENTS'];
          }

          const options = scopes.map(value => ({
            value,
            label: _(value),
          }));

          return (
            <Select
              label={_('Targets')}
              attribute={attribute}
              value={scope}
              key={attribute}
              options={options}
              addOption={this.addOption}
            />
          );
        }
        case 'status': {
          if (survey.id == null) {
            return null;
          }

          return (
            <InstanceStatus
              survey={survey}
              value={survey.organizationInstance?.status ?? 'PENDING'}
            />
          );
        }
        case 'csScope': {
          const {
            cs_app_scope,
            cs_default_scope,
          } = currentClient?.surveyPreferences ?? {
            cs_app_scope: 'INTRA_SHARE',
            cs_default_scope: 'INTRA_SHARE',
          };

          /** Filtrer les scopes <= cs_app_scope */
          const options = CS_SCOPES.slice(CS_SCOPES.indexOf(cs_app_scope)).map(
            value => ({
              value,
              label: _(value),
            }),
          );

          return (
            <Select
              label={_('Scope')}
              attribute={attribute}
              value={csScope ?? cs_default_scope}
              key={attribute}
              options={options}
              addOption={this.addOption}
            />
          );
        }
        case 'dateRange': {
          return (
            <DateRange
              startDate={(startedAt && moment(startedAt)) || moment()}
              endDate={(endedAt && moment(endedAt)) || moment()}
              addOption={this.addOption}
              blockedDates={usedDates}
            />
          );
        }
        default:
          return null;
      }
    });
  }

  render() {
    return (
      <div className="s-toolbar">
        <div className="s-toolbar__title separator">
          <div className="s-toolbar__title__label survey-font survey-font--upperCase">
            {_('Settings')}
          </div>
          <div
            className="s-toolbar__title__icon"
            onClick={this.props.closeSlider}
          >
            <span>
              <i className="icomoon-icon icon-cross" />
            </span>
          </div>
        </div>
        <div className="s-toolbar__settings settings">
          {this.renderSettings()}
          {/* <WelcomeScreen survey={survey} />
          <Banner survey={survey} /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedAs: state.auth.loggedAs,
  currentClient: state.auth.currentClient,
  surveys: state.surveys.list,
  language: state.surveys.list.language,
});

const mapDispatchToProps = {
  addSurveyOptions,
  addMetaOption,
  addSurveyLanguage,
  removeSurveyLanguage,
  changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
