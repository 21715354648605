import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { TTP_SURVEY_URL, TTP_API_URL } from 'Config';
import _ from 'i18n';
import {
  getTextByLanguage,
  getMediaByLanguage,
  getColorByStatus,
  getSurveyStatsLink
} from 'surveyUtils';
import { getAuthorizations } from 'authorizations';
import moment from 'moment';
import SurveyActions from '../SurveyActions';

class SurveyItem extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    // const num = Math.floor(Math.random() * 9);
    // this.background = `#2c2e26 url('/img/survey/temp/srv-bg-${num < 9 ? `0${num}` : num}.jpeg')`;
    this.background = 'url("/img/pictos/analysis.svg") center no-repeat';
    this.wrapperRef = React.createRef();
  }

  handleClick = () => {
    const {
      survey,
      auth: { user, currentClient, loggedAs },
      isTemplate,
      history,
    } = this.props;
    const { edit } = getAuthorizations(
      survey,
      user,
      currentClient,
      loggedAs,
      isTemplate,
    );
    if (!edit.disable) {
      history.push(`/surveys/${survey.slug}/create?source=list`);
    }
  };

  renderActions() {
    const {
      survey,
      lng,
      auth: { detached, loggedAs, currentClient },
      isTemplate,
      openSurveyModal,
    } = this.props;
    const {
      nbResponses,
      slug,
      isPublicStats,
      response,
      allowEditResponse,
    } = survey;
    if (detached && !isTemplate) {
      const statsAuthorisation = isPublicStats;
      const responseAuthorizations = !(
        response?.status == 'COMPLETED' &&
        !allowEditResponse
      );
      const stats = statsAuthorisation
        ? getSurveyStatsLink(survey, currentClient?.id ?? 0, lng, false)
        : null;
      const participate = responseAuthorizations
        ? `${TTP_SURVEY_URL}/survey/${slug}`
        : null;
      const labelResponse = ['COMPLETED', 'INCOMPLETE'].includes(response?.status)
        ? responseAuthorizations
          ? 'Edit answer'
          : 'Already answered'
        : 'Participate'; // eslint-disable-line no-nested-ternary

      return (
        <>
          <a
            href={stats}
            className={`button btn-popup btn-popup--default ${
              !statsAuthorisation ? 'disabled-link' : ''
            }`}
            type="button"
          >
            {_('Stats')}
          </a>
          <a
            href={participate}
            className={`button btn-popup btn-popup--main no-margin--left ${
              !responseAuthorizations ? 'disabled-link' : ''
            }`}
            type="button"
          >
            {_(labelResponse)}
          </a>
        </>
      );
    }

    return (
      <>
        <SurveyActions
          survey={survey}
          isTemplate={isTemplate}
          editSurvey={this.handleClick}
          loggedAs={loggedAs}
          lng={lng}
          clickable={true}
          openSurveyModal={openSurveyModal}
          opensType="click"
        />
        {nbResponses ? (
          <div className="srv-card__label">
            {nbResponses} {nbResponses == 1 ? _('response') : _('responses')}
          </div>
        ) : null}
      </>
    );
  }

  getStatus = () => {
    const {
      type,
      startedAt,
      endedAt,
      organizationInstance,
    } = this.props.survey;
    const status = organizationInstance?.status ?? 'PENDING';
    const now = moment();
    let statusPortal = 'PENDING';

    switch (type) {
      case 'PORTAL':
        if (now.isBetween(moment(startedAt), moment(endedAt), null, [])) {
          statusPortal = 'STARTED';
        } else if (now.isAfter(endedAt)) {
          statusPortal = 'ENDED';
        }

        return statusPortal;
      case 'QUIZ':
        return null;
      default:
        return status;
    }
  };

  renderLabel() {
    const { survey, auth } = this.props;

    if (survey.isSectorial) {
      return (
        <div>
          <span className="status greetings">{_('Consolidated survey')}</span>
        </div>
      );
    }

    if (
      survey.organization &&
      survey.organization?.id != auth.currentClient?.id
    ) {
      return (
        <div>
          <span className="status greetings">{_('Shared survey')}</span>
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      survey,
      auth: { detached },
      lng,
    } = this.props;
    const { title, welcomeScreen, type, isAnonymous } = survey;
    const status = this.getStatus();
    const colorStatus = getColorByStatus(status);
    const imageBackground = getMediaByLanguage(welcomeScreen, lng);
    const background =
      (imageBackground &&
        `url('${`${TTP_API_URL}/${imageBackground}`}') center center / cover`) ||
      this.background;
    const style = {
      background,
      backgroundRepeat: 'no-repeat',
    };

    return (
      <div className="column column-block">
        <div className="srv-card" ref={this.wrapperRef}>
          <div className="status-wrapper">
            <div>
              <span className="status greetings">{_(type)}</span>
            </div>
            {!detached && status && (
              <div>
                <span className={`status greetings ${colorStatus}`}>
                  {_(status)}
                </span>
              </div>
            )}
            {this.renderLabel()}
            {isAnonymous && (
              <div>
                <span className="status greetings">
                  {_('Anonymous survey')}
                </span>
              </div>
            )}
          </div>
          <div
            className="srv-card__container"
            style={style}
            onClick={this.handleClick}
          >
            <div className="srv-card__title">
              <h5 className="srv__font-bold">
                {getTextByLanguage(title, lng)}
              </h5>
            </div>
          </div>
          <div className="srv-card__info flex-container flex-dir-row-reverse">
            {this.renderActions()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lng: state.params.lng,
  auth: state.auth,
});

export default compose(connect(mapStateToProps), withRouter)(SurveyItem);
