import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { fetchRankingOrganizations } from 'thunks';
import { setRankingOrganizationsPaginationPage, setRankingOrganizationsPageSize, initializeRankingOrganization } from 'actions';
import { LIST_PAGE_SIZES } from 'Common';
import NotFoundPage from 'NotFoundPage';
import RankingOrganizationsFilter from './ranking-organizations/RankingOrganizationsFilter';
import RankingOrganizationsList from './ranking-organizations/RankingOrganizationsList';
import controlAccess from './controlAccess';
import TTPPaginator from '../common/TTPPaginator';

class RankingListPage extends Component {
  constructor(props) {
    super(props);
    this.surveyId = this.props.surveyId;
    this.handleRankingListPageClick = this.handleRankingListPageClick.bind(this);
    this.renderRankingPage = this.renderRankingPage.bind(this);
  }

  componentDidMount() {
    const { surveySlug, survey: { slug, latestInstance } } = this.props;

    if (slug && surveySlug == slug) {
      // hack to fix pagination.
      this.props.initializeRankingOrganization();
      this.props.fetchRankingOrganizations(latestInstance ? latestInstance.id : 0);
    }
  }

  componentDidUpdate(prevProps, prevState) { // eslint-disable-line no-unused-vars
    const { slug, latestInstance } = this.props.survey;
    const prevSurvey = prevProps.survey;

    if (
      prevProps.filter !== this.props.filter
      || (slug != prevSurvey.slug && slug != null)
      || prevProps.rankingSort !== this.props.rankingSort
    ) {
      this.props.fetchRankingOrganizations(latestInstance ? latestInstance.id : 0);
    }
  }

  handleRankingListPageClick(data) {
    const selectedPage = data.selected;
    this.renderRankingPage(selectedPage + 1);
  }

  handleSelectRankingListPageSize(event) {
    const pageSize = event.target.value;
    this.renderRankingPage(1, pageSize);
  }

  renderRankingPage(paginationPage, pageSize = null) {
    this.props.setRankingOrganizationsPaginationPage(paginationPage);
    if (pageSize) {
      this.props.setRankingOrganizationsPageSize(pageSize);
    }
  }

  renderPaginate() {
    const { ranking, filter } = this.props;
    if (ranking.error != null && ranking.error.code == 404) {
      return '';
    }

    return (
      <TTPPaginator
        onPageChange={this.handleRankingListPageClick}
        pageSize={filter.pageSize}
        nbResult={ranking.nbResult}
        paginationPage={filter.paginationPage}
      />
    );
  }

  render() {
    const { filter, surveySlug, hide, disabled, surveyFetching } = this.props;

    if ((hide || disabled) && !surveyFetching) {
      return <NotFoundPage />;
    }

    return (
      <div className="row wrapper">
        <div className="ranking-list">
          <div className="row">
            <div className="medium-1 columns">
              <select className="filter-select" value={filter.pageSize} onChange={this.handleSelectRankingListPageSize.bind(this)}>
                {LIST_PAGE_SIZES.map(pagesize => <option key={`page-size-${pagesize}`} value={pagesize}>{pagesize}</option>)}
              </select>
            </div>
            <div className="medium-6 columns">
              <RankingOrganizationsFilter onFilterApply={this.renderRankingPage} />
            </div>
          </div>
          <div className="row">
            <div className="medium-12 columns">
              <RankingOrganizationsList surveySlug={surveySlug} showClientsRanking={this.props.showClientsRanking} surveyId={this.surveyId} />
            </div>
          </div>
          {this.renderPaginate()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ranking: state.rankingOrganizations.list,
  filter: state.rankingOrganizations.filter.filter,
  rankingSort: state.rankingOrganizations.sort,
  surveyFetching: state.surveys.list.fetching,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchRankingOrganizations, setRankingOrganizationsPaginationPage, initializeRankingOrganization, setRankingOrganizationsPageSize,
  }, dispatch);
}

export default controlAccess(mapStateToProps, mapDispatchToProps)(RankingListPage);
