const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  nbResult: 0,
  organizationId: 0,
  error: null,
  filter: {
    pageSize: '10',
    paginationPage: 1,
    invited: '',
    revival1: '',
    revival2: '',
    partialRevival1: '',
    partialRevival2: '',
    participated: '',
    thankReceived: '',
    resultsStatus: 'all',
  }
};

export const participantUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIALIZE_PARTICIPANT_USER_FILTER': {
      return { ...state, filter: initialState.filter };
    }
    case 'SET_PARTICIPANT_USER_FILTER': {
      const { property, value } = action;
      return {
        ...state,
        filter: {
          ...state.filter,
          [property]: value
        }
      };
    }
    case 'SET_PARTICIPANT_USER_PAGE_SIZE': {
      const { pageSize } = action;
      return {
        ...state,
        filter: {
          ...state.filter,
          pageSize
        }
      };
    }
    case 'SET_PARTICIPANT_USER_PAGINATION_PAGE': {
      const { pageNumber } = action;
      return {
        ...state,
        filter: {
          ...state.filter,
          paginationPage: pageNumber
        }
      };
    }
    case 'SEND_PARTICIPANT_TRANSACTIONAL_MAIL_FULFILLED': {
      const { items } = action.payload.data.data;


      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: state.items.map(item => items.find(newItem => item.id === newItem.id) || item),
      };
    }
    case 'FETCH_PARTICIPANT_USER_PENDING': {
      return { ...state, fetching: true };
    }
    case 'FETCH_PARTICIPANT_USER_FULFILLED': {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult,
      };
    }
    case 'FETCH_PARTICIPANT_USER_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
    }
    default:
      return state;
  }
};
