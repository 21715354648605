
import { AUTHORIZATIONS } from 'Common';

const clientHasAuthorizations = (survey, currentClient) => {
  if (!survey || !survey.organization) {
    return false;
  }
  if (currentClient && currentClient.id == survey.organization.id) {
    return true;
  }

  return false;
};

const userHasAuthorizations = (survey, user, currentClient, loggedAs) => (
  clientHasAuthorizations(survey, currentClient) && (loggedAs == 'CHIEF_EDITOR' || (survey.createdBy && user && survey.createdBy.id == user.id))
);

const isOwner = (survey, user, currentClient) => (
  clientHasAuthorizations(survey, currentClient) && (survey.createdBy && user && survey.createdBy.id == user.id)
);

export const getAuthorizations = (survey, user, currentClient, loggedAs, asTemplate = false) => {
  const appAuthorization = AUTHORIZATIONS[loggedAs] ? AUTHORIZATIONS[loggedAs] : [];
  const clientHasAuthorization = clientHasAuthorizations(survey, currentClient);
  const userHasAuthorisation = userHasAuthorizations(survey, user, currentClient, loggedAs);
  const { organizationInstance, participation, isSectorial, isFixed, parent, type } = survey;
  const isEditabel = parent == null || !parent.isFixed;
  /**
   * Chief editor can publish survey that doesn't belog to the current client
   * for the purpose to participate to public surveys.
   */
  const canPublishSurvey = userHasAuthorisation || (loggedAs == 'CHIEF_EDITOR');
  const canManageSurvey = userHasAuthorisation || (loggedAs == 'CHIEF_EDITOR');
  // const canCloseSurvey = organizationInstance && organizationInstance.status && organizationInstance.status == 'STARTED';
  const surveyStarted = organizationInstance?.status === 'STARTED';
  const canEditSurvey = isEditabel && (!surveyStarted || loggedAs == 'CHIEF_EDITOR');
  const isParticipated = participation && participation.isUsed == 1;
  const isSurveyOwner = isOwner(survey, user, currentClient);
  const isQuiz = type == 'QUIZ';

  return ({
    edit: {
      hide: appAuthorization.indexOf('EDIT_SURVEY') === -1
      || (!clientHasAuthorization && ['QUIZ', 'PORTAL'].indexOf(type) !== -1), /** No need to manage Emailing generic here */
      disable: loggedAs !== 'CHIEF_EDITOR' && (!isSurveyOwner || surveyStarted),
      editMails: true,
      editSurvey: userHasAuthorisation && canEditSurvey,
    },
    createSurveyFromTemplate: {
      hide: appAuthorization.indexOf('CREATE_SURVEY_FROM_TEMPLATE') === -1 || !asTemplate,
      disable: isSectorial,
    },
    preview: {
      hide: false,
      disable: false,
    },
    penetrationRate: {
      hide: appAuthorization.indexOf('EDIT_PENETRATION_RATE') === -1 || ['QUIZ', 'PORTAL'].indexOf(type) !== -1 || !clientHasAuthorization,
      disable: !userHasAuthorisation,
    },
    stats: {
      hide: appAuthorization.indexOf('CONSULT_RESULTS') === -1,
      disable: false,
    },
    editStatus: {
      hide: appAuthorization.indexOf('EDIT_SURVEY_STATUS') === -1 || ['QUIZ', 'PORTAL'].indexOf(type) !== -1,
      disable: false
    },
    consultTranslationStatus: {
      hide: appAuthorization.indexOf('CONSULT_SURVEY_TRANSLATION_STATUS') === -1,
      disable: false,
    },
    duplicate: {
      hide: appAuthorization.indexOf('DUPLICATE_SURVEY') === -1 || (!clientHasAuthorization && (isFixed || isSectorial)),
      disable: false,
    },
    createTemplateFromSurvey: {
      hide: appAuthorization.indexOf('CREATE_TEMPLATE_FROM_SURVEY') === -1 || isQuiz || asTemplate || isSectorial,
      // prevent this scenario: fixed template => fixed survey => non-fixed template
      disable: isFixed,
    },
    participate: {
      hide: appAuthorization.indexOf('PARTICIPATE_IN_TEMPLATE') === -1 || !isSectorial || isParticipated,
      disable: false,
    },
    cancelParticipation: {
      hide: appAuthorization.indexOf('PARTICIPATE_IN_TEMPLATE') === -1 || !isSectorial || !isParticipated,
      disable: false,
    },
    publish: {
      hide: appAuthorization.indexOf('PUBLISH_SURVEY') === -1 || ['QUIZ', 'PORTAL'].indexOf(type) !== -1,
      disable: !canPublishSurvey,
    },
    manage: {
      hide: appAuthorization.indexOf('MANAGE_SURVEY') === -1,
      disable: !canManageSurvey,
    },
    remove: {
      hide: appAuthorization.indexOf('DELETE_SURVEY') === -1 || !clientHasAuthorization,
      disable: !userHasAuthorisation,
    }
  });
};
