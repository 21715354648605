import axios from 'axios';
import { TTP_API_URL } from 'Config';
import { throwCatchedError } from 'utils';

export const getCampaignStats = (token, instanceId = null, organizationId) => {
  const requestUrl = `${TTP_API_URL}/survey/stats/campaign`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      instance: instanceId,
      organization: organizationId,
    },
  }).catch((thrown) => { throwCatchedError(thrown); });
};
