import React, { Component } from 'react';

export default class MultiSwitch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: props.selectedValue || props.vals[0]
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedOption != this.props.selectedValue) {
      this.setState({ selectedOption: this.props.selectedValue }); // eslint-disable-line react/no-did-update-set-state
    }
    if (this.props.selectedValue !== prevProps.selectedValue) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        selectedOption: this.props.selectedValue
      });
    }
  }

  handleChange(e) {
    this.setState({ selectedOption: e.target.value });
    this.props.onChange(e.target.value);
  }

  render() {
    const {
      labels, vals, name, customClass
    } = this.props;

    if (!labels || labels.length == 0) {
      return null;
    }

    const returnDiv = [];
    for (let i = 0; i < labels.length; i++) {
      returnDiv.push(
        <li key={`switch-${vals[i]}`} className="switch">
          <span className={customClass}>{labels[i]}</span>
          <div className="switch-container">
            <label>
              <input
                value={vals[i]}
                ref="switch" // eslint-disable-line react/no-string-refs
                name={name}
                checked={this.state.selectedOption === vals[i]}
                onChange={this.handleChange.bind(this)}
                className="ttp-switch"
                type="radio"
              />
              <div>
                <span><g className="icon icon-toolbar grid-view" /></span>
                <span><g className="icon icon-toolbar ticket-view" /></span>
                <div />
              </div>
            </label>
          </div>
        </li>
      );
    }

    return (
      <ul>{returnDiv}</ul>
    );
  }
}
