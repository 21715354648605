import React from 'react';
import {
  PieChart, Pie, Cell, Legend, ResponsiveContainer
} from 'recharts';
import { STATS_COLORS } from 'SurveyConfig';

const data = [
  {
    name: 3,
    value: 3
  },
  {
    name: 5,
    value: 5
  },
  {
    name: 4,
    value: 4
  },
  {
    name: 8,
    value: 8
  },
  {
    name: 20,
    value: 20
  }
];

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul>
      {
        payload.map((entry, index) => (
          <li key={`legend-${index}`}><h5 className="gradiant" /></li>
        ))
      }
    </ul>
  );
};

export const DimensionSummaryFetching = () => (
  <>
    <div className="row">
      <div className="summary__block summary__dimension column small-12 fetching">
        <span className="title column"><h5 className="gradiant blue no-margin" /></span>
      </div>
    </div>


    <div className="row summary__wrapper">
      <div className="summary__block">
        <div className="row medium-up-1 large-up-2 small-up-1">
          {
            Array.from(Array(5).keys()).map(item => (
              <div className="column column-block" key={item}>
                <div className="summary__qst fetching with-others">
                  <div className="column">
                    <div className="title"><h5 className="gradiant" /></div>
                    <div className="responses">
                      <span>-- / --</span>
                    </div>
                    <ResponsiveContainer width="100%" height="auto" aspect={5 / 3}>
                      <PieChart>
                        <Legend
                          align="left"
                          layout="vertical"
                          verticalAlign="middle"
                          iconType="circle"
                          wrapperStyle={{ width: '40%', flex: 1, maxHeight: '80%', overflow: 'hidden', overflowY: 'auto' }}
                          content={renderLegend}
                        />
                        <Pie
                          data={data}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {
                            data.map((entry, index) => <Cell fill={STATS_COLORS[index % STATS_COLORS.length]} key={entry.value} />)
                          }
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  </>
);

export default DimensionSummaryFetching;
