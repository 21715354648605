import React, { Component } from 'react';
import {
  setCurrentNavPage,
  changeLanguage,
  initSurvey,
  initSurveyTheme,
} from 'actions';
import { fetchSurvey } from 'thunks';
import { connect } from 'react-redux';
import Preview from 'Preview';
import LanguageSetter from 'preview-items/LanguageSetter';
import SurveyTitlePreviewFetching from 'fetching/SurveyTitlePreviewFetching';
import NotFoundPage from 'NotFoundPage';
import SurveyStyle from './preview-items/SurveyStyle';

class SurveyPreview extends Component {
  componentDidMount() {
    const { setCurrentNavPage } = this.props;
    const { match: { params: { slug } }, lng } = this.props;

    setCurrentNavPage('RESPONSE');

    if (lng) {
      this.props.changeLanguage(lng);
    }

    if (slug) {
      this.props.fetchSurvey(slug);
    }
  }

  componentWillUnmount() {
    this.props.initSurvey();
    this.props.initSurveyTheme();
  }

  changeDisplayLanguage = (lng) => {
    if (lng) {
      this.props.changeLanguage(lng);
    }
  }

  render() {
    const {
      surveys: {
        survey,
        fetched,
        fetching,
        language
      },
      status,
    } = this.props;

    if (fetching) {
      return <SurveyTitlePreviewFetching />;
    }

    if (fetched) {
      if (survey.translationScore && parseInt(survey.translationScore[language]) !== 100) {
        return (
          <div className="pre-preview srv-background-color--fill">
            <LanguageSetter action={this.changeDisplayLanguage} survey={survey} />
          </div>
        );
      }

      return (
        <div className="preview preview--withoutScroll srv-background-color--fill survey-preview">
          <SurveyStyle theme={survey?.meta?.theme}/>
          <Preview
            survey={survey}
            status={status}
            scrollBody
            fakeResponse={true}
          />
        </div>
      );
    }

    return <NotFoundPage />;
  }
}

const mapStateToProps = state => ({
  surveys: state.surveys.list,
  status: state.surveyResponse.status,
  lng: state.params.lng,
  // theme: state.surveyThemes.theme,
});

const mapDispatchToProps = {
  setCurrentNavPage,
  fetchSurvey,
  changeLanguage,
  initSurvey,
  initSurveyTheme
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyPreview);
