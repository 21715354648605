import React, { Component } from 'react';

export default class QuestionRankingFetching extends Component {
  render() {
    return (
      <div className="quiz-preview__question question-ranking">
        {
          Array.from(Array(5).keys()).map(key => (
            <div className="user-wrapper fetching" key={key}>
              <span className="name"><h4 className="gradiant no-content m-y-xxs" style={{ width: '25rem' }} /></span>
            </div>
          ))
        }
      </div>
    );
  }
}
