import moment from 'moment';
import { getResourceType } from 'surveyUtils';

export const generateQuestionAnswer = (question, value) => {
  let answer = {
    question: question.id || question.uid,
    resourceType: getResourceType(question.type),
    isAutoFilled: true,
  };

  if (
    ['MULTIPLE_CHOICE', 'SINGLE_CHOICE', 'RATING_CHOICE'].indexOf(
      question.type,
    ) !== -1
  ) {
    answer = { ...answer, choice: Array.isArray(value) ? value : [value] };
  } else {
    answer = { ...answer, value };
  }

  return answer;
};

const isValueInRange = (value, range) => {
  if (range == null || value == null) return false;

  const [min, max] = range.value;
  if (min === null && max === null) return false;

  if (min === null) {
    return ['[[', ']['].indexOf(range.type) ? value < max : value <= max;
  } else if (max === null) {
    return [']]', ']['].indexOf(range.type) ? value > min : value >= min;
  }

  switch (range.type) {
    case '[]':
      return value >= min && value <= max;
    case '[[':
      return value >= min && value < max;
    case ']]':
      return value > min && value <= max;
    case '][':
      return value > min && value < max;
    default:
      return false;
  }
};

export const getAutoGeneratedAnswer = (question, user) => {
  if ([question, user].indexOf(undefined) !== -1) {
    throw new Error('required fields: question, user'); // TODO
  }

  switch (question.profilingType) {
    case 'FUNCTION':
      return null;
    case 'POSTAL_CODE':
      return null;
    case 'AGE': {
      const { birthday } = user;

      if (birthday == null || birthday == '') return null;

      const birthdayDate = moment(birthday);
      const age = moment().diff(birthdayDate, 'years');

      const choice = question.choices.find(choice =>
        isValueInRange(age, choice?.options?.range),
      );

      if (choice) {
        return generateQuestionAnswer(question, choice.id);
      }

      return null;
    }
    case 'GENDER': {
      const { gender } = user;
      if (gender == null || gender == '') return null;

      const choice = question.choices.find(choice => choice?.options?.value === gender);

      if (choice) {
        return generateQuestionAnswer(question, choice.id);
      }
      return null;
    }
    case 'LANGUAGE': {
      const { language } = user;
      if (language == null || language == '') return null;

      const choice = question.choices.find(choice => choice?.options?.value === language);

      if (choice) {
        return generateQuestionAnswer(question, choice.id);
      }
      return null;
    }
    default:
      return null;
  }
};

export const getFirstNotAnsweredQuestion = (questions, answers) => {
  if (Array.isArray(questions)) {
    for (const question of questions) {
      if (!answers?.[question.id]) {
        return question;
      }
    }
  }

  return null;
};
