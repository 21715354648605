import React from 'react';
import { NavLink } from 'react-router-dom';

import _ from 'i18n';

const Surveys = () => (
  <li>
    <NavLink to={{ pathname: '/surveys' }} exact activeClassName="active" className="menu__link">
      <span className="flex-column-center"><i className="icomoon icon-tt-stats" /></span>
      <span className="title">{_('Surveys')}</span>
    </NavLink>
  </li>
);


export default Surveys;
