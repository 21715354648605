import React from 'react';
import { connect } from 'react-redux';
import _ from 'i18n';
import TTButton from '../common/TTButton';

const Description = (props) => {
  const { description, language } = props;

  return (
    <>
      <div className="content">
        {description ? description[language] : ''}
      </div>
      <div className="text-right p-t-l">
        <TTButton
          text={_('Continue')}
          className="rounded full survey-main-content-gradient-background-color"
          onClick={props.scrollToNext}
          fontSize="20px"
        />
      </div>
    </>
  );
};

const mapStateToProps = state => ({ language: state.surveys.list.language });

export default connect(mapStateToProps)(Description);
