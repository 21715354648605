import axios from 'axios';
import { TTP_API_URL } from 'Config';

export const saveOrganizationInstance = (token, organizationInstance) => {
  const requestUrl = `${TTP_API_URL}/survey/organization-instance`;
  const { id, instance, organization } = organizationInstance;
  delete organizationInstance.createdAt;

  if (!id && (!instance || !organization)) {
    throw 'Please add the required fields: [id || (instance & organization)]';
  }

  return axios({
    method: 'post',
    url: requestUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: JSON.stringify(organizationInstance)
  });
};

export const fetchOrganizationInstance = (token, organizationInstanceId) => {
  const requestUrl = `${TTP_API_URL}/survey/organization-instance/${organizationInstanceId}`;

  return axios.get(requestUrl, {
    params: {
      access_token: token
    },
  });
};

export const fetchOrganizationInstances = (token, organizationId, instanceId) => {
  const requestUrl = `${TTP_API_URL}/survey/organization-instance`;
  const filter = [
    {
      property: 'instance',
      operator: 'eq',
      value: instanceId
    },
    {
      property: 'organization',
      operator: 'eq',
      value: organizationId
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter)
    },
  });
};
