import React, { useState, useEffect } from 'react';
import _ from 'i18n';
import TTPActionModal from 'common/reveal/TTPActionModal';

const STATUS = ['PENDING', 'STARTED', 'CLOSED'];

export default function SurveyStatusModal({ onAsyncAction, surveyStatus, ...props }) {
  const [status, setStatus] = useState(surveyStatus);

  const onChange = ({ target: { value } }) => {
    if (value == '') {
      value = null;
    }
    setStatus(value);
  };

  useEffect(() => {
    setStatus(surveyStatus);
  }, [surveyStatus]);

  return (
    <TTPActionModal
      labels={{ ok: _('Validate') }}
      title={_('popup_survey_status_message')}
      icon="icomoon icon-tt-settings"
      onAsyncAction={() => onAsyncAction(status)}
      {...props}
    >
      <div className="m-x-xl p-y-s">
        <select className="settings__input" onChange={onChange} value={status}>
          {STATUS.map((option, key) => <option key={key} value={option}>{_(option)}</option>)}
        </select>
      </div>
    </TTPActionModal>
  );
}
