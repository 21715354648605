import React from 'react';

export default function Play({ fill }) {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 15 15"
      fill={fill ?? 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8647 7.6003L1.22127 13.8397C0.888978 14.0037 0.5 13.7619 0.5 13.3914L0.500001 1.53815C0.500001 1.1764 0.872277 0.934366 1.2029 1.08117L13.8463 6.69494C14.2337 6.86695 14.2448 7.41272 13.8647 7.6003Z"
        stroke={fill ?? '#18A0FB'}
      />
    </svg>
  );
}
