const initialStateForSurveysFilter = {
  status: null,
  title: null,
  type: null,
  env: null,
  owner: null,
  languages: null,
};

export const surveysFilterReducer = (state = initialStateForSurveysFilter, action) => {
  switch (action.type) {
    case 'INIT_SURVEY_FILTER': {
      return initialStateForSurveysFilter;
    }
    case 'SET_SURVEY_FILTER_STATUS': {
      return { ...state, status: action.status };
    }
    case 'SET_SURVEY_FILTER_TYPE': {
      return { ...state, type: action.surveyType };
    }
    case 'SET_SURVEY_FILTER_OWNER': {
      return { ...state, owner: action.owner };
    }
    case 'SET_SURVEY_FILTER_TITLE': {
      return { ...state, title: action.title };
    }
    case 'SET_SURVEY_FILTER_LANGUAGES': {
      return { ...state, languages: action.languages };
    }
    case 'SET_SURVEY_FILTER_ENVIRONMENT': {
      return { ...state, env: action.env };
    }
    default:
      return state;
  }
};

const initialStateForSurveysSort = {
  sortConfig: {
    columnKey: 'latestInstanceDate',
    sortDirection: 'desc'
  }
};

export const surveysSortReducer = (state = initialStateForSurveysSort, action) => {
  switch (action.type) {
    case 'SET_SURVEY_LIST_SORT_CONFIG': {
      return { ...state, sortConfig: action.config };
    }
    default:
      return state;
  }
};
