import { getHistory } from "../../router/getHistory";

export const setLanguage = (language) => {
  const lng = language.toLowerCase();
  localStorage.setItem('lng', lng);

  const history = getHistory();
  history.push({ search: location.search.replace(/&lng=\w+/i, `&lng=${lng}`)});

  return {
    type: 'SET_LANGUAGE',
    language: lng,
  };
};

export const setCurrentNavPage = currentNavPage => ({
  type: 'SET_CURRENT_NAV_PAGE',
  currentNavPage,
});

export const pushSourceToken = (sourceName, sourceToken) => ({
  type: 'PUSH_SOURCE_TOKEN',
  sourceName,
  sourceToken,
});

export const clearSourcesTokens = () => ({
  type: 'CLEAR_SOURCES_TOKENS',
});

export const setTTPDialogShow = showDialog => ({
  type: 'SET_TTP_DIALOG_SHOW',
  showDialog,
});

export const setTTPDialogCustomData = customData => ({
  type: 'SET_TTP_DIALOG_CUSTOM_DATA',
  customData,
});

export const setSubMenuDirection = subMenuDirection => ({
  type: 'SET_SUB_MENU_DIRECTION',
  subMenuDirection,
});

export const setSurveyListView = view => ({
  type: 'SET_SURVEY_LIST_VIEW',
  view,
});
