import { QUESTIONS_WITH_CHOICES } from 'Common';

/**
 * @param { array } questions
 */
export const getAllChoices = (questions) => {
  if (questions != null && Array.isArray(questions)) {
    return questions.reduce(
      (prevChoices, question) => (Array.isArray(question.choices) && QUESTIONS_WITH_CHOICES.includes(question.type) ? [...prevChoices, ...question.choices] : prevChoices),
      []
    );
  }
  return [];
};
