import React, { Component } from 'react';
import styles from './EditorItems.module.scss';

export default class ChoiceAgeSetter extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();

    this.state = {
      minNumber: this.props.minValue,
      maxNumber: this.props.maxValue,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.onClose();
    }
  }

  render() {
    const { min = 1, max = 150, minValue, maxValue, onClick } = this.props;
    const { minNumber, maxNumber } = this.state;

    return (
      <div className={styles.age_setter} ref={this.wrapperRef}>
        <input
          type="number"
          value={minNumber}
          min={Math.max(min, minValue ?? min)}
          max={Math.min(max, maxValue ?? max)}
          onChange={({ target: { value } }) => this.setState({ minNumber: value })}
        />
        <div>à</div>
        <input
          type="number"
          value={maxNumber}
          min={minNumber + 1}
          max={max}
          onChange={({ target: { value } }) => this.setState({ maxNumber: value })}
        />
        <div> ans </div>
        <button onClick={() => onClick(minNumber, maxNumber)}>OK</button>
      </div>
    );
  }
}
