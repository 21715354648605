import React from 'react';

export default function TTButton({
  icon,
  showIcon = true,
  isSymmetric = false,
  color = 'blue',
  className,
  onClick,
  text,
  disabled,
  upperCase,
  fontSize,
  iconFontSize,
  dataTip,
  dataFor,
  style,
}) {
  const data = dataTip ? { 'data-tip': true, 'data-for': dataFor } : {};

  return (
    <button
      type="button"
      {...data}
      className={`tt-button ${color} ${className} ${
        disabled ? 'disabled' : ''
      }`}
      onClick={disabled ? null : onClick}
      style={style}
    >
      {icon && (
        <span
          style={{ visibility: showIcon ? 'visible' : 'hidden', fontSize: iconFontSize }}
          className="tt-button__icon"
        >
          <i className={icon} />
        </span>
      )}
      <span
        style={fontSize ? { fontSize } : null}
        className={`tt-button__text ${upperCase ? 'text-uppercase' : ''}`}
      >
        {text}
      </span>
      {isSymmetric && (
        <span style={{ visibility: 'hidden' }} className="tt-button__icon">
          <i className={icon} />
        </span>
      )}
    </button>
  );
}
