import React from 'react';
import SurveyStyle from 'preview-items/SurveyStyle';

export default function PreviewWrapper({ children, survey }) {
  return (
    <div className="preview preview--withoutScroll srv-background-color--fill survey-preview">
      <SurveyStyle theme={survey?.meta?.theme} />
      <div className="preview__wrapper row" id="scrollPreview">
        <div className="preview__background survey-main-background" />
        <div className="preview__background--bottom" />
        {children}
      </div>
    </div>
  );
}
