import update from 'immutability-helper';
import __ from 'lodash';
import { serializeAnswers } from '../../services/surveyUtils';

const initialStateForSurveyResponse = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  organizationInstanceFetching: false,
  organizationInstanceFetched: false,
  organizationInstance: null,
  surveyResponse: {
    subscribedToStats: false
  },
  items: [],
  nbResult: 0,
  answers: {},
  lastUpdatedAnswers: [], // we store the id of question to track the answer.
  requiredQuestionsIds: [],
  status: {
    surveyStarted: false,
    surveyEnded: false,
  },
  error: null,
};

export const surveyResponseReducer = (state = initialStateForSurveyResponse, action) => {
  switch (action.type) {
    case 'INIT_SURVEY_RESPONSE': {
      return { ...initialStateForSurveyResponse, organizationInstance: state.organizationInstance };
    }
    case 'INIT_SURVEY_RESPONSE_ORGANIZATION_INSTANCE': {
      return { ...state, organizationInstance: null };
    }
    case 'FETCH_RESPONSES_PENDING':
    case 'FETCH_SURVEY_RESPONSE_PENDING': {
      return { ...state, fetching: true, fetched: false };
    }
    case 'FETCH_SURVEY_RESPONSE_FULFILLED': {
      const surveyResponse = action.payload.data.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        answers: __.mapKeys(serializeAnswers(surveyResponse.answers), 'question'),
        surveyResponse: {
          ...state.surveyResponse,
          ...__.omit(surveyResponse, ['answers', 'instance', 'isAnonymous'])
        },
      };
    }
    case 'FETCH_RESPONSES_FULFILLED': {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult,
      };
    }
    case 'FETCH_RESPONSES_REJECTED':
    case 'FETCH_SURVEY_RESPONSE_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
      };
    }
    case 'FETCH_ORGANIZATION_INSTANCE_PENDING': {
      return { ...state, organizationInstance: null, organizationInstanceFetching: true, organizationInstanceFetched: false };
    }
    case 'FETCH_ORGANIZATION_INSTANCE_FULFILLED': {
      const organizationInstance = action.payload;

      return {
        ...state,
        organizationInstanceFetching: false,
        organizationInstanceFetched: true,
        error: null,
        organizationInstance,
      };
    }
    case 'FETCH_ORGANIZATION_INSTANCE_REJECTED': {
      return { ...state, organizationInstanceFetching: false, organizationInstanceFetched: false };
    }
    case 'ADD_LAST_UPDATED_ANSWER': {
      const { questionId } = action;
      return {
        ...state,
        lastUpdatedAnswers: __.union(state.lastUpdatedAnswers, [questionId]),
      };
    }
    case 'RESET_LAST_UPDATED_ANSWERS': {
      const { updatedAnswers } = action;

      /**
       * we remove saved answers from the queue
       */
      let filterdAnswers = __.difference(state.lastUpdatedAnswers, updatedAnswers);

      /**
       * answers that was deleted but didn't removed from the quue
       */
      const deletedAnswers = __.difference(
        filterdAnswers,
        __.keys(state.answers).map(key => parseInt(key)),
      );

      /**
       * remove deleted answers
       */
      filterdAnswers = __.difference(filterdAnswers, deletedAnswers);


      return {
        ...state,
        lastUpdatedAnswers: filterdAnswers,
      };
    }
    case 'ADD_REQUIRED_QUESTION_ID': {
      let exist = false;
      const ids = state.requiredQuestionsIds.map((id) => {
        if (id === action.id) {
          exist = true;
        }
        return id;
      });

      return {
        ...state,
        requiredQuestionsIds: exist ? ids : [...state.requiredQuestionsIds, action.id],
      };
    }
    case 'REMOVE_REQUIRED_QUESTION_ID': {
      const ids = state.requiredQuestionsIds.filter(id => id !== action.id);

      return {
        ...state,
        requiredQuestionsIds: ids,
      };
    }
    case 'START_SURVEY': {
      return {
        ...state,
        status: {
          ...state.status,
          surveyStarted: action.startSurvey,
        },
      };
    }
    case 'COMPLETE_SURVEY': {
      return {
        ...state,
        status: {
          ...state.status,
          surveyEnded: action.completeSurvey,
        },
      };
    }
    case 'SET_SURVEY_RESPONSE': {
      return {
        ...state,
        surveyResponse: action.surveyResponse,
      };
    }
    case 'SET_SUBSCRIBED_TO_STATS': {
      return {
        ...state,
        surveyResponse: { ...state.surveyResponse, subscribedToStats: action.subscribedToStats }
      };
    }
    case 'UPDATE_ANSWER': {
      const { answer } = action;
      let isValid = true;

      if (answer.resourceType === 'multipleChoice') {
        isValid = (answer.choice != null && answer.choice.length != 0) || (answer.otherValue != null && answer.otherValue.length != 0);
      } else {
        isValid = !(answer.answerValue == null || (answer.answerValue != null && answer.answerValue.length === 0));
      }

      const updatedAnswer = state.answers.hasOwnProperty(answer.question) ? { ...state.answers[answer.question], ...answer } : answer;
      return {
        ...state,
        answers: isValid ? { ...state.answers, [answer.question]: updatedAnswer } : __.omit(state.answers, answer.question),
        lastUpdatedAnswers: isValid ? state.lastUpdatedAnswers : state.lastUpdatedAnswers.filter(qstId => qstId != answer.question)
      };
    }
    case 'ANSWER_VALIDATION_ERROR': {
      const { questionId, error } = action;
      const errorAnswers = state.error ? state.error.answers || {} : {};

      if (errorAnswers.hasOwnProperty(questionId)) {
        const { code } = error;
        let exist = false;
        errorAnswers[questionId].forEach((answerError) => {
          if (answerError.code === code) {
            exist = true;
          }
        });

        if (!exist) {
          return {
            ...state,
            error: {
              ...state.error,
              answers: update(errorAnswers, {
                [questionId]: { $push: [error] },
              }),
            },
          };
        }
        return {
          ...state,
        };
      }

      return {
        ...state,
        error: {
          ...state.error,
          answers: update(errorAnswers, { $merge: { [questionId]: [error] } }),
        },
      };
    }
    case 'RESET_ANSWER_VALIDATION_ERROR': {
      const { questionId } = action;
      const errorAnswers = state.error ? state.error.answers || {} : {};

      if (errorAnswers.hasOwnProperty(questionId)) {
        return {
          ...state,
          error: {
            ...state.error,
            answers: update(errorAnswers, { $unset: [questionId] }),
          },
        };
      }

      return {
        ...state,
      };
    }
    case 'AUTO_SAVE_RESPONSE_PENDING':
    case 'SAVE_SURVEY_RESPONSE_PENDING': {
      return { ...state, saved: false, saving: true };
    }
    case 'SAVE_SURVEY_RESPONSE_FULFILLED': {
      const surveyResponse = action.payload;
      const { answers } = state;

      __.map(__.mapKeys(serializeAnswers(surveyResponse.answers), 'question'), (answer) => {
        if (answers.hasOwnProperty(answer.question)) {
          answers[answer.question] = { ...answers[answer.question], ...answer };
        }
      });

      return {
        ...state,
        saving: false,
        saved: true,
        surveyResponse: {
          ...state.surveyResponse,
          id: surveyResponse.id,
          status: surveyResponse.status,
        },
        answers,
        error: state.error ? { answers: state.error.answers } : state.error,
      };
    }
    case 'AUTO_SAVE_RESPONSE_FULFILLED': {
      const surveyResponse = action.payload;

      const updatedAnswers = (Array.isArray(surveyResponse?.answers)
        ? surveyResponse.answers
        : []
      ).reduce(
        (accumulated, answer) => ({
          ...accumulated,
          [answer?.question?.id ?? 0]: answer,
        }),
        {},
      );

      const injectIdsToAnswers = __.reduce(
        state.answers,
        (accumulated, answer, questionId) => {
          const updatedAnswer = updatedAnswers[questionId];
          if (updatedAnswer && answer.id == null) {
            return ({ ...accumulated, [questionId]: {...answer, id: updatedAnswer.id }});
          }

          return ({ ...accumulated, [questionId]:  answer });
        },
        {},
      );
      
      return {
        ...state,
        saving: false,
        saved: true,
        surveyResponse: {
          ...state.surveyResponse,
          id: surveyResponse.id,
          status: surveyResponse.status,
        },
        answers: injectIdsToAnswers,
        error: state.error ? { answers: state.error.answers } : state.error,
      };
    }
    case 'AUTO_SAVE_RESPONSE_REJECTED':
    case 'SAVE_SURVEY_RESPONSE_REJECTED': {
      const error = action.payload;
      switch (error.response?.status) {
        case 403:
        case 404:
          return {
            ...state,
            saving: false,
            saved: false,
            error: {
              title: error.response.data.title,
              code: error.response?.status,
            }
          };
        default:
      }
      return {
        ...state,
        saving: false,
        saved: false,
        items: [],
        error: error.response.data.errors,
      };
    }
    default:
      return state;
  }
};

export const selectAnswers = state => state.answers;
export const selectAnswerByQuestion = question => state => selectAnswers(state)[question] || null;
