import React, { Component } from 'react';
import { fetchInstances } from 'thunks';
import { connect } from 'react-redux';
import Select from 'react-select';
import { reactSelectCustomStyles } from 'utils';
import _ from 'i18n';
import moment from 'moment';
import QuizStats from './QuizStats';
import QuizParticipants from './QuizParticipants';

class QuizResults extends Component {
  state = { options: [], fetching: true, selectedOption: null };

  componentDidMount() {
    this.fetchInstances();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.surveyId != this.props.match.params.surveyId) {
      this.fetchInstances();
    }
  }

  fetchInstances() {
    const { surveyId } = this.props.match.params;

    this.props.fetchInstances(surveyId)
      .then(({ value: { data: { data } } }) => {
        const options = data
          .map(instance => ({
            value: instance,
            label: moment(instance.createdAt, 'YYYY-MM-DD HH:mm:ss')
              .locale(this.props.lng)
              .format('LLLL')
          }));
        this.setState({ options, selectedOption: options[0], fetching: false });
      })
      .catch(() => this.setState({ fetching: false, options: [] }));
  }

  onChange = selectedOption => this.setState({ selectedOption });

  renderContent() {
    const { selectedOption, fetching } = this.state;

    if (!selectedOption) {
      return (
        <div className="quiz-page-message-wrapper row">
          <p className="message">
            {
              fetching
                ? <img alt="" src="/img/loaders/tamtam.svg" />
                : _('Please select a game')
            }
          </p>
        </div>
      );
    }

    return (
      <>
        <div className="column small-6">
          <QuizStats instance={selectedOption && selectedOption.value} />
        </div>
        <div className="column small-6">
          <QuizParticipants instance={selectedOption && selectedOption.value} />
        </div>
      </>
    );
  }

  render() {
    const { options, fetching, selectedOption } = this.state;

    return (
      <div id="quiz-results">
        <div className="flex-container header">
          <div className="small-8 column">
            <div className="row summary__wrapper">
              <div className="summary__block summary__header column small-12 responses">
                <span className="text text-uppercase">{_('Select a game')}</span>
                <span className="number flex-1">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={selectedOption}
                    isClearable={true}
                    isSearchable={true}
                    isLoading={fetching}
                    name="color"
                    options={options}
                    styles={reactSelectCustomStyles()}
                    onChange={this.onChange}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-container">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lng: state.params.lng
});

export default connect(mapStateToProps, { fetchInstances })(QuizResults);
