import React, { Component } from 'react';
import { getUserNameForAvatar, setCookie } from 'utils';
import { APP_ENV, LARAVEL_SESSION_COOKIE, TTP_HOME_URL } from 'Config';
import { setAuth, setLanguage } from 'actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'i18n';

class ProfileMenu extends Component {
  changeLng = (e) => {
    if (e.target.classList.contains('selected')) {
      return;
    }
    this.props.setLanguage(e.target.id);
    /** wait until redux-persist persist the state */
    setTimeout(() => window.location.reload(), 0);
  }

  logout(e) {
    e.preventDefault();
    this.props.setAuth(null);

    const dtExpire = new Date();
    const cookieName = `ttp_auth${APP_ENV != '' ? '_' : ''}${APP_ENV}`;

    dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);
    setCookie(cookieName, '', dtExpire, '/', 'tamtam.pro');
    setCookie('XSRF-TOKEN', '', dtExpire, '/', 'tamtam.pro');
    setCookie(LARAVEL_SESSION_COOKIE, '', dtExpire, '/', 'tamtam.pro');
    setCookie(`ttp_community_${APP_ENV}`, '', dtExpire, '/', 'tamtam.pro');

    window.location = '/';
  }

  render() {
    const { user: { avatarUrl, firstName, lastName, mainEmail }, lng } = this.props;

    let avatarDiv = null;
    if (avatarUrl) {
      avatarDiv = <div className="menu__img-wrap" style={{ backgroundImage: `url(${avatarUrl})` }} />;
    } else {
      avatarDiv = (
        <div className="menu__img-wrap empty-avatar">
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
        </div>
      );
    }

    return (
      <ul className="menu">
        <li className="expandable menu__img profile">
          {avatarDiv}
          <ul className="menu__dropdown">
            <li className="flex-container">
              {avatarDiv}
              <div className="flex-container flex-dir-column infos">
                <span>{`${firstName} ${lastName}`}</span>
                <span className="email">{mainEmail}</span>
              </div>
            </li>
            <li className="menu__profile">
              <ul>
                <li>
                  <a href={`${TTP_HOME_URL}/profile`}>{_('profile')}</a>
                </li>
              </ul>
            </li>
            <li className="menu__language">
              <ul>
                <li id="fr" className={lng == 'fr' ? 'selected' : ''} onClick={this.changeLng.bind(this)}>FR</li>
                <li id="nl" className={lng == 'nl' ? 'selected' : ''} onClick={this.changeLng.bind(this)}>NL</li>
                <li id="en" className={lng == 'en' ? 'selected' : ''} onClick={this.changeLng.bind(this)}>EN</li>
              </ul>
            </li>
            <li className="social">
              <ul>
                <li><i className="icon icon-social-facebook" /></li>
                <li><i className="icon icon-social-twitter" /></li>
                <li><i className="icon icon-social-linkedin" /></li>
              </ul>
            </li>
            <li className="logout" onClick={this.logout.bind(this)}>
              <Link to="/" className="text-center">{_('logout')}</Link>
            </li>
          </ul>
        </li>
      </ul>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  lng: state.params.lng
});

const mapDispatchToProps = { setAuth, setLanguage };

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
