import React, { Component } from 'react';
import autosize from 'autosize';
import { connect } from 'react-redux';
import {
  addValidationErrors,
  removeValidationErrors,
  updateChoice,
} from 'actions';
import ReactTooltip from 'react-tooltip';
import shortId from 'shortid';
import __ from 'lodash';
import _ from 'i18n';
import { getErrorMessageByCode } from 'surveyUtils';
import DisplayErrors from 'preview-items/DisplayErrors';

const IDLE_TIME = 5; // Period of user inactivity { IDLE_TIME * INCR_PERIODE } second
const INCR_PERIODE = 5; // Increment the idle time counter every { INCR_PERIODE } second.

export class ChoiceText extends Component {
  constructor(props) {
    super(props);

    this.timer = null;
    this.idleTime = 0;

    this.state = {
      hasFocus: false,
      value: props.value ? props.value : '',
      language: props.language,
      updated: false,
    };

    this.tooltipId = `tooltip-choice-text-${shortId.generate()}`;
    this.onTextChange = __.debounce(this.props.onTextChange, 150);
  }

  static defaultProps = {
    onBlur: () => {},
    onBlurAction: () => {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value, language } = nextProps;
    if (prevState.language != language) {
      return {
        ...prevState,
        value: value || '',
        language,
      };
    }
    return prevState;
  }

  componentDidMount() {
    if (this.props.focusOnMount) {
      this.textarea.focus();
    }
    autosize(this.textarea);
  }

  onChange = ({ target: { value } }) => {
    const { removeValidationErrors, errors, maxChars, disabled } = this.props;
    const { resourceType, property, key } = this.props.validationParams;

    if (disabled) return;

    // control the maximum of chars in input
    if (!maxChars || value.length <= maxChars) {
      this.setState({ value, updated: true });
      this.onTextChange(value);
    } else {
      const editedValue = value.slice(0, maxChars);
      this.setState({ value: editedValue, updated: true });
      this.onTextChange(editedValue);
    }

    if (value.length >= 2 && errors) {
      removeValidationErrors(key, resourceType, property);
    }

    if (this.timer == null) {
      this.clearTimer();
      this.timer = setInterval(this.handleIdleTimer, 1000 * INCR_PERIODE);
    }
  };

  onFocus = () => {
    this.setState({
      hasFocus: true,
    });
    this.props.onFocus();

    this.clearTimer();
    this.timer = setInterval(this.handleIdleTimer, 1000 * INCR_PERIODE);
  };

  handleIdleTimer = () => {
    this.idleTime += 1;

    if (this.idleTime > IDLE_TIME) {
      this.props.onBlur(this.state.value);
      this.clearTimer();
    }
  };

  clearTimer = () => {
    if (this.timer != null) {
      clearInterval(this.timer);
      this.timer = null;
    }
    this.idleTime = 0;
  };

  onBlur = ({ target: { value } }) => {
    this.props.onBlur();
    this.validateLength();
    this.clearTimer();
    if (this.state.updated) {
      this.props.onBlurAction(value);
    }

    this.setState(prevState => ({
      ...prevState,
      hasFocus: !this.props.hasFocus ? false : prevState.hasFocus,
      updated: false,
    }));
  };

  validateLength() {
    const { addValidationErrors } = this.props;
    const { resourceType, key, property } = this.props.validationParams;
    const { value } = this.state;

    if (value.length < 2) {
      addValidationErrors(key, resourceType, property, { code: 150302 });
    }
  }

  render() {
    const { value, hasFocus } = this.state;
    const {
      helpText,
      language,
      lng,
      errors,
      maxChars,
      disabled,
    } = this.props;

    return (
      <>
        <div className="greetings text" data-tip data-for={this.tooltipId}>
          <div className="text">
            <textarea
              className="transparent-input"
              ref={c => {
                this.textarea = c;
              }}
              onKeyDown={this.props.onKeyDown}
              placeholder={helpText || _('choice')}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
              value={value}
              disabled={disabled}
            />
            {maxChars && (
              <div className="counter">
                {hasFocus ? maxChars - ((value && value.length) || 0) : null}
              </div>
            )}
          </div>
          {helpText && helpText != '' && lng != language ? (
            <ReactTooltip
              id={this.tooltipId}
              multiline={true}
              className="react-tooltip"
            >
              <span className="tooltip-content">{helpText}</span>
            </ReactTooltip>
          ) : null}
        </div>
        {errors &&
          errors.map(error => (
            <DisplayErrors
              message={getErrorMessageByCode(error.code)}
              key={`error-${error.code}`}
            />
          ))}
      </>
    );
  }
}

const mapStateToProps = state => ({
  language: state.surveys.list.language,
  lng: state.params.lng,
});

const mapDispatchToProps = {
  addValidationErrors,
  removeValidationErrors,
  updateChoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoiceText);
