import React, { Component } from 'react';
import { fetchRankingClients } from 'thunks';
import { setRankingClientsPaginationPage, setRankingClientsPageSize, initializedRankingClients } from 'actions';
import { LIST_PAGE_SIZES } from 'Common';
import NotFoundPage from 'NotFoundPage';
import RankingClientsFilter from './ranking-clients/RankingClientsFilter';
import RankingClientsList from './ranking-clients/RankingClientsList';
import controlAccess from './controlAccess';
import TTPPaginator from '../common/TTPPaginator';

class RankingClientsView extends Component {
  constructor(props) {
    super(props);

    this.handleRankingListPageClick = this.handleRankingListPageClick.bind(this);
    this.handleSelectRankingListPageSize = this.handleSelectRankingListPageSize.bind(this);
    this.renderRankingPage = this.renderRankingPage.bind(this);
  }

  componentDidMount() {
    const { surveySlug, survey: { slug, latestInstance }, organization } = this.props;
    if (slug && surveySlug == slug) {
      this.props.initializedRankingClients();
      this.props.fetchRankingClients(latestInstance ? latestInstance.id : 0, organization);
    }
  }

  componentDidUpdate(prevProps) {
    const { survey: { slug, latestInstance }, organization, rankingSort, filter } = this.props;
    const prevSurvey = prevProps.survey;

    if (
      prevProps.filter !== filter
      || prevProps.organization != this.props.organization
      || (slug != prevSurvey.slug && slug != null)
      || rankingSort != prevProps.rankingSort
    ) {
      this.props.fetchRankingClients(latestInstance ? latestInstance.id : 0, organization);
    }
  }

  handleRankingListPageClick(data) {
    const selectedPage = data.selected;
    this.renderRankingPage(selectedPage + 1);
  }

  handleSelectRankingListPageSize(event) {
    const pageSize = event.target.value;
    this.renderRankingPage(1, pageSize);
  }

  renderRankingPage(paginationPage, pageSize = null) {
    this.props.setRankingClientsPaginationPage(paginationPage);
    if (pageSize) {
      this.props.setRankingClientsPageSize(pageSize);
    }
  }

  renderPaginate() {
    const { ranking, filter } = this.props;
    if (ranking.error != null && ranking.error.code == 404) {
      return '';
    }

    return (
      <TTPPaginator
        onPageChange={this.handleRankingListPageClick}
        pageSize={filter.pageSize}
        nbResult={ranking.nbResult}
        paginationPage={filter.paginationPage}
      />
    );
  }

  render() {
    const { filter, organization, hide, disabled, surveyFetching } = this.props;

    if ((hide || disabled) && !surveyFetching) {
      return <NotFoundPage />;
    }

    return (
      <div className="row wrapper">
        <div className="ranking-list">
          <div className="row">
            <div className="medium-1 columns">
              <select className="filter-select" value={filter.pageSize} onChange={this.handleSelectRankingListPageSize}>
                {LIST_PAGE_SIZES.map(pagesize => <option key={`page-size-${pagesize}`} value={pagesize}>{pagesize}</option>)}
              </select>
            </div>
            <div className="medium-6 columns">
              <RankingClientsFilter onFilterApply={this.renderRankingPage} />
            </div>
          </div>
          <div className="row">
            <div className="medium-12 columns">
              <RankingClientsList surveySlug={this.props.surveySlug} organizationId={organization} />
            </div>
          </div>
          {this.renderPaginate()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ranking: state.rankingClients.list,
  filter: state.rankingClients.filter.filter,
  rankingSort: state.rankingClients.sort,
  surveyFetching: state.surveys.list.fetching,
});

const mapDispatchToProps = {
    fetchRankingClients, setRankingClientsPaginationPage, setRankingClientsPageSize, initializedRankingClients,
};

export default controlAccess(mapStateToProps, mapDispatchToProps)(RankingClientsView);
