import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { bindActionCreators } from 'redux';
import NotFoundPage from 'NotFoundPage';
import { fetchTemplates } from 'thunks';
import { initTemplateFilter, setTemplatePaginationPage } from 'actions';
import TemplateItem from './TemplateItem';
import TemplateFilter from './TemplateFilter';

const ThemeGalleryFetching = () => (
  <div className="column small-3">
    <div className="template-item gradiant">
      <div className="template-item__box gradiant" />
    </div>
  </div>
);

class GalleryTab extends Component {
  state = {
    hasMoreItems: true,
  };

  componentDidMount() {
    this.props.initTemplateFilter();
  }

  componentDidUpdate(prevProps) {
    const { currentTab, filter, fetching } = this.props;
    if (currentTab == 'CODE_OWN') {
      return;
    }

    if (filter != prevProps.filter) {
      this.props.fetchTemplates(filter.type);
    }
    if (fetching != prevProps.fetching) {
      this.setState({ // eslint-disable-line react/no-did-update-set-state
        hasMoreItems: !fetching,
      });
    }
  }

  loadItems = () => {
    const { fetching, nbResult, templates, filter } = this.props;
    if (!fetching && templates.length < nbResult) {
      const currentPage = filter.paginationPage;
      this.setState({ hasMoreItems: false });
      this.props.setTemplatePaginationPage(currentPage + 1);
    }
  }


  render() {
    const { templates, fetching, currentTab, handleShowPreview, lng } = this.props;
    const { hasMoreItems } = this.state;

    const items = [];
    if (templates && templates.length > 0) {
      for (const template of templates) {
        items.push(<TemplateItem key={template.id} template={template} handleShowPreview={handleShowPreview} lng={lng} />);
      }
    }
    if (fetching) {
      for (let i = 0; i < 4; i++) {
        items.push(<ThemeGalleryFetching key={i} />);
      }
    }

    return (
      <div className={`tab-pane template boxes row align-center align-middle ${currentTab != 'CODE_OWN' && 'active'}`}>
        <div className="row small-10 filter-wrapper">
          <div className="column">
            <TemplateFilter onFilterApply={this.renderSurveysPage} />
          </div>
        </div>
        <div className="row small-10">
          <div className="column">
            {
              (items.length > 0) ? (
                <InfiniteScroll
                  className="row small-up-4 boxes--padding"
                  pageStart={0}
                  loadMore={this.loadItems}
                  hasMore={hasMoreItems}
                  initialLoad={false}
                >
                  {items}
                </InfiniteScroll>
              ) : <NotFoundPage mainClass="small-12" />
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  templates: state.templates.items,
  nbResult: state.templates.nbResult,
  fetching: state.templates.fetching,
  filter: state.templates.filter,
  lng: state.params.lng,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchTemplates,
    initTemplateFilter,
    setTemplatePaginationPage,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryTab);
