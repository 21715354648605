import React from 'react';

const NoDataFound = props => (
  <div className="row no-data-page">
    <div className="wrapper">
      <div className="icon">
        <span><i className="icomoon-icon icon-cloud-error" /></span>
      </div>
      <div className="msg">{props.msg}</div>
      <div className="moreinfo">{props.info}</div>
    </div>
  </div>
);

export default NoDataFound;
