import axios from 'axios';
import { TTP_API_URL } from 'Config';
import { getRankingOptions } from 'TamtamAPI';

export const getRankingOrganizations = (token, instanceId, data) => {
  const requestUrl = `${TTP_API_URL}/survey/ranking/organizations`;
  const {
    pageSize, paginationPage, country, city, organizationName, sortConfig,
  } = data;

  const filter = [];
  const sort = [];

  if (instanceId != null) {
    filter.push({
      property: 'instance',
      value: instanceId,
      operator: 'eq',
    });
  }
  if (country != null && country !== 'all') {
    filter.push({
      property: 'organization.country',
      value: country,
      operator: 'eq',
    });
  }
  if (city != null && city !== 'all') {
    filter.push({
      property: 'organization.city',
      value: city,
      operator: 'eq',
    });
  }
  if (organizationName) {
    filter.push({
      property: 'organization.name',
      value: organizationName,
      operator: 'like',
    });
  }

  if (sortConfig) {
    sort.push({
      property: sortConfig.columnKey,
      dir: sortConfig.sortDirection,
    });
  }

  const params = {
    access_token: token,
    limit: pageSize || 10,
    start: (!paginationPage) ? 0 : ((paginationPage - 1) * pageSize),
  };

  params.filter = JSON.stringify(filter);
  params.sort = JSON.stringify(sort);

  return axios.get(requestUrl, { params });
};

export const getRankingOrganizationsOptions = token => getRankingOptions(token);
