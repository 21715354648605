import React, { Component } from 'react';
import { TTP_MERCURE_URL, TTP_API_URL, MERCURE_SUBSCRIBER_JWT_SECRET } from 'Config';
import { EventSourcePolyfill } from 'event-source-polyfill';
import TTButton from './common/TTButton';

/**
 * USED FOR DEBUGGING / SUBSCRIBE TO MERCURE EVENTS
 */
export default class SubscriberQ extends Component {
  state = { data: { eventType: 'none' }, topic: `${TTP_API_URL}/survey/instance/{INSERT PIN HERE}/status` };

  subscribe = () => {
    const { topic } = this.state;
    const url = new URL(TTP_MERCURE_URL);
    url.searchParams.append('topic', topic);

    this.eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${MERCURE_SUBSCRIBER_JWT_SECRET}`,
      }
    });

    this.eventSource.onmessage = (event) => {
      // const data = JSON.parse(event.data);
      const { data } = event;
      this.setState({ data });
      console.log('    ');
      console.log('******* NEW DATA *******');
      console.log(data);
      console.log('*************************');
      console.log('    ');
    };

    console.log('    ');
    console.log('******* SUBSCRIBEEEEEED *******');
    console.log('    ');
  }

  canceSubscribe = () => {
    if (this.eventSource) {
      this.eventSource.close();

      console.log('    ');
      console.log('******* CANCELEEEEEEEEED *******');
      console.log('    ');
    }
  }

  render() {
    return (
      <div style={{ height: '100vh', background: '#FAFAFB', fontSize: '30px' }}>
        <div className="m-y-l">
          <div className="m-y-m row">
            <span className="m-r-m">Topic IS:</span>
            <span>{this.state.topic}</span>
          </div>
          <div className="row">
            <input type="text" value={this.state.topic} onChange={({ target: { value } }) => this.setState({ topic: value })} />
          </div>
        </div>
        <div className="row m-y-l">{this.state.data.eventType}</div>
        <div className="row">
          <TTButton
            text="Subscribe"
            className="p-x-xxl m-r-xxl"
            onClick={this.subscribe}
            fontSize="20px"
            color="blue"
            upperCase={true}
            disabled={false}
          />
          <TTButton
            text="Cancel Subscribe"
            className="p-x-xxl"
            onClick={this.canceSubscribe}
            fontSize="20px"
            color="blue"
            upperCase={true}
            disabled={false}
          />
        </div>
      </div>
    );
  }
}
