import React, { Component } from 'react';
import _ from 'lodash';

export default class Selection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
    };
  }

  onChange(value) {
    const { attribute, addOption } = this.props;

    this.setState({
      value,
    });
    addOption(attribute, value);
  }

  renderOptions() {
    const { options } = this.props;
    return _.map(options, (option, key) => <option key={key}>{option}</option>);
  }

  render() {
    return (
      <div className="settings__block">
        <div className="settings__container">
          <div className="settings__container__label">{this.props.label}</div>
        </div>
        <div className="settings__container">
          <select className="settings__input" onChange={e => this.onChange(e.target.value)} value={this.state.value}>
            {this.renderOptions()}
          </select>
        </div>
      </div>
    );
  }
}
