import { createStore } from 'redux';
import { persistStore } from 'redux-persist';
import { createReducer } from './createReducer';
import { createEnhancer } from './createEnhancer';

const configureStore = (initialState = {}) => {
  const reducer = createReducer();
  const enhancer = createEnhancer();
  return createStore(reducer, initialState, enhancer);
};

export default () => {
  const store = configureStore();
  const persistor = persistStore(store);

  return { store, persistor };
};
