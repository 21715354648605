import React, { Component } from 'react';
import __ from 'lodash';
import _ from 'i18n';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TOOLBAR_ITEMS } from 'SurveyConfig';
import { persistSurvey, refreshCurrentQuestion } from 'thunks';
import ToolbarItem from './ToolbarItem';

class Toolbar extends Component {
  constructor(props) {
    super(props);

    const items = (this.props.items) ? this.props.items : TOOLBAR_ITEMS;

    this.state = {
      items,
      showSettings: false,
    };
  }

  showSettings() {
    const { refreshCurrentQuestion } = this.props;

    this.setState(prevState => ({
      ...prevState,
      showSettings: !prevState.showSettings,
    }));

    refreshCurrentQuestion();
  }

  render() {
    return (
      <div className="s-toolbar">
        <div className="s-toolbar__title separator">
          <div className="s-toolbar__title__label survey-font survey-font--upperCase">{ _('Questions')}</div>
          <div className="s-toolbar__title__icon" onClick={this.props.closeSlider}>
            <span><i className="icomoon-icon icon-cross" /></span>
          </div>
        </div>
        <div className="s-toolbar__items">
          {
            __.map(this.state.items, item => <ToolbarItem data={item} key={item.key} />)
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ survey: state.surveys.list.survey });

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ persistSurvey, refreshCurrentQuestion }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
