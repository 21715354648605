import React, { Component } from 'react';
import DimensionMaker from './DimensionMaker';

export default class DimensionMakerList extends Component {
  state = { focusOnMount: false };

  componentDidUpdate(prevProps) {
    if (
      !this.state.focusOnMount &&
      !prevProps.fetching &&
      prevProps.dimensions?.length !== this.props.dimensions?.length
    ) {
      this.setState({ focusOnMount: true });
    }
  }

  renderListDimensions() {
    const { dimensions } = this.props;

    if (Array.isArray(dimensions)) {
      return dimensions.map((dimension, key) => {
        if (dimension.dimensionOrder !== key) {
          dimension.dimensionOrder = key;
          dimension.isUpdated = true;
        }

        return (
          <DimensionMaker
            key={`dimension-${dimension.id || dimension.uid}`}
            order={key}
            dimension={dimension}
            language={this.props.language}
            focusOnMount={this.state.focusOnMount}
          />
        );
      });
    }
    return null;
  }

  render() {
    return <div>{this.renderListDimensions()}</div>;
  }
}
