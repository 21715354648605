import React from 'react';

export default function QuizPinPreview(props) {
  const { params: { pin } } = props.match;

  return (
    <div className="fp flex-container flex-column-center flex-1 quiz-pin-page">
      <h1>{pin}</h1>
    </div>
  );
}
