// ranking clients

export const initializedRankingClients = () => ({
  type: 'INITIALIZED_RANKING_CLIENTS',
});
export const fetchRankingClients = resp => ({
  type: 'FETCH_RANKING_CLIENTS',
  payload: resp,
});

export const fetchRankingClientsOptions = resp => ({
  type: 'FETCH_RANKING_CLIENTS_OPTIONS',
  payload: resp,
});


// Filters:

export const setRankingClientsPaginationPage = pageNumber => ({
  type: 'SET_RANKING_CLIENTS_PAGINATION_PAGE',
  pageNumber,
});

export const setRankingClientsPageSize = pageSize => ({
  type: 'SET_RANKING_CLIENTS_PAGE_SIZE',
  pageSize,
});

export const setRankingClientsFilterCountry = country => ({
  type: 'SET_RANKING_CLIENTS_FILTER_COUNTRY',
  country,
});

export const setRankingClientsFilterCity = city => ({
  type: 'SET_RANKING_CLIENTS_FILTER_CITY',
  city,
});

export const setRankingClientsFilterClientName = clientName => ({
  type: 'SET_RANKING_CLIENTS_FILTER_CLIENT_NAME',
  clientName,
});


// SORT:
export const setRankingClientsSortConfig = (columnKey, sortDirection) => ({
  type: 'SET_RANKING_CLIENTS_LIST_SORT_CONFIG',
  config: {
    columnKey,
    sortDirection,
  },
});
