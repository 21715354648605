import React, { Component } from 'react';
import { fetchQuizParticipants, calculateQuizResponseScore } from 'thunks';
import _ from 'i18n';
import __ from 'lodash';
import { connect } from 'react-redux';
import StatsNotFound from 'stats/StatsNotFound';

class QuizParticipants extends Component {
  state = { participants: [], fetching: true, fetched: false };

  componentDidMount() {
    const { instance } = this.props;

    if (instance && instance.id) {
      this.handleInstanceChanged();
    }
  }

  componentDidUpdate(prevProps) {
    const { instance } = this.props;

    if (prevProps.instance !== instance && instance) {
      this.handleInstanceChanged();
    }
  }

  handleInstanceChanged() {
    const { instance } = this.props;

    if (instance.scoreStatus != 'CALCULATED') {
      this.setState({ processing: true });

      this.props.calculateQuizResponseScore(instance.id)
        .then(() => {
          this.fetchParticipants();
        })
        .catch(() => this.fetchParticipants());
    } else {
      this.fetchParticipants();
    }
  }

  fetchParticipants() {
    const { instance } = this.props;
    this.setState({ fetching: true, processing: false });

    this.props.fetchQuizParticipants(instance.id)
      .then(({ data: { data } }) => {
        this.setState({ fetching: false, fetched: true, participants: data });
      })
      .catch(() => this.setState({ fetching: false, participants: [], fetched: false }));
  }


  renderParticipant(participant, rank) {
    const { score, user } = participant;
    const firstName = __.isEmpty(user.firstName) ? '' : user.firstName.trim();
    const lastName = __.isEmpty(user.lastName) ? '' : user.lastName.trim();
    const fullName = `${firstName}${firstName.length > 0 ? ' ' : ''}${lastName}`.trim();

    return (
      <div className="user-wrapper column" id={`participant-${user.id}-${rank}`} key={user.id}>
        <div className="wrapper">
          <div className="rank">{rank}</div>
          <div className="infos">
            <span className="name">{fullName.length > 0 ? fullName : user.mainEmail}</span>
            <span className="score">{score || 0}</span>
          </div>
        </div>
      </div>
    );
  }

  renderParticipants() {
    const { fetching, processing, participants } = this.state;

    if (processing) {
      return <div className="processing small-12">{`${_('Processing')} ...`}</div>;
    }

    if (fetching) {
      return <div className="processing small-12"><img alt="" src="/img/loaders/tamtam.svg" /></div>;
    }

    return participants.map((participant, index) => this.renderParticipant(participant, index + 1));
  }

  render() {
    const {
      fetched,
      fetching,
      processing
    } = this.state;

    if (!fetched && !fetching && !processing) {
      return (
        <>
          <div className="row summary__wrapper">
            <div className="summary__block summary__header column small-12 responses">
              <span className="text">{_('Participants')}</span>
            </div>
          </div>
          <div className="row">
            <div className="summary__block">
              <StatsNotFound />
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="row summary__wrapper">
          <div className="summary__block summary__header column small-12 responses">
            <span className="text">{_('Participants')}</span>
          </div>
        </div>
        <div className="row summary__wrapper quiz-participants">
          <div className="summary__block question-ranking row small-up-2">
            {this.renderParticipants()}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  stats: state.stats.list,
  fetching: state.stats.list.fetching,
  fetched: state.stats.list.fetched,
});

export default connect(mapStateToProps, { fetchQuizParticipants, calculateQuizResponseScore })(QuizParticipants);
