import __ from 'lodash';

/**
 * @returns object : {
 *  key,
 *  errors: { title : array, description: array }
 * }
 */
export const validateDimension = ({ id, uid, title, description }, validationResults) => {
  const key = id || uid;
  const titleErrors = [];

  if (title) {
    __.forEach(title, (text, language) => {
      if (text == null || String(text).trim() == '') {
        delete title[language];
      } else if (text.trim().length < 2) {
        titleErrors.push({ code: 150302, language });
      }
    });
  } else {
    titleErrors.push({ code: 150302 });
  }

  if (titleErrors.length == 0 && __.isEmpty(title)) {
    titleErrors.push({ code: 150302 });
  }


  if (description) {
    __.forEach(description, (text, language) => {
      if (text == null || String(text).trim() == '') {
        delete description[language];
      }
    });
  }

  let exist = false;
  const code = 150302;
  if (validationResults.title.hasOwnProperty(key) && !__.isEmpty(validationResults.title[key])) {
    validationResults.title[key].forEach((propertyError) => {
      if (propertyError.code === code) {
        exist = true;
      }
    });
  }

  if (!exist) {
    validationResults.title[key] = titleErrors.length != 0 ? [titleErrors[0]] : [];
  }

  return validationResults;
};
