import React, { PureComponent, Fragment } from 'react';

export default class Content extends PureComponent {
  render() {
    const { url, title, showHeader } = this.props;
    return (
      <Fragment>
        {
          showHeader && (
            <div className="rmodal__article-header grid-x">
              <input
                type="text"
                defaultValue={url}
                className="cell small-12"
                key="urlBar_input"
              />
              <a href={url} target="_blanc" key="urlBar_icon">
                <i className="icon icon-paper-plane" />
              </a>
            </div>
          )
        }
        <div className="cell rmodal__body grid-x article-file data_viewer">
          <div className="data_content media_container small-12 medium-12 large-12 rmodal__left">
            <iframe
              id="external-url"
              title={title}
              src={url}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
