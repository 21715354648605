import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/lib/Async';
import { CSSTransition } from 'react-transition-group';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';

import moment from 'moment';
import { SORT_OPTIONS } from 'Common';
import _ from 'i18n';
import PageSize from 'common/list/PageSize';
import SearchBox from './SearchBox';
import { colorStyles, formatGroupLabel, filterValues } from './filterUtils';

class TTPFilterHorizontalBar extends Component {
  constructor(props) {
    super(props);
    this.state = { filterFormIsVisible: false };

    this.sortOptions = SORT_OPTIONS.map(({ label, value }) => ({ label: _(label), value }));
  }

  handleToggleFilterForm() {
    const { filterFormIsVisible } = this.props;
    this.setState(prevState => ({ filterFormIsVisible: !prevState.filterFormIsVisible }), () => {
      if (filterFormIsVisible) {
        filterFormIsVisible(this.state.filterFormIsVisible);
      }
    });
  }

  handleResetFilter = () => {
    this.props.handleResetFilter();
  };

  handleSearchInputChange = (word) => {
    this.props.handleSearchInputChange(word);
  };

  onInputChange = (inputValue, action, isSimple = false) => {
    let selected = null;
    if (isSimple) {
      selected = inputValue ? `${inputValue.value}` : null;
    } else {
      selected = inputValue.map(({ value }) => value);
    }
    action(selected);
  }

  renderFilters = () => {
    const { filters, asyncFilters, sort, handleListSortChange, onChange, handleDateRangeChange, dateRange, language } = this.props;

    let i = 0;
    const colCount = (filters ? filters.length : 0)
      + (asyncFilters ? asyncFilters.length : 0)
      + ((sort && handleListSortChange) ? 1 : 0)
      + ((dateRange && handleDateRangeChange) ? 1 : 0) <= 2 ? 2 : 3;

    const filterCols = [];
    for (let j = 0; j < colCount; j++) {
      filterCols[j] = [];
    }

    if (filters && filters.length > 0) {
      filters.forEach(({ placeholder, options, values, action, isSimple }) => {
        filterCols[(i++) % colCount].push(
          <div key={placeholder} className="columns">
            <label>
              <span className="lbl">{_(placeholder)}</span>
              <Select
                isMulti={!isSimple}
                isSearchable={false}
                isClearable={true}
                closeMenuOnSelect={isSimple == true}
                placeholder={placeholder}
                options={options}
                value={filterValues(options, values)}
                onChange={inputValue => this.onInputChange(inputValue, action, isSimple)}
                className={`ttp-select uppercase ${values?.length > 0 ? (isSimple ? 'filled-box' : '') : 'empty-box'}`}
                classNamePrefix="ttp-select"
                formatGroupLabel={formatGroupLabel}
                styles={options[0] && options[0].color && colorStyles}
              />
            </label>
          </div>
        );
      });
    }

    if (asyncFilters && asyncFilters.length > 0) {
      asyncFilters.forEach(({ placeholder, loadSuggestions, handleChange, value }) => {
        const options = {
          isMulti: 'true',
          cacheOptions: 'true',
          closeMenuOnSelect: 'false',
          placeholder: _(placeholder),
          onChange: onChange ? handleChange : inputValue => this.onInputChange(inputValue, handleChange),
          className: 'ttp-select',
          classNamePrefix: 'ttp-select',
          styles: colorStyles
        };
        if (value) {
          options.value = value;
        }

        filterCols[(i++) % colCount].push(
          <div key={placeholder} className="columns">
            <label>
              <span className="lbl">{_(placeholder)}</span>
              <AsyncSelect
                {...options}
                defaultOptions
                loadOptions={loadSuggestions}
                className={`ttp-select ${value && value.length > 0 ? '' : 'empty-box'}`}
              />
            </label>
          </div>
        );
      });
    }

    if (sort && handleListSortChange) {
      filterCols[(i++) % colCount].push(
        <div key={`sort${i}`} className="columns">
          <label>
            <span className="lbl">{_('Sort by')}</span>
            <Select
              isSearchable={false}
              options={this.sortOptions}
              value={this.sortOptions.filter(s => s.value == sort)}
              onChange={inputValue => this.onInputChange(inputValue, handleListSortChange, true)}
              className="ttp-select filled-box uppercase"
              classNamePrefix="ttp-select"
            />
          </label>
        </div>
      );
    }

    if (handleDateRangeChange && dateRange) {
      moment.locale(language);
      filterCols[(i++) % colCount].push(
        <div key={`filter-${i}`} className="columns">
          <label className={!dateRange.startDate && !dateRange.endDate ? 'empty-date-picker' : 'filled-date-picker'}>
            <span className="lbl">{_('date range')}</span>
            <DateRangePicker
              showClearDates
              isOutsideRange={day => moment().diff(day) < 0}
              startDate={dateRange.startDate}
              startDateId="your_unique_start_date_id"
              endDate={dateRange.endDate}
              endDateId="your_unique_end_date_id"
              onDatesChange={handleDateRangeChange}
              focusedInput={this.state.focusedInput}
              onFocusChange={focusedInput => this.setState({ focusedInput })}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel
              startDatePlaceholderText={_('start date')}
              endDatePlaceholderText={_('end date')}
            />
          </label>

        </div>
      );
    }

    return filterCols.map((filters, index) => (<div key={`col-${index}`} className={`small-${colCount == 2 ? 6 : 4}`}>{filters}</div>));
  }

  render() {
    const { filterFormIsVisible } = this.state;
    const { customAddOn, cssClass, pageSize, handleListPageSizeChange, searchPlaceholder, handleSearchInputChange } = this.props;

    return (
      <div id="ttp-horizontal-filter" className={`${filterFormIsVisible && 'ttp-horizontal-filter__open'} ${cssClass}`}>
        <div className="filter-wrapper">
          {this.props.children}
          {handleSearchInputChange && <SearchBox placeholder={searchPlaceholder} onChange={this.handleSearchInputChange} />}
          {pageSize && <PageSize pageSize={pageSize} onChange={handleListPageSizeChange} />}
          <button className="filter-button m-l-s" type="button" onClick={this.handleToggleFilterForm.bind(this)}>
            {_('filter')}
            <svg className={filterFormIsVisible ? 'filter-icon-white' : ''} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0)">
                <path d="M11.7379 8.01789L12.9818 8.01789C13.5441 8.01789 14 7.56194 14 6.99973C14 6.43737 13.5441 5.98145 12.9818 5.98145L11.7379 5.98145C11.8799 6.29268 11.9637 6.6358 11.9637 6.99973C11.9637 7.36351 11.8799 7.70663 11.7379 8.01789Z" />
                <path d="M7.24902 5.98145L1.0183 5.98145C0.456005 5.98145 0.000169257 6.4374 0.000169233 6.99973C0.000169208 7.56194 0.456005 8.01789 1.0183 8.01789L7.24876 8.01789C7.10682 7.70663 7.02302 7.36351 7.02302 6.99973C7.02302 6.6358 7.10708 6.29268 7.24902 5.98145Z" />
                <path d="M10.9458 6.99968C10.9458 6.19925 10.2942 5.54785 9.4936 5.54785C8.69293 5.54785 8.04136 6.19925 8.04136 6.99968C8.04136 7.80052 8.69293 8.45224 9.4936 8.45224C10.2943 8.45227 10.9458 7.80052 10.9458 6.99968Z" />
                <path d="M1.64893 11.0723L1.01827 11.0723C0.455975 11.0723 0.00014017 11.5282 0.000140146 12.0904C0.000140121 12.6528 0.455975 13.1085 1.01827 13.1085L1.64867 13.1085C1.50672 12.7975 1.42301 12.4544 1.42301 12.0904C1.42298 11.7264 1.50698 11.3835 1.64893 11.0723Z" />
                <path d="M6.13795 13.1085L12.9818 13.1085C13.5441 13.1085 14 12.6528 14 12.0904C14 11.5282 13.5441 11.0723 12.9818 11.0723L6.13795 11.0723C6.27983 11.3835 6.36364 11.7264 6.36364 12.0904C6.36364 12.4543 6.27983 12.7975 6.13795 13.1085Z" />
                <path d="M5.34521 12.0905C5.34521 11.2901 4.69376 10.6389 3.89301 10.6389C3.09234 10.6389 2.44077 11.2901 2.44077 12.0905C2.44077 12.8916 3.09234 13.5433 3.89301 13.5433C4.69376 13.5433 5.34521 12.8916 5.34521 12.0905Z" />
                <path d="M2.66699 0.890625L1.01812 0.890625C0.455824 0.890625 -1.05103e-05 1.3464 -1.05349e-05 1.90879C-1.05595e-05 2.471 0.455824 2.92695 1.01812 2.92695L2.66673 2.92695C2.52476 2.61569 2.44096 2.27274 2.44096 1.90879C2.44096 1.54483 2.52502 1.20188 2.66699 0.890625Z" />
                <path d="M7.15608 2.92671L12.9818 2.92671C13.5441 2.92671 14 2.47076 14 1.90854C14 1.34616 13.5441 0.890381 12.9818 0.890381L7.15608 0.890381C7.29802 1.20164 7.38183 1.54459 7.38183 1.90854C7.38183 2.2725 7.29802 2.61545 7.15608 2.92671Z" />
                <path d="M6.36328 1.90869C6.36328 1.10823 5.71171 0.457031 4.91104 0.457031C4.11041 0.457031 3.45884 1.10823 3.45884 1.90869C3.45884 2.7095 4.11041 3.36142 4.91104 3.36142C5.71171 3.36142 6.36328 2.70947 6.36328 1.90869Z" />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="14" height="14" fill="white" transform="translate(14) rotate(90)" />
                </clipPath>
              </defs>
            </svg>
          </button>
          {customAddOn}
        </div>
        <CSSTransition in={filterFormIsVisible} timeout={200} classNames="filter-form" unmountOnExit>
          <div className="row filter-form">
            {this.renderFilters()}
            <div className="filter-close" onClick={this.handleToggleFilterForm.bind(this)}>
              <img className="icomoon icon-tt-close" src="/img/icons/close.svg" alt="" />
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

const mapStateToProps = store => ({ language: store.params.lng });
export default connect(mapStateToProps)(TTPFilterHorizontalBar);
