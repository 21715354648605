export const fetchParticipantUsers = resp => ({
  type: 'FETCH_PARTICIPANT_USER',
  payload: resp,
});

export const sendTransactionalMails = resp => ({
  type: 'SEND_PARTICIPANT_TRANSACTIONAL_MAIL',
  payload: resp,
});

// Filters:

export const initializeParticipantUserFilter = () => ({
  type: 'INITIALIZE_PARTICIPANT_USER_FILTER',
});

export const setParticipantUserPaginationPage = pageNumber => ({
  type: 'SET_PARTICIPANT_USER_PAGINATION_PAGE',
  pageNumber,
});

export const setParticipantUserPageSize = pageSize => ({
  type: 'SET_PARTICIPANT_USER_PAGE_SIZE',
  pageSize,
});

export const setParticipantUserFilter = (property, value) => ({
  type: 'SET_PARTICIPANT_USER_FILTER',
  property,
  value
});
