import React, { useState, useEffect } from 'react';
import _ from 'i18n';
import shortId from 'shortid';
import TTButton from 'common/TTButton';
import { TTPDropDownMenu, MenuItem } from 'common/TTPDropDownMenu';
import { NotificationManager } from 'react-notifications';
import Modal from '../survey/Modal';
import PopupSurveyTranslationStatus from '../survey/PopupSurveyTranslationStatus';

export default function LibraryItemFooter(props) {
  const {
    survey,
    isUsed,
    participate,
    cancelParticipate,
    goToMyLibrary,
  } = props;

  const [openedModal, setOpenedModalId] = useState();
  let acceptRequired;

  const uid = survey?.id ?? shortId.generate();
  const popupTranslationStatusId = `popup-translation-status-${uid}`;
  const popupParticipateId = `popup-participate-${uid}`;
  const popupCancelParticipateId = `popup-cancel-participate-${uid}`;

  if (survey.type == 'SATISFACTION') {
    acceptRequired = survey.isSectorial && survey?.meta?.obligatoryRanking;
  } else if (survey.type == 'OPINION') {
    acceptRequired = survey.isSectorial && survey?.meta?.consolidatedResults;
  }

  useEffect(() => {
    $(`#${popupTranslationStatusId}`).foundation();

    return () => {
      $(`#${popupTranslationStatusId}`).foundation('_destroy');
      $(`#${popupTranslationStatusId}`).remove();
    };
  }, []);

  const handleCloseModal = () => setOpenedModalId(null);
  const handleUseButton = () => {
    setOpenedModalId(isUsed ? popupCancelParticipateId : popupParticipateId);
  };

  const handleParticipate = (isAccepted) => {
    participate(survey, isAccepted)
      .then((res) => {
        onSuccess(res, _('participation_msg_success'), _('Participate'));
        handleCloseModal();
        goToMyLibrary();
      })
      .catch(() => {
        onFailure();
        handleCloseModal();
      });
  };

  const handleCancelParticipate = () => {
    const { id } = survey;
    cancelParticipate(id)
      .then((res) => {
        onSuccess(res, _('cancel_participate_msg_success'), _('cancel_participate'));
        handleCloseModal();
      })
      .catch(() => {
        onFailure();
        handleCloseModal();
      });
  };

  const onSuccess = (res, msg, title) => {
    if (res.value instanceof Error) {
      onFailure();
    } else {
      NotificationManager.success(msg, title);
    }
  };

  const onFailure = () => {
    NotificationManager.error(_('errorOccured'), _('error'));
  };

  return (
    <>
      <div className="library-actions">
        <TTPDropDownMenu type="click">
          <MenuItem
            text={_('preview')}
            to={`/survey/preview/${survey.slug}`}
            isExternal={true}
          />
          <MenuItem
            text={_('Translation status')}
            dataOpen={popupTranslationStatusId}
          />
        </TTPDropDownMenu>
        <PopupSurveyTranslationStatus popupId={popupTranslationStatusId} survey={survey} />
        <Modal
          labels={{ action: _('Submit') }}
          isOpen={openedModal == popupCancelParticipateId}
          action={handleCancelParticipate}
          onCloseModal={handleCloseModal}
          title={_('cancel_participate')}
          acceptRequired={acceptRequired}
        >
          <p>{_('cancel_participate_msg')}</p>
        </Modal>
        <Modal
          labels={{ action: _('Participate') }}
          isOpen={openedModal == popupParticipateId}
          action={handleParticipate}
          onCloseModal={handleCloseModal}
          title={_('Participate')}
          acceptRequired={acceptRequired}
        >
          <p>{_('participate_msg')}</p>
        </Modal>
      </div>
      <TTButton
        text={isUsed ? _('Remove') : _('Participate')}
        className="p-x-s p-y-xs m-b-xxs"
        onClick={handleUseButton}
        fontSize=".8rem"
        color="blue"
        upperCase={true}
        disabled={false}
      />
    </>
  );
}
