import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getParents } from 'utils';
import { setParticipantUserFilter, initializeParticipantUserFilter } from 'actions';
import _ from 'i18n';
import BooleanFilter from './BooleanFilter';

class ParticipantsFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterFormIsVisible: false,
    };
    this.handleHideFilterForm = this.handleHideFilterForm.bind(this);
  }

  componentDidMount() {
    this.handleHideFilterForm();
  }

  handleHideFilterForm() {
    document.getElementById('app').addEventListener('click', (e) => {
      const parents = getParents(e.target, document.getElementById('app'));
      for (let i = 0; i < parents.length; i++) {
        if (parents[i].className.indexOf('filter-wrapper') != -1) {
          return;
        }
      }
      this.setState({ filterFormIsVisible: false });
    });
  }

  handleToggleFilterForm() {
    this.setState(prevState => ({ filterFormIsVisible: !prevState.filterFormIsVisible }));
  }

  handleResultsStatusFilter = ({ target: { value } }) => {
    this.props.setParticipantUserFilter('resultsStatus', value);
  }

  handleResetFilter() {
    this.props.initializeParticipantUserFilter();
  }

  render() {
    const { filterFormIsVisible } = this.state;
    const { filter } = this.props;

    return (
      <div className="filter-wrapper">
        <button type="button" className="button filter-button" onClick={this.handleToggleFilterForm.bind(this)}>
          {_('filter')}
          <i className="icon icon-arrow-down" />
        </button>
        <div className={filterFormIsVisible ? 'filter-form show' : ' filter-form hide'}>
          <BooleanFilter name={_('Invited')} property="invited" trueLabel={_('Invited')} falseLabel={_('Uninvited')} hasAll={true} value={filter.invited} handleFilterChange={this.props.setParticipantUserFilter} />
          <BooleanFilter name={`${_('Revival')} 1`} property="revival1" trueLabel={_('Received')} falseLabel={_('Not received')} hasAll={true} value={filter.revival1} handleFilterChange={this.props.setParticipantUserFilter} />
          <BooleanFilter name={`${_('Revival')} 2`} property="revival2" trueLabel={_('Received')} falseLabel={_('Not received')} hasAll={true} value={filter.revival2} handleFilterChange={this.props.setParticipantUserFilter} />
          <BooleanFilter name={`${_('PartialRevival')} 1`} property="partialRevival1" trueLabel={_('Received')} falseLabel={_('Not received')} hasAll={true} value={filter.partialRevival1} handleFilterChange={this.props.setParticipantUserFilter} />
          <BooleanFilter name={`${_('PartialRevival')} 2`} property="partialRevival2" trueLabel={_('Received')} falseLabel={_('Not received')} hasAll={true} value={filter.partialRevival1} handleFilterChange={this.props.setParticipantUserFilter} />
          <BooleanFilter name={_('Participated')} property="participated" trueLabel={_('Participated')} falseLabel={_('Not participated')} hasAll={true} value={filter.participated} handleFilterChange={this.props.setParticipantUserFilter} />
          <BooleanFilter name={_('Thanks')} property="thankReceived" trueLabel={_('Received')} falseLabel={_('Not received')} hasAll={true} value={filter.thankReceived} handleFilterChange={this.props.setParticipantUserFilter} />
          <label>
            {_('Results')}
            <select value={filter.resultsStatus} onChange={this.handleResultsStatusFilter}>
              <option value="">{_('all').toUpperCase()}</option>
              {['ASKED', 'NOT_ASKED', 'RECEIVED'].map(item => (
                <option key={`status-${item}`} value={item}>
                  {_(`STATUS_RESULTS_${item}`)}
                </option>
              ))}
            </select>
          </label>
          <button type="button" className="button secondary" onClick={this.handleResetFilter.bind(this)}>{_('reset')}</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  filter: state.participantUser.filter,
});

const mapDispatchToProps = { setParticipantUserFilter, initializeParticipantUserFilter };

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsFilter);
