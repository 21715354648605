import * as actions from 'actions';
import { getGenericEmailSettingsStats } from 'TamtamAPI';

export const fetchGenericEmailSettingsStats = () => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  const clientId = currentClient.id;
  const { survey } = getState().surveys.list;

  return dispatch(actions.fetchGenericEmailSettingsStats(getGenericEmailSettingsStats(token, clientId, survey?.id ?? 0)));
};
