import React, { Component } from 'react';
import { setCurrentNavPage } from 'actions';
import { connect } from 'react-redux';
import _ from 'i18n';
import * as lottie from 'lottie-web';
import { TTP_AMAZON_S3_PROD } from 'Config';

class HomePage extends Component {
  componentDidMount() {
    this.props.setCurrentNavPage('HOME');
    this.intiAnimation();
  }

  componentWillUnmount() {
    if (this.anim) {
      this.anim.destroy();
    }
  }

  intiAnimation = () => {
    if (this.anim) {
      this.anim.destroy();
    }

    this.anim = lottie.loadAnimation({
      container: this.refs.animationContainer,
      renderer: 'svg',
      loop: 1,
      autoplay: true,
      path: `${TTP_AMAZON_S3_PROD}/survey/assets/animations/survey-header.json`,
    });
  };

  handleClickToPause = () => {
    if (!this.anim) {
      return;
    }
    if (this.anim.isPaused) {
      this.anim.goToAndPlay(0);
    } else {
      this.anim.pause();
    }
  };

  render() {
    return (
      <div className="home-page p-b-xl" id="home-page">
        <div className={'animated-header'}>
          <div className="animated-header__title">
            <h3>{_('Easily create, distribute and analyze your surveys!')}</h3>
          </div>
          <div
            className={'animated-header__animation'}
            ref="animationContainer"
            onClick={this.handleClickToPause}
          />
          <video controls poster={`${TTP_AMAZON_S3_PROD}/survey/assets/survey-tuto-th.jpg`}>
            <source
              src={`${TTP_AMAZON_S3_PROD}/survey/assets/survey-tuto.mp4`}
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    );
  }
}

export default connect(null, { setCurrentNavPage })(HomePage);
