import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setStatsProfilingFilterQuestion } from 'actions';
import _ from 'i18n';
import { getTextByLanguage } from 'surveyUtils';
import { selectQuestionFilter } from 'reducers';
import ReactTooltip from 'react-tooltip';
import shortId from 'shortid';

class TextFilter extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    this.tooltipId = `tooltip-stats-${shortId.generate()}`;
    this.state = { value: value == 'all' ? '' : value };
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({ value: value == 'all' ? '' : value });
  }

  onChange = ({ target: { value } }) => {
    this.setState({ value });
  }

  onKeyDown = (e) => {
    if (e.key == 'Enter') {
      e.preventDefault();
      this.applyFilter();
    }
  };

  applyFilter = () => {
    const { question: { id } } = this.props;
    this.props.setStatsProfilingFilterQuestion(id, 'text', 'answerValue', this.state.value, 'like');
  }

  render() {
    const { text } = this.props.question;

    return (
      <div className="profiling-item column column-block">
        <div className="title srv-tooltip">
          <div className="greetings" data-tip data-for={this.tooltipId}>{getTextByLanguage(text, this.props.language)}</div>
          <ReactTooltip id={this.tooltipId} className="react-tooltip">
            <span className="tooltip-content">{getTextByLanguage(text, this.props.language)}</span>
          </ReactTooltip>
        </div>
        <div className="content">
          <div className="input-group">
            <input
              className="input-group-field"
              type="text"
              placeholder={`${_('search value')} ...`}
              value={this.state.value}
              onChange={this.onChange}
              onKeyDown={this.onKeyDown}
            />
            <div className="input-group-button">
              <button className="button tiny secondary" type="button" onClick={this.applyFilter}>OK</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, { question: { id } }) => {
  const filter = selectQuestionFilter(state.stats.filter, id);

  return ({
    value: filter != null ? filter.value : 'all',
  });
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setStatsProfilingFilterQuestion }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TextFilter);
