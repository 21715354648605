import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'i18n';

const NotFoundPage = (props) => {
  let { mainClass } = props;
  let renderedTxt = (
    <div>
      <p>{_('pageNotFound')}</p>
      <Link className="button btn__back" to="/">
        <i className="icon-action-undo" />
        {_('home')}
      </Link>
    </div>
  );

  if (mainClass == null) {
    mainClass = 'small-12 medium-9';
  } else {
    renderedTxt = '';
  }
  return (
    <div className="not-found row align-center">

      <div className={`not-fount__main row ${mainClass}`}>
        <div className="column small-4">
          <span className="not-fount__number">4</span>
        </div>
        <div className="column small-4">
          <span className="not-fount__number">0</span>
          {renderedTxt}
        </div>
        <div className="column small-4">
          <span className="not-fount__number">4</span>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
