export const fetchStats = resp => ({
  type: 'FETCH_STATS',
  payload: resp,
});

export const setStatsListFilterCountry = country => ({
  type: 'SET_STATS_FILTER_COUNTRY',
  country,
});

export const setStatsListFilterCity = city => ({
  type: 'SET_STATS_FILTER_CITY',
  city,
});

export const fetchStatsOptions = resp => ({
  type: 'FETCH_STATS_OPTIONS',
  payload: resp,
});

export const setStatsFilterShowAll = showAll => ({
  type: 'SET_STATS_FILTER_SHOW_ALL',
  showAll,
});

// PROFILING

export const setStatsProfilingFilterLanguge = language => ({
  type: 'SET_STATS_PROFILING_FILTER_LANGUAGE',
  language,
});

export const setStatsProfilingFilterQuestion = (questionId, resourceType, property, value, operator) => ({
  type: 'SET_STATS_PROFILING_FILTER_QUESTION',
  questionId,
  resourceType,
  property,
  value,
  operator
});

export const resetStatsProfilingFilter = () => ({
  type: 'RESET_STATS_PROFILING_FILTER'
});
