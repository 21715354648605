import React from 'react';
import _ from 'i18n';

export const StatsNotFound = () => (
  <div className="stats__not-found row">
    <div className="column small-12 icon">
      <img alt="" src="/img/pictos/stats.svg" />
    </div>
    <div className="column small-12 content">
      <div className="header">
        {_('No signs of movement')}
        {' ...'}
      </div>
      <div className="message">{_('stats_survey_no_data_found_msg')}</div>
    </div>
  </div>
);

export default StatsNotFound;
