import React from 'react';
import _ from 'i18n';
import { ReactComponent as AppsIcon } from 'icons/apps.svg';
import MenuItem from '../MenuItem';

export default function Apps({ apps, lng }) {
  return (
    <MenuItem
      renderIcon={() => <AppsIcon />}
      className="apps"
    >
      <div className="social-links__wrapper">
        <div className="social-links__header">{_('TAMTAM applications')}</div>
        <div className="social-links__body">
          <ul className="small-up-3">
            {
              apps.map(app => (
                <li className="cell small-4" key={app.code}>
                  <a className={`app-wrapper${app.activated ? ' enabled' : ' disabled'}`} href={app.activated ? app.url : null}>
                    <img alt="" src={app.icon} />
                    <div className="app-name">{app.name[lng]}</div>
                  </a>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </MenuItem>
  );
}
