import React, { Component } from 'react';
import SurveyItem from './SurveyItem';
import SurveyListFetching from './SurveyListFetching';
import NoDataFound from '../NoDataFound';

export default class SurveysList extends Component {
  render() {
    const { surveys, isTemplate, openSurveyModal } = this.props;

    if (surveys.fetching) {
      return <SurveyListFetching isTemplate={isTemplate} />;
    }

    if (!surveys.fetched) {
      return (
        <div className="columns srv-list-list">
          <NoDataFound />
        </div>
      );
    }
    return (
      <div className="small-12 columns">
        {surveys.items.map(survey => <SurveyItem survey={survey} isTemplate={isTemplate} key={survey.id} openSurveyModal={openSurveyModal} />)}
      </div>
    );
  }
}
