import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setAnonymousAuthUser, fetchSurveyWithToken } from 'thunks';
import {
  getClientCredential,
  getTTPFolder,
  fetchOrganizationInstance,
  saveContact,
} from 'TamtamAPI';
import { setCurrentNavPage } from 'actions';
import _ from 'i18n';
import __ from 'lodash';
import { EMAIL_VALIDATION_REGEX, onError } from 'utils';
import { TTP_HOME_URL, TTP_SURVEY_URL } from 'Config';
import TTRoundedButton from './common/TTRoundedButton';
import Switch from './common/Switch';

class AnonymousUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      email: '',
      firstName: '',
      lastName: '',
      emailError: '',
      firstNameError: '',
      lastNameError: '',
      joinCommunity: false,
      conditionAccepted: false,
      surveyFetching: false,
      surveyFetched: false,
      survey: {},
      organization: {},
    };

    const params = new URLSearchParams(location.search);
    this.organization = params.get('organization');
    this.slug = props.match.params.slug;
    this.organizationInstanceId = props.match.params.organizationInstanceId;
  }

  componentDidMount() {
    const { user } = this.props.auth;

    this.props.setCurrentNavPage('RESPONSE');

    if (this.slug) {
      this.setState({ surveyFetching: true });

      getClientCredential()
        .then(resp => {
          const clientToken = resp.data.token.access_token;
          this.setState({ token: clientToken });
          this.props
            .fetchSurveyWithToken(this.slug, clientToken)
            .then(resp => {
              const survey = resp.value.data.data;
              this.setState({
                surveyFetching: false,
                survey,
                surveyFetched: true,
              });
            })
            .catch(err => {
              this.setState({ surveyFetching: false });
            });

          if (this.organizationInstanceId) {
            fetchOrganizationInstance(
              clientToken,
              this.organizationInstanceId,
            ).then(resp =>
              this.setState({ organization: resp.data.data.organization }),
            );
          } else if (this.organization) {
            getTTPFolder(clientToken, this.organization).then(resp =>
              this.setState({ organization: resp.data.data }),
            );
          }
        })
        .catch(() => {
          this.setState({ surveyFetching: false });
        });
    }

    if (user && user.id) {
      this.props.history.push(
        `/survey/${this.slug}/${this.organizationInstanceId}${location.search}`,
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { user, fetched } = this.props.auth;

    if (prevProps.auth.user != user && fetched && user && user.id) {
      this.props.history.push(
        `/survey/${this.slug}/${this.organizationInstanceId}${location.search}`,
      );
    }
  }

  handleCheckbox = ({ target: { id, checked } }) => {
    if (id === 'TERMS') {
      this.setState({ conditionAccepted: checked });
    }
  };

  changeEmail = ({ target: { value: email } }) =>
    this.setState({ email, emailError: null });

  changeFirstName = ({ target: { value: firstName } }) =>
    this.setState({ firstName, firstNameError: null });

  changeLastName = ({ target: { value: lastName } }) =>
    this.setState({ lastName, lastNameError: null });

  handleValidateField = (field, keyError) => {
    if (field === 'email') {
      const pattern = new RegExp(EMAIL_VALIDATION_REGEX);
      // patern.test(mail) retrun true or false
      if (!pattern.test(this.state.email)) {
        this.setState({ emailError: _('not_valid_email') });
      }
    } else {
      const value = this.state[field];
      if (!value || value?.length < 2) {
        this.setState({ [keyError]: _('length_invalid_message') });
      }
    }
  };

  validateEmail = () => {
    const {
      token,
      email,
      firstName,
      lastName,
      joinCommunity,
      organization,
      conditionAccepted,
    } = this.state;

    const promises = [];
    let isUserConnected = false;

    if (joinCommunity) {
      promises.push(
        saveContact(token, {
          email,
          firstName,
          lastName,
          hasAgreedTerms: conditionAccepted,
          organizationId: organization.id,
        }),
      );
    }

    promises.push(
      this.props
        .setAnonymousAuthUser(email)
        .then(() => {
          isUserConnected = true;
        })
        .catch(err => onError(err)),
    );

    Promise.allSettled(promises).then(() => {
      if (isUserConnected) {
        this.props.history.push(
          `/survey/${this.slug}/${this.organizationInstanceId}${location.search}`,
        );
      }
    });

    // this.props.setAnonymousAuthUser(email).then(
    //   () => {
    //     this.props.history.push(
    //       `/survey/${this.slug}/${this.organizationInstanceId}${location.search}`,
    //     );
    //   },
    //   err => err,
    // );
  };

  renderContent() {
    const goto = encodeURI(
      `${TTP_SURVEY_URL}/survey/${this.slug}/${this.organizationInstanceId}${location.search}`,
    );
    const {
      auth: { fetching },
    } = this.props;

    const {
      surveyFetching,
      surveyFetched,
      survey: { allowThirds, isAnonymous, addThirdsToCommunity },
      organization,
      conditionAccepted,
      joinCommunity,
      emailError,
      firstNameError,
      lastNameError,
    } = this.state;

    if (fetching || surveyFetching) {
      return (
        <div className="anonymous">
          <div className="m-b-l m-t-s text-center">
            <img alt="" src="/img/loaders/tamtam.svg" />
          </div>
        </div>
      );
    }

    if (surveyFetched && !allowThirds) {
      return (
        <div className="anonymous">
          <div className="members-only">
            <div className="message">{_('members_only_message')}</div>
            <div className="connect">
              {`${_('Already have an account')} ? `}
              <a href={`${TTP_HOME_URL}?goto=${goto}`}>{_('Log in')}</a>
            </div>
          </div>
        </div>
      );
    }

    const disabled =
      !conditionAccepted ||
      emailError != null ||
      (joinCommunity && (firstNameError != null || lastNameError != null));

    return (
      <div className="anonymous">
        {surveyFetched && isAnonymous && (
          <div className="infos warning">
            <div>{_('anonymous_message')}</div>
            <div className="m-t-xs">{_('anonymous_thanks_message')}</div>
          </div>
        )}
        <div className="anonymous__forms">
          {(addThirdsToCommunity == 1) && (organization?.id != null) && (
            <div className="flex-container p-x-m">
              <span className="m-r-s">
                <Switch
                  isChecked={joinCommunity}
                  onChange={joinCommunity => this.setState({ joinCommunity })}
                />
              </span>
              <span>{_('Join the community')}</span>
            </div>
          )}
          <div className="anonymous__email">
            <span>
              <img alt="" src="/img/icons/mail.png" />
            </span>
            <input
              className="text transparent-input"
              type="email"
              placeholder={_('Please enter your email address')}
              value={this.state.email}
              onChange={this.changeEmail}
              onBlur={() => this.handleValidateField('email', 'emailError')}
            />
          </div>
          <div className="anonymous__errors">{this.state.emailError}</div>
          {joinCommunity && (
            <div className="row m-t-l">
              <div className="column column-block small-12 medium-6">
                <div>{__.capitalize(_('first name'))}</div>
                <input
                  className="text transparent-input"
                  type="text"
                  placeholder={__.capitalize(_('first name'))}
                  value={this.state.firstName}
                  onChange={this.changeFirstName}
                  onBlur={() =>
                    this.handleValidateField('firstName', 'firstNameError')
                  }
                />
                <div className="anonymous__errors no-margin">
                  {this.state.firstNameError}
                </div>
              </div>
              <div className="column column-block small-12 medium-6">
                <div>{__.capitalize(_('last name'))}</div>
                <input
                  className="text transparent-input"
                  type="text"
                  placeholder={__.capitalize(_('last name'))}
                  value={this.state.lastName}
                  onChange={this.changeLastName}
                  onBlur={() =>
                    this.handleValidateField('lastName', 'lastNameError')
                  }
                />
                <div className="anonymous__errors no-margin">
                  {this.state.lastNameError}
                </div>
              </div>
            </div>
          )}
        </div>
        <fieldset className="p-x-s" onChange={this.handleCheckbox}>
          <input id="TERMS" type="checkbox" />
          <label htmlFor="TERMS">
            {_('I Agree to the terms of service')}.&nbsp;
            <i style={{ textDecoration: 'underline' }}>
              <a href={`${TTP_HOME_URL}/privacy-and-terms`} target="_blank">
                {_('Privacy & Terms')}
              </a>
            </i>
          </label>
        </fieldset>
        <div className="tt-button-group m-y-l">
          <TTRoundedButton
            disabled={disabled}
            text={_('Validate')}
            upperCase={true}
            onClick={this.validateEmail}
            fontSize="20px"
          />
        </div>
        <div className="anonymous__login">
          <span>
            {`${_('Already have an account')} ? `}
            <a href={`${TTP_HOME_URL}?goto=${goto}`}>{_('Log in')}</a>
          </span>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="pre-preview srv-background-color--fill">
        <div className="pre-preview__wrapper">
          <div className="pre-preview__title">{_('email')}</div>
          <div className="pre-preview__sub-title">{_('Log in')}</div>
          <div className="pre-preview__content">{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  setAnonymousAuthUser,
  setCurrentNavPage,
  fetchSurveyWithToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousUser);
