import { LIST, GRID } from 'constants';

export const MENUS = {
  GUEST: ['HOME'],
  CONTACT: ['HOME', 'CLIENTS', 'SURVEY_LIST'],
  AUTHOR: ['CLIENTS', 'HOME', 'SURVEY_LIST', 'SURVEY_TEMPLATE', 'CREATE_SURVEY'],
  REDACTOR: ['HOME', 'CLIENTS', 'SURVEY_LIST', 'SURVEY_TEMPLATE', 'CREATE_SURVEY'],
  CHIEF_EDITOR: ['CLIENTS', 'HOME', 'SURVEY_LIST', 'SURVEY_TEMPLATE', 'CREATE_SURVEY', 'EMAILS_TEMPLATES'],
  VOYEUR: ['HOME']
};
export const AUTHORIZATIONS = {
  GUEST: ['SEND_RESPONSE', 'PREVIEW'],
  CONTACT: ['SEND_RESPONSE', 'PREVIEW'],
  AUTHOR: ['DUPLICATE_SURVEY', 'CREATE_SURVEY', 'EDIT_SURVEY', 'DELETE_SURVEY', 'CONSULT_RESULTS', 'SEND_RESPONSE', 'CONSULT_SURVEY_TRANSLATION_STATUS', 'CONSULT_SURVEY_STATS', 'PREVIEW', 'CONSULT_RANKING_ORGANIZATION', 'CONSULT_RANKING_CLIENTS', 'CONSULT_SCORE', 'CREATE_SURVEY_FROM_TEMPLATE'],
  REDACTOR: ['EDIT_SURVEY_STATUS', 'CLOSE_SURVEY', 'DUPLICATE_SURVEY', 'CREATE_SURVEY', 'EDIT_SURVEY', 'DELETE_SURVEY', 'CONSULT_RESULTS', 'SEND_RESPONSE', 'CONSULT_SURVEY_TRANSLATION_STATUS', 'CONSULT_SURVEY_STATS', 'PUBLISH_SURVEY', 'PREVIEW', 'CONSULT_RANKING_ORGANIZATION', 'CONSULT_RANKING_ORGANIZATIONS', 'CONSULT_RANKING_CLIENTS', 'CONSULT_SCORE', 'CREATE_SURVEY_FROM_TEMPLATE', 'EDIT_PENETRATION_RATE'],
  CHIEF_EDITOR: ['MANAGE_SURVEY', 'CREATE_TEMPLATE_FROM_SURVEY', 'PARTICIPATE_IN_TEMPLATE', 'EDIT_SURVEY_STATUS', 'DUPLICATE_SURVEY', 'CONSULT_RESULTS', 'SEND_RESPONSE', 'CONSULT_SURVEY_TRANSLATION_STATUS', 'CREATE_SURVEY', 'EDIT_SURVEY', 'DELETE_SURVEY', 'CONSULT_SURVEY_STATS', 'CONSULT_RANKING_ORGANIZATION', 'PUBLISH_SURVEY', 'CLOSE_SURVEY', 'PREVIEW', 'CONSULT_RANKING_ORGANIZATIONS', 'CONSULT_RANKING_CLIENTS', 'EDIT_PENETRATION_RATE', 'CREATE_SURVEY_FROM_TEMPLATE', 'CONSULT_SCORE'],
  VOYEUR: ['CONSULT_RESULTS']
};

export const CS_SCOPES = ['EXTRA_CROSS', 'INTRA_CROSS', 'EXTRA_SHARE', 'INTRA_SHARE'];
export const SURVEY_VIEW = [LIST, GRID];
export const SURVEY_TYPE = ['SATISFACTION', 'OPINION', 'CUSTOM', 'QUIZ', 'PORTAL'];
export const SURVEY_STATUS = ['PENDING', 'STARTED', 'CLOSED'];
export const OPEN_QUESTIONS = ['TEXT', 'NUMBER', 'TRUE_FALSE', 'MULTIPLE_CHOICE', 'SINGLE_CHOICE'];
export const PORTAL_QUESTIONS = ['MULTIPLE_CHOICE', 'SINGLE_CHOICE'];
export const QUESTIONS_WITH_CHOICES = ['MULTIPLE_CHOICE', 'SINGLE_CHOICE', 'RATING_CHOICE'];
export const QUESTIONS_WITH_CHILDS = ['RATING_CHOICE', 'YES_NO', 'RATING', 'OPINION_SCALE', 'MULTIPLE_CHOICE', 'SINGLE_CHOICE', 'TRUE_FALSE'];
export const TRANSACTIONAL_MAIL_TYPES = ['SURVEY_PARTIAL_REMINDER', 'SURVEY_REMINDER', 'SURVEY_RESULTS'];
export const QUESTION_PROFILING_TYPES = ['FUNCTION', 'POSTAL_CODE', 'AGE', 'GENDER', 'LANGUAGE', 'COUNTRY'];

export const LANGUAGES = ['fr', 'nl', 'en'];

export const TTP_LOGO_URL = "/img/home/logo.svg";
export const COMMUNITY_NAME_MAX_LENGTH = 20;
export const COMMUNITY_DEFAULT_LOGO_URL = "/img/default-logo.png";

export const LIST_PAGE_SIZES = [5, 10, 25, 50, 100];
export const TAMTAM_API_LANGUAGES_MAPPING = {
  fr: 'Fr', nl: 'Nl', en: 'En', all: '',
};
export const TAMTAM_API_LANGUAGES = {
  fr: 'french',
  nl: 'dutch',
  en: 'english'
};
export const COPYRIGHT_YEAR = (new Date()).getFullYear();

export const SORT_OPTIONS = [
  { label: 'oldest first', value: 'asc' },
  { label: 'newest first', value: 'desc' },
];

function getMSIEVersion() {
  let version = 0;
  if (navigator) {
    const regEx = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');
    if (regEx.exec(navigator.userAgent) != null) {
      version = parseFloat(RegExp.$1);
    }
  }
  return version;
}

export const isIE11 = (!!window.MSInputMethodContext && !!document.documentMode) || getMSIEVersion() >= 9;
export const isEdge = /Edge/.test(navigator.userAgent);
export const isSafari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
export const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
export const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
export const getWindowHeight = () => window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
export const getWindowWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

/**
 * if the visibility is hidden it will return true,
 * because  [ visibility:hidden ] shows no content anymore,
 * but still takes the width and height of the element!
 */
export const isVisible = elem => elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
