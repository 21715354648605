import React, { Component } from 'react';
import FullScreen from 'common/FullScreen';

export default function withFullScreen(Wrapper) {
  return class WithFullScreen extends Component {
    state = { isFull: false };

    handleFullScreen = () => {
      this.setState(prevState => ({ isFull: !prevState.isFull }));
    }

    setFullScreen = isFull => this.setState({ isFull });

    render() {
      const props = { fullScreenEnabled: this.state.isFull, ...this.props };
      return (
        <FullScreen enabled={this.state.isFull} onChange={this.setFullScreen} className="flex-container flex-1">
          <Wrapper {...props} handleFullScreen={this.handleFullScreen} setFullScreen={this.setFullScreen} />
        </FullScreen>
      );
    }
  };
}
