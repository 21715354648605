import React, { Component } from 'react';
import CountDown from './CountDown';
import withResult from './withResult';

const ICONS = [
  '/img/icons/triangle.svg',
  '/img/icons/polygon.svg',
  '/img/icons/ellipse.svg',
  '/img/icons/rectangle.svg',
];

const COLORS = ['blue', 'orange', 'red', 'green'];

class QuestionContent extends Component {
  renderChoices = () => this.props.question.choices.map(({ text, choiceOrder, id }) => (
    <div className={`choice-block ${COLORS[choiceOrder]}`} key={`choice-data-${id}`}>
      <div className="icon-block">
        <div className="wrapper">
          <img alt="" src={ICONS[choiceOrder]} />
        </div>
      </div>
      <div className="text-block">
        <div className="content">{text ? text[this.props.language] : ''}</div>
        <div className="back-ground fw" style={{ width: '100%' }} />
      </div>
    </div>
  ));

  renderStats = () => this.props.question.choices.map(({ text, choiceOrder, isCorrect, id }) => {
    const { statsData, fetching } = this.props;

    return (
      <div className={`choice-block ${COLORS[choiceOrder]}`} key={`choice-stats-${id}`}>
        <div className="icon-block" style={{ opacity: isCorrect ? 1 : 0.5 }}>
          <div className="wrapper">
            {isCorrect
              ? <i className="icomoon icon-tt-correct" />
              : <img alt="" src={ICONS[choiceOrder]} />
            }
          </div>
        </div>
        <div className="text-block" style={{ opacity: isCorrect ? 1 : 0.5 }}>
          <div className="content">{text ? text[this.props.language] : ''}</div>
          <div className="back-ground fw" style={{ width: `${statsData[id] || 0}%` }} />
        </div>
        <div className="stats-block">
          {
            fetching
              ? <img alt="" src="/img/loaders/tamtam.svg" />
              : `${parseInt(statsData[id] || 0)}%`
          }
        </div>
      </div>
    );
  });

  render() {
    const { question: { text, type, timeLimit }, language, showStats } = this.props;

    if (type != 'SINGLE_CHOICE') {
      return 'NOT SUPPORTED QUESTION';
    }

    return (
      <div className="quiz-preview__question question-content row">
        <div className="small-12">
          <div className="title-block">
            <div className="title">{text ? text[language] : ''}</div>
            {!showStats ? <CountDown start={timeLimit} onTimeOut={this.props.onTimeOut} /> : null}
          </div>
          <div className="choices-block">
            {showStats ? this.renderStats() : this.renderChoices()}
          </div>
        </div>
      </div>
    );
  }
}

export default withResult(QuestionContent);
