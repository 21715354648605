import * as actions from 'actions';
import { getSurveyCampaign } from 'TamtamAPI';

export const fetchSurveyCampaign = InstanceId => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  const organizationId = currentClient ? currentClient.id : 0;
  return dispatch(actions.fetchSurveyCampaign(
    getSurveyCampaign(token, InstanceId, organizationId),
  ));
};
