import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setSelectedUsers } from 'actions';
import _ from 'i18n';

class Bubbles extends Component {
  toggleSelectAll() {
    const selectAll = document.getElementsByClassName('bubble__select-all').item(0);
    const usersAvatar = document.getElementsByClassName('employee');
    const childSpan = selectAll.getElementsByTagName('span').item(0);
    const childI = selectAll.getElementsByTagName('i').item(0).classList;

    if (selectAll.classList.contains('deselect')) {
      for (let i = 0; i < usersAvatar.length; i++) {
        usersAvatar[i].classList.remove('selected');
        usersAvatar[i].getElementsByClassName('icon').item(0).classList.remove('active');
      }
      selectAll.classList.remove('deselect');
      childSpan.textContent = _('Select All');
      document.getElementsByClassName('bubble__settings').item(0).classList.remove('active');
      childI.remove('icon-close');
      childI.add('icon-check');
    } else {
      for (let i = 0; i < usersAvatar.length; i++) {
        usersAvatar[i].classList.add('selected');
        usersAvatar[i].getElementsByClassName('icon').item(0).classList.add('active');
      }
      selectAll.classList.add('deselect');
      childSpan.textContent = _('Deselect All');
      document.getElementsByClassName('bubble__settings').item(0).classList.add('active');
      childI.add('icon-close');
      childI.remove('icon-check');
    }
  }

  displaySetting() {
    const { setSelectedUsers } = this.props;

    const mask = document.getElementsByClassName('mask').item(0).classList;
    mask.remove('hide');
    const sidebar = document.getElementById('authorisation-settings__sidebar').classList;
    sidebar.add('display');
    const controls = document.getElementById('controls').classList;
    controls.add('display');

    const selectedUsers = document.getElementById('authorisation-setting-users').getElementsByClassName('selected');
    const ids = [];
    if (selectedUsers && selectedUsers.length > 0) {
      for (let i = 0; i < selectedUsers.length; i++) {
        ids.push(parseInt(selectedUsers.item(i).id.split('-')[1]));
      }
    }
    setSelectedUsers(ids);
  }

  render() {
    return (
      <div id="bubbles">
        <div className="bubble__select-all" onClick={this.toggleSelectAll.bind(this)}>
          <i className="icon icon-check" />
          <span>{_('Select All')}</span>
        </div>
        <div className="bubble__settings" onClick={this.displaySetting.bind(this)}>
          <i className="icon icon-settings" />
          <span>{_('Preferences')}</span>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setSelectedUsers }, dispatch);
}

export default connect(null, mapDispatchToProps)(Bubbles);
