import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'i18n';
import {
  setCurrentQuestion,
  updateDimensionDescription,
  setTTPDialogCustomData,
} from 'actions';
import { DIMENSION, DIALOG_TYPES } from 'constants';
import { getOptions } from 'SurveyConfig';
import HeaderLabel from 'HeaderLabel';
import { getTextByLanguage } from 'surveyUtils';

export class DimensionDescription extends Component {
  constructor(props) {
    super(props);

    this.onFocus = this.onFocus.bind(this);
    this.removeDescription = this.removeDescription.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
  }

  onFocus() {
    this.props.setCurrentQuestion(this.props.order || 0, -1, 'DESCRIPTION');
  }

  onTextChange(text) {
    const { description, language } = this.props;
    if (text == '' && language != 'fr') {
      const newDescription = description ? { ...description } : description;
      delete newDescription[language];
      this.props.updateDimensionDescription(newDescription);
    } else {
      this.props.updateDimensionDescription({
        ...description,
        [language]: text,
      });
    }
  }

  removeDescription = () => {
    this.props.setTTPDialogCustomData({
      approvedAction: () => this.props.updateDimensionDescription(null),
      message: _('remove_block_message'),
      title: _('Confirm delete'),
      type: DIALOG_TYPES.DELETE,
    });
  };

  renderLine() {
    return <div className="editor-block__groupLine editor-block__middle" />;
  }

  render() {
    const { description, dimensionId, language, focused } = this.props;

    const value = getTextByLanguage(description, language, true);
    const frText =
      language !== 'fr' ? getTextByLanguage(description, 'fr', true) : '';

    const errors = this.props.errors[dimensionId];

    // const onTextChange = _.debounce( (text) => {this.onTextChange(text)},300);

    if (description != null) {
      return (
        <div className="editor-block__container editor-block__child">
          <div className="editor-block__root">
            <HeaderLabel
              options={getOptions('DESCRIPTION')}
              value={value}
              hasFocus={focused}
              onFocus={this.onFocus}
              onKeyDown={this.removeDescription}
              onTextChange={this.onTextChange}
              frText={frText}
              classNames={{ background: 'editor-block__filter--child' }}
              validationParams={{
                resourceType: DIMENSION,
                key: dimensionId,
                property: 'description',
              }}
              errors={errors}
            />
          </div>
          {this.renderLine()}
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  language: state.surveys.list.language,
  errors: state.surveys.list.validationErrors.dimensions.description,
});

const mapDispatchToProps = {
  setCurrentQuestion,
  updateDimensionDescription,
  setTTPDialogCustomData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DimensionDescription);
