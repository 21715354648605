import * as actions from 'actions';
import { getOrganizationScore } from 'TamtamAPI';

export const fetchOrganizationScore = (InstanceId, organizationId) => (
  dispatch,
  getState,
) => {
  const { token: userToken, currentClient, appToken } = getState().auth;
  const id = organizationId ?? currentClient?.id ?? 0;
  const token = userToken != '' ? userToken : appToken.token;
  return dispatch(
    actions.fetchOrganizationScore(getOrganizationScore(token, InstanceId, id)),
  );
};
