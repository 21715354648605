export const fetchMedias = resp => ({
  type: 'FETCH_MEDIAS',
  payload: resp
});

export const setMediasPaginationPage = pageNumber => ({
  type: 'SET_MEDIAS_PAGINATION_PAGE',
  pageNumber
});

export const setMediasSearchWord = searchWord => ({
  type: 'SET_MEDIAS_SEARCH_WORD',
  searchWord
});

export const setMediasLanguage = language => ({
  type: 'SET_MEDIAS_LANGUAGE',
  language
});

export const saveMedia = resp => ({
  type: 'SAVE_MEDIA',
  payload: resp
});

export const initMedias = () => ({
  type: 'INIT_MEDIAS',
});
