const MANAGE_TEMPLATES = 'templates';
const MANAGE_PARTICIPANTS = 'participants';
const MANAGE_CAMPAIGNS = 'campaign';

const TABS = [MANAGE_CAMPAIGNS, MANAGE_PARTICIPANTS, MANAGE_TEMPLATES];

const GENERIC_EMAIL_TYPES = {
  SURVEY_THANK: 'SURVEY_THANK',
  SURVEY_INVITATION: 'SURVEY_INVITATION',
  SURVEY_REMINDER: 'SURVEY_REMINDER',
  SURVEY_PARTIAL_REMINDER: 'SURVEY_PARTIAL_REMINDER',
  SURVEY_RESULTS: 'SURVEY_RESULTS',
};

const EMAILS_TEMPLATES_TYPES = [
  GENERIC_EMAIL_TYPES.SURVEY_THANK,
  GENERIC_EMAIL_TYPES.SURVEY_INVITATION,
  GENERIC_EMAIL_TYPES.SURVEY_REMINDER,
  GENERIC_EMAIL_TYPES.SURVEY_PARTIAL_REMINDER,
  GENERIC_EMAIL_TYPES.SURVEY_RESULTS,
];

const SURVEY_MODALS_TYPES = { DELETE: 'DELETE', STATUS: 'STATUS', PENETRATION_RATE: 'PENETRATION_RATE' };

export {
  MANAGE_CAMPAIGNS,
  MANAGE_PARTICIPANTS,
  MANAGE_TEMPLATES,
  TABS,
  EMAILS_TEMPLATES_TYPES,
  GENERIC_EMAIL_TYPES,
  SURVEY_MODALS_TYPES
};
