import React, { Component } from 'react';
import autosize from 'autosize';
import _ from 'i18n';

export default class ChoiceInput extends Component {
  componentDidMount() {
    autosize(this.textarea);
  }

  componentDidUpdate() {
    if (this.props.selected) {
      autosize(this.textarea);
      this.textarea.focus();
    }
  }

  handleKeyDown = (e) => {
    if (e.key == 'Enter' && !e.nativeEvent.shiftKey) {
      e.preventDefault();
      this.props.onEnter();
    }
  }

  render() {
    const { label } = this.props;

    return (
      <div className="other">
        <textarea
          className="transparent-input  label-choice__input"
          ref={(c) => { this.textarea = c; }}
          placeholder={label || ''}
          onChange={e => this.props.onChange(e.target.value)}
          onKeyDown={this.handleKeyDown}
          value={this.props.value || ''}
          onFocus={() => this.props.handleOtherChecked(true)}
        />
        <div className="help">
          <b>SHIFT + ENTRER</b>
          {' '}
          {_('to make a line break')}
        </div>
      </div>
    );
  }
}
