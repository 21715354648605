import * as actions from 'actions';
import { getNotifications, markSurveyNotificationAsRead, getClientCredential } from 'TamtamAPI';

export const fetchNotifications = () => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  const clientId = currentClient ? currentClient.id : null;

  if (token == '') {
    return getClientCredential().then((resp) => {
      const clientToken = resp.data.token.access_token;
      dispatch(actions.setAuthToken(clientToken));
      return dispatch(actions.fetchNotifications(
        getNotifications(clientToken, clientId)
      ));
    });
  }

  return dispatch(actions.fetchNotifications(
    getNotifications(token, clientId)
  ));
};

export const markNotificationAsRead = notificationId => (dispatch, getState) => {
  const { token } = getState().auth;

  if (token == '') {
    return getClientCredential().then((resp) => {
      const clientToken = resp.data.token.access_token;
      dispatch(actions.setAuthToken(clientToken));
      return dispatch(actions.markNotificationAsRead(
        markSurveyNotificationAsRead(clientToken, notificationId).then(res => res.data.data, err => err)
      ));
    });
  }
  return dispatch(actions.markNotificationAsRead(
    markSurveyNotificationAsRead(token, notificationId).then(res => res.data.data, err => err)
  ));
};
