import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  removeDimension,
  setCurrentQuestion,
  updateDimension,
  addDimension,
  setSettingsCurrentTab
} from 'actions';
import { DIMENSION, SURVEY_BUILDER_TABS } from 'constants';
import { deleteDimension } from 'thunks';
import { NotificationManager } from 'react-notifications';
import _ from 'i18n';
import HeaderLabel from 'HeaderLabel';
import { getOptions } from 'SurveyConfig';
import { getTextByLanguage, generateDimension } from 'surveyUtils';
import Modal from 'survey/Modal';

class DimensionHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { showDeletePopup: false };
    this.onFocus = this.onFocus.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.deleteDimension = this.deleteDimension.bind(this);

    this.hideDeletePopup = () => this.setState({ showDeletePopup: false });
  }

  onSuccess(res) {
    if (res.value instanceof Error) {
      this.onFailure();
    } else {
      NotificationManager.success(_('delete_dimension_success'), _('Delete'));
    }
  }

  onFailure() {
    NotificationManager.error(_('errorOccured'), _('error'));
  }

  onFocus() {
    const { currentTab, order } = this.props;
    const { SURVEY_SETTINGS, SETTINGS } = SURVEY_BUILDER_TABS;
    this.props.setCurrentQuestion(order || 0, -1);

    if (currentTab === SURVEY_SETTINGS) {
      this.props.setSettingsCurrentTab(SETTINGS);
    }
  }

  onTextChange(text) {
    const { language, dimension } = this.props;
    this.props.updateDimension({ ...dimension, title: { ...dimension.title, [language]: text } });
  }

  handleDeleteDimension = () => {
    if (!this.props.isLocked) {
      this.setState({ showDeletePopup: true });
    }
  }

  addNewDimension = () => {
    const { surveyType } = this.props;
    if (surveyType == 'PORTAL') {
      return;
    }
    this.props.addDimension(generateDimension(surveyType));
  }

  deleteDimension = () => {
    const { dimension, removeDimension, deleteDimension } = this.props;

    if (dimension.id) {
      deleteDimension(dimension.id).then(
        res => this.onSuccess(res), err => this.onFailure(err)
      );
    } else {
      try {
        removeDimension();
      } catch (e) {
        this.onFailure();
      }
    }
  };

  render() {
    const message = `${_('delete_dimension_message')}`;
    const {
      dimension,
      order,
      language,
      lng,
      focusOnMount,
      focused,
    } = this.props;
    const { showDeletePopup } = this.state;
    const errors = this.props.errors[dimension.id || dimension.uid];

    const value = getTextByLanguage(dimension.title, language, true);
    const helpText = getTextByLanguage(dimension.title, lng, true);

    return (
      <div>
        <HeaderLabel
          options={getOptions('DIMENSION')}
          value={value}
          order={order}
          hasFocus={focused}
          onFocus={this.onFocus}
          onEnter={this.addNewDimension}
          onKeyDown={this.handleDeleteDimension}
          onTextChange={this.onTextChange}
          helpText={helpText}
          validationParams={
            {
              resourceType: DIMENSION,
              key: dimension.id || dimension.uid,
              property: 'title'
            }
          }
          errors={errors}
          focusOnMount={focusOnMount}
        />
        {
          showDeletePopup && (
            <Modal
              labels={{ action: _('Delete') }}
              isOpen={showDeletePopup}
              action={this.deleteDimension}
              onCloseModal={this.hideDeletePopup}
              title={_('Delete dimension')}
              classNames={{ action: 'btn-popup--alert' }}
            >
              <h5>{message}</h5>
            </Modal>
          )
        }
      </div>

    );
  }
}

const mapStateToProps = state => ({
  language: state.surveys.list.language,
  errors: state.surveys.list.validationErrors.dimensions.title,
  surveyType: state.surveys.list.survey.type,
  currentTab: state.surveys.list.toolbarTab,
  lng: state.params.lng,
});

const mapDispatchToProps = {
  removeDimension,
  setCurrentQuestion,
  updateDimension,
  deleteDimension,
  addDimension,
  setSettingsCurrentTab
};
export default connect(mapStateToProps, mapDispatchToProps)(DimensionHeader);
