import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Preview from 'Preview';
import PreviewStyles from 'preview-items/PreviewStyles';
import { fetchTemplate } from 'thunks';
import SurveyTitlePreviewFetching from '../fetching/SurveyTitlePreviewFetching';

const style = {
  overlay: {
    zIndex: 4,
    backgroundColor: 'hsla(0,0%,4%,.45)'
  },
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    padding: 0,
  }
};

class TemplatePreview extends Component {
  handleAfterOpen = () => {
    const { survey } = this.props;
    this.props.fetchTemplate(survey.slug);
  }

  renderPreview = () => {
    if (this.props.fetching) {
      return (
        <div className="preview__wrapper" id="scrollPreview">
          <SurveyTitlePreviewFetching />
        </div>
      );
    }
    return (
      <Preview
        survey={this.props.survey}
        status={this.props.status}
        fakeResponse={true}
      />
    );
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.isOpen}
        onAfterOpen={this.handleAfterOpen}
        style={style}
        contentLabel="Participate in template"
        shouldCloseOnOverlayClick={true}
      >
        <div className="preview preview--withoutScroll srv-background-color--fill" style={{ minHeight: '100%' }}>
          <div className="preview srv-background-color--fill">
            <PreviewStyles theme={this.props.theme} />
            {this.renderPreview()}
          </div>
        </div>
        <button className="rmodal-close close-button" type="button" onClick={this.props.onCloseModal}>
          <span aria-hidden="true">&times;</span>
        </button>
      </ReactModal>
    );
  }
}

const mapStateToProps = state => ({
  survey: state.templates.survey,
  fetching: state.templates.fetching,
  theme: state.surveyThemes.theme,
  status: state.surveyResponse.status,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchTemplate }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePreview);
