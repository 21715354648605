import React, { useState, useEffect } from 'react';
import TTPSelect from 'common/TTPSelect';
import _ from 'i18n';
import __ from 'lodash';
import styles from './Profiling.module.scss';
import isoCountries from 'i18n-iso-countries';
import { connect, useDispatch } from 'react-redux';
import { QUESTIONS_WITH_CHOICES, LANGUAGES } from 'Common';
import { updateAnswer, addLastUpdatedAnswer } from 'actions';
import { deleteAnswer } from 'thunks';
import postalCodes from 'postal-codes-js';

function ProfilingField({
  question,
  language: surveyLanguage,
  appLanguage,
  answer,
  active,
  country,
}) {
  const dispatch = useDispatch();
  const { text, type, choices, profilingType } = question;

  const defaultValue = QUESTIONS_WITH_CHOICES.includes(type)
    ? answer?.choice
      ? answer?.choice[0]
      : null
    : answer?.answerValue ?? '';

  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState(null);

  useEffect(() => {
    const newValue = QUESTIONS_WITH_CHOICES.includes(type)
      ? answer?.choice
        ? answer?.choice[0]
        : null
      : answer?.answerValue ?? '';

    setValue(newValue);
  }, [answer, type]);

  useEffect(() => {
    if (profilingType === 'POSTAL_CODE' && value != '') {
      const isValide = validatePostalCode(value);
      setAnswerValue(isValide ? value : null);
    }
  }, [country]);

  const validatePostalCode = postalCode => {
    const isValide = postalCodes.validate(country, postalCode);

    if (isValide === true) {
      setError(null);
      return true;
    }

    setValue(postalCode);
    setError(_('invalid postal code'));
    return false;
  };

  const setPostalCode = postalCode => {
    const isValide = validatePostalCode(postalCode);

    if (isValide === true) {
      setAnswerValue(postalCode);
    }
  };

  const setAnswerValue = answerValue => {
    let resourceType = 'text';
    const withChoice = QUESTIONS_WITH_CHOICES.includes(type);

    if (withChoice) {
      resourceType = 'multipleChoice';
    } else if (['OPINION_SCALE', 'NUMBER'].includes(type)) {
      resourceType = 'number';
    }

    let newAnswer = {
      ...answer,
      question: question.id || question.uid,
      resourceType,
      isAutoFilled: false,
    };

    if (withChoice) {
      newAnswer = { ...newAnswer, choice: [answerValue] };
    } else {
      newAnswer = { ...newAnswer, answerValue };
    }

    dispatch(updateAnswer(newAnswer));

    if (newAnswer.choice != null || String(newAnswer.answerValue) != '') {
      dispatch(addLastUpdatedAnswer(question.id || question.uid));
    } else if (answer.id) {
      dispatch(deleteAnswer(answer.id, question.id));
    }
  };

  let content;

  switch (profilingType) {
    case 'GENDER':
      content = (
        <div className={styles.gender}>
          {choices?.map(({ text, id, uid }) => {
            const choiceValue = id ?? uid;
            const isSelected = String(value) === String(choiceValue);
            return (
              <div className="m-r-s" key={choiceValue}>
                <div
                  className={`${styles.gender_button} ${
                    isSelected ? styles.gender_button_selected : ''
                  }`}
                  onClick={() => setAnswerValue(choiceValue)}
                />
                <div className="m-l-xs">{text?.[surveyLanguage] ?? ''}</div>
              </div>
            );
          })}
        </div>
      );
      break;
    case 'POSTAL_CODE':
      content = (
        <input
          className={`${styles.input_field} ${
            String(value)?.length > 0 ? styles.has_value : ''
          } ${active ? styles.active : ''}`}
          type="text"
          value={value}
          onChange={({ target: { value } }) => setPostalCode(value)}
          placeholder={_('Postal code')}
        />
      );
      break;
    case 'COUNTRY':
      if (!value) {
        setAnswerValue('BE'); // !! called on render
      }

      const lng = LANGUAGES.includes(appLanguage) ? appLanguage : 'fr';

      const countryOptions = __.map(
        isoCountries.getNames(lng) ?? [],
        (label, value) => ({
          label,
          value,
        }),
      );

      content = (
        <TTPSelect
          cssClass={active ? styles.active : ''}
          simple={true}
          notClearable={true}
          values={value}
          placeholder={_('Country')}
          options={countryOptions}
          onChange={option => setAnswerValue(option)}
        />
      );
      break;
    case 'LANGUAGE':
      const languageOptions = question?.choices?.map(
        ({ text, id, uid, options }) => ({
          value: id ?? uid,
          label: (
            <span>
              <img alt="" src={`/img/flags/${options?.value}.svg`} />
              &nbsp;&nbsp;{text?.[surveyLanguage] ?? ''}
            </span>
          ),
        }),
      );
      content = (
        <TTPSelect
          cssClass={active ? styles.active : ''}
          simple={true}
          notClearable={true}
          values={value}
          placeholder={_('Language')}
          options={languageOptions}
          onChange={option => setAnswerValue(option)}
        />
      );
      break;
    case 'AGE':
    case 'FUNCTION':
      const functionOptions = question?.choices?.map(({ text, id, uid }) => ({
        value: id ?? uid,
        label: text?.[surveyLanguage] ?? '',
      }));

      content = (
        <TTPSelect
          cssClass={active ? styles.active : ''}
          simple={true}
          notClearable={true}
          values={value}
          placeholder={
            profilingType === 'AGE' ? _('Age') : _('Profession')
          }
          options={functionOptions}
          onChange={option => setAnswerValue(option)}
        />
      );
      break;
    default:
      content = null;
  }

  return (
    <div className="column small-12 medium-6">
      <div className={styles.profile_field}>
        <div className={styles.header}>{text[surveyLanguage] ?? ''}</div>
        {content}
        {error ? (
          <div className={`${styles.footer} ${styles.alert}`}>{error}</div>
        ) : active ? (
          <div className={`${styles.footer} ${styles.alert}`}>{_('Please fill in this field')}</div>
        ) : null}
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const { question } = props;
  const questionId = question.id ?? question.uid;

  return {
    answer: state.surveyResponse.answers?.[questionId] ?? null,
    appLanguage: state.params.lng,
  };
};

const mapDispatchToProps = { updateAnswer, addLastUpdatedAnswer };

export default connect(mapStateToProps, mapDispatchToProps)(ProfilingField);
