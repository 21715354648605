import React, { Component } from 'react';
import _ from 'i18n';
import { TAMTAM_API_LANGUAGES } from 'Common';

export default class LanguageSetter extends Component {
  renderLanguages() {
    const { survey } = this.props;
    const translationScore = survey ? survey.translationScore : null;

    if (translationScore == null) {
      return null;
    }
    return (
      <ul>
        {
          ['fr', 'nl', 'en'].map(lng => (
            <li className={parseInt(translationScore[lng]) !== 100 ? 'disabled' : ''} key={lng} onClick={() => this.props.action(lng)}>
              <span className="icon-wrapper"><img src={`/img/flags/${lng}.svg`} alt="" /></span>
              <span className="text">{_(TAMTAM_API_LANGUAGES[lng])}</span>
            </li>
          ))
        }
      </ul>
    );
  }

  render() {
    return (
      <div className="pre-preview__wrapper">
        <div className="pre-preview__title">{_('language')}</div>
        <div className="pre-preview__sub-title">{_('Please Choose An Available Language')}</div>
        <div className="pre-preview__content">
          <div className="language-setter">
            {
              this.renderLanguages()
            }
          </div>
        </div>
      </div>
    );
  }
}
