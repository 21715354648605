import React from 'react';
import styles from './EditorItems.module.scss';
import { MOVE_UP, MOVE_DOWN } from 'constants';
import { ReactComponent as SimpleArrowUp } from 'icons/simple-arrow-up.svg';
// import { ReactComponent as DoubleArrowUp } from 'icons/double-arrow-up.svg';

export default function MoveButtons({ onClick, disableUp, disableDown }) {
  return (
    <div className={styles.move_wrapper}>
      {/* <button className={styles.move_button}>
      <DoubleArrowUp />
    </button> */}
      <button
        disabled={disableUp}
        className={`${styles.move_button} ${disableUp ? 'disabled-link' : ''}`}
        onClick={() => onClick(MOVE_UP)}
      >
        <SimpleArrowUp />
      </button>
      <button
        disabled={disableDown}
        className={`${styles.move_button} ${
          disableDown ? 'disabled-link' : ''
        }`}
        onClick={() => onClick(MOVE_DOWN)}
      >
        <SimpleArrowUp className={styles.flip} />
      </button>
      {/* <button className={styles.move_button}>
      <DoubleArrowUp className={styles.flip} />
    </button> */}
    </div>
  );
}
