export const getUsersSettings = resp => ({
  type: 'FETCH_USERS_SETTINGS',
  payload: resp,
});

export const saveUsers = resp => ({
  type: 'SAVE_USERS',
  payload: resp,
});

export const updateUsers = payload => ({
  type: 'UPDATE_USERS',
  payload,
});

export const initSaveUsers = () => ({
  type: 'INIT_SAVE_USERS',
});
