import axios from 'axios';
import { TTP_API_URL } from 'Config';
import { throwCatchedError } from 'utils';

export const getSurveyCampaign = (token, instanceId = null, organizationId) => {
  const requestUrl = `${TTP_API_URL}/survey/campaign`;
  const fields = ['campaign', '*'];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(','),
      filter: JSON.stringify([
        {
          property: 'organization',
          value: organizationId,
          operator: 'eq',
        },
        {
          property: 'instance',
          value: instanceId,
          operator: 'eq',
        }
      ]),
      sort: JSON.stringify([
        {
          property: 'createdAt',
          dir: 'desc',
        }
      ]),
    },
  }).catch((thrown) => { throwCatchedError(thrown); });
};
