export const initSurvey = (surveyType = 'SATISFACTION', language) => ({
  type: 'INIT_SURVEY',
  surveyType,
  language,
});
export const setSurveys = surveys => ({
  type: 'SET_SURVEYS',
  surveys,
});
export const setCurrentChoice = (
  dimensionOrder,
  questionOrder,
  choiceOrder,
  level = 'TITLE'
) => ({
  type: 'SET_CURRENT_CHOICE',
  dimensionOrder,
  questionOrder,
  choiceOrder,
  level,
});
export const setCurrentQuestion = (dimensionOrder, questionOrder, level = 'TITLE') => ({
  type: 'SET_CURRENT_QUESTION',
  dimensionOrder,
  questionOrder,
  level,
});
export const setCurrentSurvey = (survey, isParsed = false) => ({
  type: 'SET_CURRENT_SURVEY',
  survey,
  isParsed,
});
export const addSurvey = survey => ({
  type: 'ADD_SURVEY',
  survey,
});
export const updateTitle = title => ({
  type: 'UPDATE_TITLE',
  title,
});
export const updateSurveyDescription = (language, description) => ({
  type: 'UPDATE_SURVEY_DESCRIPTION',
  language,
  description,
});
export const removeSurveyDescription = () => ({
  type: 'REMOVE_SURVEY_DESCRIPTION',
});
export const updateWelcomeMessage = (language, welcomeMessage) => ({
  type: 'UPDATE_WELCOME_MESSAGE',
  language,
  welcomeMessage,
});
export const addSurveyLanguage = language => ({
  type: 'ADD_SURVEY_LANGUAGE',
  language,
});
export const removeSurveyLanguage = language => ({
  type: 'REMOVE_SURVEY_LANGUAGE',
  language,
});
export const removeWelcomeMessage = () => ({
  type: 'REMOVE_WELCOME_MESSAGE',
});
export const updateDimensionDescription = description => ({
  type: 'UPDATE_DIMENSION_DESCRIPTION',
  description,
});
export const moveDimension = (dimensionOrder, direction) => ({
  type: 'MOVE_DIMENSION',
  dimensionOrder,
  direction,
});
export const addDimension = dimension => ({
  type: 'ADD_DIMENSION',
  dimension,
});
export const updateDimension = dimension => ({
  type: 'UPDATE_DIMENSION',
  dimension,
});
export const removeDimension = () => ({
  type: 'REMOVE_DIMENSION',
});
export const deleteDimension = dimensionId => ({
  type: 'DELETE_DIMENSION',
  payload: dimensionId,
});
export const moveQuestion = (dimensionOrder, questionOrder, direction) => ({
  type: 'MOVE_QUESTION',
  dimensionOrder,
  questionOrder,
  direction,
});
export const addQuestion = question => ({
  type: 'ADD_QUESTION',
  question,
});
export const updateQuestion = question => ({
  type: 'UPDATE_QUESTION',
  question,
});
export const removeQuestion = () => ({
  type: 'REMOVE_QUESTION',
});
export const deleteQuestion = questionId => ({
  type: 'DELETE_QUESTION',
  payload: questionId,
});
export const addChoice = choice => ({
  type: 'ADD_CHOICE',
  choice,
});
export const updateChoice = choice => ({
  type: 'UPDATE_CHOICE',
  choice,
});
export const removeChoice = () => ({
  type: 'REMOVE_CHOICE',
});
export const deleteChoice = choiceId => ({
  type: 'DELETE_CHOICE',
  payload: choiceId,
});
export const setCorrectChoice = (
  currentDimensionIndex,
  currentQuestionIndex,
  currentChoiceIndex,
) => ({
  type: 'SET_CORRECT_CHOICE',
  currentDimensionIndex,
  currentQuestionIndex,
  currentChoiceIndex,
});
export const addOptions = options => ({
  type: 'ADD_QUESTION_OPTIONS',
  options,
});
export const addSurveyOptions = options => ({
  type: 'ADD_SURVEY_OPTIONS',
  options,
});
export const addMetaOption = (property, value) => ({
  type: 'ADD_META_OPTION',
  property,
  value,
});
export const refreshCurrentQuestion = () => ({
  type: 'REFRESH_CURRENT_QUESTION',
});
export const fetchSurveys = (resp, mode = 'FETCH') => ({
  type: mode == 'PUSH' ? 'PUSH_SURVEYS' : 'FETCH_SURVEYS',
  payload: resp,
});
export const fetchSurvey = resp => ({
  type: 'FETCH_SURVEY',
  payload: resp,
});
export const fetchUsedSurveysDates = resp => ({
  type: 'FETCH_USED_SURVEYS_DATES',
  payload: resp,
});
export const fetchSurveyExtraData = resp => ({
  type: 'FETCH_SURVEY_EXTRA_DATA',
  payload: resp,
});
export const createSurvey = resp => ({
  type: 'SAVE_SURVEY',
  payload: resp,
});
export const removeSurvey = surveyId => ({
  type: 'REMOVE_SURVEY',
  surveyId,
});
export const deleteSurvey = surveyId => ({
  type: 'DELETE_SURVEY',
  payload: surveyId,
});
export const closeSurvey = surveySlug => ({
  type: 'CLOSE_SURVEY',
  payload: surveySlug,
});
export const changeLanguage = language => ({
  type: 'CHANGE_LANGUAGE',
  language,
});

export const validateSurvey = () => ({
  type: 'VALIDATE_SURVEY',
});
export const resetValidationErrors = () => ({
  type: 'RESET_VALIDATION_ERRORS',
});
export const addValidationErrors = (key, resourceType, property, error) => ({
  type: 'ADD_VALIDATION_ERROR',
  key,
  resourceType,
  property,
  error,
});
export const removeValidationErrors = (key, resourceType, property) => ({
  type: 'REMOVE_VALIDATION_ERROR',
  key,
  resourceType,
  property,
});

export const updatePenetrationRate = resp => ({
  type: 'UPDATE_PENETRATION_RATE',
  payload: resp,
});

export const duplicateSurvey = resp => ({
  type: 'DUPLICATE_SURVEY',
  payload: resp,
});

export const setSurveyType = surveyType => ({
  type: 'SET_SURVEY_TYPE',
  surveyType,
});

export const createSurveyFromTemplate = resp => ({
  type: 'CREATE_SURVEY_FROM_TEMPLATE',
  payload: resp,
});

export const updateInstanceStatus = resp => ({
  type: 'UPDATE_INSTANCE_STATUS',
  payload: resp,
});

export const setCurrentSurveyTheme = theme => ({
  type: 'SET_CURRENT_SURVEY_THEME',
  theme,
});

export const setSurveyPaginationPage = pageNumber => ({
  type: 'SET_SURVEY_PAGINATION_PAGE',
  pageNumber,
});

export const setSurveyPageSize = pageSize => ({
  type: 'SET_SURVEY_PAGE_SIZE',
  pageSize,
});

export const initSurveyPagination = () => ({
  type: 'INIT_SURVEY_PAGINATION',
});

export const setSettingsCurrentTab = tab => ({
  type: 'SET_SURVEY_SETTINGS_CURRENT_TAB',
  tab,
});

export const setShowSettingsSlider = showSettingsSlider => ({
  type: 'SHOW_SURVEY_SETTINGS_SLIDER',
  showSettingsSlider,
});

export const setSurveyOrganizationInstance = (
  surveyId,
  organizationInstance,
) => ({
  type: 'SET_SURVEY_ORGANIZATION_INSTANCE',
  surveyId,
  organizationInstance,
});

/*
  MEDIA
*/

export const setMediaBanner = banner => ({
  type: 'SET_MEDIA_BANNER',
  banner,
});

export const setMediaWelcomeScreen = welcomeScreen => ({
  type: 'SET_MEDIA_WELCOME_SCREEN',
  welcomeScreen,
});

export const setMediaThankScreen = thankScreen => ({
  type: 'SET_MEDIA_THANK_SCREEN',
  thankScreen,
});

export const deleteMediaWelcomeScreen = () => ({
  type: 'DELETE_MEDIA_WELCOME_SCREEN',
});

export const deleteMediaThankScreen = () => ({
  type: 'DELETE_MEDIA_THANK_SCREEN',
});

export const deleteMediaBanner = () => ({
  type: 'DELETE_MEDIA_BANNER',
});

/*
  FILTER
*/

export const setSurveyListFilterStatus = status => ({
  type: 'SET_SURVEY_FILTER_STATUS',
  status,
});

export const setSurveyListFilterType = surveyType => ({
  type: 'SET_SURVEY_FILTER_TYPE',
  surveyType,
});

export const setSurveyListFilterOwner = owner => ({
  type: 'SET_SURVEY_FILTER_OWNER',
  owner,
});

export const setSurveyListFilterTitle = title => ({
  type: 'SET_SURVEY_FILTER_TITLE',
  title,
});

export const setSurveyListFilterLanguages = languages => ({
  type: 'SET_SURVEY_FILTER_LANGUAGES',
  languages,
});

export const setSurveyListFilterEnvironment = env => ({
  type: 'SET_SURVEY_FILTER_ENVIRONMENT',
  env,
});

export const initSurveyListFilter = () => ({
  type: 'INIT_SURVEY_FILTER',
});

/*
  SORT
*/
export const setSurveyListSortConfig = (columnKey, sortDirection) => ({
  type: 'SET_SURVEY_LIST_SORT_CONFIG',
  config: {
    columnKey,
    sortDirection,
  },
});
