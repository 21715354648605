import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getUserNameForAvatar } from 'utils';
import Controls from 'common/Controls';
import { updateUsersSettings } from 'thunks';
import {
  initSaveUsers, setRole, setAllowCreateSurvey, setAllowPublishSurvey, setAllowCloseSurvey, setAllowAccessResult
} from 'actions';
import _ from 'i18n';
import Switch from '../common/Switch';
import MultiSwitch from '../common/MultiSwitch';

class Sidebar extends Component {
  componentDidUpdate(prevProps) {
    const {
      selectedIds, users, setAllowCreateSurvey,
      setAllowPublishSurvey, setAllowCloseSurvey, setAllowAccessResult,
      setRole
    } = this.props;

    const selectedUsers = users.filter(user => selectedIds.indexOf(user.id) > -1);
    if (!!selectedUsers && selectedUsers.length != 0 && prevProps.selectedIds != selectedIds) {
      const authorisations = selectedUsers[0].surveyAuthorisation;
      const withoutPreferences = selectedUsers.length > 1 || !authorisations || !authorisations.preferences;
      const selectedRole = selectedUsers.length > 1 || !authorisations ? 'CONTACT' : authorisations.role;
      const allowCreation = !(withoutPreferences || authorisations.preferences.AllowCreateSurvey == 0);
      const allowPublishing = !(withoutPreferences || authorisations.preferences.AllowPublishSurvey == 0);
      const allowClosing = !(withoutPreferences || authorisations.preferences.AllowCloseSurvey == 0);
      const allowAccess = !(withoutPreferences || authorisations.preferences.AllowAccessResult == 0);
      setAllowCreateSurvey(allowCreation);
      setAllowPublishSurvey(allowPublishing);
      setAllowCloseSurvey(allowClosing);
      setAllowAccessResult(allowAccess);
      setRole(selectedRole);
    }
  }

  deselectUsers() {
    const selectAllIcon = document.getElementsByClassName('bubble__select-all').item(0);
    const userAvatars = document.querySelectorAll('#authorisation-setting-users .user-avatar');
    selectAllIcon.classList.remove('deselect');
    selectAllIcon.getElementsByTagName('span').item(0).textContent = _('Select All');
    document.getElementsByClassName('bubble__settings').item(0).classList.remove('active');
    selectAllIcon.getElementsByTagName('i').item(0).classList.remove('icon-close');
    selectAllIcon.getElementsByTagName('i').item(0).classList.add('icon-check');

    for (let i = 0; i < userAvatars.length; i++) {
      userAvatars.item(i).classList.remove('selected');
      userAvatars.item(i).getElementsByClassName('icon').item(0).classList.remove('active');
    }
  }

  init = () => {
    const { initSaveUsers } = this.props;
    initSaveUsers();
    const clDiv = document.getElementsByClassName('mask').item(0);
    clDiv.click();
    this.deselectUsers();
  }

  saveUsersSettings = () => {
    const { updateUsersSettings } = this.props;
    updateUsersSettings();
  }

  renderSingleUser(user) {
    const {
      firstName, lastName, avatarUrl, role, surveyAuthorisation
    } = user;

    let avatarDiv = null;
    if (avatarUrl != undefined) {
      avatarDiv = (
        <div className="user-avatar" style={{ backgroundImage: `url(${avatarUrl})` }} />
      );
    } else {
      avatarDiv = (
        <div className="user-avatar empty-avatar">
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
        </div>
      );
    }

    const signatureDiv = [];
    signatureDiv.push(<h3 key="sh3">{`${firstName} ${lastName}`}</h3>);
    if (role && role.id != undefined) {
      signatureDiv.push(<h4 key="sh4">{_(role.type)}</h4>);
    }
    signatureDiv.push(
      <h5 key="h5">
        {(surveyAuthorisation && surveyAuthorisation.role != undefined) ? _(surveyAuthorisation.role) : '____'}
      </h5>
    );
    return (
      <div className="user sidebar__user">
        {avatarDiv}
        <div className="user-header">
          {signatureDiv}
        </div>
      </div>
    );
  }

  renderGroupUsers(users) {
    const avatarsDiv = [];
    const names = [];

    for (let i = 0; i < users.length; i++) {
      if (i >= 3) {
        break;
      }
      const {
        id, firstName, lastName, avatarUrl
      } = users[i];

      names.push(users.length <= 3 ? `${firstName} ${lastName}` : firstName);
      if (avatarUrl) {
        avatarsDiv.push(<div key={`user-${id}`} className="user-avatar" style={{ backgroundImage: `url(${avatarUrl})` }} />);
      } else {
        avatarsDiv.push((
          <div key={`user-${id}`} className="user-avatar empty-avatar">
            <span>{getUserNameForAvatar(firstName, lastName)}</span>
          </div>
        ));
      }
    }
    let other = 0;
    if (users.length > 3) {
      other = users.length - 3;
    }
    return (
      <div className="side__bar-group">
        <div className="side__bar-group-avatar">
          {avatarsDiv}
        </div>
        <div className="side__bar-group-names">
          {names.join((users.length <= 3) ? ' and ' : ', ')}
          {(other > 0) ? (
            <span>
              {' '}
              {_('and')}
              {' '}
              <strong>
                {other}
                {' '}
                {_('other user(s)')}
                .
              </strong>
            </span>
          ) : null}
        </div>
      </div>
    );
  }

  renderUsers() {
    const { users, selectedIds } = this.props;
    const selectedUsers = users.filter(user => selectedIds.indexOf(user.id) > -1);

    if (!selectedUsers || selectedUsers.length == 0) {
      return null;
    }

    if (selectedUsers.length == 1) {
      return this.renderSingleUser(selectedUsers[0]);
    }
    return this.renderGroupUsers(selectedUsers);
  }

  setMandatedValue(val) {
    this.props.setIsMandated(val);
  }

  setRoleValue(val) {
    const { setRole } = this.props;
    setRole(val);
  }

  setAllowCreateSurvey(allowCreation) {
    const { setAllowCreateSurvey } = this.props;
    setAllowCreateSurvey(allowCreation);
  }

  setAllowPublishSurvey(allowPublishing) {
    const { setAllowPublishSurvey } = this.props;
    setAllowPublishSurvey(allowPublishing);
  }

  setAllowCloseSurvey(allowClosing) {
    const { setAllowCloseSurvey } = this.props;
    setAllowCloseSurvey(allowClosing);
  }

  setAllowAccessResult(allowAccess) {
    const { setAllowAccessResult } = this.props;
    setAllowAccessResult(allowAccess);
  }

  renderPrivilegesBlock() {
    const {
      role, allowCreateSurvey, allowPublishSurvey,
      allowCloseSurvey, allowAccessResult
    } = this.props;
    if (role == 'ADMIN') {
      return null;
    }
    return (
      <div>
        <div className="separator">{_('survey privileges')}</div>
        <div className="preferences-switch">
          <div className="switch">
            <span className="sidebar_role">{_('allow create survey')}</span>
            <Switch name="surveyCreate" keyAttr="survey-create" isChecked={allowCreateSurvey} onChange={val => this.setAllowCreateSurvey(val)} />
          </div>
          <div className="switch">
            <span className="sidebar_role">{_('allow publish survey')}</span>
            <Switch name="surveyPublish" keyAttr="survey-publish" isChecked={allowPublishSurvey} onChange={val => this.setAllowPublishSurvey(val)} />
          </div>
          <div className="switch">
            <span className="sidebar_role">{_('allow access results')}</span>
            <Switch name="surveyResult" keyAttr="survey-result" isChecked={allowAccessResult} onChange={val => this.setAllowAccessResult(val)} />
          </div>
          <div className="switch">
            <span className="sidebar_role">{_('allow close survey')}</span>
            <Switch name="surveyClose" keyAttr="survey-close" isChecked={allowCloseSurvey} onChange={val => this.setAllowCloseSurvey(val)} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      fetched, updating, updated, role
    } = this.props;
    if (!fetched) {
      return null;
    }
    return (
      <div>
        <div id="authorisation-settings__sidebar" className="sidebar">
          {this.renderUsers()}
          <div className="separator">{_('role')}</div>
          <div className="switches">
            <MultiSwitch
              labels={[_('AUTHOR'), _('REDACTOR'), _('CHIEF_EDITOR')]}
              selectedValue={role}
              vals={['AUTHOR', 'REDACTOR', 'CHIEF_EDITOR']}
              name="surveyRole"
              onChange={val => this.setRoleValue(val)}
              customClass="sidebar_role"
            />
          </div>
          {/* {this.renderPrivilegesBlock()} */}
        </div>
        <Controls
          ok="Apply"
          cancel="Cancel"
          updated={updated}
          updating={updating}
          saveCallback={this.init}
          label={_('Apply authorization to selected users')}
          closeDiv="mask"
          closeDivType="class"
          action={this.saveUsersSettings}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedIds: state.settings.selectedUsers,
  users: state.users.items,
  fetched: state.users.fetched,
  updating: state.users.updating,
  updated: state.users.updated,
  role: state.settings.role,
  allowCreateSurvey: state.settings.allowCreateSurvey,
  allowPublishSurvey: state.settings.allowPublishSurvey,
  allowCloseSurvey: state.settings.allowCloseSurvey,
  allowAccessResult: state.settings.allowAccessResult
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    initSaveUsers,
    setRole,
    setAllowCreateSurvey,
    setAllowPublishSurvey,
    setAllowCloseSurvey,
    setAllowAccessResult,
    updateUsersSettings
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
