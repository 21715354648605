import React from 'react';

const PreviewMessage = props => (
  <div className="preview_message">
    <div className="preview__container__title">
      {props.title && <h1 className="text-center">{props.title}</h1>}
      <h5 className="text-center m-t-xl">{props.children}</h5>
    </div>
  </div>
);

export default PreviewMessage;
