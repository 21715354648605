import * as actions from 'actions';
import { getCampaignStats } from 'TamtamAPI';

export const fetchCampaignStats = InstanceId => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  const id = currentClient ? currentClient.id : 0;
  return dispatch(actions.fetchCampaignStats(
    getCampaignStats(token, InstanceId, id),
  ));
};
