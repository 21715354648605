const initialState = {
  isOpen: false,
  area: null,
  id: null,
  communityId: null,
  targetId: null,
  targetTitle: null,
  targetApp: 'SURVEY',
  targetType: null,
  language: 'fr',
  onCloseCallback: null
};

export const CampaignViewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLOSE_EMAILING_CAMPAIGN_VIEWER': {
      return initialState;
    }
    case 'OPEN_EMAILING_CAMPAIGN_VIEWER': {
      return { ...state, ...action.viewer };
    }
    default:
      return state;
  }
};
