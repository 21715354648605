import React, { PureComponent } from 'react';
import {
  getCommunityDisplayName,
  getCommunityLogoUrl,
} from '../../services/auth';

export default class Community extends PureComponent {
  render() {
    const { community, onClick } = this.props;

    return (
      <div className="sc__community cell small-4" onClick={onClick}>
        <div className="sc__community-logo">
          <img src={getCommunityLogoUrl(community)} alt={community.name} />
        </div>
        <span className="sc__community-name" title={community.name}>
          {getCommunityDisplayName(community)}
        </span>
      </div>
    );
  }
}
