import React from 'react';

export default function statsTabsFetching() {
  return (
    <div className="stats__menu-item fetching">
      <h5 className="gradiant no-margin" />
    </div>
  );
}

export function TabListItemFetching() {
  const tabs = [];

  for (let i = 0; i < 5; i++) {
    tabs.push(
      <div className="stats__menu-item">
        <h5 className="gradiant" />
      </div>
    );
  }

  return tabs;
}
