import * as actions from 'actions';
import { getParticipantUserStats } from 'TamtamAPI';

export const fetchParticipantUserStats = InstanceId => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;
  const id = currentClient ? currentClient.id : 0;
  return dispatch(actions.fetchParticipantUserStats(
    getParticipantUserStats(token, InstanceId, id),
  ));
};
