import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_ID } from 'Config';

export function initGA() {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
}

export function setPageView(pageType, id) {
  const path = window.location.pathname;

  ReactGA.set({ page: path });
  if (pageType && id) {
    const title = `${pageType}#${id}`;
    ReactGA.set({ title });
    ReactGA.set({ dimension1: id });
    ReactGA.pageview(path);
  } else {
    ReactGA.pageview(path);
  }
}
