import axios from 'axios';
import { TTP_API_URL } from 'Config';
import { getRankingOptions } from 'TamtamAPI';


export const getParticipantOrganizations = (token, surveyId, data) => {
  const requestUrl = `${TTP_API_URL}/survey/organization-survey`;
  const {
    pageSize, paginationPage, country, city, organizationName, sortConfig,
  } = data;
  const sort = [];
  const fields = ['id', 'isUsed', 'createdAt', 'organization'];
  const filter = [
    {
      property: 'survey',
      value: surveyId || 0,
      operator: 'eq',
    },
    {
      property: 'isUsed',
      value: 1,
      operator: 'eq',
    },
  ];

  if (country != null && country !== 'all') {
    filter.push({
      property: 'organization.country',
      value: country,
      operator: 'eq',
    });
  }
  if (city != null && city !== 'all') {
    filter.push({
      property: 'organization.city',
      value: city,
      operator: 'eq',
    });
  }
  if (organizationName) {
    filter.push({
      property: 'organization.name',
      value: organizationName,
      operator: 'like',
    });
  }

  if (sortConfig) {
    sort.push({
      property: sortConfig.columnKey,
      dir: sortConfig.sortDirection,
    });
  }

  const params = {
    access_token: token,
    limit: pageSize || 10,
    start: (!paginationPage) ? 0 : ((paginationPage - 1) * pageSize),
    fields: fields.join(','),
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort)
  };

  return axios.get(requestUrl, { params });
};

export const getParticipantOrganizationsOptions = token => getRankingOptions(token);
