import React from 'react';

const QuestionResultsFetching = () => (
  <div className="row wrapper">
    <div className="medium-12 columns">
      <div className="medium-12 columns list-fetching">
        {
          Array.from(Array(5).keys()).map(item => (
            <div className="row result-row border" key={item}>
              <div className="column medium-8 result-row__header-wrapper">
                <div className="result-row__header"><h5 className="gradiant" /></div>
              </div>
              <div className="column medium-2 separator">
                <div className="result-row__score-item">
                  <div className="score-label"><label className="no-content" /></div>
                  <div className="score-score">--</div>
                </div>
              </div>
              <div className="column medium-2">
                <div className="result-row__score-item">
                  <div className="score-label"><label className="no-content" /></div>
                  <div className="score-score">--</div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  </div>
);

export default QuestionResultsFetching;
