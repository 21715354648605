import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  updateAnswer,
  addLastUpdatedAnswer,
  removeAnswerByQuestionId,
} from 'actions';
import { deleteAnswer } from 'thunks';
import { isDisplayed } from 'surveyUtils';
import { selectAnswerByQuestion } from 'reducers';
import { QuestionHeader } from './QuestionHeader';
import PreviewControlButton from './PreviewControlButton';

class OpinionScaleQuestionPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerValue: props.answer ? props.answer.answerValue : null,
    };
  }

  componentDidUpdate(prevProps) {
    const { parentAnswer, question } = this.props;

    if (
      isDisplayed(question, parentAnswer) !==
      isDisplayed(question, prevProps.parentAnswer)
    ) {
      this.setState({ answerValue: null });
      this.props.removeAnswerByQuestionId(question?.id ?? question?.uid);
    }
  }

  onClick(number) {
    const {
      question,
      updateAnswer,
      addLastUpdatedAnswer,
      deleteAnswer,
    } = this.props;
    const answerValue = this.state.answerValue == number ? null : number;
    const answer = {
      ...this.props.answer,
      question: question.id || question.uid,
      answerValue,
      resourceType: 'number',
    };

    this.setState(prevState => ({
      ...prevState,
      answerValue,
    }));

    updateAnswer(answer);
    if (answer.answerValue != null && answer.answerValue.length !== 0) {
      addLastUpdatedAnswer(question.id || question.uid);
    } else if (answer.id) {
      deleteAnswer(answer.id, question.id);
    }

    if (answerValue !== null) {
      setTimeout(() => {
        this.props.scrollToNext(this.ref);
      }, 0);
    }
  }

  goToNext = goBack => this.props.scrollToNext(this.ref, goBack);

  renderOpinionScaleSteps() {
    const { maxNumber } = this.props.question;
    const { survey } = this.props.surveys;
    const { steps, type } = survey;
    const range = type == 'SATISFACTION' ? steps || maxNumber : maxNumber || 10;
    const list = [];

    for (let i = 1; i <= range; i++) {
      const isSelected = this.state.answerValue === i;
      list.push(
        <div
          key={i}
          className={`srv-scale__elm no-background-opacity survey-main-content-background-hover-light ${
            isSelected
              ? 'is-selected survey-main-content-background-color survey-main-content-color'
              : ''
          }`}
          onClick={() => this.onClick(i)}
        >
          {i}
        </div>,
      );
    }

    return list;
  }

  render() {
    const { question, parentAnswer, order, surveys } = this.props;
    const { text } = question;
    const isShowed = isDisplayed(question, parentAnswer);
    const hasParent = question.parent != null;

    return (
      <div
        className="preview__container qst__wrapper"
        style={{ display: isShowed ? 'block' : 'none' }}
        ref={ref => {
          this.ref = ref;
          this.props.addRef(ref, null, question);
        }}
      >
        {/* <div className="preview__container__bg" /> */}
        <QuestionHeader
          id={`question-label-${question.id || question.uid}`}
          text={text[surveys.language]}
          order={order}
          isRequired={question.required == '1'}
          hasOrder={!hasParent}
        />
        <div className="preview__container__content qst__content">
          <div className="preview-input">
            <div className="srv-scale__wrapper srv-secondary-border-color--medium-opacity srv-secondary-color">
              {this.renderOpinionScaleSteps()}
            </div>
          </div>
          <PreviewControlButton
            className="srv-button-color--fill"
            onClick={this.goToNext}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { question } = props;
  const questionId = question.id || question.uid;

  return {
    surveys: state.surveys.list,
    answer: state.surveyResponse.answers.hasOwnProperty(questionId)
      ? state.surveyResponse.answers[questionId]
      : null,
    parentAnswer: selectAnswerByQuestion(question.parent)(state.surveyResponse),
  };
};

const mapDispatchToProps = {
  updateAnswer,
  addLastUpdatedAnswer,
  deleteAnswer,
  removeAnswerByQuestionId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpinionScaleQuestionPreview);
