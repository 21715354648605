import axios from 'axios';
import {
  CLIENT_CREDENTIAL,
  TTP_API_URL,
  ANONYMOUS_USER_CREDENTIAL,
} from 'Config';
import { getFormData } from 'utils';

export const getTTPUser = (userId, token, ignorePermissionFilter = 0) => {
  const filter = [
    {
      property: 'id',
      value: userId,
      operator: 'eq',
    },
  ];
  const fields = [
    '*',
    'email',
    'avatar',
    'description',
    'cover',
    'url',
    'roles',
    'birthday',
    'surveyClients',
  ];
  const requestUrl = `${TTP_API_URL}/organization/user`;

  const params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(','),
  };

  if (ignorePermissionFilter === 1) {
    params.ignorePermissionFilter = 1;
  }

  return axios.get(requestUrl, {
    params,
  });
  /* return $.ajax({
    async: false,
    url: requestUrl,
    data: params
  }); */
};

export const getTTPFolder = (token, organizationId) =>
  axios.get(`${TTP_API_URL}/organization/folder/${organizationId}`, {
    params: {
      access_token: token,
    },
  });

// export const getClientCredential = () => {
//   const requestUrl = `${TTP_API_URL}/token`;

//   return $.ajax({
//     type: 'POST',
//     url: requestUrl,
//     data: CLIENT_CREDENTIAL,
//   });
// };

export const getClientCredential = () => {
  const requestUrl = `${TTP_API_URL}/token`;
  return axios.post(requestUrl, getFormData(CLIENT_CREDENTIAL));
};

export const getCurrentsUser = token => {
  const fields = [
    '*',
    'email',
    'avatar',
    'description',
    'cover',
    'url',
    'roles',
    'birthday',
    'surveyClients',
  ];

  const requestUrl = `${TTP_API_URL}/organization/user/getCurrentUser`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(','),
    },
  });
};

export const getAnonymousUser = email => {
  const requestUrl = `${TTP_API_URL}/token`;

  const formData = new FormData();
  formData.append('grant_type', ANONYMOUS_USER_CREDENTIAL.grant_type);
  formData.append(
    'grant_access_type',
    ANONYMOUS_USER_CREDENTIAL.grant_access_type,
  );
  formData.append('client_id', ANONYMOUS_USER_CREDENTIAL.client_id);
  formData.append('client_secret', ANONYMOUS_USER_CREDENTIAL.client_secret);
  formData.append('scope', ANONYMOUS_USER_CREDENTIAL.scope);
  formData.append('username', email);

  return axios.post(requestUrl, formData);
};

export const setSelectedOrganization = (token, client) => {
  const requestUrl = `${TTP_API_URL}/organization/user/save-selected-organization`;

  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("organizationId", client.id);

  return axios.post(requestUrl, formData);
};