const SURVEY_BUILDER_TABS = {
  EDITOR: 'editor',
  PREVIEW: 'preview',
  MAILS: 'mails',
  TEMPLATES: 'templates',
  SETTINGS: 'settings',
  SURVEY_SETTINGS: 'survey-settings',
  THEMES: 'themes',
  SURVEY_THEMES: 'survey-themes', // TODO remove when themes are activated
  LIBRARY: 'library',
};

const MOVE_UP = 'MOVE_UP';
const MOVE_DOWN = 'MOVE_DOWN';
const MOVE_TO_START = 'MOVE_TO_START';
const MOVE_TO_END = 'MOVE_TO_END';

export { SURVEY_BUILDER_TABS, MOVE_UP, MOVE_DOWN, MOVE_TO_START, MOVE_TO_END };
