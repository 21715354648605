import React, { Fragment } from 'react';

const FullPageMessage = props => (
  <Fragment>
    <div className="preview__container survey-full-page srv-flex srv-flex--center">
      <div className="preview__container__title">
        {
          props.title
          && <h1 className="text-center">{props.title}</h1>
        }
        <h5>
          {props.children}
        </h5>
      </div>
    </div>
  </Fragment>
);

export default FullPageMessage;
