import React, { Component } from 'react';
import { orderToChar } from '../../services/surveyUtils';

export default class QuestionChoiceItem extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: false };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      order: nextProps.order,
      selected: nextProps.selected,
      value: nextProps.value,
      label: nextProps.label
    };
  }

  render() {
    const { order, value, selected, label } = this.state;
    const { disabled } = this.props;
    const classLabel = `label-choice ${selected ? 'selected survey-main-content-gradient-background-color' : ''} ${disabled ? 'disabled-link' : ''}`;
    const classIcon = `choice-icon survey-main-color ${selected ? 'selected' : ''}`;

    return (
      <label className={classLabel}>
        <input
          type="checkbox"
          className="visually-hidden"
          name="test"
          value={value}
          onChange={e => this.props.onChange(value, e.target.checked)}
          checked={selected}
          disabled={disabled}
        />
        <div className="title-choice">
          {
            selected
              ? <span className={classIcon}><i className="icomoon-icon icon-tt-correct" /></span>
              : <span className={classIcon}>{orderToChar(order)}</span>
          }
          <span className="content-choice srv-second" id={this.props.id}>{label}</span>
        </div>
      </label>
    );
  }
}
