import { serializeSurvey } from 'surveyUtils';

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  survey: null,
  error: null,
  nbResult: 0,
  filter: {
    language: '',
    sortDirection: 'desc',
    searchWord: '',
    status: 'all',
    title: '',
    type: 'all',
    env: 'all',
    owner: 'all',
    pageSize: 8,
    paginationPage: 1,
    isPublicTheme: false
  },
};

export const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_TEMPLATE_FILTER': {
      return { ...state, filter: initialState.filter };
    }
    case 'INIT_TEMPLATES_PAGINATION': {
      const { pageSize, paginationPage, nbResult } = initialState;
      return {
        ...state,
        pageSize,
        paginationPage,
        nbResult
      };
    }
    case 'SET_TEMPLATES_PAGINATION_PAGE': {
      return { ...state, filter: { ...state.filter, paginationPage: action.pageNumber } };
    }
    case 'SET_TEMPLATES_PAGE_SIZE': {
      return { ...state, filter: { ...state.filter, pageSize: action.pageSize } };
    }
    case 'SET_TEMPLATE_SEARCH_WORD': {
      return { ...state, filter: { ...state.filter, searchWord: action.searchWord, paginationPage: 1 } };
    }
    case 'SET_TEMPLATE_LIST_SORT': {
      return { ...state, filter: { ...state.filter, sortDirection: action.sortDirection, paginationPage: 1 } };
    }
    case 'SET_TEMPLATE_FILTER_STATUS': {
      return { ...state, filter: { ...state.filter, status: action.status, paginationPage: 1 } };
    }
    case 'SET_TEMPLATE_FILTER_TYPE': {
      return { ...state, filter: { ...initialState.filter, type: action.templateType, paginationPage: 1 }, items: [] };
    }
    case 'SET_TEMPLATE_FILTER_OWNER': {
      return { ...state, filter: { ...state.filter, owner: action.owner, paginationPage: 1 } };
    }
    case 'SET_TEMPLATE_FILTER_TITLE': {
      return { ...state, filter: { ...state.filter, title: action.title, paginationPage: 1 } };
    }
    case 'SET_TEMPLATE_FILTER_ENVIRONMENT': {
      return { ...state, filter: { ...state.filter, env: action.env, paginationPage: 1 } };
    }
    case 'SET_CURRENT_TEMPLATE': {
      const { survey } = action;
      return { ...state, survey: serializeSurvey(survey) };
    }
    case 'FETCH_TEMPLATE_PENDING':
    case 'PUSH_TEMPLATES_PENDING':
    case 'FETCH_TEMPLATES_PENDING': {
      return { ...state, fetching: true };
    }
    case 'FETCH_TEMPLATES_FULFILLED': {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult,
      };
    }
    case 'FETCH_TEMPLATE_REJECTED':
    case 'FETCH_TEMPLATES_REJECTED': {
      let error = action.payload;
      switch (error && error.status && error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        fetched: false,
        items: [],
        error,
      };
    }
    case 'FETCH_TEMPLATE_FULFILLED': {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        survey: serializeSurvey(data),
        nbResult,
      };
    }
    case 'PUSH_TEMPLATES_FULFILLED': {
      const { data, nbResult } = action.payload.data;
      const allItems = state.items;
      allItems.push(...data);
      return {
        ...state,
        items: allItems,
        fetching: false,
        fetched: true,
        nbResult
      };
    }
    case 'PUSH_TEMPLATES_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: false
      };
    }
    default:
      return state;
  }
};
