import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'i18n';
import { TTP_API_URL } from 'Config';
import { setCurrentTemplate, initSurveyResponse } from 'actions';
import { createSurveyFromTemplate } from 'thunks';
import { getTextByLanguage, getMediaByLanguage } from 'surveyUtils';
import { NotificationManager } from 'react-notifications';

class TemplateItem extends Component {
  showPreview = () => {
    const { template } = this.props;
    this.props.setCurrentTemplate(template);
    this.props.initSurveyResponse();
    this.props.handleShowPreview();
  }

  handleCreateSurveyFromTemplate = () => {
    const msg = _('create_survey_from_template_success');
    const title = _('create_survey');
    const { createSurveyFromTemplate, template, history } = this.props;
    createSurveyFromTemplate(template.slug).then(
      (res) => {
        const template = res.value.data.data;

        this.onSuccess(res, msg, title);
        if (template.isFixed) {
          history.push('/surveys?is-template=0');
        } else {
          history.push(`/surveys/${template.slug}/create`);
        }
      }, err => this.onFailure(err),
    );
  }

  onFailure() {
    NotificationManager.error(_('errorOccured'), _('error'));
  }

  onSuccess(res, msg, title) {
    if (res.value instanceof Error) {
      this.onFailure();
    } else {
      NotificationManager.success(msg, title);
    }
  }

  render() {
    const { name, isFixed, title, welcomeScreen, isSectorial } = this.props.template;

    const imageBackground = getMediaByLanguage(welcomeScreen, this.props.lng);
    const background = (imageBackground && `url('${`${TTP_API_URL}/${imageBackground}`}') center center / cover`) || 'none';
    const style = {
      background,
      backgroundRepeat: 'no-repeat',
    };

    return (
      <div className="column column-block" key={this.props.key}>
        <div
          className="template-item__box srv-card"
          style={style}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <div className="status-wrapper">
            {isSectorial && <span className="status greetings">{_('Consolidated survey')}</span>}
            {isFixed && !isSectorial && <span className="status greetings color-warning">{_('isFixed')}</span>}
          </div>
          <div className="srv-card__container">
            <div className="srv-card__title">
              <h5 className="srv__font-bold">{getTextByLanguage(title, this.props.lng)}</h5>
            </div>
          </div>
          <div className="template-item__overlay align-center align-middle">
            <div className="template-item__action" onClick={this.showPreview}>
              {_('preview')}
            </div>
            <div className="template-item__action" onClick={this.handleCreateSurveyFromTemplate}>
              {_('Use this template')}
            </div>
          </div>
        </div>
        <div className="template-item__title">{_(name)}</div>
      </div>
    );
  }
}


const mapDispatchToProps = { setCurrentTemplate, initSurveyResponse, createSurveyFromTemplate };

export default compose(
  connect(null, mapDispatchToProps),
  withRouter
)(TemplateItem);
