import React from 'react';
import _ from 'i18n';

export default function NoDataFound() {
  return (
    <div className="srv__list empty-data">
      <div className="srv-list-no-data">
        <div className="srv-list-no-data__icon">
          <span><i className="icomoon-icon icon-cloud-error" /></span>
        </div>
        <div className="srv-list-no-data__msg">{_('no_survey_data_msg')}</div>
        <div className="srv-list-no-data__moreinfo">{_('no_survey_data_info')}</div>
      </div>
    </div>
  );
}
