// ranking organizations
export const initializeRankingOrganization = () => ({
  type: 'INITIALIZE_RANKING_ORGANIZATION',
});

export const fetchRankingOrganizations = resp => ({
  type: 'FETCH_RANKING_ORGANIZATIONS',
  payload: resp,
});

export const fetchRankingOrganizationsOptions = resp => ({
  type: 'FETCH_RANKING_ORGANIZATIONS_OPTIONS',
  payload: resp,
});

// Filters
export const setRankingOrganizationsPaginationPage = pageNumber => ({
  type: 'SET_RANKING_ORGANIZATIONS_PAGINATION_PAGE',
  pageNumber,
});

export const setRankingOrganizationsPageSize = pageSize => ({
  type: 'SET_RANKING_ORGANIZATIONS_PAGE_SIZE',
  pageSize,
});

export const setRankingOrganizationsFilterCountry = country => ({
  type: 'SET_RANKING_ORGANIZATIONS_FILTER_COUNTRY',
  country,
});

export const setRankingOrganizationsFilterCity = city => ({
  type: 'SET_RANKING_ORGANIZATIONS_FILTER_CITY',
  city,
});

export const setRankingOrganizationsFilterOrganizationName = organizationName => ({
  type: 'SET_RANKING_ORGANIZATIONS_FILTER_ORGANIZATION_NAME',
  organizationName,
});

// Sort:
export const setRankingOrganizationsSortConfig = (columnKey, sortDirection) => ({
  type: 'SET_RANKING_ORGANIZATIONS_LIST_SORT_CONFIG',
  config: {
    columnKey,
    sortDirection,
  },
});
