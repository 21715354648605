const initialState = {
  selectedUsers: [],
  role: 'AUTHOR',
  allowCreateSurvey: false,
  allowPublishSurvey: false,
  allowCloseSurvey: false,
  allowAccessResult: false,
  filter: {
    role: 'ALL',
    searchWord: '',
  }
};

export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_USERS': {
      return { ...state, selectedUsers: action.selectedUsers };
    }
    case 'SET_ROLE': {
      return { ...state, role: action.role };
    }
    case 'SET_ALLOW_CREATE_SURVEY': {
      return { ...state, allowCreateSurvey: action.allowCreateSurvey };
    }
    case 'SET_ALLOW_PUBLISH_SURVEY': {
      return { ...state, allowPublishSurvey: action.allowPublishSurvey };
    }
    case 'SELECT_ROLE': {
      return { ...state, filter: { ...state.filter, role: action.role } };
    }
    case 'SELECT_SEARCH_WORD': {
      return { ...state, filter: { ...state.filter, searchWord: action.searchWord } };
    }
    case 'SET_ALLOW_CLOSE_SURVEY': {
      return { ...state, allowCloseSurvey: action.allowCloseSurvey };
    }
    case 'SET_ALLOW_ACCESS_RESULT': {
      return { ...state, allowAccessResult: action.allowAccessResult };
    }
    default:
      return state;
  }
};
