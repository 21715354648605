import React from 'react';
import { getTextByLanguage } from 'surveyUtils';
import ProfileMenu from '../menu/right-side/ProfileMenu';

export const PreviewHeader = ({ title, lng, children }) => (
  <div className="preview__header__survey front" id="mainMenu">
    <span className="title-wrapper">
      <span className="img-wrapper"><img alt="" src="/img/pictos/logo.svg" /></span>
      <span className="greetings">{getTextByLanguage(title, lng)}</span>
    </span>
    <span className="top-bar-right">
      {children}
      <ProfileMenu />
    </span>
  </div>
);

export default PreviewHeader;
