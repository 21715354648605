import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  setTemplateFilterOwner,
  setTemplateFilterTitle,
  setTemplateFilterEnvironment,
  initTemplateFilter
} from 'actions';
import _ from 'i18n';
import { bindActionCreators } from 'redux';

class TemplateFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterFormIsVisible: false,
      searchQuery: '',
      oldSearchQuery: '',
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ filterFormIsVisible: false });
    }
  }

  handleToggleFilterForm() {
    this.setState(prevState => ({ filterFormIsVisible: !prevState.filterFormIsVisible }));
  }

  handleSelectOwner({ target: { value } }) {
    this.props.setTemplateFilterOwner(value);
    this.props.onFilterApply(1);
  }

  handleSelectEnvironment({ target: { value } }) {
    this.props.setTemplateFilterEnvironment(value);
    this.props.onFilterApply(1);
  }

  handleResetFilter() {
    this.props.initTemplateFilter();
    this.setState({ searchQuery: '', oldSearchQuery: '' });

    this.props.onFilterApply(1);
  }

  handleSearchSubmitClick() {
    const { searchQuery, oldSearchQuery } = this.state;
    if (searchQuery != oldSearchQuery) {
      this.props.setTemplateFilterTitle(this.state.searchQuery);
      this.props.onFilterApply(1);
    }
    this.setState({ oldSearchQuery: searchQuery });
  }

  handleSearchInputChange = ({ target: { value } }) => {
    this.setState(prevState => ({ searchQuery: value, oldSearchQuery: prevState.searchQuery }));
  }

  handleSearchInputEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSearchSubmitClick();
    }
  }

  render() {
    const { filterFormIsVisible, searchQuery } = this.state;
    const { filter, userId } = this.props;

    return (
      <div className="filter-wrapper">
        <button type="button" className="button filter-button" onClick={this.handleToggleFilterForm.bind(this)}>
          {_('filter')}
          <i className="icon icon-arrow-down" />
        </button>
        <div className={filterFormIsVisible ? 'filter-form show' : ' filter-form hide'} ref={this.wrapperRef}>
          <label>
            {_('owner')}
            <select value={filter.owner} placeholder="filter by owner" onChange={this.handleSelectOwner.bind(this)}>
              <option value="all">{_('all').toUpperCase()}</option>
              <option value={userId}>{_('My surveys').toUpperCase()}</option>
            </select>
          </label>
          <label>
            {_('environment')}
            <select value={filter.env} placeholder="filter by environment" onChange={this.handleSelectEnvironment.bind(this)}>
              <option value="all">{_('all').toUpperCase()}</option>
              <option value="private">{_('private').toUpperCase()}</option>
              <option value="public">{_('public').toUpperCase()}</option>
            </select>
          </label>
          <button type="button" className="button secondary" onClick={this.handleResetFilter.bind(this)}>{_('reset')}</button>
        </div>
        <div className="search">
          <input value={searchQuery} placeholder={_('search by survey title')} className="search-input" onChange={this.handleSearchInputChange} onKeyPress={this.handleSearchInputEnterKeyPress} />
          <button type="button" id="search-btn" className="search-submit" onClick={this.handleSearchSubmitClick.bind(this)}><i className="icon-magnifier" /></button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  filter: state.templates.filter,
  userId: state.auth.user.id
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setTemplateFilterOwner,
    setTemplateFilterTitle,
    setTemplateFilterEnvironment,
    initTemplateFilter
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateFilter);
