import React, { Component } from 'react';
import { connect } from 'react-redux';
import Menu from 'Menu';
import Footer from 'Footer';
import LoadingBar from 'react-redux-loading-bar';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { withRouter } from 'react-router-dom';
import { closeGenericEmailingViewer } from 'actions';
import { defaultTheme } from 'themes';
import { HORIZONTAL } from 'constants';
import { getLocalStorageRedirectUrl, setLocalStorageRedirectUrl } from '../api/SurveyAPI';
import { initGA } from '../services/googleAnalytics';
import PreviewStyles from './preview-items/PreviewStyles';
import TTPDialog from './common/TTPDialog';
import GenericEmailingViewer from './reveal/emailing-viewer/generic-emailing/GenericEmailingViewer';
import CampaignViewer from './reveal/emailing-viewer/campaign-viewer/CampaignViewer';
import SubMenu from './menu/SubMenu';
import SelectCommunity from "./SelectCommunity/SelectCommunity";
import TTPLoader from "./ui/TTPLoader";
import __ from 'lodash';

class Layout extends Component {
  constructor(props) {
    super(props);
    initGA();
  }

  componentDidMount() {
    window.addEventListener('message', (event) => {
      const { closeGenericEmailingViewer } = this.props;

      if (event?.data === 'CLOSE_VIEWER') {
        closeGenericEmailingViewer();
      }
    }, false);
  }

  componentDidUpdate() {
    const { history, auth: { fetched } } = this.props;
    const redirectUrl = getLocalStorageRedirectUrl();

    if (fetched && redirectUrl != null) {
      history.push(redirectUrl);
      setLocalStorageRedirectUrl(null);
    }
  }

  render() {
    const { currentNavPage, subMenuDirection, auth: { fetching, currentClient, user, fetchingAnonymous, isVisitor, appTokenFetching } } = this.props;

    if (appTokenFetching) {
      return <TTPLoader cssClass={"fixed-loader"} />;
    }
    
    if (!fetchingAnonymous && !isVisitor) {
      if(fetching) {
      }
  
      const clients = Array.isArray(user?.surveyClients)
      ? __.compact(user?.surveyClients)
      : [];

      if(user && !currentClient && clients.length > 0) {
        return <SelectCommunity />;
      }
    }

    return (
      <div id="offCanvas-wrapper" className="off-canvas-wrapper">
        <PreviewStyles theme={defaultTheme} />
        <div className="off-canvas-content srv-content">
          <LoadingBar className="loading-bar" />
          {(['SURVEY_BUILDER', 'RESPONSE', 'SURVEY_QUIZ'].indexOf(currentNavPage) == -1) ? <Menu /> : null}
          <NotificationContainer />
          <div className={`flex-container flex-child-auto ${subMenuDirection == HORIZONTAL ? 'flex-dir-column' : 'flex-dir-row'}`}>
            {(['SURVEY_BUILDER', 'RESPONSE', 'SURVEY_QUIZ'].indexOf(currentNavPage) == -1) ? <SubMenu /> : null}
            <div className="flex-child-auto flex-container flex-dir-column">
              {this.props.children}
              {(['SURVEY_BUILDER', 'RESPONSE', 'SURVEY_QUIZ', 'TEMPLATE_PICKER', 'SETTINGS', 'TYPE_SELECTOR', 'STATS', 'SURVEY_MANAGER'].indexOf(currentNavPage) == -1) ? <Footer /> : null}
            </div>
          </div>
        </div>
        <div className="mask hide" />
        <TTPDialog />
        <GenericEmailingViewer />
        <CampaignViewer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  currentNavPage: state.params.currentNavPage,
  subMenuDirection: state.params.subMenuDirection,
  redirectUrl: state.auth.redirectUrl,
});

const mapDispatchToProps = {
  closeGenericEmailingViewer,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
