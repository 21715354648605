import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchRankingClients } from 'thunks';
import { setRankingClientsSortConfig } from 'actions';
import __ from 'lodash';
import _ from 'i18n';
import NotFoundPage from 'NotFoundPage';
import RankingClientsListFetching from '../../fetching/stats/RankingClientsListFetching';

class RankingClientsList extends Component {
  onSortChange(columnKey) {
    const { rankingSort, setRankingClientsSortConfig } = this.props;
    let sortDir = 'asc';
    if (rankingSort.sortConfig.columnKey === columnKey) {
      sortDir = this.reverseSortDirection(rankingSort.sortConfig.sortDirection);
    }
    setRankingClientsSortConfig(columnKey, sortDir);
  }

  reverseSortDirection(sortDir) {
    return sortDir === 'desc' ? 'asc' : 'desc';
  }

  renderSortDirection(columnKey) {
    const { rankingSort } = this.props;
    if (columnKey === rankingSort.sortConfig.columnKey) {
      const isDesc = rankingSort.sortConfig.sortDirection === 'desc';
      const className = `icomoon-icon ${isDesc ? 'icon-sort-numberic-desc' : 'icon-sort-numeric-asc'}`;

      return (
        <span className="ranking-list__sort__icon" onClick={() => this.onSortChange(columnKey)}><i className={className} /></span>
      );
    }
    return (
      <span className="ranking-list__sort__icon" onClick={() => this.onSortChange(columnKey)}><i className="icomoon-icon icon-tab-sort" /></span>
    );
  }

  renderRankingList() {
    const { rankingSort } = this.props;
    const {
      items, paginationPage, pageSize, nbResult,
    } = this.props.ranking;

    return __.map(items, (item, index) => {
      let rank = 0;
      if (rankingSort.sortConfig.sortDirection === 'desc') {
        rank = (paginationPage - 1) * pageSize + index + 1;
      } else {
        rank = nbResult - (paginationPage - 1) * pageSize - index;
      }
      const { client } = item;
      return (
        <tr key={item.id}>
          <td className="cell-rank text-center">{rank}</td>
          <td className="text-left">{client ? client.name : ''}</td>
          <td className="text-center">
            {item.score ? `${item.score} %` : '--'}
          </td>
        </tr>
      );
    });
  }

  render() {
    const { ranking, surveyFetching } = this.props;

    if (ranking.fetching || surveyFetching) {
      return <RankingClientsListFetching />;
    }

    if (ranking.error != null && ranking.error.code == 404) {
      return <NotFoundPage mainClass="small-10" />;
    }

    return (
      <table className="unstriped hover">
        <thead>
          <tr>
            <th width="150" className="text-center"><label>{_('Rank')}</label></th>
            <th><label>{_('Client')}</label></th>
            <th width="200" className="text-center">
              <label>{_('Score')}</label>
              {this.renderSortDirection('score')}
            </th>
          </tr>
        </thead>
        <tbody>
          {this.renderRankingList()}
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = state => ({
  ranking: state.rankingClients.list,
  rankingSort: state.rankingClients.sort,
  surveyFetching: state.surveys.list.fetching,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchRankingClients, setRankingClientsSortConfig }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RankingClientsList);
