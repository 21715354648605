import React, { Component } from 'react';
import { fetchQuizStats } from 'thunks';
import _ from 'i18n';
import { connect } from 'react-redux';
import DimensionSummaryFetching from 'fetching/response-summary/DimensionSummaryFetching';
import DimensionStats from 'stats/response-summary/DimensionStats';
import StatsNotFound from 'stats/StatsNotFound';

class QuizStats extends Component {
  componentDidMount() {
    const { instance } = this.props;

    if (instance && instance.id) {
      this.props.fetchQuizStats(instance.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { instance } = this.props;

    if (prevProps.instance !== instance && instance) {
      this.props.fetchQuizStats(instance.id);
    }
  }

  renderList() {
    const { items, nbResult } = this.props.stats;
    const { fetching } = this.props;

    if (fetching) {
      return <DimensionSummaryFetching />;
    }
    return items.map((item, key) => (
      <DimensionStats data={item} nbResponses={nbResult} key={key} />
    ));
  }

  render() {
    const {
      fetched,
      fetching,
      stats: { nbResult },
    } = this.props;

    if (!fetched && !fetching) {
      return (
        <div className="row">{_('Please wait the results are in preparation')}</div>
      );
    }

    if (nbResult == 0 && !fetching) {
      return (
        <>
          <div className="row summary__wrapper">
            <div className="summary__block summary__header column small-12 responses">
              <span className="text">{_('Total number of responses')}</span>
              <span className="number">{fetching ? '--' : nbResult}</span>
            </div>
          </div>
          <div className="row">
            <div className="summary__block">
              <StatsNotFound />
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="row summary__wrapper">
          <div className="summary__block summary__header column small-12 responses">
            <span className="text">{_('Total number of responses')}</span>
            <span className="number">{fetching ? '--' : nbResult}</span>
          </div>
        </div>
        {this.renderList()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  stats: state.stats.list,
  fetching: state.stats.list.fetching,
  fetched: state.stats.list.fetched,
});

export default connect(mapStateToProps, { fetchQuizStats })(QuizStats);
