import axios from 'axios';
import { TTP_API_URL } from 'Config';


export const getParticipantUsers = (token, instance, organization, filter) => {
  const requestUrl = `${TTP_API_URL}/survey/participant`;
  const {
    pageSize,
    paginationPage,
    invited,
    revival1,
    revival2,
    partialRevival1,
    partialRevival2,
    participated,
    thankReceived,
    resultsStatus
  } = filter;
  const sort = [];
  const filters = [];
  const fields = ['*', 'user'];

  if (invited != null && invited != '') {
    filters.push({
      property: 'invited',
      value: invited,
      operator: 'eq',
    });
  }

  if (revival1 != null && revival1 != '') {
    filters.push({
      property: 'revival1',
      value: revival1,
      operator: 'eq',
    });
  }

  if (revival2 != null && revival2 != '') {
    filters.push({
      property: 'revival2',
      value: revival2,
      operator: 'eq',
    });
  }

  if (partialRevival1 != null && partialRevival1 != '') {
    filters.push({
      property: 'partialRevival1',
      value: partialRevival1,
      operator: 'eq',
    });
  }

  if (partialRevival2 != null && partialRevival2 != '') {
    filters.push({
      property: 'partialRevival2',
      value: partialRevival2,
      operator: 'eq',
    });
  }

  if (participated != null && participated != '') {
    filters.push({
      property: 'participated',
      value: participated,
      operator: 'eq',
    });
  }

  if (thankReceived != null && thankReceived != '') {
    filters.push({
      property: 'thankReceived',
      value: thankReceived,
      operator: 'eq',
    });
  }

  if (resultsStatus != null && resultsStatus !== 'all') {
    filters.push({
      property: 'resultsStatus',
      value: resultsStatus,
      operator: 'eq',
    });
  }

  const params = {
    instance,
    organization,
    access_token: token,
    limit: pageSize || 10,
    start: (!paginationPage) ? 0 : ((paginationPage - 1) * pageSize),
    fields: fields.join(',')
  };

  params.filter = JSON.stringify(filters);
  params.sort = JSON.stringify(sort);

  return axios.get(requestUrl, { params });
};

export const sendTransactionalMails = (token, type, participants) => {
  const requestUrl = `${TTP_API_URL}/survey/participant/transactional-mail`;

  return axios({
    method: 'post',
    url: requestUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    data: JSON.stringify({ type, participants })
  });
};
