import React, { useState } from 'react';
import _ from 'i18n';
import { useDispatch, useSelector } from 'react-redux';
import { onSuccess, onError } from 'utils';
import { updatePenetrationRate, deleteSurvey, saveOrganizationInstance } from 'thunks';
import { setSurveyOrganizationInstance } from 'actions';
import { LIST, SURVEY_MODALS_TYPES } from 'constants';
import { getTextByLanguage } from 'surveyUtils';
import { TTPConfirmDeleteModal } from 'common/reveal/TTPActionModal';
import GridList from './surveyGrid/SurveyList';
import List from './surveyList/SurveyList';
import SurveyStatusModal from './reveal/SurveyStatusModal';
import SurveyPenetrationRateModal from './reveal/SurveyPenetrationRateModal';

const SurveyList = (props) => {
  const { view, lng, ...rest } = props;

  /** redux */
  const dispatch = useDispatch();
  const { surveys } = useSelector(state => ({ surveys: state.surveys.list }));

  /** state */
  const [survey, setSurvey] = useState(surveys?.items?.[0] ?? null);
  const [activeModal, setActiveModal] = useState(null);

  const hideActiveModal = () => setActiveModal(false);
  const openSurveyModal = (survey, modalType) => {
    setSurvey(survey);
    setActiveModal(modalType);
  };

  const handleDeleteSurvey = () => dispatch(deleteSurvey(survey.id))
    .then(res => onSuccess(res, { body: _('delete_survey_success'), title: _('Delete') }), err => onError(err));

  const setInstanceStatus = (status) => {
    const { id, organizationInstance, latestInstance } = survey;
    const organizationInstanceData = { status };

    if (status == null) return null;

    if (organizationInstance?.id) {
      organizationInstanceData.id = organizationInstance.id;
    } else {
      organizationInstanceData.instance = latestInstance?.id;
    }

    return dispatch(saveOrganizationInstance(organizationInstanceData))
      .then(
        (res) => {
          const organizationInstance = res.value.data.data;
          if (organizationInstance) {
            dispatch(setSurveyOrganizationInstance(id, organizationInstance));
          }
          onSuccess(res, { body: _('edit_survey_status') });
        }, err => onError(err),
      );
  };

  const setPenetrationRate = penetrationRate => dispatch(updatePenetrationRate(penetrationRate, survey.slug))
    .then(res => onSuccess(res, { body: _('edit_penetration_rate'), title: _('Edit') }), err => onError(err));

  const Component = view === LIST ? List : GridList;

  return (
    <>
      <Component {...rest} openSurveyModal={openSurveyModal} surveys={surveys} />
      {
        survey && (
          <>
            <TTPConfirmDeleteModal
              title={_('Confirm delete')}
              message={`${_('delete_survey_message')} : ${getTextByLanguage(survey.title, lng)}`}
              onCloseModal={hideActiveModal}
              isOpen={activeModal === SURVEY_MODALS_TYPES.DELETE}
              onAsyncAction={handleDeleteSurvey}
            />
            <SurveyStatusModal
              surveyStatus={survey?.organizationInstance?.status ?? 'PENDING'}
              onCloseModal={hideActiveModal}
              isOpen={activeModal === SURVEY_MODALS_TYPES.STATUS}
              onAsyncAction={setInstanceStatus}
            />
            <SurveyPenetrationRateModal
              penetrationRate={survey.penetrationRate}
              onCloseModal={hideActiveModal}
              isOpen={activeModal === SURVEY_MODALS_TYPES.PENETRATION_RATE}
              onAsyncAction={setPenetrationRate}
            />
          </>
        )
      }
    </>
  );
};

export default SurveyList;
