import React, { Component } from 'react';
import _ from 'i18n';
import NotFoundPage from 'NotFoundPage';
import controlAccess from './controlAccess';
import SurveyCampaignList from './survey-campaign/SurveyCampaignList';
import SurveyCampaignStats from './survey-campaign/SurveyCampaignStats';

class CampaignStats extends Component {
  render() {
    const { hide, disabled } = this.props;

    if (hide || disabled) {
      return <NotFoundPage />;
    }

    return (
      <div className="row campaign-stats">
        <div style={{ width: '100%' }}>
          <SurveyCampaignStats {...this.props} />
          <div className="column small-12 m-b-xxl">
            <div className="campaign-stats__header">{_('campaigns')}</div>
            <SurveyCampaignList {...this.props} />
          </div>
        </div>
      </div>
    );
  }
}

export default controlAccess()(CampaignStats);
