import _ from 'lodash';
import { defaultTheme, validConfigValue } from 'themes';

const initialState = {
  fetching: false,
  fetched: false,
  publicItemsFetched: false,
  privateItemsFetched: false,
  publicItems: {},
  privateItems: {},
  theme: defaultTheme,
  error: null,
  nbResult: 0,
};

export const surveyThemesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_SURVEY_THEME': {
      return { ...state, theme: defaultTheme };
    }
    case 'SET_SURVEY_THEME': {
      const { theme } = action;
      return { ...state, theme: theme || defaultTheme };
    }
    case 'SET_SURVEY_THEME_FONT': {
      const { font } = action;
      return { ...state, theme: { ...state.theme, font } };
    }
    case 'SET_SURVEY_THEME_NAME': {
      const { name } = action;
      return { ...state, theme: { ...state.theme, name } };
    }
    case 'SET_SURVEY_THEME_IS_TEMPLATE': {
      const { isTemplate } = action;
      return { ...state, theme: { ...state.theme, isTemplate } };
    }
    case 'SET_SURVEY_THEME_COLORS': {
      const { key, value } = action;
      if (validConfigValue('colors', key)) {
        return {
          ...state,
          theme: {
            ...state.theme,
            colors: {
              ...state.theme.colors,
              [key]: value
            }
          }
        };
      }

      return state;
    }
    case 'SET_SURVEY_THEME_BACKGROUND': {
      const { key, value } = action;
      if (validConfigValue('background', key)) {
        return {
          ...state,
          theme: {
            ...state.theme,
            background: {
              ...state.theme.background,
              [key]: value
            }
          }
        };
      }

      return state;
    }
    case 'REVERT_SURVEY_THEME_CHANGES': {
      const { theme } = state;
      if (theme.isTemplate) {
        const oldTheme = _.find(state.publicItems, ({ id }) => theme.id === id);

        return { ...state, theme: oldTheme || theme };
      }

      const oldTheme = _.find(state.privateItems, ({ id }) => theme.id === id);
      return { ...state, theme: oldTheme || theme };
    }
    case 'FETCH_PRIVATE_SURVEY_THEME_PENDING':
    case 'FETCH_PUBLIC_SURVEY_THEME_PENDING':
    case 'FETCH_SURVEY_THEMES_PENDING': {
      return { ...state, fetching: true };
    }
    case 'FETCH_PUBLIC_SURVEY_THEMES_FULFILLED': {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult,
        publicItemsFetched: true,
        publicItems: _.mapKeys(data, 'id'),
      };
    }
    case 'FETCH_PRIVATE_SURVEY_THEMES_FULFILLED': {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult,
        privateItemsFetched: true,
        privateItems: _.mapKeys(data, 'id'),
      };
    }
    case 'FETCH_SURVEY_THEME_FULFILLED': {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        theme: data,
        nbResult,
      };
    }
    case 'FETCH_SURVEY_THEME_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        fetched: false,
        error,
      };
    }
    case 'FETCH_PUBLIC_SURVEY_THEMES_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        publicItemsFetched: false,
        error,
      };
    }
    case 'FETCH_PRIVATE_SURVEY_THEMES_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        privateItemsFetched: false,
        error,
      };
    }
    case 'SAVE_SURVEY_THEME_PENDING': {
      return { ...state, fetched: false, fetching: true };
    }
    case 'APPLY_SURVEY_THEME_FULFILLED':
    case 'SAVE_SURVEY_THEME_FULFILLED': {
      const theme = { ...action.payload.data.data };

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        privateItems: { ...state.privateItems, [theme.id]: theme },
        publicItems: theme.isTemplate ? { ...state.publicItems, [theme.id]: theme } : state.publicItems,
        theme,
      };
    }
    case 'APPLY_SURVEY_THEME_REJECTED':
    case 'SAVE_SURVEY_THEME_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
      };
    }
    case 'DELETE_SURVEY_THEME_PENDING': {
      return { ...state, deleted: false, deleting: true };
    }
    case 'DELETE_SURVEY_THEME_FULFILLED': {
      const themeId = action.payload.data.deletedId;
      return {
        ...state,
        deleted: true,
        deleting: false,
        publicItems: _.omit(state.publicItems, themeId),
        privateItems: _.omit(state.privateItems, themeId),
      };
    }
    case 'DELETE_SURVEY_THEME_REJECTED': {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        deleted: false,
        deleting: false,
        error,
      };
    }
    default:
      return state;
  }
};
