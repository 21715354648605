const initialStateForMedia = {
  items: [],
  nbResult: 0,
  fetching: false,
  fetched: false,
  updating: false,
  filter: {
    searchWord: null,
    language: null,
    pageSize: 8,
    paginationPage: 1,
  }
};
export const mediaReducer = (state = initialStateForMedia, action) => {
  switch (action.type) {
    case 'INIT_MEDIA': {
      return initialStateForMedia;
    }
    case 'FETCH_MEDIAS_PENDING': {
      return {
        ...state,
        fetching: true
      };
    }

    case 'FETCH_MEDIAS_FULFILLED': {
      const { data, nbResult } = action.payload.data;
      const { filter, items } = state;
      let newItems = [];
      if (filter.paginationPage == 1) {
        newItems = data;
      } else {
        newItems = items;
        newItems.push(...data);
      }

      return {
        ...state,
        items: newItems,
        fetching: false,
        fetched: true,
        nbResult
      };
    }

    case 'FETCH_MEDIAS_REJECTED': {
      let { nbResult } = state;
      const { filter, items } = this.state;
      let newItems = items;

      if (filter.paginationPage == 1) {
        newItems = [];
        nbResult = 0;
      }
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          };
          break;
        default:
      }
      return {
        ...state,
        items: newItems,
        fetching: false,
        fetched: false,
        nbResult,
        error
      };
    }

    case 'SET_MEDIAS_SEARCH_WORD': {
      return { ...state, filter: { ...state.filter, searchWord: action.searchWord, paginationPage: 1 } };
    }

    case 'SET_MEDIAS_LANGUAGE': {
      return { ...state, filter: { ...state.filter, language: action.language, paginationPage: 1 } };
    }

    case 'SET_MEDIAS_PAGINATION_PAGE': {
      return { ...state, filter: { ...state.filter, paginationPage: action.pageNumber } };
    }

    case 'SAVE_MEDIA_PENDING': {
      return { ...state, updating: true };
    }

    case 'SAVE_MEDIA_FULFILLED': {
      const { data } = action.payload.data;
      const { items } = state;
      let exist = false;
      const newItems = items.map((item) => {
        if (item.id === data.id) {
          exist = true;
          return data;
        }
        return item;
      });
      if (!exist) {
        newItems.push(data);
      }

      return {
        ...state,
        updating: false,
        fetched: true,
        items: newItems
      };
    }

    default:
      return state;
  }
};
