
export const fetchTemplates = (resp, mode) => ({
  type: (mode == 'PUSH') ? 'PUSH_TEMPLATES' : 'FETCH_TEMPLATES',
  payload: resp,
});

export const fetchTemplate = resp => ({
  type: 'FETCH_TEMPLATE',
  payload: resp,
});

export const setCurrentTemplate = survey => ({
  type: 'SET_CURRENT_TEMPLATE',
  survey
});

export const setTemplateType = templateType => ({
  type: 'SET_TEMPLATE_TYPE',
  templateType
});

export const participate = resp => ({
  type: 'PARTICIPATE_IN_TEMPLATE',
  payload: resp
});

export const cancelParticipate = resp => ({
  type: 'CANCEL_PARTICIPATE_IN_TEMPLATE',
  payload: resp
});

export const transformToSectorialTemplate = resp => ({
  type: 'TRANSFORM_TO_SECTORIAL_TEMPLATE',
  payload: resp
});

export const setTemplatePaginationPage = pageNumber => ({
  type: 'SET_TEMPLATES_PAGINATION_PAGE',
  pageNumber,
});

export const setTemplatePageSize = pageSize => ({
  type: 'SET_TEMPLATES_PAGE_SIZE',
  pageSize,
});

export const initTemplatePagination = () => ({
  type: 'INIT_TEMPLATES_PAGINATION',
});


/*
  FILTER
*/

export const setTemplateFilterStatus = status => ({
  type: 'SET_TEMPLATE_FILTER_STATUS',
  status,
});

export const setTemplateFilterType = templateType => ({
  type: 'SET_TEMPLATE_FILTER_TYPE',
  templateType,
});

export const setTemplateFilterOwner = owner => ({
  type: 'SET_TEMPLATE_FILTER_OWNER',
  owner,
});

export const setTemplateFilterTitle = title => ({
  type: 'SET_TEMPLATE_FILTER_TITLE',
  title,
});

export const setTemplateFilterEnvironment = env => ({
  type: 'SET_TEMPLATE_FILTER_ENVIRONMENT',
  env,
});

export const initTemplateFilter = () => ({
  type: 'INIT_TEMPLATE_FILTER'
});
