export const selectRole = role => ({
  type: 'SELECT_ROLE',
  role,
});

export const setRole = role => ({
  type: 'SET_ROLE',
  role,
});

export const setSelectedUsers = selectedUsers => ({
  type: 'SET_SELECTED_USERS',
  selectedUsers,
});

export const selectSearchWord = searchWord => ({
  type: 'SELECT_SEARCH_WORD',
  searchWord,
});

export const setAllowCreateSurvey = allowCreateSurvey => ({
  type: 'SET_ALLOW_CREATE_SURVEY',
  allowCreateSurvey,
});

export const setAllowPublishSurvey = allowPublishSurvey => ({
  type: 'SET_ALLOW_PUBLISH_SURVEY',
  allowPublishSurvey,
});

export const setAllowCloseSurvey = allowCloseSurvey => ({
  type: 'SET_ALLOW_CCLOSE_SURVEY',
  allowCloseSurvey,
});

export const setAllowAccessResult = allowAccessResult => ({
  type: 'SET_ALLOW_ACCESS_RESULT',
  allowAccessResult,
});
