import { createTransform } from 'redux-persist';
import { convertToRaw } from 'draft-js';
import _ from 'lodash';
import { serializeSurvey } from '../services/surveyUtils';

const editorStateTransform = createTransform(
  (inboundState) => {
    const survey = { ...inboundState };
    const description = { ...survey.description };
    const welcomeMessage = { ...survey.welcomeMessage };

    if (description != null && !_.isEmpty(description)) {
      const serializedDescription = {};

      _.forEach(description, (editorState, key) => {
        if (!_.isEmpty(key)) {
          serializedDescription[key] = convertToRaw(editorState.getCurrentContent());
        }
      });

      survey.description = serializedDescription;
    } else {
      delete survey.description;
    }

    if (welcomeMessage != null && !_.isEmpty(welcomeMessage)) {
      const message = {};

      _.forEach(welcomeMessage, (editorState, key) => {
        if (!_.isEmpty(key)) {
          message[key] = convertToRaw(editorState.getCurrentContent());
        }
      });

      survey.welcomeMessage = message;
    } else {
      delete survey.welcomeMessage;
    }
    return { ...survey };
  },

  outboundState => serializeSurvey(outboundState, true), // true to indicate that the description of survey is already on json format

  { whitelist: ['survey'] }
);
export default editorStateTransform;
