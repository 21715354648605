import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

const HIDDEN_STYLE = { transform: 'scale(0)' };
export const STYLE = {
  transition: 'transform 0.15s cubic-bezier(0.3, 1.2, 0.2, 1) 0s',
  left: '32px',
  top: '18px',
  transform: 'scale(1)',
};

export const FloatMenuItem = ({ className, onClick, id, icon, children, disabled, message }) => (
  <>
    <div
      className={`float-menu__elem ${className || ''}`}
      onClick={!disabled ? onClick : null}
      data-tip
      data-for={id}
    >
      {
        icon && (
          <div className={`float-menu__elem__icon ${disabled ? 'disabled-link' : ''}`}>
            <span><i className={icon} /></span>
          </div>
        )
      }
      <div className={disabled ? 'disabled-link' : ''}>{children}</div>
    </div>
    {
      disabled && message && (
        <ReactTooltip id={id} multiline={true} className="react-tooltip" type="warning">
          <span className="tooltip-content">{message}</span>
        </ReactTooltip>
      )
    }
  </>
);

export default class FloatMenu extends Component {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.state = { showMenu: false };
    this.handleOpenMenu = () => this.setState(prevState => ({ showMenu: !prevState.showMenu }));
    this.handleCloseMenu = () => this.setState({ showMenu: false });
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.active != this.props.active) {
      this.handleCloseMenu();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.handleCloseMenu();
    }
  }

  render() {
    const { active, style } = this.props;

    return (
      <div className="float-menu__wrapper" style={active ? style : HIDDEN_STYLE} ref={this.wrapperRef} onClick={this.handleOpenMenu}>
        <div>
          <div className="float-menu__icon">
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" fill="#8d8d8d">
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
            </svg>
          </div>
          <div>
            {
              this.state.showMenu
              && (
                <div className="float-menu__content">
                  {this.props.children}
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}
