import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchParticipantUserStats } from 'thunks';
import { initializeParticipantUserStats } from 'actions';
import _ from 'i18n';
import __ from 'lodash';
import Piechart from '../charts/PieChart';

class ParticipantsStats extends Component {
  componentDidMount() {
    const { surveySlug, survey: { slug, latestInstance } } = this.props;
    this.props.initializeParticipantUserStats();
    if (slug && surveySlug == slug) {
      this.props.fetchParticipantUserStats((latestInstance && latestInstance.id) || 0);
    }
  }

  componentDidUpdate(prevProps) {
    const { survey: { latestInstance } } = this.props;
    const prevInstance = prevProps.survey.latestInstance;

    if (prevInstance != latestInstance) {
      this.props.fetchParticipantUserStats((latestInstance && latestInstance.id) || 0);
    }
  }

  render() {
    const { stats, fetching, fetched } = this.props;
    let data = {
      invited: 0,
      nbParticipants: 0,
      revival1: 0,
      revival2: 0,
      partialRevival1: 0,
      partialRevival2: 0,
      resultsReceived: 0,
      resultsSubscribers: 0,
      incompleteResponse: 0,
      completeResponse: 0,
      totalResponses: 0,
    };

    let dataSuggestedCampaign;

    if (!fetching && fetched) {
      data = (stats && stats.participantsStats) || data;
      dataSuggestedCampaign = (stats && stats.suggestedCampaign);
    }

    const totalSuggested = dataSuggestedCampaign && __.reduce(dataSuggestedCampaign, (sum, item) => (sum + item.total), 0);

    return (
      <div className="row">
        <div className="column small-12">
          <h3>{_('Stats')}</h3>
        </div>
        <div className="column small-12">
          <div className="row large-up-4 small-up-2">
            <div className="column column-block">
              <Piechart
                label={_('guests')}
                rate={data.invited}
                pieColor="#3863A3"
                target={55}
                max={data.nbParticipants || undefined}
                symbole=""
              />
            </div>
            <div className="column column-block">
              <Piechart
                label={_('Complete responses')}
                rate={data.completeResponse}
                pieColor="#1CAF95"
                target={55}
                max={data.totalResponses || undefined}
                symbole=""
              />
            </div>
            <div className="column column-block">
              <Piechart
                label={_('Incomplete responses')}
                rate={data.incompleteResponse}
                pieColor="#F3B04E"
                target={55}
                max={data.totalResponses || undefined}
                symbole=""
              />
            </div>
            <div className="column column-block">
              <Piechart
                label={_('results')}
                rate={data.resultsReceived}
                pieColor="#258B35"
                target={55}
                max={data.resultsSubscribers || undefined}
                symbole=""
              />
            </div>
            <div className="column column-block">
              <Piechart
                label={`${_('revival')} 1`}
                rate={data.revival1}
                pieColor="#0091E3"
                target={55}
                max={data.invited || undefined}
                symbole=""
              />
            </div>
            <div className="column column-block">
              <Piechart
                label={`${_('revival')} 2`}
                rate={data.revival2}
                pieColor="#EF5331"
                target={55}
                max={data.invited || undefined}
                symbole=""
              />
            </div>
            <div className="column column-block">
              <Piechart
                label={`${_('PartialRevival')} 1`}
                rate={data.partialRevival1}
                pieColor="#25B5D4"
                target={55}
                max={data.incompleteResponse || undefined}
                symbole=""
              />
            </div>
            <div className="column column-block">
              <Piechart
                label={`${_('PartialRevival')} 2`}
                rate={data.partialRevival2}
                pieColor="#D44141"
                target={55}
                max={data.incompleteResponse || undefined}
                symbole=""
              />
            </div>
          </div>
        </div>
        {
          dataSuggestedCampaign && totalSuggested != 0 && (
            <>
              <div className="column small-12">
                <h3>{_('Suggested campaigns')}</h3>
              </div>
              <div className="column small-12 ranking-list suggested-campaign">
                <table className="unstriped hover">
                  <thead>
                    <tr>
                      <th className="text-center"><label>{`${_('revival')} 1`}</label></th>
                      <th className="text-center"><label>{`${_('revival')} 2`}</label></th>
                      <th className="text-center"><label>{`${_('PartialRevival')} 1`}</label></th>
                      <th className="text-center"><label>{`${_('PartialRevival')} 2`}</label></th>
                      <th className="text-center"><label>{_('results')}</label></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">{dataSuggestedCampaign.revival1.total || '--'}</td>
                      <td className="text-center">{dataSuggestedCampaign.revival2.total || '--'}</td>
                      <td className="text-center">{dataSuggestedCampaign.partialRevival1.total || '--'}</td>
                      <td className="text-center">{dataSuggestedCampaign.partialRevival2.total || '--'}</td>
                      <td className="text-center">{dataSuggestedCampaign.resultsAsked.total || '--'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  stats: state.participantUserStats.stats,
  currentClient: state.auth.currentClient,
  fetching: state.participantUserStats.fetching,
  fetched: state.participantUserStats.fetched,
  error: state.participantUserStats.error,
});

const mapDispatchToProps = { fetchParticipantUserStats, initializeParticipantUserStats };

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsStats);
