import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { connectRouter } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {
  authReducer,
  currentNavPageReducer,
  languageReducer,
  subMenuDirectionReducer,
  listViewReducer,
  sourcesTokensReducer,
  usersReducer,
  surveysReducer,
  surveysFilterReducer,
  surveysSortReducer,
  surveyResponseReducer,
  surveyThemesReducer,
  rankingOrganizationsReducer,
  rankingOrganizationsFilterReducer,
  statsReducer,
  statsFilterReducer,
  organizationScoreReducer,
  rankingOrganizationsSortReducer,
  rankingClientsFilterReducer,
  rankingClientsReducer,
  rankingClientsSortReducer,
  dimensionScoreReducer,
  questionScoreReducer,
  templatesReducer,
  settingsReducer,
  notificationsReducer,
  participantOrganizationReducer,
  participantOrganizationFilterReducer,
  participantUserReducer,
  participantUserStatsReducer,
  participantOrganizationSortReducer,
  mediaReducer,
  campaignStatsReducer,
  genericEmailReducer,
  genericEmailingViewerReducer,
  CampaignViewerReducer,
  dialogReducer,
  surveyCampaignReducer,
  quizReducer,
  responseFilterReducer,
  surveyProfileQuestionsReducer,
  surveyLibraryQuestionsReducer,
} from 'reducers';
import { getHistory } from '../router/getHistory';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['params'],
};

const paramsPersistConfig = {
  key: 'params',
  storage,
  whitelist: ['subMenuDirection', 'listView', 'lng']
};

export const createReducer = () => {
  const history = getHistory();
  const reducer = combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    params: persistReducer(paramsPersistConfig, combineReducers({
      lng: languageReducer,
      currentNavPage: currentNavPageReducer,
      sourcesTokens: sourcesTokensReducer,
      subMenuDirection: subMenuDirectionReducer,
      listView: listViewReducer,
    })),
    users: usersReducer,
    loadingBar: loadingBarReducer,
    surveys: combineReducers({
      list: surveysReducer,
      filter: surveysFilterReducer,
      sort: surveysSortReducer,
    }),
    templates: templatesReducer,
    stats: combineReducers({
      list: statsReducer,
      filter: statsFilterReducer,
    }),
    campaignStats: campaignStatsReducer,
    organizationScore: organizationScoreReducer,
    rankingOrganizations: combineReducers({
      list: rankingOrganizationsReducer,
      filter: rankingOrganizationsFilterReducer,
      sort: rankingOrganizationsSortReducer,
    }),
    surveyResponse: surveyResponseReducer,
    surveyThemes: surveyThemesReducer,
    rankingClients: combineReducers({
      list: rankingClientsReducer,
      filter: rankingClientsFilterReducer,
      sort: rankingClientsSortReducer,
    }),
    participantOrganization: combineReducers({
      list: participantOrganizationReducer,
      filter: participantOrganizationFilterReducer,
      sort: participantOrganizationSortReducer,
    }),
    participantUser: participantUserReducer,
    participantUserStats: participantUserStatsReducer,
    dimensionScore: dimensionScoreReducer,
    questionScore: questionScoreReducer,
    settings: settingsReducer,
    notifications: notificationsReducer,
    medias: mediaReducer,
    genericEmail: genericEmailReducer,
    emailingViewer: combineReducers({
      genericEmailing: genericEmailingViewerReducer,
      campaign: CampaignViewerReducer,
    }),
    filters: combineReducers({
      response: responseFilterReducer,
    }),
    TTPDialog: dialogReducer,
    surveyCampaign: surveyCampaignReducer,
    quiz: quizReducer,
    surveyQuestions: combineReducers({
      profile: surveyProfileQuestionsReducer,
      library: surveyLibraryQuestionsReducer,
    }),
  });

  return persistReducer(persistConfig, reducer);
};
