import React, { Component } from 'react';
import { getProfilingQuestions } from 'surveyUtils';
import { connect } from 'react-redux';
import _ from 'i18n';
import { resetStatsProfilingFilter } from 'actions';
import shortId from 'shortid';
import MultipleChoiceFilter from './profiling-filters/MultipleChoiceFilter';
// import NumberFilter from './profiling-filters/NumberFilter';
import LanguageFilter from './profiling-filters/LanguageFilter';
import BooleanFilter from './profiling-filters/BooleanFilter';
import TextFilter from './profiling-filters/TextFilter';
import StepsFilter from './profiling-filters/StepsFilter';

class ProfilingFilter extends Component {
  constructor(props) {
    super(props);

    this.resetFilters = this.resetFilters.bind(this);
  }

  renderFilters(profilingQuestions) {
    const { language } = this.props;

    return profilingQuestions.map(question => {
      const props = {
        question,
        language,
      };
      switch (question.type) {
        case 'SINGLE_CHOICE':
          return <MultipleChoiceFilter {...props} key={shortId.generate()} />;
        // case 'NUMBER': return <NumberFilter {...props} key={shortId.generate()} />;
        case 'NUMBER':
          return (
            <StepsFilter
              resource="number"
              {...props}
              key={shortId.generate()}
            />
          );
        case 'TEXT':
          return <TextFilter {...props} key={shortId.generate()} />;
        case 'TRUE_FALSE':
        case 'YES_NO':
          return <BooleanFilter {...props} key={shortId.generate()} />;
        case 'RATING':
          return (
            <StepsFilter
              resource="rating"
              {...props}
              key={shortId.generate()}
            />
          );
        case 'OPINION_SCALE':
          return (
            <StepsFilter
              resource="number"
              {...props}
              key={shortId.generate()}
            />
          );
        default:
          return null;
      }
    });
  }

  resetFilters() {
    this.props.resetStatsProfilingFilter();
  }

  render() {
    const profilingQuestions = getProfilingQuestions(this.props.survey);

    if (profilingQuestions.length == 0) {
      return null;
    }

    const withLanguageQuestion = profilingQuestions.find(
      ({ profilingType }) => profilingType === 'LANGUAGE',
    );

    return (
      <div className="profiling">
        <div className="clearfix reset" onClick={this.resetFilters}>
          <span className="reset-icon float-right">
            <i className="icomoon-icon icon-loop" />
          </span>
        </div>
        <div className="profiling-item columns title">
          {_('Profiling Filters')}
        </div>
        <div className="column profiling-wrapper">
          <div className="row large-up-5 medium-up-3 small-up-2">
            {this.renderFilters(profilingQuestions)}
            {!withLanguageQuestion && <LanguageFilter />}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { resetStatsProfilingFilter })(ProfilingFilter);
