import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCurrentNavPage } from 'actions';
import _ from 'i18n';
import ReactTooltip from 'react-tooltip';
import { getTextByLanguage } from 'surveyUtils';
import { STATS_SINGLE_COLORS } from 'SurveyConfig';
import ScaleChart from '../charts/ScaleChart';
import NoData from '../charts/NoDataChart';

export const ScaleStats = (props) => {
  const { lng, data, nbResponses } = props;
  const { text, id } = data.question;
  const { nbResponse } = data.stats;
  const idTooltip = `responses-qst-${id}`;

  return (
    <div className="column column-block">
      <div className="summary__qst">
        <div className="column">
          <div className="title">{getTextByLanguage(text, lng)}</div>
          <div className="responses">
            <span data-tip data-for={idTooltip}>
              {nbResponse}
              {'/'}
              {nbResponses}
            </span>
            <ReactTooltip id={idTooltip} multiline={true} className="react-tooltip">
              <span className="tooltip-content">
                {`${nbResponse} ${_('out of')} ${nbResponses} ${_('people answered this question')}`}
              </span>
            </ReactTooltip>
          </div>
          {
            nbResponse != 0
              ? <ScaleChart data={data.stats.data} color={STATS_SINGLE_COLORS[id % STATS_SINGLE_COLORS.length]} />
              : <NoData />
          }
        </div>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCurrentNavPage }, dispatch);
}

const mapStateToProps = state => ({
  lng: state.params.lng,
});

export default connect(mapStateToProps, mapDispatchToProps)(ScaleStats);
