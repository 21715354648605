import React from 'react';
import _ from 'i18n';
import moment from 'moment';
import { ReactComponent as NotifsIcon } from 'icons/notifs.svg';
import MenuItem from '../MenuItem';

export default function Notifs({ notifications, lng, onClick }) {
  const renderNotifications = () => {
    const subject = `subject${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
    if (notifications.items == '') {
      return (<li className="p-b-m">{_('Nothing to show')}</li>);
    }

    return (notifications.items.map((notification) => {
      const createdAtDate = moment(notification.createdAt).locale(lng);
      const text = moment()
        .locale(lng)
        .diff(createdAtDate, 'month', true) > 1 ? createdAtDate.format('DD MMM YYYY') : createdAtDate.fromNow();

      return (
        <li key={notification.id} className={notification.status == 'UNREAD' ? 'notRead' : ''}>
          <a href={notification.url || null} onClick={() => onClick(notification.id)}>
            <span>{notification[subject]}</span>
            <div className="infos">{text}</div>
          </a>
        </li>
      );
    }));
  };

  const unreadNotifs = notifications.items.filter(notif => notif.status == 'UNREAD');

  return (
    <MenuItem
      renderIcon={() => <NotifsIcon />}
      className="notif"
      count={unreadNotifs.length}
    >
      <div className="social-links__wrapper">
        <div className="social-links__header">{_('Notifications of updates')}</div>
        <div className="social-links__body">
          <ul className="sub-menu__dropdown">
            {renderNotifications()}
          </ul>
        </div>
      </div>
    </MenuItem>
  );
}
