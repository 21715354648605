import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setRankingOrganizationsSortConfig } from 'actions';
import __ from 'lodash';
import _ from 'i18n';
import NotFoundPage from 'NotFoundPage';
import RankingOrganizationsListFetching from 'fetching/stats/RankingOrganizationsListFetching';

class RankingList extends Component {
  onSortChange(columnKey) {
    const { rankingSort } = this.props;

    let sortDir = 'asc';
    if (rankingSort.sortConfig.columnKey === columnKey) {
      sortDir = this.reverseSortDirection(rankingSort.sortConfig.sortDirection);
    }
    this.props.setRankingOrganizationsSortConfig(columnKey, sortDir);
  }

  reverseSortDirection(sortDir) {
    return sortDir === 'desc' ? 'asc' : 'desc';
  }

  renderSortDirection(columnKey) {
    const { rankingSort } = this.props;
    if (columnKey === rankingSort.sortConfig.columnKey) {
      const isDesc = rankingSort.sortConfig.sortDirection === 'desc';
      const className = `icomoon-icon ${isDesc ? 'icon-sort-numberic-desc' : 'icon-sort-numeric-asc'}`;

      return (
        <span className="ranking-list__sort__icon" onClick={() => this.onSortChange(columnKey)}><i className={className} /></span>
      );
    }
    return (
      <span className="ranking-list__sort__icon" onClick={() => this.onSortChange(columnKey)}><i className="icomoon-icon icon-tab-sort" /></span>
    );
  }

  renderRankingList() {
    const { rankingSort } = this.props;
    const { items, nbResult } = this.props.ranking;
    const { paginationPage, pageSize } = this.props.filter;

    return __.map(items, (item, index) => {
      let rank = 0;
      if (rankingSort.sortConfig.sortDirection === 'desc') {
        rank = (paginationPage - 1) * pageSize + index + 1;
      } else {
        rank = nbResult - (paginationPage - 1) * pageSize - index;
      }
      return (
        <tr key={item.id}>
          <td className="text-center cell-rank">{rank}</td>
          <td className="text-left">{item.organization ? item.organization.name : ''}</td>
          <td className="text-center">
            {item.nps ? `${item.nps} %` : '--'}
          </td>
          <td className="text-center">
            {item.organizationNps ? `${item.organizationNps} %` : '--'}
          </td>
          <td className="text-center">
            {item.score ? `${item.score} %` : '--'}
          </td>
        </tr>
      );
    });
  }

  render() {
    const { ranking, fetching, surveyFetching } = this.props;

    if (fetching || surveyFetching) {
      return <RankingOrganizationsListFetching />;
    }


    if ((ranking.error != null && ranking.error.code == 404) || ranking.items.length == 0) {
      return <NotFoundPage mainClass="small-10" />;
    }

    return (
      <table className="unstriped hover">
        <thead>
          <tr>
            <th width="150" className="text-center"><label>{_('Rank')}</label></th>
            <th className="text-center"><label>{_('Organization')}</label></th>
            <th width="200" className="text-center">
              <label>{_('Nps')}</label>
              {this.renderSortDirection('nps')}
            </th>
            <th width="200" className="text-center">
              <label>
                {_('Global Nps')}
              </label>
              {this.renderSortDirection('organizationNps')}
            </th>
            <th width="200" className="text-center">
              <label>
                {_('Coast')}
              </label>
              {this.renderSortDirection('score')}
            </th>
          </tr>
        </thead>
        <tbody>
          {this.renderRankingList()}
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = state => ({
  ranking: state.rankingOrganizations.list,
  filter: state.rankingOrganizations.filter.filter,
  fetching: state.rankingOrganizations.list.fetching,
  surveyFetching: state.surveys.list.fetching,
  rankingSort: state.rankingOrganizations.sort,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setRankingOrganizationsSortConfig }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RankingList);
