import React, { Component } from 'react';
import shortId from 'shortid';
import ReactTooltip from 'react-tooltip';

export default class Boolean extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.value,
    };
  }

  onChange(checked) {
    const { attribute, disabled } = this.props;

    if (disabled) return;

    this.setState({
      checked,
    });

    if (checked) {
      this.props.addOption(attribute, 1);
    } else {
      this.props.addOption(attribute, 0);
    }
  }

  render() {
    const id = shortId.generate();
    const { label, disabled, msgDisabled, msgSR } = this.props;
    return (
      <div className="settings__block">
        <div className="settings__container">
          <div className="settings__container__label">{label}</div>
          <div className="settings__container__item">
            <div className={`switch tiny settings__switch ${disabled ? 'disabled' : ''}`} data-tip data-for={`msg-${id}`}>
              <input
                className="switch-input"
                id={id}
                disabled={disabled}
                type="checkbox"
                name={id}
                checked={this.state.checked}
                onChange={e => this.onChange(e.target.checked)}
              />
              <label className="switch-paddle" htmlFor={id}>
                <span className="show-for-sr">{msgSR}</span>
              </label>
            </div>
            {
              disabled && msgDisabled ? (
                <ReactTooltip id={`msg-${id}`} multiline={true} className="react-tooltip" type="info">
                  <span className="tooltip-content">{msgDisabled}</span>
                </ReactTooltip>
              ) : null
            }
          </div>
        </div>
      </div>
    );
  }
}
