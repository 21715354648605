import React, { Component } from 'react';
import {
  setCurrentNavPage,
  changeLanguage,
  initSurvey,
  initSurveyTheme,
  initSurveyResponseOrganizationInstance,
} from 'actions';
import { fetchSurvey, fetchOrganizationInstance } from 'thunks';
import { connect } from 'react-redux';
import _ from 'i18n';
import { getTextByLanguage } from 'surveyUtils';
import Preview from 'Preview';
import LanguageSetter from 'preview-items/LanguageSetter';
import SurveyTitlePreviewFetching from 'fetching/SurveyTitlePreviewFetching';
import NotFoundPage from 'NotFoundPage';
import PreviewMessage from 'preview-items/PreviewMessage';
import { setPageView } from 'googleAnalytics';
import moment from 'moment';
import SurveyStyle from './preview-items/SurveyStyle';
import PreviewWrapper from './preview-items/PreviewWrapper';

class SurveyResponse extends Component {
  componentDidMount() {
    const { setCurrentNavPage } = this.props;
    const {
      match: {
        params: { slug, organizationInstanceId },
      },
      lng,
    } = this.props;
    const params = new URLSearchParams(location.search);
    this.client = params.get('client');
    this.organization = params.get('organization');

    setCurrentNavPage('RESPONSE');
    this.props.initSurveyResponseOrganizationInstance();

    if (lng) {
      this.props.changeLanguage(lng);
    }

    if (slug) {
      this.props.fetchSurvey(slug).then(resp => {
        const survey = resp.value.data.data;
        if (
          (!organizationInstanceId || isNaN(organizationInstanceId)) &&
          this.organization &&
          !isNaN(this.organization)
        ) {
          this.props.fetchOrganizationInstance({
            organizationId: this.organization,
            instanceId: survey?.latestInstance?.id ?? 0,
          });
        }
      });
      setPageView('survey', slug);
    }

    if (organizationInstanceId && !isNaN(organizationInstanceId)) {
      this.props.fetchOrganizationInstance({ organizationInstanceId });
    }
  }

  componentDidUpdate(prevProps) {
    const { lng } = this.props;
    if (prevProps.lng !== lng && lng) {
      this.props.changeLanguage(lng);
    }
  }

  componentWillUnmount() {
    this.props.initSurvey();
    this.props.initSurveyTheme();
  }

  changeDisplayLanguage = lng => {
    if (lng) {
      this.props.changeLanguage(lng);
    }
  };

  render() {
    const {
      surveys: { survey, fetched, fetching, language },
      status,
      auth,
      organizationInstanceFetching,
      organizationInstance,
      lng,
    } = this.props;

    if (fetching || auth.fetching || organizationInstanceFetching) {
      return <SurveyTitlePreviewFetching />;
    }

    if (fetched && auth.fetched) {
      const {
        response,
        allowEditResponse,
        type,
        title,
        startedAt,
        endedAt,
      } = survey;

      if (response?.status == 'COMPLETED' && !allowEditResponse) {
        return (
          <PreviewWrapper survey={survey}>
            <PreviewMessage title={_('user_has_already_responded_title')}>
              {_('user_has_already_responded_message')}
            </PreviewMessage>
          </PreviewWrapper>
        );
      }

      /** Check if survey is started */
      if (type === 'PORTAL') {
        const now = moment();

        if (now.isBefore(moment(startedAt))) {
          return (
            <PreviewWrapper survey={survey}>
              <PreviewMessage title={getTextByLanguage(title, lng)}>
                {_('survey_not_started_message')}
              </PreviewMessage>
            </PreviewWrapper>
          );
        } else if (now.isAfter(moment(endedAt))) {
          return (
            <PreviewWrapper survey={survey}>
              <PreviewMessage title={getTextByLanguage(title, lng)}>
                {_('survey_closed_message')}
              </PreviewMessage>
            </PreviewWrapper>
          );
        }
      } else if (organizationInstance?.status != 'STARTED') {
        let msg = '';
        const status = organizationInstance?.status ?? 'PENDING';
        if (status == 'CLOSED' || status == 'ENDED') {
          msg = 'survey_closed_message';
        } else if (status == 'PENDING') {
          msg = 'survey_not_started_message';
        }

        return (
          <PreviewWrapper survey={survey}>
            <PreviewMessage title={getTextByLanguage(title, lng)}>
              {_(msg)}
            </PreviewMessage>
          </PreviewWrapper>
        );
      }
      /** End check if survey started */

      if (
        survey.translationScore &&
        parseInt(survey.translationScore[language]) !== 100
      ) {
        return (
          <PreviewWrapper survey={survey}>
            {/* <div className="pre-preview srv-background-color--fill"> */}
            <div className="pre-preview">
              <LanguageSetter
                action={this.changeDisplayLanguage}
                survey={survey}
              />
            </div>
          </PreviewWrapper>
        );
      }

      return (
        <div className="preview preview--withoutScroll srv-background-color--fill survey-preview">
          <SurveyStyle theme={survey?.meta?.theme} />
          <Preview
            survey={survey}
            status={status}
            scrollBody
            fakeResponse={false}
            organization={organizationInstance?.organization?.id}
            client={this.client}
          />
        </div>
      );
    }

    return <NotFoundPage />;
  }
}

const mapStateToProps = state => ({
  surveys: state.surveys.list,
  status: state.surveyResponse.status,
  organizationInstanceFetching:
    state.surveyResponse.organizationInstanceFetching,
  organizationInstanceFetched: state.surveyResponse.organizationInstanceFetched,
  organizationInstance: state.surveyResponse.organizationInstance,
  lng: state.params.lng,
  auth: state.auth,
  theme: state.surveyThemes.theme,
});

const mapDispatchToProps = {
  setCurrentNavPage,
  fetchSurvey,
  changeLanguage,
  initSurvey,
  initSurveyTheme,
  fetchOrganizationInstance,
  initSurveyResponseOrganizationInstance,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyResponse);
