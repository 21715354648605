import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createInstance, keepAlive } from 'thunks';
import _ from 'i18n';
import __ from 'lodash';
import { TTP_MERCURE_URL, TTP_MERCURE_API_URL, MERCURE_SUBSCRIBER_JWT_SECRET } from 'Config';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { FullScreenButton } from 'common/FullScreen';
import TTButton from '../common/TTButton';
import QuizPinPageFetching from '../fetching/quiz/QuizPinPageFetching';

const KEEP_ALIVE_INTERVAL = 15 * 1000;

class QuizPinPage extends Component {
  state = { participants: [] };

  componentDidMount() {
    this.props.createInstance(this.props.quizId);
  }


  componentDidUpdate(prevProps) {
    const { started } = this.props.quiz;

    if (prevProps.quiz.started != started && started) {
      this.fetchConnectedUsers();
      this.keepAlive();
    }
  }

  componentWillUnmount() {
    if (this.eventSource) {
      this.eventSource.close();
    }
    clearInterval(this.interval);
  }

  fetchConnectedUsers = () => {
    const { instance } = this.props.quiz;
    const url = new URL(TTP_MERCURE_URL);
    url.searchParams.append('topic', `${TTP_MERCURE_API_URL}/survey/instance/${instance.pin}/participants`);

    this.eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${MERCURE_SUBSCRIBER_JWT_SECRET}`,
      }
    });

    this.eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const { firstName, lastName } = data;
      const fullName = `${__.trim(firstName)}${__.trim(firstName).length != 0 ? ' ' : ''}${__.trim(lastName)}`;
      const participant = { ...data, fullName };

      this.setState(prevState => ({ participants: __.uniqBy([participant, ...prevState.participants], 'id') }));
    };
  }

  keepAlive() {
    const { instance } = this.props.quiz;

    clearInterval(this.interval);
    this.interval = setInterval(() => this.props.keepAlive(instance.pin), KEEP_ALIVE_INTERVAL);
  }

  render() {
    const { quiz: { instance, started, starting }, fullScreenEnabled } = this.props;
    const { participants } = this.state;

    if (starting || !started) {
      return <QuizPinPageFetching />;
    }

    return (
      <div className="quiz-pin flex-1">
        <div className="game-pin row flex-dir-column">
          <span onClick={this.props.handleFullScreen} className="fs-previw-config fs-wrapper"><FullScreenButton enabled={fullScreenEnabled} /></span>
          <h1 className="m-r-xl">{_('Join with the Event Mobile app')}</h1>
          <h4>
            {_('with Game PIN')}
            :
          </h4>
          <div>
            {
              this.props.showPin
                ? <h1 className="pin">{instance.pin}</h1>
                : (
                  <a href={`/quiz/pin/${instance.pin}`} target="_blank" rel="noopener noreferrer">
                    <TTButton
                      text={_('Show PIN')}
                      className="p-x-xl m-y-s"
                      fontSize="20px"
                      color="blue"
                      upperCase={true}
                      disabled={!instance}
                    />
                  </a>
                )
            }
          </div>
        </div>
        <div className="quiz-pin__actions row">
          <div className="total-players">
            <span className="total">{participants.length}</span>
            <span className="text-label">{_('Players')}</span>
          </div>
          <div className="action">
            <TTButton
              text={_('Start')}
              className="p-x-xxl"
              onClick={this.props.startSurvey}
              fontSize="20px"
              color="blue"
              upperCase={true}
              disabled={participants.length < 1}
            />
          </div>
        </div>
        <div className="quiz-pin__players small-up-4 row">
          {
            participants.map(({ fullName, id }) => (
              <div key={id} id={`participant-${id}`} className="column player">
                <div className="container"><div className="name">{fullName}</div></div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  quiz: state.quiz,
});

export default connect(mapStateToProps, { createInstance, keepAlive })(QuizPinPage);
