const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  nbResult: 0,
};

const surveyQuestionReducer = resource => (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_CLIENT':
    case `INITIALIZE_${resource}`: {
      return initialState;
    }
    case `FETCH_${resource}_PENDING`: {
      return { ...state, fetching: true };
    }
    case `FETCH_${resource}_FULFILLED`: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: action.payload.data.data,
      };
    }
    case `FETCH_${resource}_REJECTED`: {
      let error = action.payload;
      switch (error.response?.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
        items: []
      };
    }
    default:
      return state;
  }
};


export const surveyProfileQuestionsReducer = surveyQuestionReducer('PROFILE_SURVEY_QUESTION');
export const surveyLibraryQuestionsReducer = surveyQuestionReducer('LIBRARY_SURVEY_QUESTION');