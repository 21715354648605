import React, { Component } from 'react';
import ReactModal from 'react-modal';
import _ from 'i18n';

const style = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'hsla(0,0%,4%,.45)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  }
};

class Modal extends Component {
  render() {
    const { labels, classNames } = this.props;
    const action = labels && labels.action ? labels.action : _('ok');
    const inaction = labels && labels.inaction ? labels.inaction : _('close');
    const classNameAction = classNames && classNames.action ? classNames.action : 'btn-popup--main';

    return (
      <ReactModal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        style={style}
        contentLabel="Participate in template"
        shouldCloseOnOverlayClick={true}
      >
        <div className="rmodal-wrapper">
          <div className="rmodal-title">
            <span>{this.props.title}</span>
          </div>
          <div className="rmodal-content">
            {this.props.children}
          </div>
          <div className="rmodal-action">
            <button className="button btn-popup btn-popup--default" type="button" onClick={this.props.onCloseModal}>{inaction}</button>
            <button type="button" className={`button btn-popup ${classNameAction}`} onClick={this.props.action}>{action}</button>
          </div>
          <button className="rmodal-close close-button" type="button" onClick={this.props.onCloseModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </ReactModal>
    );
  }
}

export default Modal;
