export const initOrganizationInstance = () => ({
  type: 'INIT_ORGANIZATION_INSTANCE',
});

export const saveOrganizationInstance = resp => ({
  type: 'SAVE_ORGANIZATION_INSTANCE',
  payload: resp,
});

export const fetchOrganizationInstance = resp => ({
  type: 'FETCH_ORGANIZATION_INSTANCE',
  payload: resp,
});
