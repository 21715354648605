import React, { Component } from 'react';
import { setCurrentQuestion, initSurveyResponse } from 'actions';
import { closeInstanceBeacon } from 'thunks';
import { connect } from 'react-redux';
import _ from 'i18n';
import __ from 'lodash';
import { sortLanguages } from 'utils';
import PreviewHeader from 'preview-items/PreviewHeader';
import { FullScreenButton } from 'common/FullScreen';
import QuizPinPage from './QuizPinPage';
import TTButton from '../common/TTButton';
import DimensionPreview from './DimensionPreview';
import QuestionPreview from './QuestionPreview';
import QuestionRanking from './QuestionRanking';
import QuizResult from './QuizResult';
import PreviewConfig from './PreviewConfig';

const views = {
  dimension: 'DIMENSION',
  question: 'question',
  ranking: 'ranking',
  result: 'result'
};

class Preview extends Component {
  state = {
    showPin: true,
    pending: false,
    view: views.dimension,
    showQuizTitle: true,
    started: false,
    dimension: Array.isArray(this.props.quiz.dimensions) ? this.props.quiz.dimensions[0] : null,
    question: Array.isArray(this.props.quiz.dimensions) ? this.props.quiz.dimensions[0].questions[0] : null,
    currentDimension: 0,
    currentQuestion: 0,
  };

  componentWillUnmount() {
    if (!this.state.showQuizTitle) {
      window.removeEventListener('beforeunload', this.preventRefresh);
      window.removeEventListener('unload', this.closeInstance);
    }
  }

  hidePreviewConfig = () => {
    this.setState({ showQuizTitle: false });
    window.addEventListener('beforeunload', this.preventRefresh);
    window.addEventListener('unload', this.closeInstance);
  }

  preventRefresh = (event) => {
    const confirmationMessage = 'Are you sure you want to leave?';
    event.returnValue = confirmationMessage;
    return confirmationMessage;
  }

  closeInstance = () => {
    const { instance } = this.props;
    if (instance) {
      this.props.closeInstanceBeacon(instance.id);
    }
  }

  setIsPending = pending => this.setState({ pending });

  startSurvey = () => {
    this.setState({ started: true });
  }

  setShowPin = showPin => this.setState({ showPin });

  next = () => {
    const { dimension, currentQuestion, currentDimension, view, pending } = this.state;

    if (pending || view == views.result) return;

    if (view == views.dimension) {
      this.setState({
        view: views.question,
        currentQuestion: 0,
        question: dimension.questions[0]
      });
    } else {
      const hasNextQuestion = currentQuestion < (dimension.questions.length - 1);
      const hasNextDimension = currentDimension < this.props.quiz.dimensions.length - 1;

      if (hasNextQuestion) {
        if (view == views.ranking) {
          this.setState({
            view: views.question,
            currentQuestion: currentQuestion + 1,
            question: dimension.questions[currentQuestion + 1]
          });
        } else if (view == views.question) {
          this.setState({ view: views.ranking });
        }
      } else if (hasNextDimension) {
        if (view == views.ranking) {
          this.setState({
            view: views.dimension,
            currentDimension: currentDimension + 1,
            dimension: this.props.quiz.dimensions[currentDimension + 1]
          });
        } else if (view == views.question) {
          this.setState({ view: views.ranking });
        }
      } else {
        this.setState({ view: views.result });
      }
    }
  }

  renderContent() {
    const { dimension, question, view } = this.state;
    let { languages } = this.props;
    const isBilingual = languages.length > 1;
    languages = sortLanguages(languages);

    switch (view) {
      case views.dimension: return <DimensionPreview dimension={dimension} languages={languages} isBilingual={isBilingual} />;
      case views.question: {
        const questionIndex = __.findIndex(dimension.questions, ({ id }) => id == question.id);
        const nbQuestions = dimension.questions.length;
        return (
          <QuestionPreview
            question={question}
            languages={languages}
            questionOrder={questionIndex + 1}
            nbQuestions={nbQuestions}
            setIsPending={this.setIsPending}
            isBilingual={isBilingual}
          />
        );
      }
      case views.ranking: return <QuestionRanking question={question} isBilingual={isBilingual} />;
      case views.result: return <QuizResult />;
      default: return null;
    }
  }

  renderSurvey() {
    const { quiz, lng, instance, languages, fullScreenEnabled } = this.props;
    const { showQuizTitle, started, pending, view, showPin } = this.state;
    const isBilingual = languages.length > 1;

    if (showQuizTitle) {
      return (
        <PreviewConfig
          quiz={quiz}
          startSurvey={this.hidePreviewConfig}
          setShowPin={this.setShowPin}
          showPin={showPin}
          handleFullScreen={this.props.handleFullScreen}
          fullScreenEnabled={fullScreenEnabled}
        />
      );
    }

    if (!started) {
      return (
        <QuizPinPage
          startSurvey={this.startSurvey}
          quizId={quiz.id}
          showPin={showPin}
          handleFullScreen={this.props.handleFullScreen}
          fullScreenEnabled={fullScreenEnabled}
        />
      );
    }

    return (
      <div className="quiz-wrapper small-12">
        <div className="preview__ctrlPanel" id="preview-header">
          <div className="box-animation">
            <PreviewHeader title={quiz.title} lng={lng}>
              <span onClick={this.props.handleFullScreen} className="fs-wrapper"><FullScreenButton enabled={fullScreenEnabled} /></span>
            </PreviewHeader>
          </div>
        </div>
        {
          view != views.result ? (
            <div>
              <div className={`quiz-actions ${isBilingual ? '' : 'row'}`}>
                <div className="game-pin">
                  <span>{_('Game PIN')}</span>
                  {
                    showPin
                      ? <span className="pin">{instance ? instance.pin : '??????'}</span>
                      : (
                        <a href={`/quiz/pin/${instance.pin}`} target="_blank" rel="noopener noreferrer">
                          <TTButton
                            text={_('Show PIN')}
                            className="p-x-xl"
                            fontSize="20px"
                            color="blue"
                            upperCase={true}
                            disabled={!instance}
                          />
                        </a>
                      )
                  }
                </div>
                <TTButton
                  text={_('Next')}
                  className="p-x-xxl"
                  onClick={this.next}
                  fontSize="20px"
                  color="blue"
                  upperCase={true}
                  disabled={pending}
                />
              </div>
            </div>
          ) : null
        }
        <div className="quiz-preview">
          {this.renderContent()}
        </div>
      </div>
    );
  }


  render() {
    return (
      <div className="preview__wrapper srv-background-color--fill row expanded flex-1">
        {this.renderSurvey()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  language: state.quiz.language,
  languages: state.quiz.languages,
  lng: state.params.lng,
  loggedAs: state.auth.loggedAs,
  instance: state.quiz.instance,
});

const mapDispatchToProps = { setCurrentQuestion, initSurveyResponse, closeInstanceBeacon };

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
