import React from 'react';
import { NavLink } from 'react-router-dom';

import _ from 'i18n';

const Template = () => (
  <li>
    <NavLink to={{ pathname: '/library', search: '?tab=mylibrary' }} activeClassName="active" className="menu__link">
      <span className="flex-column-center"><i className="icomoon icon-tt-folder" /></span>
      <span className="title">{_('Library')}</span>
    </NavLink>
  </li>
);

export default Template;
