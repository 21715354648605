import en from './en';
import fr from './fr';
import nl from './nl';

export const messages = {
  en,
  fr,
  nl,
};

const supportedLngs = ['fr', 'en', 'nl'];

export default function (id) {
  let lng = localStorage.getItem('lng');
  lng = lng == null || supportedLngs.indexOf(lng) == -1 ? 'fr' : lng;
  return messages[lng][id] && messages[lng][id].length !== 0 ? messages[lng][id] : id;
}
