const { protocol } = location;

export const APP_ENV = process.env.REACT_APP_APP_ENV;
export const LARAVEL_SESSION_COOKIE = process.env.REACT_APP_LARAVEL_SESSION_COOKIE;

export const TTP_AMAZON_S3 = `${protocol}//${process.env.REACT_APP_TTP_AMAZON_S3}` ;
export const TTP_AMAZON_S3_PROD = 'https://s3.tamtam.pro/prod' ;

export const TTP_MERCURE_API_URL = process.env.REACT_APP_TTP_MERCURE_API_URL;
export const TTP_MERCURE_URL = `${protocol}//${process.env.REACT_APP_TTP_MERCURE_URL}` ;

export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

export const TTP_API_URL = `${protocol}//${process.env.REACT_APP_TTP_API_URL}` ;
export const TTP_HOME_URL = `${protocol}//${process.env.REACT_APP_TTP_HOME_URL}` ;

export const TTP_BLOG_URL = `${protocol}//${process.env.REACT_APP_TTP_BLOG_URL}` ;
export const TTP_FLUX_URL = `${protocol}//${process.env.REACT_APP_TTP_FLUX_URL}` ;
export const TTP_EMAILING_URL = `${protocol}//${process.env.REACT_APP_TTP_EMAILING_URL}` ;
export const TTP_EVENT_URL = `${protocol}//${process.env.REACT_APP_TTP_EVENT_URL}` ;
export const TTP_TALK_URL = `${protocol}//${process.env.REACT_APP_TTP_TALK_URL}` ;
export const TTP_SURVEY_URL = `${protocol}//${process.env.REACT_APP_TTP_SURVEY_URL}` ;
export const TTP_MEDIA_URL = `${protocol}//${process.env.REACT_APP_TTP_MEDIA_URL}` ;
export const TTP_PAYMENT_URL = `${protocol}//${process.env.REACT_APP_TTP_PAYMENT_URL}` ;
export const TTP_FORUM_URL = `${protocol}//${process.env.REACT_APP_TTP_FORUM_URL}` ;
export const TTP_WEBTOOL_URL = `${protocol}//${process.env.REACT_APP_TTP_WEBTOOL_URL}` ;

export const TTP_BACKGROUNDS = `${TTP_AMAZON_S3}/assets/backgrounds`;

export const CLIENT_CREDENTIAL = {
  grant_type: 'client_credentials',
  client_id: '10012',
  client_secret: 'EmailingSecretS#K$',
  scope: 'ttp',
};

export const ANONYMOUS_USER_CREDENTIAL = {
  grant_type: 'password',
  grant_access_type: 'anonymous',
  client_id: '10055',
  client_secret: 'SURVEYZtHFa44rAY7uP_9^^LUs9*',
  scope: 'anonymous',
};

export const MERCURE_PUBLISHER_JWT_SECRET = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InB1Ymxpc2giOlsidGFtdGFtLnBybyJdfX0.Qi2h2GXGwYzDz6RoEWZly7NsdA1OFsE9MshJcXYK-wk';
export const MERCURE_SUBSCRIBER_JWT_SECRET = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InN1YnNjcmliZSI6WyJ0YW10YW0ucHJvIl19fQ.UCdmGv6vr7UmLudUQHNwZKyySPiF2ytGN9-9eDi4E8c';
