import React, { useState } from 'react';
import ReactSelect from 'react-select';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const Select = (props) => {
  const [value, setValue] = useState(props.value);

  const onInputChange = (inputValue) => {
    setValue(inputValue ? inputValue.value : null);
    props.addOption(props.attribute, inputValue ? inputValue.value : null);
  };

  const { options, label } = props;

  return (
    <div className="settings__block">
      <div className="settings__container">
        <div className="settings__container__label">{label}</div>
        <div className="settings__container__item">
          <ReactSelect
            isSearchable={false}
            options={options}
            value={options.filter(o => o.value == value)}
            onChange={onInputChange}
            className="ttp-select filled-box uppercase min-width-200"
            classNamePrefix="ttp-select"
          />
        </div>
      </div>
    </div>
  );
};

export default Select;
