import axios from 'axios';
import { TTP_API_URL } from 'Config';
import { throwCatchedError } from 'utils';

export const getMediaImages = ({
  clientId,
  pageSize,
  paginationPage,
  searchWord,
  docType
}) => {
  const fields = ['*'];

  const filter = [{
    property: 'docType',
    operator: 'eq',
    value: docType
  }];

  if (clientId) {
    filter.push(
      {
        property: 'organization',
        operator: 'eq',
        value: clientId
      }
    );
  }

  if (searchWord) {
    filter.push({
      property: 'titleFr',
      operator: 'like',
      value: searchWord
    });
  }

  const sort = [
    {
      property: 'createdAt',
      dir: 'desc'
    }
  ];

  const requestUrl = 'https://uat2.api.tamtam.pro/media/media';

  return axios.get(requestUrl, {
    params: {
      // access_token: token,
      access_token: '0004b9fed5b91f3c16f35e322a12ca0b515bdb8b',
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
      fields: fields.join(','),
      limit: pageSize,
      start: (paginationPage - 1) * pageSize
    }
  }).catch((thrown) => {
    throwCatchedError(thrown);
  });
};

export const persistMediaWelcomeScreen = (token, data) => {
  const { surveySlug, attachment, language } = data;
  const requestUrl = `${TTP_API_URL}/survey/survey/${surveySlug}/upload/welcome-screen`;
  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('language', language);
  if (attachment instanceof File) {
    formData.append('welcome_screen', attachment, attachment.name);
  }

  return axios.post(requestUrl, formData);
};

export const persistMediaBanner = (token, data) => {
  const { surveySlug, attachment, language } = data;
  const requestUrl = `${TTP_API_URL}/survey/survey/${surveySlug}/upload/banner`;
  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('language', language);
  if (attachment instanceof File) {
    formData.append('banner', attachment, attachment.name);
  }

  return axios.post(requestUrl, formData);
};

export const persistMediaThankScreen = (token, data) => {
  const { surveySlug, attachment, language } = data;
  const requestUrl = `${TTP_API_URL}/survey/survey/${surveySlug}/upload/thank-screen`;
  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('language', language);
  if (attachment instanceof File) {
    formData.append('thank_screen', attachment, attachment.name);
  }

  return axios.post(requestUrl, formData);
};

export const removeMediaWelcomeScreen = (token, surveySlug) => {
  const requestUrl = `${TTP_API_URL}/survey/survey/${surveySlug}/welcome-screen`;

  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('_method', 'delete');

  return axios.post(requestUrl, formData);
};

export const removeMediaThankScreen = (token, surveySlug) => {
  const requestUrl = `${TTP_API_URL}/survey/survey/${surveySlug}/thank-screen`;

  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('_method', 'delete');

  return axios.post(requestUrl, formData);
};

export const removeMediaBanner = (token, surveySlug) => {
  const requestUrl = `${TTP_API_URL}/survey/survey/${surveySlug}/banner`;

  const formData = new FormData();

  formData.append('access_token', token);
  formData.append('_method', 'delete');

  return axios.post(requestUrl, formData);
};
