import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getUsedSurveys, participate, cancelParticipate } from 'thunks';
import { setSurveyPaginationPage } from 'actions';
import LibraryList from './LibraryList';

const MyLibrary = (props) => {
  const {
    currentTab,
    getUsedSurveys,
    surveys: { items, fetching, fetched, nbResult, paginationPage },
    currentClientId,
    participate,
    cancelParticipate,
    goToMyLibrary,
    goToPublicLibrary,
    lng,
  } = props;

  useEffect(() => {
    fetchLibrarySurveys();
    return cleanSurveys;
  }, [currentTab, paginationPage]);

  const cleanSurveys = () => { };
  const fetchLibrarySurveys = () => {
    getUsedSurveys();
  };

  const params = {
    surveys: items,
    fetching,
    fetched,
    nbResult,
    paginationPage,
    currentClientId,
    lng,
    isActive: currentTab == 'MYLIBRARY',
    isUsed: true,
    participate,
    cancelParticipate,
    goToMyLibrary,
    goToPublicLibrary,
    setPaginationPage: props.setSurveyPaginationPage
  };

  return <LibraryList {...params} />;
};

const mapStateToProps = state => ({
  surveys: state.surveys.list,
  lng: state.params.lng,
  currentClientId: state.auth.currentClient?.id ?? 0,
});

const mapDispatchToProps = {
  getUsedSurveys,
  participate,
  cancelParticipate,
  setSurveyPaginationPage
};
export default connect(mapStateToProps, mapDispatchToProps)(MyLibrary);
