import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addDimension,
  addQuestion,
  updateSurveyDescription,
  updateDimensionDescription,
  updateWelcomeMessage,
} from 'actions';
import { EditorState } from 'draft-js';
import { QUESTIONS } from 'SurveyConfig';
import {
  generateQuestion,
  generateDimension,
} from '../../services/surveyUtils';
import { OPEN_QUESTIONS, PORTAL_QUESTIONS } from '../../config/Common';

class ToolbarItem extends Component {
  onClick(disabled) {
    if (!disabled) {
      switch (this.props.data.key) {
        case 'DIMENSION': {
          const {
            survey: { type },
          } = this.props.surveys;
          this.props.addDimension(generateDimension(type));
          break;
        }
        case 'DESCRIPTION': {
          const {
            updateSurveyDescription,
            updateDimensionDescription,
            surveys,
          } = this.props;
          const surveyDescription = surveys.survey.description;
          const { current, language } = surveys;
          const { currentDimensionIndex } = current;
          if (currentDimensionIndex === -1 && surveyDescription == null) {
            updateSurveyDescription(language, EditorState.createEmpty());
            break;
          } else if (currentDimensionIndex !== -1) {
            updateDimensionDescription('');
          }
          break;
        }
        case 'WELCOME_MESSAGE': {
          const { updateWelcomeMessage, language } = this.props;
          updateWelcomeMessage(language, EditorState.createEmpty());
          break;
        }
        default: {
          const { data, surveys } = this.props;

          this.props.addQuestion(
            generateQuestion(data.key, surveys.survey.type),
          );
        }
      }
    }
  }

  render() {
    const {
      survey,
      current: { currentDimensionIndex },
    } = this.props.surveys;
    const { color, key, icon, name } = this.props.data;
    const surveyType = survey.type ?? 'CUSTOM';

    let disabled = false;
    if (surveyType == 'SATISFACTION' && OPEN_QUESTIONS.indexOf(key) !== -1) {
      disabled = true;
    } else if (surveyType == 'QUIZ' && QUESTIONS.hasOwnProperty(key)) {
      disabled = key !== 'SINGLE_CHOICE';
    } else if (surveyType == 'PORTAL') {
      if (key == 'DIMENSION') {
        disabled = survey.dimensions && survey.dimensions.length > 0;
      } else if (QUESTIONS.hasOwnProperty(key)) {
        disabled =
          PORTAL_QUESTIONS.indexOf(key) === -1 ||
          survey.dimensions?.[0]?.questions?.length > 0;
      }
    }

    if (currentDimensionIndex === 0 && !disabled && key !== 'DIMENSION') {
      disabled = survey.dimensions?.[0]?.isProfile ?? false;
    }

    disabled =
      disabled ||
      (currentDimensionIndex == -1 && QUESTIONS.hasOwnProperty(key));

    const disabledClass = disabled && 'disabled-link';
    const style = { backgroundColor: color };

    return (
      <div
        className={`toolbar-list-item ${disabledClass}`}
        onClick={() => this.onClick(disabled)}
      >
        <div className={`block-wrapper ${disabledClass}`}>
          <div className={`block-icon ${disabledClass}`} style={style}>
            <div className="_icon ">
              <span>
                <i className={icon} />
              </span>
            </div>
          </div>
          <div className={`block-title ${disabledClass}`}>{name}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  surveys: state.surveys.list,
});

const mapDispatchToProps = {
  addDimension,
  addQuestion,
  updateSurveyDescription,
  updateDimensionDescription,
  updateWelcomeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarItem);
