import React, { Component } from 'react';
import _ from 'i18n';
import shortId from 'shortid';
import DimensionPreview from './DimensionPreview';
import DisplayErrors from './DisplayErrors';

class DimensionPreviewList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnswerInvalid: false,
      isMissingAnswer: false,
    };
  }

  componentDidMount() {
    const scrollPreview = document.querySelector('#scrollPreview');
    scrollPreview.className = `${scrollPreview.className} preview--center`;

    if (this.props.missingQuestion != null) {
      setTimeout(() => {
        this.props.scrollToMissingQuestion();
      }, 0);
    }
  }

  componentWillUnmount() {
    const scrollPreview = document.querySelector('#scrollPreview');
    scrollPreview.className = scrollPreview.className.replace('preview--center', '');
    this.props.resetListRefs();
  }

  displayErrors() {
    const { isAnswerInvalid, isMissingAnswer } = this.state;
    const listErrors = [];
    if (isAnswerInvalid) {
      listErrors.push(
        <DisplayErrors message={_('answers_not_valid_message')} key={shortId.generate()} />,
      );
    }
    if (isMissingAnswer) {
      listErrors.push(
        <DisplayErrors message={_('required_answers_message')} key={shortId.generate()} />,
      );
    }

    return listErrors;
  }

  renderDimensions() {
    const { dimensions } = this.props;

    if (!dimensions) {
      return null;
    }

    return dimensions.map((dimension, key) => {
      const uniqueKey = dimension.id != null ? dimension.id : dimension.uid || key;
      return (
        <DimensionPreview
          dimension={dimension}
          key={uniqueKey}
          order={key}
          addRef={this.props.addRef}
          scrollToNext={this.props.scrollToNext}
          removeRef={this.props.removeRef}
        />
      );
    });
  }

  render() {
    return (
      <div className="preview__wrapper__item small-11">
        {this.renderDimensions()}
        <div className="preview__container">{this.displayErrors()}</div>
      </div>
    );
  }
}

export default DimensionPreviewList;
