import React, { Component } from 'react';
import { setCurrentNavPage } from 'actions';
import { fetchStats } from 'thunks';
import _ from 'i18n';
import controlAccess from 'stats/controlAccess';
import NotFoundPage from 'NotFoundPage';
import DimensionSummaryFetching from 'fetching/response-summary/DimensionSummaryFetching';
import DimensionStats from './response-summary/DimensionStats';
import ProfilingFilter from './ProfilingFilter';
import StatsNotFound from './StatsNotFound';

class ResponseSummary extends Component {
  componentDidMount() {
    const { surveySlug, survey: { slug, type, latestInstance }, currentClient, organization } = this.props;

    if (surveySlug == slug && latestInstance?.id) {
      const organizationId = organization ?? currentClient?.id ?? 0;
      this.props.fetchStats(latestInstance ? latestInstance.id : 0, type === 'PORTAL' ? null : organizationId);
    }
  }

  componentDidUpdate(prevProps) {
    const { slug, latestInstance, type } = this.props.survey;
    const { currentClient, surveySlug, organization } = this.props;
    const prevSurvey = prevProps.survey;

    if (surveySlug == slug && (prevSurvey.slug !== slug || prevProps.filter != this.props.filter)) {
      if (latestInstance?.id) {
        const organizationId = organization ?? currentClient?.id ?? 0;
        this.props.fetchStats(latestInstance?.id ?? 0, type === 'PORTAL' ? null : organizationId);
      }
    }
  }

  // TODO use a better way instead of sending oranizationId as null
  getOrganizationId = () => {
    const { currentClient, survey } = this.props;
    const organizationId = (currentClient && currentClient.id);

    switch (survey.type) {
      case 'SATISFACTION': return organizationId || 0;
      case 'OPINION': {
        if (survey.isSectorial) {
          /**
           * if owner
           */
          if (survey.organization && survey.organization.id == organizationId) {
            return null; // we omit organization from filter, to get all responses of all organizations
          }
          return organizationId || 0;
        }

        /**
         * if it's not sectorial, we must return all stats.
         */
        return null;
      }
      default: return null;
    }
  }

  renderList() {
    const { items, nbResult } = this.props.stats;
    const { fetching, fetchingSurvey } = this.props;
    // if ((error != null && error.code == 404) || !stats || stats.length == 0) {
    //   return <NotFoundPage mainClass="small-10" />;
    // }
    if (fetching || fetchingSurvey) {
      return <DimensionSummaryFetching />;
    }
    return items.map((item, key) => (
      <DimensionStats data={item} nbResponses={nbResult} key={key} />
    ));
  }

  render() {
    const {
      lng,
      survey,
      fetched,
      fetching,
      fetchingSurvey,
      fetchedSurvey,
      stats: { nbResult },
      disabled,
      hide
    } = this.props;


    if (hide || disabled) {
      return <NotFoundPage mainClass="small-10" />;
    }

    if (!fetched && !fetching && !fetchingSurvey) {
      return (
        <div className="row">{_('Please wait the results are in preparation')}</div>
      );
    }

    if (nbResult == 0 && !fetching && !fetchingSurvey) {
      return (
        <div className="row">
          <div className="summary__block">
            <StatsNotFound />
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="row summary__wrapper">
          <div className="summary__block summary__header column small-12 responses">
            <span className="text">{_('Total number of responses')}</span>
            <span className="number">{fetching || fetchingSurvey ? '--' : nbResult}</span>
          </div>
          <div className="summary__block column small-12">
            {
              (fetching || fetchingSurvey || !fetchedSurvey)
                ? null
                : <ProfilingFilter survey={survey} language={lng} onFilterApply={this.renderStatsPage} />
            }
          </div>
        </div>
        {this.renderList()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentClient: state.auth.currentClient,
  stats: state.stats.list,
  filter: state.stats.filter,
  error: state.stats.list.error,
  fetching: state.stats.list.fetching,
  fetchingSurvey: state.surveys.list.fetching,
  fetchedSurvey: state.surveys.list.fetched,
  fetched: state.stats.list.fetched,
  lng: state.params.lng,
});

const mapDispatchToProps = { setCurrentNavPage, fetchStats };

export default controlAccess(mapStateToProps, mapDispatchToProps)(ResponseSummary);
