import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCurrentNavPage } from 'actions';
import {
  PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import _ from 'i18n';
import { STATS_SINGLE_COLORS } from 'SurveyConfig';

const RADIAN = Math.PI / 180;

export class BooleanChart extends Component {
  renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" fontSize="14px" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  renderList() {
    const { data, type } = this.props;

    return data && data.map((elem) => {
      switch (elem.value) {
        case 0:
          return ({
            name: type == 'TRUE_FALSE' ? _('I don\'t accept') : _('No'),
            value: elem.count
          });
        case 1:
          return ({
            name: type == 'TRUE_FALSE' ? _('I accept') : _('Yes'),
            value: elem.count
          });
        default: return null;
      }
    });
  }

  render() {
    const startColorIndex = this.props.startColorIndex || 0;
    const data = this.renderList().reverse();

    return (
      <ResponsiveContainer width="100%" height="auto" aspect={5 / 3}>
        <PieChart onMouseEnter={this.onPieEnter}>
          <Legend
            align="left"
            layout="vertical"
            verticalAlign="middle"
            iconType="circle"
          />
          <Tooltip />
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={this.renderCustomizedLabel}
            fill="#8884d8"
            dataKey="value"
          >
            {
              data.map((entry, index) => <Cell fill={STATS_SINGLE_COLORS[(index + startColorIndex) % STATS_SINGLE_COLORS.length]} key={`boolean-chart-item-${index}`} />)
            }
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCurrentNavPage }, dispatch);
}

export default connect(null, mapDispatchToProps)(BooleanChart);
