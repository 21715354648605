import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setCurrentNavPage } from 'actions';
import _ from 'lodash';
import { getTextByLanguage } from 'surveyUtils';
import RatingStats from './RatingStats';
import ScaleStats from './ScaleStats';
import BooleanStats from './BooleanStats';
import MultipleChoiceStats from './MultipleChoiceStats';
import InputValueStats from './InputStats';

class DimensionStats extends Component {
  renderList() {
    const { stats } = this.props.data;
    const { nbResponses } = this.props;
    return _.map(stats, (data, key) => {
      switch (data.question.type) {
        case 'RATING':
          return <RatingStats data={data} nbResponses={nbResponses} key={key} />;
        case 'OPINION_SCALE':
          return <ScaleStats data={data} nbResponses={nbResponses} key={key} />;
        case 'YES_NO':
        case 'TRUE_FALSE':
          return <BooleanStats data={data} nbResponses={nbResponses} key={key} />;
        case 'SINGLE_CHOICE':
        case 'RATING_CHOICE':
        case 'MULTIPLE_CHOICE':
          return <MultipleChoiceStats data={data} nbResponses={nbResponses} key={key} />;
        case 'TEXT':
        case 'NUMBER':
          return <InputValueStats data={data} nbResponses={nbResponses} key={key} />;
        default:
          return null;
      }
    });
  }

  render() {
    const { lng } = this.props;
    const value = this.props.data.dimension.title;

    return (
      <>
        <div className="row">
          <div className="summary__block summary__dimension column small-12">
            <span className="title column">{getTextByLanguage(value, lng)}</span>
          </div>
        </div>


        <div className="row summary__wrapper">
          <div className="summary__block">
            <div className="row medium-up-1 large-up-2 small-up-1">
              {this.renderList()}
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCurrentNavPage }, dispatch);
}

const mapStateToProps = state => ({
  lng: state.params.lng,
});

export default connect(mapStateToProps, mapDispatchToProps)(DimensionStats);
