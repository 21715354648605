import React, { useState, useEffect, useRef } from 'react';
import styles from './SurveyThemes.module.scss';
import BackgroundItem from './BackgroundItem';

export const getHeight = element =>
  (element &&
    (element.scrollHeight || element.clientHeight || element.offsetHeight)) ||
  0;

export default function SurveyBackgroundList({
  data,
  title,
  onBackgroundSelected,
  isOpen,
  surveyTheme,
}) {
  const [showContent, setShowContent] = useState(isOpen ?? false);
  /**
   * Pictures takes time to load, this variable is used to reduce the time of rendering items
   * Items needs to be rendered all the time, to perform a smouth transition
   **/
  const [alwaysOn, setAlwaysOn] = useState(isOpen ?? false);
  const [contentHeight, setContentHeight] = useState(0);
  const contentWrapper = useRef(null);

  useEffect(() => {
    if (alwaysOn) {
      setContentHeight(getHeight(contentWrapper.current));
    }
  }, [alwaysOn]);

  const handleClick = () => {
    if (!alwaysOn && !showContent) {
      setAlwaysOn(true);
    }
    setShowContent(!showContent);
  };

  return (
    <div className={styles.background_list}>
      <div className={styles.background_title_wrapper}>
        <div className={styles.background_title}>{title}</div>
        <span
          className={`${styles.background_show_icon} icon`}
          onClick={handleClick}
        >
          <i
            className={`${showContent ? 'icon-arrow-up' : 'icon-arrow-down'}`}
          />
        </span>
      </div>
      <div
        ref={contentWrapper}
        className={styles.background_content}
        style={{ height: showContent ? `calc(${contentHeight}px + 1rem)` : 0 }}
      >
        <div className={styles.list}>
          {alwaysOn &&
            data.map(url => {
              const isSelected =
                url === surveyTheme?.background?.backgroundImage;

              return (
                <div className={styles.survey_themes__wrapper}>
                  <BackgroundItem
                    key={url}
                    backgroundUrl={url}
                    onClick={() =>
                      onBackgroundSelected(!isSelected ? url : null)
                    }
                    isSelected={isSelected}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
