import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionRankingFetching from '../fetching/quiz/QuestionRankingFetching';

class QuestionRanking extends Component {
  render() {
    const { result, fetching, isBilingual } = this.props;

    if (fetching) {
      return <QuestionRankingFetching />;
    }

    const data = Array.isArray(result) ? result : [];

    return (
      <div className={`quiz-preview__question question-ranking ${isBilingual ? 'bilingual' : 'row'}`}>
        {
          data.slice(0, 5).map(({ user, score }, key) => (
            <div className="user-wrapper" id={`participant-${user.id}-${key}`} key={user.id}>
              <span className="name">{user.fullName}</span>
              <span className="score">{score || 0}</span>
            </div>
          ))
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  result: state.quiz.result,
  fetching: state.quiz.resultFetching,
});
export default connect(mapStateToProps)(QuestionRanking);
