import { isIE11, isEdge, getWindowHeight } from 'Common';

export const getScrollTop = (element = null) => {
  if (element) return element.scrollTop;
  return (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
};

export const getHeight = element => (element && (element.clientHeight || element.offsetHeight || element.scrollHeight)) || 0;

export const doScrollBody = (to) => {
  if (isIE11 || isEdge) {
    window.scrollTo(0, to);
  } else {
    window.scrollTo(
      {
        top: to,
        behavior: 'smooth'
      }
    );
  }
};

export const doScrollElement = (to, element) => { element.scrollTop = to; };

export const scrollTo = (to, duration, element = null, doScroll = doScrollBody) => {
  const start = getScrollTop(element);
  const change = to - start;
  let currentTime = 0;
  const increment = 30;

  const animateScroll = () => {
    currentTime += increment;
    const val = Math.easeInOutQuad(currentTime, start, change, duration);
    doScroll(val, element);
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };

  if (element != null || isIE11 || isEdge) {
    animateScroll();
  } else {
    doScrollBody(to);
  }
};

// t = current time
// b = start value
// c = change in value
// d = duration
Math.easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return c / 2 * t * t + b;
  t--;
  return -c / 2 * (t * (t - 2) - 1) + b;
};

export const getOverlayZone = x => (x * getWindowHeight()) / 100;

export const getOffset = (el) => {
  let x = 0; let y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    x += el.offsetLeft - el.scrollLeft;
    y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: y, left: x };
};

export const isElementInViewport = (el, scrollTop, offset) => {
  const docViewTop = scrollTop;
  const docViewBottom = docViewTop + getWindowHeight();
  const elemTop = el.offsetTop;
  const elemBottom = elemTop + getHeight(el);

  return !((docViewBottom - elemTop) < 0 || (elemBottom - docViewTop - offset < 0));
};

/**
 * calculate the distance betwen the {elem} and the {center} of the page
 *
 * returned negative value, when the element is placed before the center of Preview
 * returned positive value, when the element is placed after the center of Preview
 * returned zero, when the element is on the center
 */
export const isElementInCenterPreview = (elem, center) => {
  const elemTop = elem.offsetTop;
  const elemBottom = elemTop + getHeight(elem);

  if (elemBottom < center) {
    return elemBottom - center;
  }

  if (elemTop > center) {
    return elemTop - center;
  }

  return 0;
};
